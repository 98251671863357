import DetailsText from "../DetailsText";
import styles from './Card.module.scss'

const Card = ({ children, details }) => (
  <section className={styles.card}>
    <div className={styles.cardContent}>{children}</div>
    {details && <DetailsText className={styles.details}>{details}</DetailsText>}
  </section>
);

export default Card;