import React, { useContext } from 'react';
import useCachedData from '../hooks/useCachedData';


export const DictionaryContext = React.createContext({});

/**
 * Wraps a section of the application in a react context and provides the dictionary.
 */
export const DictionaryProvider = ({ children }) => {
  const { data: dictionary } = useCachedData('/content/dictionary');

  return (
    <DictionaryContext.Provider value={dictionary}>
      {children}
    </DictionaryContext.Provider>
  )
}

/**
 * Takes a value and gets the dictionary translation from the dictionary context
 * The common use-case for this is displaying a user's select field choice in Preview mode.
 * @param {String} value - a value of a select field that has a more user-readable version in the dictionary
 */
export const dictionaryValue = (term) => {
  const dictionary = useContext(DictionaryContext)

  if (term) {
    return dictionary && dictionary[term] || term;
  }

  return '';
}

export const DictionaryConsumer = ({ term }) => {
  const dictionary = useContext(DictionaryContext);

  if (term) {
    return dictionary && dictionary[term] || term;
  }

  return null;
}