import { Form } from 'react-final-form';
import Button from '../../../buttons/Button';
import TextArea from '../../../Form/TextArea'
import Modal from '../../../Modal';
import ModalFooter from '../../../Modal/ModalFooter';

const DenyActionRequestModal = ({ handleRejectRequest, onClose }) => {
  return (
    <Modal onClose={onClose} title='Reject Action'>
      <Form onSubmit={handleRejectRequest} subscription={{}}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <p>Please explain why this action cannot be approved. The action will be returned to the Draft status, and we will send the submitter an email with your explanation.</p>
            <TextArea name='reason' />
            <ModalFooter>
              <Button type="submit" primary>SEND</Button>
              <Button onClick={onClose} secondary>CANCEL</Button>
            </ModalFooter>
          </form>
        )}
      </Form>
    </Modal>
  );
}

export default DenyActionRequestModal;