import React from 'react';
import { Field, useField } from 'react-final-form';
import AddButton from '../../buttons/AddButton';
import CloseButton from '../../buttons/CloseButton';
import DateField from '../../Form/DateField';
import FieldArray from '../../Form/FieldArray';
import TextField from '../../Form/TextField';
import Label from '../../Form/Label';
import UploadDocsField from '../../Form/UploadDocsField';
import Box from '../../Box';
import Grid from '../../Grid';
import DocumentList from '../../DocumentList';
import styles from './TwoLetterCoord.module.scss';

const TwoLetterCoordEdit = ({ barPhase, deleteFile }) => {
  const { input: { value: docs } } = useField('twoLetterCoord.docs');
  const esss = docs ? docs.filter((d) => d.type === 'esss') : [];
  const mfr = docs ? docs.filter((d) => d.type === 'mfr') : [];
  const briefing = docs ? docs.filter((d) => d.type === 'briefing') : [];
  const cnp = docs ? docs.filter((d) => d.type === 'cnppag') : [];
  const env = docs ? docs.filter((d) => d.type === 'environmental') : [];
  const additional = docs ? docs.filter((d) => d.type === 'additional') : [];
  const adjCoordPackage = docs ? docs.filter((d) => d.type === 'adjCoordPackage') : [];


  const templateColumns = [5, '2fr 2.5fr 2.5fr 3fr 3fr'];
  return (
    <div>
      <Grid columns={[2, '6fr 1fr']}>
        <Box>
          <Label text='Last Review Notes' />
        </Box>
        <Box>
          <Label text='Last Review Date' />
        </Box>
        <TextField name={'twoLetterCoord.lastReviewNotes'} />
        <DateField name={'twoLetterCoord.lastReviewDate'} />
        <Field name='twoLetterCoord.phase' component='input' type='hidden' initialValue={barPhase} />
      </Grid>

      <FieldArray name={'twoLetterCoord.endorsements'} >
      {({ fields, add, isMaxLength, isMinLength, remove }) => (
        <>
        <Grid columns={templateColumns}>
          <Box>
            <Label text='Sent To' />
          </Box>
          <Box>
            <Label text='Sent On' />
          </Box>
          <Box>
            <Label text='Endorsed On' />
          </Box>
          <Box>
            <Label text='Endorsed By' />
          </Box>
          <Box>
            <Label text='Notes' />
          </Box>
        </Grid>
        {fields.map((name, index) => (
            <Grid key={name} columns={templateColumns} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
              <TextField name={`${name}.sentTo`} />
              <DateField name={`${name}.sentOn`} />
              <DateField name={`${name}.endorsedOn`} />
              <TextField name={`${name}.endorsedBy`} />
              <TextField name={`${name}.notes`} />
            </Grid>
          ))}
          {!isMaxLength && <Grid><AddButton onClick={() => add()} secondary>Add Row</AddButton></Grid>}
        </>
      )}
      </FieldArray>

      <FieldArray name={'twoLetterCoord.docs'} initialValue={docs} minLength={0}>
      {({ fields, add, remove }) => {
        return(
        <>
          <DocumentList docList={esss} title='ESSS' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'twoLetterCoord.docs'} add={add} remove={remove} fields={fields} docType='esss' packageType='twoLetterCoord' />
          <DocumentList docList={mfr} title='MFR' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'twoLetterCoord.docs'} add={add} remove={remove} fields={fields} docType='mfr' packageType='twoLetterCoord' />
          <DocumentList docList={briefing} title='Briefing' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'twoLetterCoord.docs'} add={add} remove={remove} fields={fields} docType='briefing' packageType='twoLetterCoord' />
          <DocumentList docList={cnp} title='CNP/PAG' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'twoLetterCoord.docs'} add={add} remove={remove} fields={fields} docType='cnppag' packageType='twoLetterCoord' />
          <DocumentList docList={env} title='Environmental Documents' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'twoLetterCoord.docs'} add={add} remove={remove} fields={fields} docType='environmental' packageType='twoLetterCoord' />
          <DocumentList docList={additional} title='Additional Documents' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'twoLetterCoord.docs'} add={add} remove={remove} fields={fields} docType='additional' packageType='twoLetterCoord' />

          <hr />

          <DocumentList docList={adjCoordPackage} title='Adjudicated Coordination Package' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'twoLetterCoord.docs'} add={add} remove={remove} fields={fields} docType='adjCoordPackage' packageType='twoLetterCoord' />


        </>
      )}}
      </FieldArray>
    </div>
  );
}

export default TwoLetterCoordEdit;