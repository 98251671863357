import React from 'react';
import TextField from '../../Form/TextField';
import Grid from '../../Grid';
import PointsField from '../basingCriteria/PointsField';
import NumberRangeField from '../../Form/NumberRangeField';
import FieldArray from '../../Form/FieldArray';
import ReadOnlyField from '../../Form/ReadOnlyField';
import Label from '../../Form/Label';
import styles from './BasingCriteriaDefinition.module.scss';
import ReadOnlyFacilityField from '../../Form/ReadOnlyFacilityField';

const RequirementsDefinition = ({ name, initialValue, typeKey = 'type' }) => {
  const templateColumns = [3, '22fr 4fr 3fr'];

  const getRequirement = (name, requirementValue) => (
    <Grid columns={templateColumns}>
      {typeKey === 'facility' ? (
        <ReadOnlyFacilityField name={name} />
      ) : (
        <h3 className={styles.heading}>{requirementValue?.label || requirementValue}</h3>
      )}

      <PointsField name={`${name}.point`} readOnly />
    </Grid>
  )

  const getSubRequirements = (name) => (
    <FieldArray name={name}>
      {({ fields }) => (
        <>
          {fields.map((name) => (
            <React.Fragment key={name}>
              <Grid columns={templateColumns}>
                <h4 className={styles.subHeading}><ReadOnlyField name={`${name}.subRequirement`} /></h4>
                <PointsField name={`${name}.point`} readOnly />
              </Grid>
              {getFields(name)}
            </React.Fragment>
          ))}
        </>
      )}
    </FieldArray>
  )

  const getFields = (name) => (
    <Grid key={name} columns={templateColumns} className={styles.grid}>
      <TextField name={`${name}.optimalValueDescription`} placeholder='Optimal definition'/>
      <NumberRangeField name={`${name}.optimalValue`} />
      <Label className={styles.label} text='Optimal' />

      <TextField name={`${name}.suitableValueDescription`} placeholder='Suitable definition'/>
      <NumberRangeField name={`${name}.suitableValue`} />
      <Label className={styles.label} text='Suitable' />

      <TextField name={`${name}.limitsValueDescription`} placeholder='Limits definition'/>
      <NumberRangeField name={`${name}.limitsValue`} />
      <Label className={styles.label} text='Limits' />
    </Grid>
  )

  return (
    <Grid>
      <FieldArray name={name} initialValue={initialValue}>
        {({ fields }) => fields.map((name, index) => {
          const field = fields.value[index];
          return (
            <React.Fragment key={name}>
              {field.requirement && getRequirement(name, field.requirement)}
              {field.subRequirements && field.subRequirements.length > 0 && getSubRequirements(`${name}.subRequirements`)}
              {field.definition && getRequirement(name, field.definition)}
              {field[typeKey] && getRequirement(name, field[typeKey])}
              {field.subRequirements && field.subRequirements.length === 0 && getFields(name)}
              {(field.definition || field[typeKey]) && getFields(name)}
            </React.Fragment>
          );
        })}
      </FieldArray>
    </Grid>
  );
}

export default RequirementsDefinition;
