import { useEffect } from 'react';
import NumberField from '../../Form/NumberField';
import Label from '../../Form/Label';
import { Field, useForm } from 'react-final-form';
import styles from './ManpowerFields.module.scss';
import useFields from '../../hooks/useFields';

const ManpowerFields = ({ label, proposed }) => {
  const index = proposed ? 1 : 0;
  const currentOrProposed = proposed ? 'proposed' : 'current';
  
  const names = {
    ftOfficer: `manpower[${index}].ftOfficer`,
    ftEnlisted: `manpower[${index}].ftEnlisted`,
    ftCivilian: `manpower[${index}].ftCivilian`,
    ftContractor: `manpower[${index}].ftContractor`,
    ftTotal: `manpower[${index}].ftTotal`,
    nonftOfficer: `manpower[${index}].nonftOfficer`,
    nonftEnlisted: `manpower[${index}].nonftEnlisted`,
    nonftCivilian: `manpower[${index}].nonftCivilian`,
    nonftContractor: `manpower[${index}].nonftContractor`,
    nonftTotal: `manpower[${index}].nonftTotal`,
    subtotalOfficer: `manpower[${index}].subtotalOfficer`,
    subtotalEnlisted: `manpower[${index}].subtotalEnlisted`,
    subtotalCivilian: `manpower[${index}].subtotalCivilian`,
    subtotalContractor: `manpower[${index}].subtotalContractor`,
    subtotalTotal: `manpower[${index}].subtotalTotal`,
    AvgDailyStudentLoad: `manpower[${index}].AvgDailyStudentLoad`,
    baseSupportTail: `manpower[${index}].baseSupportTail`,
    currentOrProposed: `manpower[${index}].currentOrProposed`,
    grandTotal: `manpower[${index}].grandTotal`,
  }
  
  const form = useForm();
  
  // totals fields  
  const ftTotalFields = useFields([names.ftOfficer, names.ftEnlisted, names.ftCivilian, names.ftContractor]);
  const nonftTotalFields = useFields([names.nonftOfficer, names.nonftEnlisted, names.nonftCivilian, names.nonftContractor]);
  const subtotalOfficerFields = useFields([names.ftOfficer, names.nonftOfficer]);
  const subtotalEnlistedFields = useFields([names.ftEnlisted, names.nonftEnlisted]);
  const subtotalCivilianFields = useFields([names.ftCivilian, names.nonftCivilian]);
  const subtotalContractorFields = useFields([names.ftContractor, names.nonftContractor]);
  const subtotalTotalFields = useFields([names.ftTotal, names.nonftTotal]);
  const grandTotalFields = useFields([names.subtotalTotal, names.AvgDailyStudentLoad, names.baseSupportTail]);

  const calcFieldsTotal = (fieldState, totalFieldName) => {
    let total = 0;
    Object.values(fieldState).forEach(field => {
      const value = parseInt(field.input.value, 10);
      if (!isNaN(value)) {
        total += parseInt(value, 10);
      }
    });
    form.change(totalFieldName, total);
  }
  
  useEffect(() => {
    calcFieldsTotal(ftTotalFields, names.ftTotal);
  }, [ftTotalFields]);

  useEffect(() => {
    calcFieldsTotal(nonftTotalFields, names.nonftTotal);
  }, [nonftTotalFields]);
  
  useEffect(() => {
    calcFieldsTotal(subtotalOfficerFields, names.subtotalOfficer);
  }, [subtotalOfficerFields]);
  
  useEffect(() => {
    calcFieldsTotal(subtotalEnlistedFields, names.subtotalEnlisted);
  }, [subtotalEnlistedFields]);
  
  useEffect(() => {
    calcFieldsTotal(subtotalCivilianFields, names.subtotalCivilian);
  }, [subtotalCivilianFields]);
  
  useEffect(() => {
    calcFieldsTotal(subtotalContractorFields, names.subtotalContractor);
  }, [subtotalContractorFields]);
  
  useEffect(() => {
    calcFieldsTotal(subtotalTotalFields, names.subtotalTotal);
  }, [subtotalTotalFields]);
  
  useEffect(() => {
    calcFieldsTotal(grandTotalFields, names.grandTotal);
  }, [grandTotalFields]);

  return (
    <>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td><Label text={label} /></td>
            <td>Officer</td>
            <td>Enlisted</td>
            <td>Civilian</td>
            <td>Contractor</td>
            <td>Total</td>
          </tr>
          <tr>
            <td><span>Full Time: Active Duty/Res/Guard</span></td>
            <td><NumberField name={names.ftOfficer} /></td>
            <td><NumberField name={names.ftEnlisted} /></td>
            <td><NumberField name={names.ftCivilian} /></td>
            <td><NumberField name={names.ftContractor} /></td>
            <td><NumberField name={names.ftTotal} readOnly tabIndex={-1} /></td>
          </tr>
          <tr>
            <td><span>Part Time: Res/Guard</span></td>
            <td><NumberField name={names.nonftOfficer} /></td>
            <td><NumberField name={names.nonftEnlisted} /></td>
            <td><NumberField name={names.nonftCivilian} /></td>
            <td><NumberField name={names.nonftContractor} /></td>
            <td><NumberField name={names.nonftTotal} readOnly tabIndex={-1} /></td>
          </tr>
          <tr>
            <td><span>Subtotal</span></td>
            <td><NumberField name={names.subtotalOfficer} readOnly tabIndex={-1} /></td>
            <td><NumberField name={names.subtotalEnlisted} readOnly tabIndex={-1} /></td>
            <td><NumberField name={names.subtotalCivilian} readOnly tabIndex={-1} /></td>
            <td><NumberField name={names.subtotalContractor} readOnly tabIndex={-1} /></td>
            <td><NumberField name={names.subtotalTotal} readOnly tabIndex={-1} /></td>
          </tr>
          <tr>
            <td colSpan='5'><span>Average Daily Student Load</span></td>
            <td><NumberField name={names.AvgDailyStudentLoad} /></td>
          </tr>
          {proposed && (
            <tr>
              <td colSpan='5'><span>Base Support Tail</span></td>
              <td><NumberField name={names.baseSupportTail} /></td>
            </tr>
          )}
          <tr>
            <td colSpan='5'><span>Grand Total</span></td>
            <td><NumberField name={names.grandTotal} readOnly tabIndex={-1} /></td>
          </tr>
        </tbody>
      </table>
      <Field component='input' name={names.currentOrProposed} type='hidden' initialValue={currentOrProposed} />
    </>
  );
}

export default ManpowerFields;
