import DropDown from "../shared/DropDown";
import styles from './BarLanding.module.scss'
import useCachedData from '../hooks/useCachedData';
import _ from 'lodash';
import useFetchSAFOptions from '../hooks/useFetchSAFOptions';

const BasingActionsFilter = (props) => {
    const {change, resetFilters, filters, numSelected} = props;
    const { data: basingOffices = {} } = useCachedData('/content/leadBasingOffices');
    const { data: steps = {} } = useCachedData('/content/stepOptions');
    const { data: phases = {} } = useCachedData('/content/phaseOptions');
    const { data: status = {} } = useCachedData('/content/status');
    const safs = useFetchSAFOptions();
    const basingActions = [
        {label: 'Watched Basing Actions', value:'isWatched'},
    ];

    const findSelectedValue = (filterKey, options = []) => options.find(option => option.value === filters[filterKey]) || null;

    return(
        <>
            <span className={styles.tableHeaderText}>{numSelected} {_.values(filters).length > 0 && !_.values(filters).every((f) => _.isNull(f) || f === '') ? `Selected Actions` : 'Total Actions'}</span>
            <DropDown className={styles.filterDropdown} name= {'phase'} headerLabel={'All Phases'}
                value={findSelectedValue('phase', phases.options)}
                setter={change} selectOptions={phases.options || []} />
            <DropDown className={styles.filterDropdown} name={'step'} headerLabel={'All Steps'}
                value={findSelectedValue('step', steps.options)}
                setter={change} selectOptions={steps.options || []} />
            <DropDown className={styles.filterDropdown} name={'status'} headerLabel={'All Statuses'}
                value={findSelectedValue('status', status.options)}
                setter={change} selectOptions={status.options || []} />
            <DropDown className={styles.filterDropdown} name={'leadBasingOffice'} headerLabel={'All Basing Offices'}
                value={findSelectedValue('leadBasingOffice', basingOffices.options)}
                setter={change} selectOptions={basingOffices.options || []} />
            <DropDown className={styles.filterDropdown} name={'safActionOfficer'} headerLabel={'All SAF AOs'}
                value={findSelectedValue('safActionOfficer', safs)}
                setter={change} selectOptions={safs} />
            <DropDown className={styles.filterDropdown} name={'basingActions'} headerLabel={'All Actions'}
                value={findSelectedValue('basingActions', basingActions)}
                setter={change} selectOptions={basingActions} />

            <span className={styles.resetFilters} onClick={resetFilters}>Reset Filters</span>
        </>
    )
}

export default BasingActionsFilter;

