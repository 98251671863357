import React, { useState, useContext } from 'react';
import { Form } from 'react-final-form';
import axios from '../../utils/sharedAxios';
import { AuthContext } from './Authentication';
import ContactFields, { validateContactFields } from '../ContactFields';
import Modal from '../../components/Modal';
import Button from '../../components/buttons/Button';
import styles from './Register.module.scss';
import ModalFooter from '../Modal/ModalFooter';
import ErrorText from '../ErrorText';
import Logo from '../svg/Logo';

// registration form for new users
const Register = () => {
  const {user} = useContext(AuthContext);
  if(user.status === 'APPROVED') {
    window.location.replace('/dashboard');
  }
  const [success, setSuccess] = useState(user.role === 'PENDINGAPPROVAL');
  const [showModal, setShowModal] = useState(false);

  const onSubmit = async (values) => {
    if(user && user.uuid){
      const result = await axios.post('/users/register', {...values, uuid: user.uuid});
        if (_.get(result, 'data.errors')) {
            return result.data.errors;
        } else {
            // done registering, display success page
            setSuccess(true);
        }
    }
  }

  if(!success){
    return <div className={styles.registerPage}>
      {showModal &&
      <Modal className={styles.modal} onClose={() => setShowModal(false)} title='Register'>
        <Form onSubmit={onSubmit} subscription={{ submitFailed: true, error: true, submitErrors: true, submitting: true }} validate={validateContactFields}>
          {({ submitErrors, handleSubmit, submitFailed }) => (
            <form onSubmit={handleSubmit} noValidate>
              <ContactFields />
              {submitFailed && submitErrors && <ErrorText>{Object.values(submitErrors).map(value => <span>{value}</span>)}</ErrorText>}

              <ModalFooter>
                <div className={styles.modalButtons}>
                  <Button type='submit' primary>Register</Button>
                </div>
              </ModalFooter>
            </form>
          )}
        </Form>
      </Modal>}

      <div>
        <p className={styles.welcome}>WELCOME TO <span className={styles.saber}> SABER</span></p>
        <p className={styles.saberMeaning}>The Strategic Air and Space Basing Enterprise Repository</p>
        <p className={styles.registerToStart}>Register to get started</p>
        <Button onClick={() => setShowModal(true)} primaryAlt>REGISTER</Button>
        <Logo className={styles.logo} />
      </div>
    </div>
  }

  return(
    <div className={styles.registerPage}>
        <div className={styles.thankYou}>Thank you for registering for a &nbsp;<span className={styles.saber}>SABER</span> account.</div>
        <div className={styles.notified}>An administrator has been notified. You will receive an email when your account has been approved.</div>
        <Logo className={styles.logo} />
    </div>
  )
}

export default Register;
