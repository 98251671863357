import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../Utility/Authentication';
import axios from '../../utils/sharedAxios';
import styles from './SafReviewCheckbox.module.scss';
import CheckBoxSharp from '@material-ui/icons/CheckBoxSharp';
import CheckBoxOutlineBlankSharp from '@material-ui/icons/CheckBoxOutlineBlankSharp';

// appears in the header on Bar Detail and marks if an action needs review or not
const SafReviewCheckbox = ({ barId, isReviewedBySaf, owningOffice }) => {
  const { user } = useContext(AuthContext);
  const [isReviewed, setIsReviewed] = useState(isReviewedBySaf);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async () => {
    setIsLoading(true);
    await axios.get(user.role === 'SAM' ? '/bars/reviewed-by-saf' : '/bars/ready-for-review', { params: { barId, value: !isReviewed } });
    setIsReviewed(!isReviewed);
    setIsLoading(false);
  }

  useEffect(() => {
    setIsReviewed(isReviewedBySaf);
  }, [isReviewedBySaf]);

  const checked = (isReviewed && user.role === 'SAM') || (!isReviewed && user.role === 'ERIN');

  if (user.role === 'SAM' || (user.role === 'ERIN' && user.office === owningOffice)) {
    return (
      <label className={styles.checkboxLabel} htmlFor='readyForSafReview'>
        <span className={styles.checkboxLabelText}>{user.role === 'ERIN' && 'Ready for SAF Review'}{user.role === 'SAM' && 'Reviewed by SAF'}</span>
        {checked ? <CheckBoxSharp className={styles.checkboxIcon} /> : <CheckBoxOutlineBlankSharp className={styles.checkboxIcon} />}
        <input className={styles.checkboxInput} name='readyForSafReview' id='readyForSafReview' type='checkbox' onChange={handleChange} checked={checked} disabled={isLoading} />
      </label>
    )
  }

  return null;
}

export default SafReviewCheckbox;