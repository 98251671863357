export const downloadCsv = (filename, data) => {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(data));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }

export const downloadPdf = (filename, data) => {
    // window.open(window.URL.createObjectURL(data), "_blank");
    let element = document.createElement('a');
    element.setAttribute('href', window.URL.createObjectURL(data));
    
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }