import Anchor from '../Anchor';
import useCachedData from '../hooks/useCachedData';

const endpoints = {
  document: {
    dataEndpoint: '/documents/document',
    downloadEndpoint: '/documents/download'
  },
  resource: {
    dataEndpoint: '/resources/getResource',
    downloadEndpoint: '/resources/download'
  },
  staticResource: {
    dataEndpoint: '',
    downloadEndpoint: '/staticResource/download'
  }
}
// link to download file
const DisplayFile = ({ className, label, uuid, isResource = false }) => {
  const options = {params: { docId: uuid } };
  const endpoint = isResource ? '/resources/getResource' : '/documents/document';
  const downloadEndpoint = isResource ? '/resources/download' : '/documents/download';
  const { data: { originalFileName, fileName } = {} } = useCachedData(uuid && endpoint, options);
  const displayName = isResource ? fileName : originalFileName;
  return displayName ? <Anchor className={className} href={`${process.env.REACT_APP_API_URL}${downloadEndpoint}?${new URLSearchParams({docId: uuid, fileName: displayName}).toString()}`} download={displayName} light>{label || displayName}</Anchor> : null;
}

export default DisplayFile;