import React, { useEffect, useState } from 'react';
import { Field, useField } from 'react-final-form';
import FieldArray from '../Form/FieldArray';
import TextField from '../Form/TextField';
import SelectField from '../Form/SelectField';
import Box from '../Box';
import Grid from '../Grid';
import AddButton from '../buttons/AddButton';
import CloseButton from '../buttons/CloseButton';
import PointsField from './basingCriteria/PointsField';
import uniqueId from 'lodash/uniqueId';
import ReadOnlyFacilityField from '../Form/ReadOnlyFacilityField';

const templateColumns = [2, '4fr 1fr'];
const templateColumnsReadOnly = [2, '14fr 3fr'];
const templateColumnsInner = [5, '3fr 2fr 1fr 2fr 1fr'];

const FacilityRow = ({ index, isMinLength, name, readOnly, remove, selectKey, uid }) => {
  const { input: { value } } = useField(name);

  const getQuantityType = () => value?.[selectKey]?.unitType;
  const getAreaType = () => value?.[selectKey]?.areaType;
  const quantityType = getQuantityType();
  const areaType = getAreaType();
  const noQuantity = !quantityType && typeof quantityType === 'boolean';
  const noArea = !areaType && typeof areaType === 'boolean';
  const quantityField = useField(`${name}.quantity`);
  const areaField = useField(`${name}.totalAreaSqft`);
  const disabledQuantityTypeField = readOnly || noQuantity;
  const disableAreaTypeField = readOnly || noArea;

  useEffect(() => {
    noQuantity && quantityField.input.value && quantityField.input.onChange('');
  }, [noQuantity, quantityField.input.value]);

  useEffect(() => {
    noArea && areaField.input.value && areaField.input.onChange('');
  }, [noArea, areaField.input.value]);
  return (
    <Grid columns={readOnly ? templateColumnsReadOnly : templateColumns} absoluteChild={!readOnly && !isMinLength && <CloseButton onClick={remove(index)} />}>
      {readOnly && <Box><ReadOnlyFacilityField name={name} /></Box>}
      {!readOnly && (
        <Grid columns={templateColumnsInner}>
          <Box><SelectField name={`${name}.${selectKey}`} disabled={readOnly} fieldDataEndpoint='/content/facilityRequirement' isSearchable /></Box>
          <Box><TextField name={`${name}.quantity`} disabled={disabledQuantityTypeField} type='number' parse={Number} /></Box>
          <Box>{quantityType}</Box>
          <Box><TextField name={`${name}.totalAreaSqft`} disabled={disableAreaTypeField} type='number' parse={Number} /></Box>
          <Box>{areaType}</Box>
        </Grid>
      )}

      {!readOnly && <Box><TextField name={`${name}.notes`} /></Box>}
      {readOnly && <Box><PointsField name={`${name}.point`} /></Box>}
      <Field component='input' type='hidden' name={`${name}.id`} defaultValue={uid} />
    </Grid>
  );
};

const FacilitiesField = ({ name, readOnly, selectKey = 'facility' }) => {
  const [uid] = useState(uniqueId('tempId__'));

  return (
    <Grid>
      <FieldArray name={name} ignoredKeys={['id']}>
        {({ fields, add, remove, isMaxLength, isMinLength }) => (
          <div>
            {!readOnly && (
              <Grid columns={templateColumns}>
                <Grid columns={templateColumnsInner}>
                  <Box>Type</Box>
                  <Box>Quantity</Box>
                  <Box />
                  <Box>Total Area</Box>
                  <Box />
                </Grid>
                <Box>Notes (Optional)</Box>
              </Grid>
            )}
            {fields.map((name, index) => <FacilityRow key={index} name={name} index={index} readOnly={readOnly} remove={remove} selectKey={selectKey} uid={uid} isMinLength={isMinLength} />)}
            {!isMaxLength && !readOnly && <Grid><AddButton onClick={()=> add()} secondary>Add Requirement</AddButton></Grid>}
          </div>
        )}
      </FieldArray>
    </Grid>
  )
}

export default FacilitiesField;
