import React from 'react';
import Grid from '../../Grid';
import Box from '../../Box';
import RadioField from '../../Form/RadioField';
import SelectField from '../../Form/SelectField';
import Wysiwig from '../../Form/Wysiwyg';
import WeaponsSystemField from './WeaponsSystemField';
import ManpowerFields from './ManpowerFields';
import DetailsText from '../../DetailsText';
import Label from '../../Form/Label';
import TextField from '../../Form/TextField';
import styles from '../../Form/Form.module.scss';
import RichTextDisplay from '../../Form/RichTextDisplay';

const StageSetterEdit = () => {
    return (
        <>
            <h3 className={styles.sectionHeading}>Purpose Statement</h3>
            <Grid columns={[3]}>
                <Box>
                    <SelectField label='Whose approval do you need?' name='needApprovalFrom' fieldDataEndpoint='/content/needApprovalFrom' required/>
                </Box>
            </Grid>

            <Grid columns={[2]}>
                <Box>
                    <TextField label='What do you need approval of?' name='toObtainApprovalOf' required maxLength={150} />
                </Box>
            </Grid>

            <Grid columns={[2, '3fr 1fr']}>
                <Box>
                    <RadioField label='What AFI 10-503 criteria does this basing action meet?' name='AFI_10_503[0]' fieldDataEndpoint='/content/AFI_10_503' required horizontal={false} />
                </Box>
            </Grid>

            <Label text={<h3 className={styles.sectionHeading}>Strategic Narrative</h3>} required />
            <DetailsText>3 to 5 bullets, 30,000 foot view<br/>Include directive or strategic guidance driving the action if available</DetailsText>

            <Wysiwig name='strategicNarrative' />

            <RadioField label='Type of mission:' name='missionType' fieldDataEndpoint='/content/missionTypes' horizontal={false} />

            <h3 className={styles.sectionHeading}>Force Structure</h3>
            <DetailsText>Total increase for weapons system and manpower</DetailsText>

            <Label text='Weapons System' />
            <WeaponsSystemField addButtonText='Add Current' label='Current' name='currentWeaponsSystems' />
            <WeaponsSystemField addButtonText='Add Proposed' label='Proposed' name='proposedWeaponsSystems' proposed />

            <ManpowerFields label='Current Manpower' prefix={'current'} />
            <ManpowerFields label='Proposed Manpower' prefix={'proposed'} proposed />

            <Label text={<h3 className={styles.sectionHeading}>Where is the manpower coming from?</h3>} />
            <DetailsText>
            Describe circumstances around the number of existing manpower authorizations at the proposed location performing the mission today (if applicable), number of personnel relocating, realignment of positions, and POM growth requirement.
            </DetailsText>
            <Wysiwig name='whereManpower' />

        </>
    );

}

export default StageSetterEdit;
