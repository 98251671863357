import React, { useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import cn from 'classnames';
import FacilitiesField from '../FacilitiesField';
import PointsField from './PointsField';
import Label from '../../Form/Label';
import BasingCriteriaArrayField from './BasingCriteriaArrayField';
import SelectField from '../../Form/SelectField';
import Box from '../../Box';
import Grid from '../../Grid';
import TextField from '../../Form/TextField';
import MissionRequirementsPointsField from './MissionRequirementsPointsField';
import useCachedData from '../../hooks/useCachedData';
import Collapsible from '../../Collapsible';
import DetailsText from '../../DetailsText';
import RichTextDisplay from '../../Form/RichTextDisplay';
import styles from './BasingCriteria.module.scss';
import formStyles from '../../Form/Form.module.scss';
import getDecimal from '../../../utils/getDecimal';

const templateColumns = [2, '14fr 3fr'];

const BasingCriteriaEdit = () => {
  const { data: militaryFamilyReadiness } = useCachedData('/content/militaryFamilyReadiness');
  const { data: supportOfMilitaryFamilies } = useCachedData('/content/supportOfMilitaryFamilies');
  const [grandTotalLimit, setGrandTotalLimit] = useState(100);
  const form = useForm();
  const { initialValues, values } = form.getState();
  const { enterpriseDefinition } = values;
  const { input: { value: missionRequirementTotalPoints } } = useField('missionRequirementTotalPoints');
  const { input: { value: capacityTotalPoints } } = useField('capacityTotalPoints');
  const { input: { value: facilityCriteriaTotalPoints } } = useField('facilityCriteriaTotalPoints');
  const { input: { value: facilityRequirements } } = useField('facilityRequirements');
  const { input: { value: baseSupportTailCriteriaTotalPoints } } = useField('baseSupportTailCriteriaTotalPoints');
  const { input: { value: environmentRequirementTotalPoints } } = useField('environmentRequirementTotalPoints');
  const { input: { value: costCriteriaTotalPoints } } = useField('costCriteriaTotalPoints');
  const { input: { value: militaryFamilyReadinessTotalPoints } } = useField('militaryFamilyReadinessTotalPoints');
  const { input: { value: missionCriteriaMilitaryFamilyReadiness } } = useField('missionCriteria.militaryFamilyReadiness');
  const { input: { value: supportOfMilitaryFamiliesTotalPoints } } = useField('supportOfMilitaryFamiliesTotalPoints');
  const { input: { value: basingCriteriaGrandTotalPoints } } = useField('basingCriteriaGrandTotalPoints');
  const { input: { value: costCriteria } } = useField('missionCriteria.costCriteria');
  const { input: { value: additionalCosts } } = useField('missionCriteria.additionalCosts');
  const { input: { value: baseSupportTailCriteria } } = useField('missionCriteria.baseSupportTailCriteria');
  const { input: { value: additionalBaseSupportTailCriteria } } = useField('missionCriteria.additionalBaseSupportTailCriteria');

  // baseSupportTailCriteriaTotalPoints
  useEffect(() => {
    let total = 0;
    baseSupportTailCriteria?.length && baseSupportTailCriteria.forEach(item => total += (item.point ? Number(item.point) : 0));
    additionalBaseSupportTailCriteria?.length && additionalBaseSupportTailCriteria.forEach(item => total += (item.point ? Number(item.point) : 0));

    baseSupportTailCriteriaTotalPoints !== total && form.change('baseSupportTailCriteriaTotalPoints', getDecimal(total));
  }, [baseSupportTailCriteria, additionalBaseSupportTailCriteria]);

  // capacityTotalPoints
  useEffect(() => {
    const total = Number(facilityCriteriaTotalPoints) + Number(baseSupportTailCriteriaTotalPoints);
    capacityTotalPoints !== total && form.change('capacityTotalPoints', getDecimal(total));
  }, [facilityCriteriaTotalPoints, baseSupportTailCriteriaTotalPoints]);

  // facilityCriteriaTotalPoints
  useEffect(() => {
    if (Array.isArray(facilityRequirements) && facilityRequirements.length) {
      let total = 0;
      facilityRequirements.forEach(item => total += (item.point ? Number(item.point) : 0));
      facilityCriteriaTotalPoints !== total && form.change('facilityCriteriaTotalPoints', getDecimal(total));
    }
  }, [facilityRequirements]);

  // costTotalPoints
  useEffect(() => {
    let total = 0;
    costCriteria?.length && costCriteria.forEach(item => total += (item.point ? Number(item.point) : 0));
    additionalCosts?.length && additionalCosts.forEach(item => total += (item.point ? Number(item.point) : 0));

    costCriteriaTotalPoints !== total && form.change('costCriteriaTotalPoints', getDecimal(total));
  }, [costCriteria, additionalCosts]);

  // militaryFamilyReadinessTotalPoints
  useEffect(() => {
    let total = 0;

    if (Array.isArray(missionCriteriaMilitaryFamilyReadiness) && missionCriteriaMilitaryFamilyReadiness.length) {
      missionCriteriaMilitaryFamilyReadiness.forEach(item => total += (item.point ? Number(item.point) : 0));
    }

    total += (Number(supportOfMilitaryFamiliesTotalPoints) || 0);
    militaryFamilyReadinessTotalPoints !== total && form.change('militaryFamilyReadinessTotalPoints', getDecimal(total));
  }, [missionCriteriaMilitaryFamilyReadiness, supportOfMilitaryFamiliesTotalPoints]);

  // supportOfMilitaryFamiliesTotalPoints
  useEffect(() => {
    setGrandTotalLimit(supportOfMilitaryFamiliesTotalPoints > 0 ? 120 : 100)
  }, [supportOfMilitaryFamiliesTotalPoints]);

  // grandTotal
  useEffect(() => {
    let pointsTotal = 0;
    const totals = [
      missionRequirementTotalPoints,
      capacityTotalPoints,
      environmentRequirementTotalPoints,
      costCriteriaTotalPoints,
      militaryFamilyReadinessTotalPoints
    ];

    totals.forEach(value => pointsTotal += Number(value) || 0);

    form.change('basingCriteriaGrandTotalPoints', getDecimal(pointsTotal));
  }, [
    missionRequirementTotalPoints,
    capacityTotalPoints,
    environmentRequirementTotalPoints,
    costCriteriaTotalPoints,
    militaryFamilyReadinessTotalPoints
  ]);

  return (
    <div>
      <h3 className={formStyles.sectionHeading}>Enterprise</h3>
      <Grid columns={templateColumns}>
        <Box><RichTextDisplay html={enterpriseDefinition?.definition} /></Box>

        <Label text='Points' />
      </Grid>

      <Collapsible
        toggle={
          <Grid columns={templateColumns}>
            <h3 className={cn(styles.groupHeading, formStyles.sectionHeading)}>Mission</h3>
            <PointsField name='missionRequirementTotalPoints' readOnly />
          </Grid>
        }>
        <MissionRequirementsPointsField />
      </Collapsible>

      <Collapsible
        toggle={
          <Grid columns={templateColumns}>
            <h3 className={cn(styles.groupHeading, formStyles.sectionHeading)}>Capacity</h3>
            <PointsField name='capacityTotalPoints' readOnly />
          </Grid>
        }>
        <Grid columns={templateColumns}>
          <div className={styles.requirement}><p>Facilities Criteria</p></div>
          <PointsField name='facilityCriteriaTotalPoints' readOnly />
        </Grid>

        <FacilitiesField name='facilityRequirements' readOnly />

        <Grid columns={templateColumns}>
          <Label text='Base Support Tail Criteria' />
          <PointsField name='baseSupportTailCriteriaTotalPoints' readOnly />
        </Grid>

        <BasingCriteriaArrayField name='missionCriteria.baseSupportTailCriteria'
          primaryField={SelectField}
          fieldDataEndpoint='/content/baseSupportTail' />

        <Grid><Label text='Additional Base Support Tail Criteria' /></Grid>
        <BasingCriteriaArrayField name='missionCriteria.additionalBaseSupportTailCriteria'
          minLength={0}
          primaryField={TextField} />
      </Collapsible>

      <Collapsible
        toggle={
          <Grid columns={templateColumns}>
            <h3 className={cn(styles.groupHeading, formStyles.sectionHeading)}>Environmental</h3>
            <PointsField name='environmentRequirementTotalPoints' readOnly />
          </Grid>
        }>
        <BasingCriteriaArrayField name='missionCriteria.environmentalCriteria'
          primaryField={TextField}
          parentTotalName='environmentRequirementTotalPoints' />
      </Collapsible>

      <Collapsible
        toggle={
          <Grid columns={templateColumns}>
            <h3 className={cn(styles.groupHeading, formStyles.sectionHeading)}>Cost</h3>
            <PointsField name='costCriteriaTotalPoints' readOnly />
          </Grid>
        }>
        <BasingCriteriaArrayField name='missionCriteria.costCriteria'
          primaryField={SelectField}
          fieldDataEndpoint='/content/costCriteria' />

        <Grid><Label text='Additional Costs' /></Grid>
        <BasingCriteriaArrayField name='missionCriteria.additionalCosts'
          minLength={0}
          primaryField={TextField} />
      </Collapsible>



      <Collapsible
        toggle={
          <Grid columns={templateColumns}>
            <h3 className={cn(styles.groupHeading, formStyles.sectionHeading)}>Military Family Readiness</h3>
            <PointsField name='militaryFamilyReadinessTotalPoints' readOnly />
          </Grid>
        }>
        <BasingCriteriaArrayField name='missionCriteria.militaryFamilyReadiness'
          disabledCount={2}
          primaryField={TextField}
          minLength={0}
          initialValue={initialValues?.missionCriteria?.militaryFamilyReadiness || militaryFamilyReadiness?.initialValue} />

        <Grid columns={templateColumns}>
          <div>
            <h5>Support of Military Families</h5>
            <DetailsText>Zeroing out both sub-requirements removes 20 points from the over all total.</DetailsText>
          </div>
          <PointsField name='supportOfMilitaryFamiliesTotalPoints' readOnly />
        </Grid>

        <BasingCriteriaArrayField name='missionCriteria.supportOfMilitaryFamilies'
          disabledCount={2}
          primaryField={TextField}
          minLength={0}
          maxLength={2}
          initialValue={initialValues?.missionCriteria?.supportOfMilitaryFamilies || supportOfMilitaryFamilies?.initialValue}
          parentTotalName='supportOfMilitaryFamiliesTotalPoints' />
      </Collapsible>




      <p className={styles.grandTotal}>Total <span className={styles.grandTotalValue}>{basingCriteriaGrandTotalPoints}/{grandTotalLimit}</span></p>
    </div>
  );
}

export default BasingCriteriaEdit;
