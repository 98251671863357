import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-final-form';
import DisplayDate from '../../../DisplayDate';
import PhaseLegend from '../../PhaseLegend';
import useFetchData from '../../../hooks/useFetchData';
import phaseAbbreviations from '../../../../constants/phaseAbbreviations';
import dateFormats from '../../../../constants/dateFormats';

const unscheduled = 'Unscheduled';

const PendingCongressionalNotifications = () => {
  const { data: pendingNotifications } = useFetchData('/reviews/pending-notifications');
  const formApi = useForm();
  const [queue, setQueue] = useState([]);

  useEffect(() => {
    formApi.change('confirmedPendingCongressionalNotifications', 1)
  }, []);

  useEffect(() => {
    if (pendingNotifications) {
      const queueObject = pendingNotifications.reduce((acc, bar) => {
        let day = bar.date ? moment(new Date(bar.date)).format('YYYY-MM-DD') : unscheduled;
        if (!acc[day]) {
          acc[day] = [];
        }

        acc[day].push(bar);

        return acc;
      }, {});

      const queueArray = Object.entries(queueObject)
        .map(([date, bars]) => {
          return ({
            date,
            bars
          });
        });

      // move Unscheduled to the end
      queueArray.sort((a, b) => {
        if (a.date === unscheduled) {
          return 1;
        }
        if (b.date === unscheduled) {
          return -1;
        }
        return 0;
      });

      setQueue(queueArray);
    }
  }, [pendingNotifications]);

  return (
    <>
      {queue.map(({ date, bars }) => (
        <React.Fragment key={date}>
          <h3>{date === unscheduled ? unscheduled : <DisplayDate date={date} format={dateFormats.short}/>}</h3>
          {bars && bars.length > 0 && (
            <ul>
              {bars.map(({ currentPhase, title }, index) => (
                <li key={index}><b>{phaseAbbreviations[currentPhase]?.short}</b> - {title}</li>
              ))}
            </ul>
          )}
        </React.Fragment>
      ))}
      <PhaseLegend />
    </>
  );
};

export default PendingCongressionalNotifications;