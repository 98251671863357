import { useCallback, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router";
import MeatBallMenuItem from "./MeatBallMenuItem";
import AuthorizedContent from '../AuthorizedContent';
import Button from "../buttons/Button"
import useOnClickOutside from "../hooks/useOnClickOutside";
import styles from './NavBar.module.scss';

const MeatBallMenu = () => {
  const location = useLocation();
  const ref = useRef();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const memoizedCloseMenu = useCallback(() => setMenuOpen(false), []);

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  useOnClickOutside(ref, memoizedCloseMenu);

  return (
    <AuthorizedContent authorizedRoles={['SAM', 'LEE']}>
    <div ref={ref}>
      <Button className={styles.meatBallButton} onClick={toggleMenu}><div className={styles.meatballs}>menu</div></Button>
      {menuOpen && (
        <div className={styles.meatBallMenu}>
          <AuthorizedContent authorizedRoles='SAM'>
            <MeatBallMenuItem href='/admin'>Admin</MeatBallMenuItem>
          </AuthorizedContent>
          <AuthorizedContent authorizedRoles={['SAM', 'LEE']}>
            <MeatBallMenuItem href='/reporting'>Reporting</MeatBallMenuItem>
          </AuthorizedContent>
        </div>
      )}
    </div>
    </AuthorizedContent>
  )
}

export default MeatBallMenu;