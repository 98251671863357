import axios from '../../../../utils/sharedAxios';
import { useState } from 'react';
import Button from '../../../buttons/Button';
import CardWrapper from '../../../CardWrapper';
import Modal from '../../../Modal';
import ModalFooter from '../../../Modal/ModalFooter';
import ActionRequestCard from './ActionRequestCard';
import DenyActionRequestModal from './DenyActionRequestModal';
import styles from '../../Admin.module.scss';

const NewActionRequests = ({actionRequests, setActionRequests}) => {
    const [rejectingBarId, setRejectingBarId] = useState(null);
    const [showApprovedSuccess, setShowApprovedSuccess] = useState(false);
    const [showDeniedSuccess, setShowDeniedSuccess] = useState(false);

    const removeRequest = (id) => setActionRequests(actionRequests.filter((req) => req.barId !== id));

    const handleApproveRequest = async (values) => {
        const { safAo, phase, step, id } = values;
        const response = await axios.post('/bars/approve', { id, phase: phase.value, step: step.value, assignee: safAo.value });
        if(!response.hasErrors) {
            removeRequest(id);
            setShowApprovedSuccess(true);
        }
        //what to display in case of an error
    }

    const openRejectRequestModal = (id) => {
        return setRejectingBarId(id)
    }

    const handleRejectRequest = async (id, values) => {
        const { reason } = values;
        const response = await axios.post('/bars/reject', { id, reason });
        if(!response.hasErrors) {
            removeRequest(id);
            setRejectingBarId(null);
            setShowDeniedSuccess(true);
        }
        //what to display in case of an error
    }

    const closeDenyRequestModal = () => setRejectingBarId(null);
    return <>
        <h3 className={styles.subTitle}>Accept or reject newly submitted basing actions</h3>

        <p>
            <b>Acceptance:</b> A basing action must be assigned a SAF Action Officer, and the phase and step they will enter into the Strategic Basing Process.<br />
            <b>Rejection:</b> Rejected actions will be returned to the Draft status.<br />
            <b>Notification:</b> The submitter of the action will be notified of either outcome.
        </p>

        {actionRequests.length > 0 ? (
            <CardWrapper>
                {actionRequests.map(request => (
                <ActionRequestCard
                    key={request.barId}
                    handleApproveRequest={handleApproveRequest}
                    openRejectRequestModal={openRejectRequestModal}
                    request={request} />
                ))}
            </CardWrapper>
        ) : (
            <h3>There are no new pending action requests</h3>
        )}

      {rejectingBarId &&
        <DenyActionRequestModal
          handleRejectRequest={(vals) => handleRejectRequest(rejectingBarId, vals)}
          onClose={closeDenyRequestModal} />
      }

    {showApprovedSuccess && <Modal onClose={() => setShowApprovedSuccess(false)} title='Action Accepted!'>
        <p>We will let the submitter know you accepted their action. It will now be viewable to anyone who has equity in it.</p>
        <ModalFooter>
          <Button onClick={() => setShowApprovedSuccess(false)} primary>Back to Admin</Button>
        </ModalFooter>
      </Modal>}

      {showDeniedSuccess && <Modal onClose={() => setShowDeniedSuccess(false)} title='Action Rejected'>
        <p>We will notify the submitter that the action has returned to the Draft status.</p>
        <ModalFooter>
          <Button onClick={() => setShowDeniedSuccess(false)} primary>Back to Admin</Button>
        </ModalFooter>
      </Modal>}
    </>;
};

export default NewActionRequests;