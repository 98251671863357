const onlyNumbers = string => string && (string.replace(/[^\d]/g, "")).slice(0, 10);

// returns a readable phone format ex: 555-5555 or 555-555-5555
const formatPhone = value => {
  if (!value) return value;

  const stripped = onlyNumbers(value);

  if (stripped.length <= 3) {
    return stripped;
  }

  if (stripped.length <= 7) {
    return `(${stripped.slice(0, 3)}) ${stripped.slice(3, 7)}`;
  }

  return `(${stripped.slice(0, 3)}) ${stripped.slice(3, 6)}-${stripped.slice(6, 10)}`;
}

export default formatPhone;
