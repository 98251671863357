import { useEffect, useState } from 'react';
import axios from '../../utils/sharedAxios';
import useCachedData from './useCachedData';

// returns a list of users with SAM role in the shape of an options array
const useFetchSAFOptions = () => {
  const [safOptions, setSafOptions] = useState([]);
  const { data: roles = [] } = useCachedData('/users/roles');

  useEffect(() => {
    const fetchSafActionOfficers = async () => {
      const { data: allUsers = [] } = await axios.get('/users/all');

      const samRole = roles.find(role => role.name === 'SAM');
      const samUsers = allUsers.filter(user => user.roleId === samRole.id);
      const options = samUsers.map(user => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id
      }));

      setSafOptions(options);
    }

    roles.length && fetchSafActionOfficers();
  }, [roles]);

  return safOptions;
}

export default useFetchSAFOptions;
