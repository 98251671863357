import React from 'react'
import { useField } from 'react-final-form';

// self-hosted tinymce
import 'tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';


import { Editor } from '@tinymce/tinymce-react'; // v3.9.0 only! do not upgrade. package.json is locked down.
import cn from 'classnames';
import onPasteHandler from './onPasteHandler';
import './skins/blue/skin.css';
import styles from './Wysiwyg.module.scss';

// Rich text editor using TinyMCE
const Wysiwyg = ({ maxHeight = 360, minHeight = 140, width, className, name, onChange }) => {
    const { input } = useField(name);
    const handleEditorChange = (content) => {
        onChange && onChange(content);
        input.onChange && input.onChange(content);
    }

    return (
        <div className={cn(styles.wysiwyg, className)}>
            <Editor
                value={input.value}
                init={{
                    max_height: maxHeight,
                    min_height: minHeight,
                    width: width,
                    menubar: false,
                    statusbar: false,
                    plugins: [
                        'autoresize',
                        'lists',
                        'paste'
                    ],
                    paste_preprocess: onPasteHandler,
                    smart_paste: false,
                    toolbar: 'bold italic bullist outdent indent removeformat',
                    toolbar_location: 'bottom',
                    body_class: 'tinymce_body',
                    content_css: false,
                    content_style: "* { font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; } p { margin-top: 0; margin-bottom: 0 } ul { margin-top: 0 }",
                    skin: false

                }}
                onEditorChange={handleEditorChange}
            />
       </div>
    );
}

export default Wysiwyg;
