import { useEffect, useRef, useState } from 'react';
import { Field, useField } from 'react-final-form';
import CloseButton from '../../buttons/CloseButton';
import UploadButton from '../../buttons/UploadButton';
import DateField from '../../Form/DateField';
import Label from '../../Form/Label';
import styles from './BasingDecisionPackage.module.scss';

const SignedDecisionPackage = ({ deleteFile, docType, label, name, phase }) => {
  const [document, setDocument] = useState(null);
  const docs = useField(`${name}.docs`);
  const fileName = useField(`${name}.fileName`);
  const inputRef = useRef(null);

  const onClickUpload = () => inputRef.current.click();

  const onInputChange = (e) => {
    const file = e.target.files[0];
    docs.input.onChange([{ file, type: docType, packageType: 'signedDecisionPackage' }]);
    fileName.input.onChange(file.name);
  };

  const onClickRemove = () => {
    fileName.input.onChange('');
    inputRef.current.value = '';
    docs.input.onChange([]);
    setDocument(null);

    if (deleteFile && document?.uuid){
      deleteFile(document?.uuid);
    }
  }

  useEffect(() => {
    if (docs.input.value.length) {
      const doc = docs.input.value[0];

      setDocument(doc.file || doc);
    }
  }, [docs.input.value]);

  const fileNameDisplay = document && (document.originalFileName || document.name) || '';

  return (
    <div className={styles.signedDecisionPackage}>
      <Label text={label} />
      <div className={styles.fileRow}>
        <p className={styles.label}>Signed Package:</p>
        <input type='file' onChange={onInputChange} ref={inputRef} style={{ display: 'none' }} />
        {!fileNameDisplay && <UploadButton onClick={onClickUpload} />}
        {fileNameDisplay && <span>{fileNameDisplay}</span>}
        {fileNameDisplay && <CloseButton className={styles.closeButton} onClick={onClickRemove} />}
      </div>
      <div className={styles.dateRow}>
        <p className={styles.label}>Decision date:</p>
        <DateField name={`${name}.lastReviewDate`} showClearDate />
      </div>
      <Field name={`${name}.phase`} component='input' type='hidden' initialValue={phase} />
      <Field name={`${name}.type`} component='input' type='hidden' initialValue='signedDecisionPackage' />
      <Field name={`${name}.docType`} component='input' type='hidden' initialValue={docType} />
    </div>
  );
}

export default SignedDecisionPackage;