import React from 'react';
import cn from 'classnames';
import PointsField from '../basingCriteria/PointsField';
import styles from './BasingCriteriaDefinition.module.scss';
import Label from '../../Form/Label';
import Box from '../../Box';
import Grid from '../../Grid';
import useCachedData from '../../hooks/useCachedData';
import RequirementsDefinition from './RequirementDefinition';
import Collapsible from '../../Collapsible';
import formStyles from '../../Form/Form.module.scss';
import { useForm } from 'react-final-form';

const CriteriaDefinitionEdit= () => {
  const form = useForm();
  const { initialValues } = form.getState();
  const { data: militaryFamilyReadiness } = useCachedData('/content/militaryFamilyReadiness');
  const { data: supportOfMilitaryFamilies } = useCachedData('/content/supportOfMilitaryFamilies');
  const templateColumns = [3, '22fr 4fr 3fr'];
  const renderTitle = (title) => <h3 className={cn(styles.groupHeading, formStyles.sectionHeading)}>{title}</h3>
  return (
    <>
      <Grid className={styles.titleGrid} columns={templateColumns}>
        <Box />
        <Label className={styles.pointsLabel} text='Points' />
      </Grid>

      <Collapsible
        toggle={
          <Grid columns={templateColumns}>
            {renderTitle('Mission')}
            <PointsField name='missionRequirementTotalPoints' readOnly />
          </Grid>
        }>
        <RequirementsDefinition name='missionRequirements' />
      </Collapsible>

      <Collapsible
        toggle={
          <Grid columns={templateColumns}>
            {renderTitle('Capacity')}
            <PointsField name='capacityTotalPoints' readOnly />
          </Grid>
        }>
          <Grid columns={templateColumns}>
            <h3>Facilities</h3>
            <PointsField name='facilityCriteriaTotalPoints' readOnly />
          </Grid>
          <RequirementsDefinition name='facilityRequirements' typeKey='facility' />

          <Grid columns={templateColumns}>
            <h3>Base Support Tail</h3>
            <PointsField name='baseSupportTailCriteriaTotalPoints' readOnly />
          </Grid>
          <RequirementsDefinition name='missionCriteria.baseSupportTailCriteria' />
          <RequirementsDefinition name='missionCriteria.additionalBaseSupportTailCriteria' />
      </Collapsible>

      <Collapsible
        toggle={
          <Grid columns={templateColumns}>
            {renderTitle('Environmental')}
            <PointsField name='environmentRequirementTotalPoints' readOnly />
          </Grid>
        }>
        <RequirementsDefinition name='missionCriteria.environmentalCriteria' />
      </Collapsible>

      <Collapsible
        toggle={
          <Grid columns={templateColumns}>
            {renderTitle('Cost')}
            <PointsField name='costCriteriaTotalPoints' readOnly />
          </Grid>
        }>
        <RequirementsDefinition name='missionCriteria.costCriteria' />
        <RequirementsDefinition name='missionCriteria.additionalCosts' />
      </Collapsible>

      <Collapsible
        toggle={
          <Grid columns={templateColumns}>
            {renderTitle('Military Family Readiness')}
            <PointsField name='militaryFamilyReadinessTotalPoints' readOnly />
          </Grid>
        }>
        <RequirementsDefinition name='missionCriteria.militaryFamilyReadiness' initialValue={initialValues.missionCriteria.militaryFamilyReadiness || militaryFamilyReadiness?.initialValue} />
        <Grid columns={templateColumns}>
            <h3>Support of Military Families</h3>
            <PointsField name='supportOfMilitaryFamiliesTotalPoints' readOnly />
          </Grid>
        <RequirementsDefinition name='missionCriteria.supportOfMilitaryFamilies' initialValue={initialValues.missionCriteria.supportOfMilitaryFamilies || supportOfMilitaryFamilies?.initialValue} />
      </Collapsible>
    </>
  );
}

export default CriteriaDefinitionEdit;
