import React from 'react';
import { useField } from 'react-final-form';
import FieldArray from '../../Form/FieldArray';
import UploadDocsField from '../../Form/UploadDocsField';
import DocumentList from '../../DocumentList';
import SelectField from '../../Form/SelectField';
import TextField from '../../Form/TextField';
import DetailsText from '../../DetailsText';
import Grid from '../../Grid';
import { Box } from 'theme-ui';
import Label from '../../Form/Label';
import styles from '../../Form/Form.module.scss';

const environmentalDocumentTypeOptions = [
  {
    label: 'Environmental Impact Statement',
    value: 'Environmental Impact Statement'
  },
  {
    label: 'Environmental Assessment',
    value: 'Environmental Assessment'
  },
  {
    label: 'CATEX',
    value: 'CATEX'
  },
  {
    label: 'FGS',
    value: 'FGS'
  },
];

const AttachmentsEdit = ({ deleteFile }) => {
  const { input: { value: docs } } = useField('attachments');
  const phoneBook = docs ? docs.filter((d) => d.type === 'phoneBook') : [];
  const siteSurveyAuthLetter = docs ? docs.filter((d) => d.type === 'siteSurveyAuthLetter') : [];
  const siteSurveyReport = docs ? docs.filter((d) => d.type === 'siteSurveyReport') : [];
  const congressionalNotificationPlan = docs ? docs.filter((d) => d.type === 'congressionalNotificationPlan') : [];
  const publicAffairsGuidance = docs ? docs.filter((d) => d.type === 'publicAffairsGuidance') : [];
  const bdm = docs ? docs.filter((d) => d.type === 'bdm') : [];
  const additionalAttachments = docs ? docs.filter((d) => d.type === 'additionalAttachments') : [];
  const environmental = docs ? docs.filter((d) => d.type === 'environmental') : [];
  const { input: { value: environmentalDocumentType } } = useField('environmentalDocumentType');


  return (
    <div>
      <FieldArray name={'attachments'} initialValue={docs} minLength={0}>
      {({ fields, add, remove }) => (
        <>
          <DocumentList docList={phoneBook} title='Phone Book' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'attachments'} add={add} remove={remove} fields={fields} docType='phoneBook' packageType='attachments' />

          <DocumentList docList={siteSurveyAuthLetter} title='Site Survey Authorization Letter' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'attachments'} add={add} remove={remove} fields={fields} docType='siteSurveyAuthLetter' packageType='attachments' />

          <DocumentList docList={siteSurveyReport} title='Site Survey Report' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'attachments'} add={add} remove={remove} fields={fields} docType='siteSurveyReport' packageType='attachments' />

          <DocumentList docList={congressionalNotificationPlan} title='Congressional Notification Plan' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'attachments'} add={add} remove={remove} fields={fields} docType='congressionalNotificationPlan' packageType='attachments' />

          <DocumentList docList={publicAffairsGuidance} title='Public Affairs Guidance' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'attachments'} add={add} remove={remove} fields={fields} docType='publicAffairsGuidance' packageType='attachments' />

          {/* Environmental */}
          <Label text='Environmental Documents' />
          <p>Which type of environmental document is used for this basing action?</p>
          <SelectField name='environmentalDocumentType' options={environmentalDocumentTypeOptions} />
          <DocumentList title={environmentalDocumentType?.value || null} docList={environmental} remove={remove} fields={fields} deleteFile={deleteFile} />
          {environmentalDocumentType?.value && <p>Please link or upload the {environmentalDocumentType.value}</p>}
          <Grid columns={[2, '50px 1fr']}>
            <Box><Label htmlFor='environmentalDocumentHref' text='Link:' /></Box>
            <Box><TextField name='environmentalDocumentHref' placeholder='https://' /></Box>

            <Box><Label htmlFor='environmentalDocumentHref' text='File:' /></Box>
            <Box>
            <UploadDocsField name={'attachments'} add={add} remove={remove} fields={fields} docType='environmental' packageType='attachments' />
            </Box>
          </Grid>




          <DocumentList docList={bdm} title='BDM' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'attachments'} add={add} remove={remove} fields={fields} docType='bdm' packageType='attachments' />

          <DocumentList docList={additionalAttachments} title='Additional Attachments' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'attachments'} add={add} remove={remove} fields={fields} docType='additionalAttachments' packageType='attachments' />
        </>
      )}
      </FieldArray>
    </div>
  );
}

export default AttachmentsEdit;
