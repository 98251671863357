// when bar is being saved/updated, these field names are queried for new images/files
// and then uploaded to S3
export const imageFields = [
    'enterpriseComponents.mission',
    'enterpriseComponents.capacity',
    'enterpriseComponents.environmental',
    'enterpriseComponents.cost',
    'enterpriseComponents.somf',
    'enterpriseComponents.componentScores',
    'backups.additionalInfo',
    'backups.additionalBackup',
    'backups.additionalMfrBackup',
    'backups.mfrHealthcare',
    'backups.mfrHousing',
    'backups.somfProfessionalLicensure',
    'backups.somfPublicEducation',
    'enterpriseDefinition',
    'eisSummaryOfFindings',
    'weAreHere'
];

export const docFields = [
    'twoLetterCoord.docs',
    'congressionalEngagementDocuments',
    'basingDecisionPackage.docs',
    'attachments',
    'signedDecisionPackage[0].docs',
    'signedDecisionPackage[1].docs',
    'signedDecisionPackage[2].docs',
    'signedDecisionPackage[3].docs'
];