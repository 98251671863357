import { useEffect, useState } from 'react';
import ActionAgingReport from './ActionAgingReport';
import CongressionalEngagementReport from './CongressionalEngagementReport';
import axios from '../../../../utils/sharedAxios';

const OnDemandReports = () => {
  const [barOptions, setBarOptions] = useState([]);

  useEffect(() => {
    const fetchAllBars = async () => {
      const { data: barData } = await axios.get('/bars/all_with_title?status=active,awaitingFOC,foc,paused');

      if (barData) {
        setBarOptions(barData.map(({ id, title }) => {
          return ({
            value: id,
            label: title
          })
        }));
      }
    }

    fetchAllBars();
  },[]);
  return (
    <>
      <ActionAgingReport barOptions={barOptions} />
      <CongressionalEngagementReport barOptions={barOptions} />
    </>
  )
}

export default OnDemandReports;