import Button from '../buttons/Button';
import Modal from '../Modal';
import ModalFooter from '../Modal/ModalFooter';

const ConfirmDeleteDraftModal = ({ handleDeleteBar, onClose }) => {
  return (
    <Modal onClose={onClose} title='Delete Draft?'>
      <p>Are you sure you would like to delete this basing action?</p>
      
      <ModalFooter>
        <Button onClick={handleDeleteBar} danger>DELETE</Button>
        <Button onClick={onClose} secondary>CANCEL</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmDeleteDraftModal;