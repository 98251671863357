import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import Label from '../../Form/Label';
import NestedTextFieldArray from '../../Form/NestedTextFieldArray'
import FacilitiesField from '../FacilitiesField';
import SiteSurveyValuesSpy from './SiteSurveyValuesSpy';
import styles from '../../Form/Form.module.scss';
import TextField from '../../Form/TextField';
import CheckboxField from '../../Form/CheckboxField';
import Grid from '../../Grid';

const SiteSurveyCriteriaEdit = () => {
  const form = useForm();
  const { initialValues } = form.getState();
  const ignoredKeys = ['subRequirements', 'id'];

  const staticNestedArrayProps = {
    definitionName: 'requirement',
    placeholderText: 'Criteria',
    addText: 'Add Criteria',
    subName: 'subRequirements',
    subDefinitionName: 'requirement',
    subPlaceholderText: 'Sub-Criteria',
    subAddText: 'Add Sub-Criteria',
  };

  const communitySupportInitialValue = [
    { requirement: 'Transportation' },
    { requirement: 'Utility Infrastructure' },
    { requirement: 'Housing' },
    { requirement: 'Education (State and Local Officials)' },
    { requirement: 'Family Support Activities' },
  ];
  const militaryFamilyReadinessInitialValue = [
    { requirement: 'Healthcare' },
    { requirement: 'Housing' },
  ];
  const supportOfMilitaryFamiliesInitialValue = [
    { requirement: 'Public Education' },
    { requirement: 'Licensure Portability' },
  ];

  useEffect(() => {
    if (initialValues) {
      initialValues.siteSurveyRequirements = {
        communitySupport: communitySupportInitialValue,
        facility: initialValues.facilityRequirements ? initialValues.facilityRequirements.map(item => ({
          ...item,
          requirement: item.facility
        })) : [],
        militaryFamilyReadiness: militaryFamilyReadinessInitialValue,
        mission: initialValues.missionRequirements ? initialValues.missionRequirements.map(item => ({
          ...item,
          subRequirements: item.subRequirements.map(subItem => ({ ...subItem, requirement: subItem.subRequirement }))
        })) : [],
        supportOfMilitaryFamilies: supportOfMilitaryFamiliesInitialValue,
        ...initialValues.siteSurveyRequirements
      }

      form.initialize(initialValues);
    }
  }, []);

  const additionalCostComponent = ({ name, definitionName, placeholder, disabled }) => (
    <Grid columns={[2, '3fr 1fr']}>
      <TextField name={`${name}.${definitionName}`} placeholder={placeholder} disabled={disabled} />
      <CheckboxField name={`${name}.quantity`} labelText='Recurring Cost' />
    </Grid>
  )
  return (
    <div>
      <Label text={<h3 className={styles.sectionHeading}>Mission</h3>} required />
      <NestedTextFieldArray
        ignoredKeys={ignoredKeys}
        name='siteSurveyRequirements.mission'
        {...staticNestedArrayProps}
        includeNesting
      />

      <h3 className={styles.sectionHeading}>Joint and All Domain Training</h3>
      <NestedTextFieldArray
        ignoredKeys={ignoredKeys}
        minLength={0}
        name='siteSurveyRequirements.jointAndAllDomainTraining'
        {...staticNestedArrayProps}
        includeNesting
      />

      <h3 className={styles.sectionHeading}>Airspace and Training Areas</h3>
      <NestedTextFieldArray
        ignoredKeys={ignoredKeys}
        minLength={0}
        name='siteSurveyRequirements.airspaceAndTrainingAreas'
        {...staticNestedArrayProps}
        includeNesting
      />

      <h3 className={styles.sectionHeading}>Capacity</h3>
      <FacilitiesField name='siteSurveyRequirements.facility' selectKey='requirement' />

      <NestedTextFieldArray
        ignoredKeys={ignoredKeys}
        labelText='Base Support Tail'
        name='siteSurveyRequirements.baseSupportTail'
        {...staticNestedArrayProps}
      />

      <NestedTextFieldArray
        ignoredKeys={ignoredKeys}
        labelText='Community Support'
        name='siteSurveyRequirements.communitySupport'
        {...staticNestedArrayProps}
      />

      <h3 className={styles.sectionHeading}>Environmental</h3>
      <NestedTextFieldArray
        ignoredKeys={ignoredKeys}
        name='siteSurveyRequirements.environmental'
        {...staticNestedArrayProps}
      />

      <h3 className={styles.sectionHeading}>Military Family Readiness</h3>
      <NestedTextFieldArray
        ignoredKeys={ignoredKeys}
        name='siteSurveyRequirements.militaryFamilyReadiness'
        {...staticNestedArrayProps}
      />

      <NestedTextFieldArray
        ignoredKeys={ignoredKeys}
        labelText='Support of Military Families'
        name='siteSurveyRequirements.supportOfMilitaryFamilies'
        {...staticNestedArrayProps}
      />

      <h3 className={styles.sectionHeading}>Costs</h3>
      <NestedTextFieldArray
        ignoredKeys={ignoredKeys}
        labelText='One Time Costs'
        name='siteSurveyRequirements.costOneTime'
        fieldDataEndpoint='/content/criteriaOneTimeCosts'
        {...staticNestedArrayProps}
      />

      <NestedTextFieldArray
        ignoredKeys={ignoredKeys}
        labelText='Recurring Costs'
        name='siteSurveyRequirements.costRecurring'
        fieldDataEndpoint='/content/criteriaRecurringCosts'
        {...staticNestedArrayProps}
      />

      <NestedTextFieldArray
        ignoredKeys={ignoredKeys}
        labelText='Additional Costs'
        minLength={0}
        name='siteSurveyRequirements.costAdditional'
        component={additionalCostComponent}
        {...staticNestedArrayProps}
      />

      <SiteSurveyValuesSpy />
    </div>
  );
}

export default SiteSurveyCriteriaEdit;