import { useField } from 'react-final-form';
import cn from 'classnames';
import Select from '../Select';
import Label from '../Form/Label';
import ErrorIndicator from './ErrorIndicator';
import styles from '../Form/Form.module.scss';

const SelectField = ({
  className,
  initialValue,
  label,
  multiple = false,
  name,
  onChange: customOnChange,
  placeholderText = '- Select -',
  required,
  validate,
  ...props
  }) => {
  const { input: { onChange, value, ...inputRest }, meta } = useField(name, { initialValue, type: 'select', multiple, validate })

  // the form makes use of the entire option object
  const handleOnChange = (selection) => {
    onChange(selection);
    customOnChange && customOnChange(selection);
  }

  return (
    <div className={cn(styles.fieldControl, className)}>
      {label && <Label htmlFor={name} required={required} text={label} />}
      <div className={styles.inputContainer}>
        {meta.touched && meta.error && <ErrorIndicator />}
        <Select
          className={cn(className)}
          multiple={multiple}
          name={name}
          onChange={handleOnChange}
          round
          error={meta.touched && meta.error}
          value={value}
          placeholderText={placeholderText}
          {...inputRest}
          {...props}
          />
      </div>
    </div>
  );
}

export default SelectField;