import React, { useState } from 'react';
import FieldArray from './FieldArray';
import TextField from './TextField';
import SelectField from './SelectField';
import Box from '../Box';
import Grid from '../Grid';
import AddButton from '../buttons/AddButton';
import CloseButton from '../buttons/CloseButton';
import styles from './Form.module.scss';
import Label from './Label';
import { Field } from 'react-final-form';
import uniqueId from 'lodash/uniqueId';

const NestedTextFieldArray = ({
  component: Component,
  disabledCount = 0,
  ignoredKeys,
  initialValue,
  labelText,
  maxLength,
  minLength,
  name,
  definitionName,
  fieldDataEndpoint,
  placeholderText,
  addText,
  hiddenFields,
  includeNesting,
  subName,
  subDefinitionName,
  subFieldDataEndpoint,
  subPlaceholderText,
  subAddText
}) => {
  const [uid] = useState(uniqueId('tempId__'));

  return (
    <FieldArray name={name} ignoredKeys={ignoredKeys} initialValue={initialValue} disabledCount={disabledCount} maxLength={maxLength} minLength={minLength}>
      {({ fields, add, remove, isMaxLength, isMinLength }) => (
        <div>
          {labelText && <Label text={labelText} />}
          {fields.map((fieldName, index) => {
            const disabled = index < disabledCount;

            return (
              <React.Fragment key={index}>
                <Grid key={index} absoluteChild={!isMinLength && !disabled && <CloseButton onClick={remove(index)} />}>
                  <Box>
                    {Component && <Component name={fieldName} definitionName={definitionName} placeholder={placeholderText} disabled={disabled} />}
                    {!Component && fieldDataEndpoint && <SelectField name={`${fieldName}.${definitionName}`} fieldDataEndpoint={fieldDataEndpoint} placeholder={placeholderText} disabled={disabled} />}
                    {!Component && !fieldDataEndpoint && <TextField name={`${fieldName}.${definitionName}`} placeholder={placeholderText} disabled={disabled} />}
                  </Box>
                  <Field component='input' type='hidden' name={`${fieldName}.id`} initialValue={`${uid}__${index}`} />
                </Grid>

                {includeNesting && (
                  <Grid>
                    <SubFieldArray name={`${fieldName}.${subName}`}
                      definitionName={subDefinitionName}
                      fieldDataEndpoint={subFieldDataEndpoint}
                      placeholderText={subPlaceholderText}
                      addText={subAddText} />
                  </Grid>
                )}
              </React.Fragment>
            )})}
            {!isMaxLength && <Grid><AddButton onClick={()=> add()} secondary>{addText}</AddButton></Grid>}
            {hiddenFields && hiddenFields.map(({ fieldName, value }) => (
              <input key={fieldName} type='hidden' name={`${name}.${fieldName}`} value={value} />
            ))}
        </div>
      )}
    </FieldArray>
  )
}

const SubFieldArray = ({ name, definitionName, fieldDataEndpoint, placeholderText, addText }) => {
  const [uid] = useState(uniqueId('tempId__'));

  return (
    <FieldArray name={name} minLength={0} isNested>
      {({ fields, add, remove, isMaxLength, isMinLength }) => (
        <div className={styles.nestedRequirement}>
          {fields.map((fieldName, index) => (
            <Grid key={index} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
              <Box>
                {fieldDataEndpoint ? (
                  <SelectField name={`${fieldName}.${definitionName}`} fieldDataEndpoint={fieldDataEndpoint} placeholder={placeholderText} />
                ) : (
                  <TextField name={`${fieldName}.${definitionName}`} placeholder={placeholderText} />
                )}
              </Box>
              <Field component='input' type='hidden' name={`${fieldName}.id`} initialValue={`${uid}__${index}`} />
            </Grid>
            ))}
            {!isMaxLength && <Grid><AddButton onClick={()=> add()} secondary>{addText}</AddButton></Grid>}
        </div>
      )}
    </FieldArray>
  );
}

export default NestedTextFieldArray;
