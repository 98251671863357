import React, { useEffect } from 'react';
import Display from './RollCallDisplay';
import Edit from './RollCallEdit';
import useCachedData from '../../../hooks/useCachedData';
import { useForm } from 'react-final-form';

const RollCall = ({ isEditing, ...props }) => {
  const { data: rollCall } = useCachedData('/reviews/roll-call');
  const form = useForm();
  const { initialValues, values } = form.getState();

  useEffect(() => {
    if (!initialValues.rollCall && rollCall) {
      props.setInitialValues({
        ...initialValues,
        rollCall
      });

      // reinitializing the form will nuke previous entries.
      // re-applying those entries after the state updates
      setTimeout(() => {
        form.batch(() => {
          Object.entries(values)
            .forEach(([key, value]) => form.change(key, value));
        });
      }, 0)
      
      
    }
  }, [initialValues.rollCall, rollCall]);
  return isEditing ? <Edit {...props} /> : <Display {...props} />;
};

export default RollCall;