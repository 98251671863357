import React from 'react';
import RichTextDisplay from '../../Form/RichTextDisplay';
import { useFormState } from 'react-final-form';
import useCachedData from '../../hooks/useCachedData';
import _ from 'lodash';
import styles from './Backups.module.scss';

const BackupSlideDisplay = ({titleKey}) => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });

  const fileKey = _.get(initialValues, `backups.${titleKey}.fileKey`);
  const { data: fileSrc } = useCachedData(fileKey && '/documents/image', {params: { docId: fileKey } });
  return (
    <>
      {fileSrc && (
        <>
          <img src={fileSrc} />
        </>
      )}
      <div className={styles.spacer}></div>
      <RichTextDisplay html={_.get(initialValues, `backups.${titleKey}.description`)} />
    </>
  );
}

export default BackupSlideDisplay;
