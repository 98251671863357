import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import axios from '../../utils/sharedAxios';
import styles from './Dashboard.module.scss'
import { adminViewKeys } from '../../constants/adminViewMapping';

const NewAccounts = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const { data } = await axios.get('/users/pendingApproval');
      data && setUsers(data);
    }

    fetchUsers();
  }, []);
  
  const handleClick = () => history.push(`/admin?activeView=${adminViewKeys.newAccountRequests}`);

  return (
    <div className={cn(styles.chip, { [styles.active]: users.length > 0 })} onClick={handleClick}>
      <h6 className={styles.chipHeading}>NEW ACCOUNTS</h6>
      <p className={styles.chipValue}>{users.length || 0}</p>
    </div>
  );
}

export default NewAccounts;