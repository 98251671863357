import React from 'react';
import { useFormState } from 'react-final-form';
import RichTextDisplay from '../../Form/RichTextDisplay';
import useCachedData from '../../hooks/useCachedData';
import getBaseName from '../../../utils/getBaseName';
import styles from '../../Form/Form.module.scss';

const EnterpriseDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });
  const { data: bases } = useCachedData('/content/bases');

  const fileKey = initialValues?.enterpriseDefinition?.fileKey;
  const options = {params: { docId: fileKey } };
  const { data: uploadedImageSrc } = useCachedData(fileKey && '/documents/image', options);

  const namedLocations = initialValues?.enterpriseDefinition?.locations?.length ? initialValues.enterpriseDefinition.locations.map(item => getBaseName(item, bases)) : [];

  return (
    <>
      {initialValues?.enterpriseDefinition?.definition && (
        <>
          <h3 className={styles.sectionHeading}>Overview</h3>
          <RichTextDisplay html={initialValues.enterpriseDefinition.definition} />
        </>
      )}

      {namedLocations.length > 0 && (
        <>
          <h3 className={styles.sectionHeading}>Locations</h3>
          <ul>
            {namedLocations.map(location => (
              <li key={location}>{location}</li>
            ))}
          </ul>
        </>
      )}
      {uploadedImageSrc && (
        <>
          <img src={uploadedImageSrc} />
        </>
      )}
    </>
  );
}

export default EnterpriseDisplay;
