import React from 'react';
import useCachedData from '../../hooks/useCachedData';
import BackupSlide from './BackupSlide';

const MfrHousing = (props) => {
  const { data: keys } = useCachedData('/content/barViewKeys');
  
  return (
    <BackupSlide titleKey={keys && keys.mfrHousing} {...props} />
  );
}

export default MfrHousing;
