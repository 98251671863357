import React from 'react';
import MissionRequirementsField from './MissionRequirementsField.js';
import DetailsText from '../../DetailsText';
import FacilitiesField from '../FacilitiesField.js';
import TimingField from './TimingField.js';
import Wysiwyg from '../../Form/Wysiwyg';
import CostField from './CostField';
import Label from '../../Form/Label';
import styles from '../../Form/Form.module.scss';

const RequirementsEdit = () => {
  return (
    <div>
      <Label text={<h3 className={styles.sectionHeading}>Mission</h3>} required />
      <DetailsText>What are the operational mission requirements that must be supported?<br />
      NOT a mission statement - could include requirements noted in enterprise definition<br />
      Identify requirements used to define the Enterprise<br />
      Include airpsace & training areas available, joint and all domain training capabilities</DetailsText>
      <MissionRequirementsField />
      
      <Label text={<h3 className={styles.sectionHeading}>Facilities</h3>} required />
      <FacilitiesField name='facilityRequirements' />
      
      <Label text={<h3 className={styles.sectionHeading}>Personnel</h3>} required />
      <DetailsText>Type of personnel required to support the mission, ex: pilot, recruiter, instructors, etc…<br/>
      Include the requirements (if applicable) for additional BST</DetailsText>
      <Wysiwyg name='personnelRequirement.description' />

      <Label text={<h3 className={styles.sectionHeading} required>Costs </h3>} />
      <Label text='One-Time Costs ' required />
      <CostField name='costRequirements.oneTime' costType='oneTime' addBtnLabel='One-Time' fieldDataEndpoint='/content/requirementOneTimeCosts' />

      <Label text='Recurring Costs ' required />
      <CostField name='costRequirements.recurring' costType='recurring' addBtnLabel='Recurring' fieldDataEndpoint='/content/requirementRecurringCosts' />

      <Label text='Additional Costs' />
      <CostField name='costRequirements.custom' costType='custom' addBtnLabel='Additional'/>

      <h3 className={styles.sectionHeading}>Timing</h3>
      <DetailsText>Additional timing options are available on the Way Ahead</DetailsText>
      <TimingField />
    </div>
  );
}

export default RequirementsEdit;
