import { useEffect, useState } from 'react';
import cn from 'classnames';
import moment from 'moment';
import useCachedData from '../hooks/useCachedData';
import DisplayBarLink from '../DisplayBarLink';
import CloseButton from '../buttons/CloseButton';
import axios from '../../utils/sharedAxios';
import dateFormats from '../../constants/dateFormats';
import styles from './Dashboard.module.scss'
import DisplayUser from '../DisplayUser';

const ActivityFeed = () => {
  const { data: barViewKeys = {} } = useCachedData('/content/barViewKeys');
  const { data: phases = {} } = useCachedData('/content/phases');
  const { data: steps = {} } = useCachedData('/content/steps');
  const [notifications, setNotifications] = useState([]);

  const removeNotification = (id) => async () => {
    await axios.get(`/notifications/remove/${id}`);
    setNotifications(notifications.filter(notification => notification.id !== id));
  };

  const notificationsMap = {
    actualDateCongressNotified: {
      initialState: () => ({ activeView: barViewKeys.congressionalDates}),
      render: ({ eventDate, title }) => `Congress was notified for ${title} on ${moment(eventDate).format(dateFormats.short)}.`,
    },
    attachments: {
      initialState: () => ({ activeView: barViewKeys.attachments }),
      render: ({ actor, title }) => (<><DisplayUser userId={actor} /> added a file to Attachments for {title}.</>),
    },
    barAccepted: {
      initialState: () => ({}),
      render: ({ title }) => `${title} was accepted by the SABER team.`,
    },
    barMove: {
      initialState: ({ phase, step }) => ({ phase, step }),
      render: ({ phase, step, title }) => `${title} has moved to ${steps[step]} in the ${phases[phase]} phase.`,
    },
    basingDecisionPackage: {
      initialState: () => ({ activeView: barViewKeys.basingDecisionPackage }),
      render: ({ actor, title }) => (<><DisplayUser userId={actor} /> added a file to Basing Decision Package for {title}.</>),
    },
    cnNotified: {
      initialState: () => ({}),
      render: () => ``,
    },
    cnScheduled: {
      initialState: () => ({}),
      render: () => ``,
    },
    comment: {
      initialState: ({ phase, step }) => ({ phase, step }),
      render: ({ actorFirstName, actorLastName, title }) => `${actorFirstName} ${actorLastName} commented on ${title}.`,
    },
    plannedDateCongressNotified: {
      initialState: () => ({ activeView: barViewKeys.congressionalDates }),
      render: ({ eventDate, title }) => `Congressional notification for ${title} is planned for ${moment(eventDate).format(dateFormats.short)}.`,
    },
    scheduledForCN: {
      initialState: () => ({}),
      render: () => ``,
    },
    twoLetterCoord: {
      initialState: () => ({ activeView: barViewKeys.twoLetterCoord }),
      render: ({ actor, title }) => (<><DisplayUser userId={actor} /> added a file to 2-Letter Coord Package for {title}.</>),
    },
    readyForReview: {
      initialState: () => ({}),
      render: ({ title }) => `${title} is ready for review.`,
    },
    notReadyForReview: {
      initialState: () => ({}),
      render: ({ title }) => `${title} has been marked NOT ready for review.`,
    },
    barReviewedBySaf: {
      initialState: () => ({}),
      render: ({ title }) => `${title} has been reviewed by SAF.`,
    },
    barNotReviewedBySaf: {
      initialState: () => ({}),
      render: ({ title }) => `${title} has NOT been reviewed by SAF.`,
    },
  };
  useEffect(() => {
    const fetchNotifications = async () => {
      const { data: results } = await axios.get('/notifications/mine');
      results.sort((a, b) =>{
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        return 0;
      })
      results && setNotifications(results);
    }

    fetchNotifications();
  }, []);

  const renderNotification = (notification) => {
    const type = notificationsMap[notification.type];

    return (
      <div key={notification.id} className={cn(styles.activityRow, notification.type)}>
        <DisplayBarLink className={styles.activityLink} barId={notification.actionId} initialState={type.initialState(notification)}>
          {type && type.render(notification)}
        </DisplayBarLink>

        <span className={styles.activityDate}>{moment(notification.createdAt).fromNow()}</span>
        <CloseButton className={styles.activityClose} onClick={removeNotification(notification.id)} />
      </div>
    );
  }

  return (
    <div>
      <h5 className={styles.panelHeading}>ACTIVITY</h5>
      <div className={styles.tile}>
        {notifications.map(renderNotification)}
        {notifications.length === 0 && <p className={styles.noResults}>No recent activity</p>}
      </div>
    </div>
  );
}

export default ActivityFeed;