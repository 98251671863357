import styles from './InvalidAction.module.scss';

// error page for when a bar ID does not exist
const InvalidAction = () => {
  return (
    <div className={styles.invalidAction}>
      <h3>You attempted to view an invalid basing action.</h3>
    </div>
  )
}

export default InvalidAction;