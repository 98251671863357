import { useEffect, useState } from 'react';
import Button from '../../../buttons/Button';
import styles from '../../Admin.module.scss';
import DetailsText from '../../../DetailsText';
import DisplayStaticResource from '../../../DisplayStaticResource';
import ReplaceModal from './ReplaceModal';

const StaticResources = () => {
  const [modalTitle, setModalTitle] = useState('');
  const [modalType, setModalType] = useState(null);
  const [keys, setKeys] = useState({});

  const showModal = (title, type) => () => {
    setModalTitle(title);
    setModalType(type);
  }

  const closeModal = () => {
    setModalTitle('');
    setModalType(null);
  }

  const onSuccess = (type) => {
    setKeys({ ...keys, [type]: (new Date()).valueOf() })
  }

  const renderResourceByType = (sectionLabel, detailsText, title, type) => {
    return (
      <div className={styles.resourceSection}>
        <h4>{sectionLabel}</h4>
        <DetailsText>{detailsText}</DetailsText>
        {keys[type] && <DisplayStaticResource className={styles.staticResourceLink} id={keys[type]} type={type} />}

        <div>
          <Button className={styles.staticResourceReplace} onClick={showModal(`Replace ${title}`, type)} primary>Replace</Button>
        </div>
      </div>
    );
  }

  useEffect(() => {
    const now = (new Date()).valueOf();

    setKeys({
      snakechart: now,
      enterprisemap: now
    });
  }, []);

  return (
    <>
      <h3 className={styles.subTitle}>Update static files and templates used throughout the basing process</h3>

      {renderResourceByType(
        'We Are Here',
        'Default snake chart file',
        'Snake Chart',
        'snakechart'
      )}
      {renderResourceByType(
        'Enterprise Map',
        'Blank map template used in Enterprise Definitions',
        'Enterprise Map',
        'enterprisemap'
      )}

      {modalType && <ReplaceModal onClose={closeModal} onSuccess={onSuccess} title={modalTitle} type={modalType} />}
    </>
  );
};

export default StaticResources;