import React from 'react';
import NavListHeading from '../SideNav/NavListHeading';
import NavList from '../SideNav/NavList';
import NavListItem from '../SideNav/NavListItem';
import { adminViewKeys, adminViewMapping } from '../../constants/adminViewMapping';

const AdminSideNav = ({ activeView, newRequestsCount, newActionsCount, setActiveView }) => {
  return (
    <>
      <NavListHeading>User Management</NavListHeading>

      <NavList>
        <NavListItem onClick={() => setActiveView(adminViewKeys.newAccountRequests)} isActive={activeView === adminViewKeys.newAccountRequests}>
          {adminViewMapping[adminViewKeys.newAccountRequests].navTitle} {newRequestsCount > 0 ? `(${newRequestsCount})` : null}
        </NavListItem>
        <NavListItem onClick={() => setActiveView(adminViewKeys.contactsGroups)} isActive={activeView === adminViewKeys.contactsGroups}>
          {adminViewMapping[adminViewKeys.contactsGroups].navTitle}
        </NavListItem>
      </NavList>

      <NavListHeading>Basing Action Management</NavListHeading>

      <NavList>
        <NavListItem onClick={() => setActiveView(adminViewKeys.newActionRequests)} isActive={activeView === adminViewKeys.newActionRequests}>
          {adminViewMapping[adminViewKeys.newActionRequests].navTitle} {newActionsCount > 0 ? `(${newActionsCount})` : null}
        </NavListItem>
        <NavListItem onClick={() => setActiveView(adminViewKeys.actionEquity)} isActive={activeView === adminViewKeys.actionEquity}>
          {adminViewMapping[adminViewKeys.actionEquity].navTitle}
        </NavListItem>
        <NavListItem onClick={() => setActiveView(adminViewKeys.actionsOfInterest)} isActive={activeView === adminViewKeys.actionsOfInterest}>
          {adminViewMapping[adminViewKeys.actionsOfInterest].navTitle}
        </NavListItem>
      </NavList>

      <NavListHeading>Special Actions</NavListHeading>

      <NavList>
        <NavListItem onClick={() => setActiveView(adminViewKeys.databaseQuery)} isActive={activeView === adminViewKeys.databaseQuery}>
          {adminViewMapping[adminViewKeys.databaseQuery].navTitle}
        </NavListItem>
      </NavList>

    </>
  );
}

export default AdminSideNav;
