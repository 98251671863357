import React from 'react';
import cn from 'classnames';
import Button from '../buttons/Button';
import styles from './Search.module.scss';
import useCachedData from '../hooks/useCachedData';
import { DictionaryConsumer } from '../Dictionary/DictionaryContext'
import DisplayDate from '../DisplayDate';
import Anchor from '../Anchor';
import dateFormats from '../../constants/dateFormats';
import Select from 'react-select';
import Sort from '../svg/Sort';
import SortAlpha from '../svg/SortAlpha';
import DisplayFile from '../DisplayFile';
import DisplayBarLink from '../DisplayBarLink';
import pluralize from '../../utils/pluralize';

const days = [
  {
    label: '30 days',
    value: 30
  },
  {
    label: '60 days',
    value: 60
  },
  {
    label: '90 days',
    value: 90
  },
  {
    label: 'Year',
    value: 365
  },
  {
    label: 'All Time',
    value: 0
  }
];

const SearchResults = ({ results, searchType, sortByLastUpdated, setSortByLastUpdated, setSortAscending, sortAscending, daysToShow, setDaysToShow }) => {
  const { data: phases = {} } = useCachedData('/content/phases');
  const { data: steps = {} } = useCachedData('/content/steps');

  const onDaysToShowChange = (option) => setDaysToShow(option.value);
  const daysSelected = days.find(day => day.value === daysToShow);

  const onClickSortDate = () => {
    if (sortByLastUpdated) {
      setSortAscending(!sortAscending);
    }
    else {
      setSortAscending(true);
      setSortByLastUpdated(true)
    }
  };

  const onClickSortAlpha = () => {
    if (!sortByLastUpdated) {
      setSortAscending(!sortAscending);
    } else {
      setSortAscending(true);
      setSortByLastUpdated(false);
    }
  }

  const renderUpdated = (result) => (
    <>| Updated <DisplayDate date={result.updatedAt} format={dateFormats.short} />{result.lastUpdatedBy && ` by ${result.lastUpdatedBy}`}</>
  );

  const renderResult = (result) => (
    <div className={styles.result} key={result.id}>
      {searchType.resultType === 'action' && (
        <>
          <p className={styles.resultHeading}><DictionaryConsumer term={result.typeOfAction} /> {renderUpdated(result)}</p>
          <DisplayBarLink className={styles.resultTitle} barId={result.id}>{result.title}</DisplayBarLink>
          <p className={styles.resultDetails}>
            {result.currentPhase && phases[result.currentPhase]} | {result.currentStep && steps[result.currentStep]} | SAF AO: {result.assignee}
          </p>
          {result.source && <p className={styles.resultSource}>Returned based on {result.source} match</p>}
        </>
      )}
      {searchType.resultType === 'contact' && (
        <>
          <p>
            <span className={styles.delimited}>{result.pocRank} {result.firstName} {result.lastName}</span>
            {result.capacity && <span className={styles.delimited}>{result.capacity}</span>}
            {result.officeSymbol && <span className={styles.delimited}>{result.officeSymbol}</span>}
          </p>
          <p>
            {result.niprEmail && <span className={styles.delimited}>NIPR Email: <Anchor href={`mailto:${result.niprEmail}`}>{result.niprEmail}</Anchor></span>}
            {result.siprEmail && <span className={styles.delimited}>SIPR Email: <Anchor href={`mailto:${result.siprEmail}`}>{result.siprEmail}</Anchor></span>}
          </p>
          {result.commercialPhone && <p className={styles.resultPhone}>Commercial: {result.commercialPhone}</p>}
          {result.dsnPhone && <p className={styles.resultPhone}>DSN: {result.dsnPhone}</p>}
          {result.securePhone && <p className={styles.resultPhone}>Secure: {result.securePhone}</p>}
        </>
      )}
      {searchType.resultType === 'package' && (
        <>
          {result.generatedDate && <p className={styles.resultHeading}>Generated <DisplayDate date={result.generatedDate} format={dateFormats.short} /> by {result.generatedBy}</p>}
          {result.docId && <DisplayFile uuid={result.docId} />}
          {((new Date(result.date)).valueOf() < (new Date()).valueOf()) && <p>{result.meetingType} reviewed on <DisplayDate date={result.date} format={dateFormats.short} /></p>}
        </>
      )}
      {searchType.resultType === 'document' && (
        <>
          {result.uploadedBy && <p className={styles.resultHeading}>Added <DisplayDate date={result.updatedAt} format={dateFormats.short} /> by {result.uploadedBy}</p>}
          {result.uuid && <DisplayFile uuid={result.uuid} />}
          {result.barTitle && <p>Document attached to {result.barTitle}</p>}
        </>
      )}
    </div>
  );

  return (
    <div className={styles.searchResults}>
      {results.length > 0 ? (
        <>
          <div className={styles.resultsHeader}>
            <h4 className={styles.resultsCount}>{results.length} Matching {pluralize(results.length, 'Result', 'Results')}</h4>

            <div className={styles.sortButtons}>
              <Button className={cn(styles.sortButton, styles.sortButtonDate, {
                [styles.sortSelected]: sortByLastUpdated
              })}
              onClick={onClickSortDate}><Sort />Last Updated</Button>
              <Button className={cn(styles.sortButton, styles.sortButtonAlpha, {
                [styles.sortSelected]: !sortByLastUpdated
              })}
              onClick={onClickSortAlpha}><SortAlpha reversed={!sortByLastUpdated && !sortAscending} /></Button>
            </div>
          </div>

          {results.map(renderResult)}

          {searchType.showDaysOptions && (<div className={styles.daysToShow}>
            <p className={styles.daysToShowHeading}>Show the Last</p>
            <Select
              className={cn(styles.daysToShowSelect)}
              classNamePrefix='reactSelect'
              id='daysToShow'
              name='daysToShow'
              onChange={onDaysToShowChange}
              defaultValue={days[0]}
              value={daysSelected}
              options={days}
            />
          </div>)}
        </>
      ) : (
        <h3 className={styles.noResultsFound}>No Search Results Found</h3>
      )}
    </div>
  );
}

export default SearchResults;