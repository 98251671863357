import React, { useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import Grid from '../../Grid';
import FieldArray from '../../Form/FieldArray';
import DateField from '../../Form/DateField';
import SelectField from '../../Form/SelectField';
import AddButton from '../../buttons/AddButton';
import CloseButton from '../../buttons/CloseButton';
import useCachedData from '../../hooks/useCachedData';
import Label from '../../Form/Label';
import styles from './PreferredLocationDecision.module.scss';
import { Field, useFormState } from 'react-final-form';
import getBaseOptions from '../../../utils/getBaseOptions';
import Wysiwig from '../../Form/Wysiwyg';

const PreferredLocationDecisionEdit = () => {
  const { data: bases } = useCachedData('/content/bases');
  const [baseOptions, setBaseOptions] = useState([]);
  const { initialValues: { preferredLocationDecision } = {} } = useFormState({ subscription: { initialValues: true } });

  useEffect(() => {
    if (bases) {
      const formattedBases = getBaseOptions(bases);

      setBaseOptions(formattedBases);
    }
  }, [bases])

  return (
    <>
      <div className={styles.margin}>
        <Label text='Approved On' required />
        <DateField name='preferredLocationDecisionDate' />
      </div>
      
      <FieldArray name='preferredLocationDecision' initialValue={preferredLocationDecision || null} ignoredKeys={['type']}>
        {({ fields, add, remove, isMinLength }) => {
          let preferredIndex = fields.value.findIndex((val) => val && val.type === 'preferred');
          if (preferredIndex === -1) {
            preferredIndex = preferredLocationDecision.length;
          }

          return (
            <>
              <div className={styles.margin}>
                <h3>Preferred Location</h3>
                <div className={styles.entry}>
                  <Grid>
                    <Box>
                      <Label text='Location' required />
                      <SelectField name= {`preferredLocationDecision[${preferredIndex}].baseId`} options= {baseOptions}  isSearchable/>
                    </Box>
                  </Grid>
                  <Grid>
                    <Box>
                      <Label text='Rationale' required />
                      <Wysiwig name={`preferredLocationDecision[${preferredIndex}].rationale`} />
                    </Box>
                  </Grid>
                  <Field component='input' type='hidden' name={`preferredLocationDecision[${preferredIndex}].type`} defaultValue='preferred' />
                </div>
              </div>

              <h3>Alternative Locations</h3>
              {fields.map((name, index) => index !== preferredIndex ? (
                <div className={styles.entry} key={name}>
                  <Grid absoluteChild={!isMinLength && <CloseButton onClick = {remove(index)} />}>
                    <Box>
                      <Label text='Location' required />
                      <SelectField name={`${name}.baseId`} options={baseOptions} isSearchable/>
                    </Box>
                  </Grid>
                  <Grid>
                    <Box>
                      <Label text='Rationale' required />
                      <Wysiwig name={`${name}.rationale`} />
                    </Box>
                  </Grid>
                  <Field component='input' type='hidden' name={`${name}.type`} defaultValue='alternative' />
                </div>
              ) : null)}
              <AddButton onClick={() => add()} secondary>Add Location</AddButton>
            </>
          )}
        }
      </FieldArray>
    </>
  );
}

export default PreferredLocationDecisionEdit;
