import React from 'react';
import { useFormState } from 'react-final-form';
import phaseAbbreviations from '../../../../constants/phaseAbbreviations';
import PhaseLegend from '../../PhaseLegend';
import { dictionaryValue } from '../../../Dictionary/DictionaryContext'
import styles from './UpcomingDecisions.module.scss'
import TextField from '../../../Form/TextField';

const PendingMFREdit = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });

  return (
    <>
      {initialValues.mfr?.map(({ needApprovalFrom, bars }, mfrIndex) => (
        <React.Fragment key={needApprovalFrom}>
          <div className={styles.flexTable}>
            <h3 className={styles.barTitleFlex}>{needApprovalFrom === 'null' ? 'No Approval Requested' : dictionaryValue(needApprovalFrom)}</h3>
            {mfrIndex === 0 && <h3 className={styles.statusFlex}>Status</h3>}
          </div>
            {bars && bars.length > 0 && (
              <ul>
                {bars.map(({currentPhase, title }, index) => (
                  <li className={styles.flexTable} key={index}><li className={styles.barTitleFlex}><b>{phaseAbbreviations[currentPhase]?.short}</b> - {title}</li><div className={styles.statusFlex}><TextField name={`mfr[${mfrIndex}].bars[${index}].decisionMfrStatus`} maxLength={19} /></div></li>
                ))}
              </ul>
            )}
        </React.Fragment>
      ))}
      <PhaseLegend />
    </>
  );
};

export default PendingMFREdit;