import React from 'react';
import { useForm } from 'react-final-form';
import Label from '../../Form/Label';
import Wysiwig from '../../Form/Wysiwyg';
import BaseSelector from "../candidateDecision/BaseSelector";

// formerly "RationaleForDecisionEdit"
const SummaryOfFindingsEdit = () => {
    const form = useForm();
    const { initialValues } = form.getState();
    const { candidateBases, rationaleForDecisionLocations } = initialValues;
    return (
        <>
            <Label text={<h3>Overview</h3>} required />
            <Wysiwig name='rationaleForBaseDecisionOverview' />
            <BaseSelector name='rationaleForDecisionLocations' initialValue={rationaleForDecisionLocations || candidateBases || null} />
        </>
    );
}

export default SummaryOfFindingsEdit;
