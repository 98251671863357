import React from 'react';
import DetailsText from '../../DetailsText';
import UploadField from '../../Form/UploadField';

const WeAreHereEdit = ({deleteFile}) => {
    return (
        <>
            <DetailsText>Please upload an image file.  Allowable image types are .PNG, .JPEG, and .JPG</DetailsText>
            <UploadField name='weAreHere' deleteFile={deleteFile} removeLabel='Remove Diagram' staticResourceType='snakechart' />
        </>
    )
}


export default WeAreHereEdit;
