import React from 'react';
import Wysiwig from '../../Form/Wysiwyg';
import DetailsText from '../../DetailsText';
import Label from '../../Form/Label';
import styles from '../../Form/Form.module.scss';

const BackgroundEdit = () => {
  return (
    <div>
      <Label text={<h3 className={styles.sectionHeading}>Overview</h3>} required />
      <DetailsText>Identify authority or driver behind the basing action (new weapon system, CSAF-directed)<br />
      Provide any information on actions pertaining to the BAR or related issues<br />
      Describe the CONOPs for the mission:How does the mission operate?</DetailsText>
      <Wysiwig name='overView' />

      <Label text={<h3 className={styles.sectionHeading}>Significant Views of Others</h3>} />
      <DetailsText>Provide any significant views of others (Senior Leaders, other services, other Dept of the Air Force Basing Office)</DetailsText>
      <Wysiwig name='significantViewOfOthers' />

      <Label text={<h3 className={styles.sectionHeading}>Status of Funding and Manpower</h3>} />
      <DetailsText>Identify the funding source, and describe the funding stream throughout the FYDP<br />
      Provide the list of MILCON and other construction projects as well as POM status for those projects.</DetailsText>

      <Wysiwig name='fundingAndManpowerStatus' />
    </div>
  );
}

export default BackgroundEdit;
