import React from 'react';
import useCachedData from '../../hooks/useCachedData';
import BackupSlide from './BackupSlide';

const SomfPublicEducation = (props) => {
  const { data: keys } = useCachedData('/content/barViewKeys');
  
  return (
    <BackupSlide titleKey={keys && keys.somfPublicEducation} {...props} />
  );
}

export default SomfPublicEducation;
