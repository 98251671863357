import cn from 'classnames';
import { useField } from 'react-final-form';
import Label from './Label';
import has from 'lodash/has';
import styles from './Form.module.scss';

// display data from values instead of initialValues
const ReadOnlyField = ({ className, label, name, required }) => {
  const { input } = useField(name);
  const renderedValue = has(input.value, 'value') ? input.value.value : input.value;

  return (
    <div className={cn(styles.fieldControl, className)}>
      {label && <Label htmlFor={name} required={required} text={label} />}
      <p className={styles.readOnlyValue}>{renderedValue}</p>
    </div>
  );
}

export default ReadOnlyField;
