import { useEffect, useState } from 'react';
import Modal from '../../Modal';
import ModalFooter from '../../Modal/ModalFooter';
import { Form } from 'react-final-form';
import SelectField from '../../Form/SelectField';
import Button from '../../buttons/Button';
import styles from './BarDetail.module.scss';
import axios from '../../../utils/sharedAxios';
import useCachedData from '../../hooks/useCachedData';

const EditStatusModal = ({ barId, initialValues, onClose, onMoveSuccess }) => {
  const { data: safAos } = useCachedData('/users/safAos');
  const [safOptions, setSafOptions] = useState([]);

  const onSubmit = async (values) => {
    const phase = values?.phase?.value || null;
    const step = values?.step?.value || null;
    const status = values?.status?.value || null;
    const assignee = values?.assignee?.value || null;

    if (phase !== initialValues.phase || step !== initialValues.step || status !== initialValues.status || assignee !== initialValues.assignee) {
      await axios.post('/bars/move', {id: barId, phase, step, status, assignee})
        .then(result => {
          if (result.data.success) {
            onMoveSuccess && onMoveSuccess();
          }
        });
    }

    onClose && onClose();
  }

  useEffect(()=>{
    safAos && setSafOptions(safAos.map(saf => ({ label: saf.firstName + ' ' + saf.lastName, value: saf.id })));
  }, [safAos]);
  return (
    <>
      <Modal className={styles.editModal} onClose={onClose} title='Edit Status'>
        <Form onSubmit={onSubmit} initialValues={initialValues} subscription={{ submitting: true }}>
          {({ form, handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} noValidate>
              <SelectField fieldDataEndpoint='/content/phaseOptions' label='Current Phase' name='phase' isClearable={false} />
              <SelectField fieldDataEndpoint='/content/stepOptions' label='Current Step' name='step' isClearable={false} />

              <SelectField fieldDataEndpoint='/content/status' label='Status' name='status' isClearable={false} />

              <SelectField label='SAF Action Officer' name='assignee' options={safOptions} isClearable={false} />
              <ModalFooter className={styles.buttonRow}>
                <Button type="submit" primary disabled={submitting}>SAVE</Button>
                <Button onClick={onClose} secondary>CANCEL</Button>
              </ModalFooter>
            </form>
          )}
        </Form>
      </Modal>


    </>
  );
}

export default EditStatusModal;