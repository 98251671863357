import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { useField } from 'react-final-form';
import moment from 'moment'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react-dates-overrides.scss';
import styles from '../Form.module.scss';
import Label from '../Label';

const parse = (date) => date ? date.toISOString() : '';
const format = (date) => date ? moment(date) : '';

const DateField = ({ startDate, label, name, placeholder = 'MM/DD/YYYY', required, initialValue, showClearDate, ...props }) => {
  const { input } = useField(name, { format, parse, initialValue });
  const [isFocused, setIsFocused] = useState(false);

  const onChange = (m) => input.onChange(moment(m).endOf('day'));

  return (
    <div className={styles.fieldControl}>
      {label && <Label text={label} required={required} />}
      <SingleDatePicker
        date={input.value || null}
        onDateChange={onChange}
        focused={isFocused}
        onFocusChange={() => setIsFocused(!isFocused)}
        id={name}
        isOutsideRange={() => false}
        placeholder={placeholder}
        verticalSpacing={10}
        showClearDate={showClearDate}
        {...props}
        />
    </div>
  );
}

export default DateField;
