import React from 'react';
import NavListHeading from '../SideNav/NavListHeading';
import NavList from '../SideNav/NavList';
import NavListItem from '../SideNav/NavListItem';
import { reportingViewKeys, reportingViewMapping } from '../../constants/reportingViewMapping';

const ReportingSideNav = ({ activeView, setActiveView }) => {
  return (
    <>
      <NavListHeading>Reporting</NavListHeading>
      
      <NavList>
        <NavListItem onClick={() => setActiveView(reportingViewKeys.onDemandReports)} isActive={activeView === reportingViewKeys.onDemandReports}>
          {reportingViewMapping[reportingViewKeys.onDemandReports].navTitle}
        </NavListItem>
      </NavList>
    </>
  );
}

export default ReportingSideNav;
