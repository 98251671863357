import { useContext, useEffect, useState } from 'react';
import { AuthContext } from './Utility/Authentication';

// shows / hides content according to user's role
const AuthorizedContent = ({ children, authorizedRoles }) => {
  const { user } = useContext(AuthContext);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (user.role && authorizedRoles) {

      if (Array.isArray(authorizedRoles)) {
        const lowerCaseRoles = authorizedRoles.map(role => role.toLowerCase());
        setIsAuthorized(lowerCaseRoles.includes(user.role.toLowerCase()))
      }

      if (typeof authorizedRoles === 'string') {
        setIsAuthorized(authorizedRoles.toLowerCase() === user.role.toLowerCase())
      }
    }

  }, [user.role, authorizedRoles]);

  return isAuthorized ? children : null;
}

export default AuthorizedContent;