import React from 'react';
import { useFormState } from 'react-final-form';
import cn from 'classnames';
import useCachedData from '../../hooks/useCachedData';
import _ from 'lodash';
import styles from './EnterpriseWideLook.module.scss';
import formStyles from '../../Form/Form.module.scss';

const EnterpriseScoresDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });
  const scoresFileKey = _.get(initialValues, 'enterpriseComponents.componentScores.fileKey');
  
  const { data: scoresSrc } = useCachedData(scoresFileKey && '/documents/image', {params: { docId: scoresFileKey } });

  return (
    <>
      <h3 className={cn(styles.headers, formStyles.sectionHeading)}>Scores Chart</h3>
      {scoresSrc && (
        <>
          <img src={scoresSrc} />
        </>
      )}
    </>
  );
}

export default EnterpriseScoresDisplay;
