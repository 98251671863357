import { useEffect, useState } from 'react';
import Button from '../../../buttons/Button';
import EditButton from '../../../buttons/EditButton';
import useCacheInvalidator from '../../../hooks/useCacheInvalidator';
import useCachedData from '../../../hooks/useCachedData';
import Table from '../../../Table';
import ContactFormModal from './ContactFormModal';
import CreateGroupModal from './CreateGroupModal';
import ConfirmDeleteContactModal from './ConfirmDeleteContactModal';
import axios from '../../../../utils/sharedAxios';
import DisplayContactGroup from '../../../DisplayContactGroup';
import DisplayUserRole from '../../../DisplayUserRole';
import stripValuesFromObject from '../../../../utils/stripValuesFromObject';
import ConfirmDeleteGroupModal from './ConfirmDeleteGroupModal';
import adminStyles from '../../Admin.module.scss';
import styles from './ContactsGroups.module.scss';
import Select from '../../../Select';

const ContactsGroups = () => {
  const { shouldInvalidate, setShouldInvalidate } = useCacheInvalidator();
  const { data: groups } = useCachedData('/users/groups', { invalidate: shouldInvalidate });
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const [editingContactId, setEditingContactId] = useState(null);
  const [editingContact, setEditingContact] = useState(null);
  const [deletingContactId, setDeletingContactId] = useState(null);
  const [deletingContact, setDeletingContact] = useState(null);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [newGroupName, setNewGroupName] = useState(null);
  
  const getLatestContacts = async () => {
    const response = await axios.get('/users/all');
    response.data && setContacts(response.data);
  };

  const openEditModal = (id) => () => {
    setEditingContact(contacts.find(contact => contact.id === id));
    setEditingContactId(id);
  }

  const closeEditModal = () => {
    setEditingContact(null);
    setEditingContactId(null);
  }

  const openDeleteModal = () => {
    setDeletingContact(editingContact);
    setDeletingContactId(editingContactId);
  }

  const closeDeleteModal = () => {
    setDeletingContact(null);
    setDeletingContactId(null);
  }

  const handleSelectGroup = (option) => setSelectedGroup(option);

  const handleCreateGroup = async (values) => {
    await axios.post('/admin/add-group', { ...values });

    setNewGroupName(values.name);
    setShouldInvalidate(true);
    setShowCreateGroupModal(false);
  }
  
  const handleDeleteGroup = async () => {
    await axios.post('/admin/remove-group', { id: selectedGroup.value });
    setShouldInvalidate(true);
    setShowDeleteGroupModal(false);
  }

  const handleAddContact = async (values) => {
    await axios.post('/users/addContact', { ...(stripValuesFromObject(values))});
    await getLatestContacts();
    setShowAddContactModal(false);
  }

  const handleEditContact = async (values) => {
    await axios.post('/users/edit-user', { id: editingContactId, ...(stripValuesFromObject(values))});
    await getLatestContacts();
    closeEditModal();
  }

  const handleDeleteContact = async () => {
    closeEditModal();
    closeDeleteModal();
    await axios.post('/users/remove-user', { id: deletingContactId });
    await getLatestContacts();
  }

  useEffect(() => {
    getLatestContacts();
  }, []);

  useEffect(() => {
    if (groups) {
      const options = groups.sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();

        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return +1;
        }
        return 0;
      })
      .map(group => ({ label: group.name, value: group.id }));

      setGroupOptions(options);
    }
  }, [groups]);

  useEffect(() => {
    if (groupOptions.length > 0) {
      setSelectedGroup(newGroupName ? groupOptions.find(option => option.label === newGroupName) : groupOptions[0]);
      setNewGroupName(null);
    }
  }, [groupOptions]);

  useEffect(() => {
    if (selectedGroup) {
      setFilteredContacts(contacts.filter(contact => contact.contactGroup === selectedGroup.value));
    }
  }, [contacts, selectedGroup])

  return (
    <>
      <div className={styles.header}>
        <div className={styles.manageContacts}>
          <h3 className={adminStyles.subTitle}>Manage Contacts for Group: </h3>
          <Select
            className={styles.groupSelect}
            isClearable={false}
            name='selectedGroup'
            onChange={handleSelectGroup}
            defaultValue={groupOptions[0]}
            value={selectedGroup}
            options={groupOptions}
            round
          />
        </div>

        <div className={styles.headerButtons}>
          <Button onClick={() => setShowCreateGroupModal(true)} primary>Create Group</Button>
          <Button onClick={() => setShowAddContactModal(true)} primaryAlt>Add Contact</Button>
        </div>
      </div>

      <p className={adminStyles.bodyText}><b>Groups:</b> Contacts are organized and navigated on the Contacts section (accessible to all users). Add a group here, and you can assign contacts to it. Only groups that contain at least one contact will show up in the Contacts section.</p>
      <p className={adminStyles.bodyText}><b>Contacts:</b> Adding contacts here will not grant them access to the system, but their contact info will display in the Contacts section. These contacts have the role “Contact Only” assigned to them automatically. (Note: This is not a role you can assign to new or approved users.)</p>

      <Table>
        <thead>
          <tr>
            <th>Edit / Delete</th>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Rank</th>
            <th>Job Title</th>
            <th>Group</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {filteredContacts.length > 0 ? filteredContacts.map(contact => (
            <tr key={contact.id}>
              <td><EditButton onClick={openEditModal(contact.id)} /></td>
              <td>{contact.lastName}</td>
              <td>{contact.firstName}</td>
              <td>{contact.userRank}</td>
              <td>{contact.capacity}</td>
              <td><DisplayContactGroup groupId={contact.contactGroup} /></td>
              <td>{contact.roleId ? <DisplayUserRole roleId={contact.roleId} /> : 'Contact Only'}</td>
            </tr>
          )) : (
            <tr><td colSpan={7}>No Records Found.</td></tr>
          )}
          
        </tbody>
      </Table>

      {selectedGroup && filteredContacts.length === 0 && <Button onClick={() => setShowDeleteGroupModal(true)} danger>DELETE THIS GROUP</Button>}

      {showCreateGroupModal && (
        <CreateGroupModal
          handleCreateGroup={handleCreateGroup}
          onClose={() => setShowCreateGroupModal(false)} />
      )}

      {showAddContactModal && (
        <ContactFormModal
          title='Add Contact'
          contact={{ contactGroup: selectedGroup }}
          groupOptions={groupOptions}
          handleSave={handleAddContact}
          onClose={() => setShowAddContactModal(false)} />
      )}

      {editingContactId && editingContact && (
        <ContactFormModal
          title='Edit Contact'
          contact={editingContact}
          groupOptions={groupOptions}
          handleSave={handleEditContact}
          openDeleteModal={openDeleteModal}
          onClose={closeEditModal} />
      )}

      {deletingContactId && (
        <ConfirmDeleteContactModal
          contact={deletingContact}
          handleDeleteContact={handleDeleteContact}
          onClose={closeDeleteModal} />
      )}

      {showDeleteGroupModal && (
        <ConfirmDeleteGroupModal
        group={selectedGroup} 
        handleDeleteGroup={handleDeleteGroup}
        onClose={() => setShowDeleteGroupModal(false)} />
      )}
    </>
  );
};


export default ContactsGroups;