import useCachedData from '../../components/hooks/useCachedData';

// displays a user's full name
const DisplayUser = ({ userId }) => {
  const { data } = useCachedData(`/users/${userId}`);

  if (data) {
    const fullName = `${data.firstName} ${data.lastName}`;

    return fullName;
  }

  return null;

}

export default DisplayUser;