import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import styles from './Modal.module.scss';
import CloseButton from '../buttons/CloseButton';

// our standard modal component
const Modal = ({ centered, children, className, onClose, title }) => {
  useEffect(() => {
    document.querySelector('body').style.overflow = 'hidden';
    return () => {
      document.querySelector('body').style.overflow = 'auto';
    };
  }, []);

  return createPortal(
    <div className={styles.modalOverlay}>
      <div className={cn(styles.modal, {[styles.modalCentered]: centered}, className)} role='dialog'>
        {onClose && <CloseButton className={styles.modalCloseIcon} onClick={onClose} />}
        <header className={styles.modalHeader}>
          <h2 className={styles.modalHeaderTitle}>{title}</h2>
        </header>
        <div className={styles.modalContent}>{children}</div>
      </div>
    </div>,
    document.querySelector('#app-root')
  );
};

export default Modal;