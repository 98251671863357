export const timeZonifyDate = (date, asDateTime) => {
    let result = null;
    if(date) {
        const d = new Date(date);
        if(d.getTime()) {
            const dateOffset = new Date().getTimezoneOffset() * 60 * 1000;
            result = d.getTime() - dateOffset
            if(asDateTime) {
                result = new Date(result)
            }
        }
    }
    return result;
}