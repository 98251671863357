import Anchor from '../Anchor';
import useFetchData from '../hooks/useFetchData';

// link to download file
const DisplayStaticResource = ({ className, id, label, type }) => {
  const downloadEndpoint = '/staticResources/download';
  const endpoint = '/staticResources/type';
  const options = { params: { type }, id }; // id is only used to force a re-fetch in case props change
  const { data: { fileName = '', uuid } = {} } = useFetchData(endpoint, options);
  const href = `${process.env.REACT_APP_API_URL}${downloadEndpoint}?${new URLSearchParams({ uuid, fileName }).toString()}`

  return fileName ? <Anchor className={className} href={href} download={fileName}>{label || fileName}</Anchor> : null;
}

export default DisplayStaticResource;