import { useState } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Button from '../../../buttons/Button';
import AddButton from '../../../buttons/AddButton';
import CloseButton from '../../../buttons/CloseButton';
import FieldArray from '../../../Form/FieldArray';
import SelectField from '../../../Form/SelectField';
import RadioField from '../../../Form/RadioField';
import Grid from '../../../Grid';
import Box from '../../../Box';
import axios from '../../../../utils/sharedAxios';
import styles from '../../Reporting.module.scss'
import Spinner from '../../../Spinner';
import moment from 'moment';
import {downloadCsv} from '../../../../utils/download.js';

const templateColumns = [2, '4fr 1fr'];
const initialValues = {
  allActions: 1
};

const ActionAgingReport = ({ barOptions }) => {
  const [loading, setLoading] = useState(false);

  const onFormSubmit = async (values) => {
    setLoading(true);
    const body = {format: 'csv'};

    if (values.allActions === 0 && values.bars) {
      body.barIds = values.bars.map(({ bar }) => bar.value);
    }

    const result = await axios.post('/reports/action-aging', body);
    if(result && result.data && typeof result.data === 'string'){
      const date = moment().format('D MMM YYYY kkm')
      downloadCsv(`Action Aging Report ${date}.csv`, result.data);
    }
    setLoading(false);
  };

  const validate = (values) => {
    const errors = {};

    if (values.allActions === 0 && (!values.bars || values.bars.every(item => !item?.bar?.value))) {
      errors._error = 'No actions selected';
    }

    return errors;
  }

  return (
    <>
      <h3 className={styles.subTitle}>Action Aging Report</h3>
      <p className={styles.bodyText}>Generates a report that shows the time durations between the movement of phase and step for all actions in the system.</p>

      <Form onSubmit={onFormSubmit} mutators={{ ...arrayMutators }} initialValues={initialValues} subscription={{ submitting: true, values: true, invalid: true  }} validate={validate} destroyOnUnregister>
        {({ form, handleSubmit }) => {
          const { invalid, submitting, values } = form.getState();

          return (
            <>
              <RadioField className={styles.radio} name='allActions' horizontal={false} options={[
                {label: 'All Actions', value: 1},
                {label: 'Select Actions', value: 0},
              ]} required />

              {values.allActions === 0 && (
                <FieldArray name='bars'>
                  {({ fields, add, remove, isMinLength }) => (
                    <>
                      {fields.map((name, index) => (
                        <Grid key={name} columns={templateColumns} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
                          <Box><SelectField name={`${name}.bar`} isSearchable options={barOptions} /></Box>
                        </Grid>
                      ))}
                      <AddButton onClick={()=> add()} secondary>Add an Action</AddButton>
                    </>
                  )}
                </FieldArray>

              )}

              <Button className={styles.submit} onClick={handleSubmit} primary disabled={invalid || submitting}>GENERATE AND DOWNLOAD</Button>
            </>
          );
        }}
      </Form>
      <Spinner on={loading} />
    </>
  );
}

export default ActionAgingReport;