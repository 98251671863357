import { useField } from 'react-final-form';
import cn from 'classnames';
import useCachedData from '../hooks/useCachedData';
import has from 'lodash/has';
import styles from './Form.module.scss';
import DisplayFacility from '../bars/DisplayFacility';

// non-editable Facility field reading form values instead of initialValues
const ReadOnlyFacilityField = ({ className, name }) => {
  const { data: facilityData } = useCachedData('/content/facilityRequirement');
  const { input: { value: facilityOption } } = useField(`${name}.facility`);
  const { input: { value: quantity } } = useField(`${name}.quantity`);
  const { input: { value: totalAreaSqft } } = useField(`${name}.totalAreaSqft`);
  const { input: { value: notes } } = useField(`${name}.notes`);
  const facility = has(facilityOption, 'value') ? facilityOption.value : facilityOption;
  const getFacilityOption = (facilityValue) => facilityData?.options && facilityData.options.find(({ value }) => value === facilityValue);
  const { areaType, unitType } = getFacilityOption(facility) || {};

  return (
    <DisplayFacility
      className={cn(styles.readOnlyFieldValue, className)}
      areaType={areaType}
      notes={notes}
      quantity={quantity}
      selection={facility}
      totalAreaSqft={totalAreaSqft}
      unitType={unitType}
      />
  );
}

export default ReadOnlyFacilityField;
