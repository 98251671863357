const stripTagsFromString = (string, allowedTags) => {
  allowedTags = Array.isArray(allowedTags) ? allowedTags : [];

  const whiteList = allowedTags.join('|');
  const reAttributes = `\\s*[\\w*\\-*]+=\\"([^"]*)\\"`;
  const regex = new RegExp(`(?:<span\>n<\/span\>)|((\B<span style="mso-special-format: bullet;(.*?)>\B))(\\<\\/?${whiteList ? `(?!${whiteList})` : ''}[A-Za-z0-9]*)(${reAttributes})*\\>|${reAttributes}`, 'gim');
  const newString = string.replace(regex, '');
  return newString;
};

export default stripTagsFromString;