import React from 'react';
import UploadField from '../../Form/UploadField';
import styles from '../../Form/Form.module.scss';

const EnterpriseScoresEdit = ({deleteFile}) => {
  return (
    <div>
      <h3 className={styles.sectionHeading}>Scores Chart</h3>
      <UploadField name='enterpriseComponents.componentScores' deleteFile={deleteFile} type='enterpriseComponent' />
    </div>
  );
}

export default EnterpriseScoresEdit;