import { useContext } from 'react';
import cn from 'classnames';
import { AuthContext } from '../Utility/Authentication';
import ApprovedActions from './ApprovedActions';
import CongressNotified from './CongressNotified';
import styles from './Dashboard.module.scss';

const ApprovalsNotificationsPanel = () => {
  const { user: { role } = {} } = useContext(AuthContext);
  const displayHorizontal = (['KRIS', 'LEE'].includes(role));
  
  return (
    <div className={styles.panel}>
      <h5 className={styles.panelHeading}>{'APPROVALS & \nNOTIFICATIONS'}</h5>
      
      <div className={cn({
        [styles.chipRows]: !displayHorizontal,
        [styles.chipColumns]: displayHorizontal
      })}>
        <ApprovedActions />
        <CongressNotified />
      </div>
      
    </div>
  );
}

export default ApprovalsNotificationsPanel;
