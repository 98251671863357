import Logo from '../svg/Logo';
import styles from './Spinner.module.scss';

// full page overlay with logo indicates a process and locks the user in place until completed
const Spinner = ({ on, content }) => {
  return on ? (
    <div className={styles.overlay}>
      <Logo className={styles.logo} />
      <h1 className={styles.spinner}>{content ? content : 'Loading'}</h1>
    </div>
  ) : null;
};

export default Spinner;