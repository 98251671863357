import React from 'react';
import UploadField from '../../Form/UploadField';
import styles from './EnterpriseWideLook.module.scss';
import formStyles from '../../Form/Form.module.scss';

const EnterpriseWideLookEdit = ({deleteFile}) => {
  return (
    <div>
      <h3 className={formStyles.sectionHeading}>Mission</h3>
      <UploadField name='enterpriseComponents.mission' deleteFile={deleteFile} type='enterpriseComponent' />
      <div className={styles.spacer}></div>
      <h3 className={formStyles.sectionHeading}>Capacity</h3>
      <UploadField name='enterpriseComponents.capacity' deleteFile={deleteFile} type='enterpriseComponent' />
      <div className={styles.spacer}></div>
      <h3 className={formStyles.sectionHeading}>Environmental</h3>
      <UploadField name='enterpriseComponents.environmental' deleteFile={deleteFile} type='enterpriseComponent' />
      <div className={styles.spacer}></div>
      <h3 className={formStyles.sectionHeading}>Cost</h3>
      <UploadField name='enterpriseComponents.cost' deleteFile={deleteFile} type='enterpriseComponent' />
      <div className={styles.spacer}></div>
      <h3 className={formStyles.sectionHeading}>Support of Military Families</h3>
      <UploadField name='enterpriseComponents.somf' deleteFile={deleteFile} type='enterpriseComponent' />
    </div>
  );
}

export default EnterpriseWideLookEdit;