import Button from '../../../buttons/Button';
import Modal from '../../../Modal';
import ModalFooter from '../../../Modal/ModalFooter';
import styles from '../../Admin.module.scss';

const ConfirmDeleteGroupModal = ({ group, handleDeleteGroup, onClose }) => {
  return (
    <Modal className={styles.modalMedium} onClose={onClose} title='Delete Group?'>
      <p>Are you sure you want to delete the group {group.label}?</p>
      
      <ModalFooter>
        <Button onClick={handleDeleteGroup} danger>DELETE</Button>
        <Button onClick={onClose} secondary>CANCEL</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmDeleteGroupModal;