import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import BasingActionsFilter from './BasingActionFilters';
import TopMatrix from "./TopMatrix";
import axios from '../../utils/sharedAxios';
import _ from 'lodash';
import BarsTable from './BarsTable';
import styles from './BarLanding.module.scss';
import Header from '../Header';
import useCachedData from '../hooks/useCachedData';
import useDocumentTitle from '../hooks/useDocumentTitle';

const setInitialFilters = search => {
    const params = new URLSearchParams(search);
    let initialFilters = {};

    for (const [key, value] of params.entries()) {
        const num = parseInt(value, 10);
        initialFilters[key] = isNaN(num) ? value : num;
    }

    return initialFilters;
}

const BasingActionLanding = () => {
    useDocumentTitle('Basing Actions');
    const { search } = useLocation();
    const [bars, setBars] = useState([]);
    const [matrixBars, setMatrixBars] = useState([]);
    const [filters, setFilters] = useState(setInitialFilters(search));
    const { data: steps } = useCachedData('/content/steps');
    const { data: phases } = useCachedData('/content/phases');
    const stepsArr = Object.values(steps || {});
    const phasesArr = Object.values(phases || {});
    const [barTable, setBarTable] = useState({});
    const [barSpread, setBarSpread] = useState([]);
    const [watchList, setWatchList] = useState([]);

    const formatActionOfficer =
        (bar) => ({id: bar.assignee, firstName: bar.safFirstName || '', lastName: bar.safLastName || ''})

    const setActionOfficers =
        (barArray) => barArray.map((bar) => bar.assignee ? {...bar, safActionOfficer: formatActionOfficer(bar)} : bar)

    const getFilteredResult = () => {
        return bars.filter(bar => {
            if (filters.phase && bar.currentPhase !== filters.phase) {
                return false;
            }
            if (filters.step && bar.currentStep !== filters.step) {
                return false;
            }
            if (filters.status && bar.status !== filters.status) {
                return false;
            }
            if (filters.leadBasingOffice && bar.owningOffice !== filters.leadBasingOffice) {
                return false;
            }
            if (filters.safActionOfficer && bar.assignee !== filters.safActionOfficer) {
                return false;
            }
            if (filters.basingActions && !bar[filters.basingActions]) {
                return false;
            }
            return true;
        });
    }

    const filteredBars = getFilteredResult();

    const changeFilter = (name, value) => setFilters((prevFilters) => ({...prevFilters, [name]: value}))

    const resetFilters = () => setFilters({});

    useEffect(() => {
        const fetchBars = async () => {
            const { data: barsTable } = await axios.get('/bars/all/table');
            // const { data: watching } = await axios.get('/userbar/watching');
            const barsCollection = { allBars: [], matrixBars: [] };

            barsTable.reduce((acc, bar) => {
                if (bar.assignee) {
                    bar.safActionOfficer = formatActionOfficer(bar);
                }

                acc.allBars.push(bar);
                bar.status === 'active' && acc.matrixBars.push(bar);

                return acc;
            }, barsCollection);

            setBars(barsCollection.allBars)
            setMatrixBars(barsCollection.matrixBars)
        }

        const fetchWatching = async () => {
            const { data } = await axios.get('/userbar/watching')
            setWatchList(data)
        }

        fetchBars();
        fetchWatching();
    }, []);

    useEffect(() => {
        if (watchList.length && bars.length) {
            const withWatched = bars.map(bar => ({
                ...bar,
                isWatched: watchList.includes(bar.id)
            }));

            setBars(withWatched);
        }
    }, [bars.length, watchList.length]);

    useEffect(()=> {
        if(matrixBars.length > 0 && phasesArr.length > 0 && stepsArr.length > 0 && _.isEmpty(barTable)){
            const localBarTable = {};
            const localBarSpread = [];
            localBarTable.min = 0;
            const countNumberOfBars = (phase, step) => {
                let total = 0;
                matrixBars.forEach(bar => {
                    if (bar.currentPhase === phase && bar.currentStep === step) {
                        total = total + 1;
                    }
                })
                localBarSpread.push(total);
                return total;
            }
            let min = -1;
            let max = 0;
            for (let phase = 1; phase <= phasesArr.length; phase++) {
                for (let step = 1; step <= stepsArr.length; step++) {
                    if (!localBarTable[phase]) {
                        localBarTable[phase] = {};
                    }
                    let numBars = countNumberOfBars(phase, step);
                    if (numBars > max) {
                        max = numBars;
                        localBarTable.max = max;
                    }

                    if (numBars < min) {
                        min = numBars;
                        localBarTable.min = min;
                    }
                    localBarTable[phase][step] = numBars;
                }
            }
            setBarTable(localBarTable);
            setBarSpread(localBarSpread);
        }
    }, [matrixBars, phasesArr, stepsArr, barTable]);

    return (
        <div>
            <Header leftContent={
                <>
                    <div className={styles.title}>Basing Actions</div>
                    <div className={styles.subTitle}>{matrixBars.length} Actions Active in the Strategic Basing Process</div>
                </>
            }>
                <TopMatrix barTable={barTable} barSpread={barSpread} changeFilter={changeFilter} resetFilters={resetFilters} filters={filters} />
            </Header>
            <div className={styles.contentPadding}>
                <BasingActionsFilter numSelected={filteredBars.length} filters={filters} change={changeFilter} resetFilters={resetFilters} />
                <BarsTable bars={filteredBars} watchedBars={watchList} setWatchedBars={setWatchList} />
            </div>

        </div>)
}

export default BasingActionLanding;
