import React from 'react';
import { useFormState } from 'react-final-form';
import FacilityDisplay from '../FacilityDisplay';
import styles from '../../Form/Form.module.scss';

const RiskAssessmentCriteriaDisplay = () => {
  const { initialValues: { siteSurveyRequirements = {} } = {} } = useFormState({ subscription: { initialValues: true } });

  const displayNestedList = (requirements, label, options = {}) => requirements?.length > 0 && (
    <>
      {label}
      <ul>
        {requirements.map(({ requirement, quantity, optimalValue, suitableValue, limitsValue, subRequirements = [] }, index) => (
          <li key={index}>
            {requirement} {options.additionalCosts && (<>({quantity ? 'Recurring' : 'One-Time'})</>)}
            {subRequirements.length > 0 ? (
              displayNestedList(subRequirements)
            ) : (
              <ul>
                {optimalValue && (
                  <li>
                    <span>Low: </span>
                    <span>{optimalValue}</span>
                  </li>
                )}
                {suitableValue && (
                  <li>
                    <span>Medium: </span>
                    <span>{suitableValue}</span>
                  </li>
                )}
                {limitsValue && (
                  <li>
                    <span>High: </span>
                    <span>{limitsValue}</span>
                  </li>
                )}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </>
  );

  const displayFacilities = (facilities, label) => facilities?.length > 0 && (
    <>
      {label}
      <ul>
        {facilities.map((facility, index) => (
          <li key={index}>
            <FacilityDisplay {...facility} facilityKey='requirement' />
            <ul>
            {facility.optimalValue && (
              <li>
              <span>Low: </span>
                <span>{facility.optimalValue}</span>
              </li>
            )}
              {facility.suitableValue && (
                <li>
                  <span>Medium: </span>
                  <span>{facility.suitableValue}</span>
                </li>
              )}
              {facility.limitsValue && (
                <li>
                  <span>High: </span>
                  <span>{facility.limitsValue}</span>
                </li>
              )}
            </ul>
          </li>
        ))}
      </ul>
    </>
  );

  return (
    <>
      {displayNestedList(siteSurveyRequirements.mission, <h3 className={styles.sectionHeading}>Mission</h3>)}
      {displayNestedList(siteSurveyRequirements.jointAndAllDomainTraining, <h3 className={styles.sectionHeading}>Joint and All Domain Training</h3>)}
      {displayNestedList(siteSurveyRequirements.airspaceAndTrainingAreas, <h3 className={styles.sectionHeading}>Airspace and Training Areas</h3>)}
      {siteSurveyRequirements?.facility?.length > 0 && <h3 className={styles.sectionHeading}>Capacity</h3>}
      {displayFacilities(siteSurveyRequirements.facility, <p>Facilities</p>)}
      {displayNestedList(siteSurveyRequirements.baseSupportTail, <p>Base Support Tail</p>)}
      {displayNestedList(siteSurveyRequirements.communitySupport, <p>Community Support</p>)}
      {displayNestedList(siteSurveyRequirements.environmental, <h3 className={styles.sectionHeading}>Environmental</h3>)}
      {displayNestedList(siteSurveyRequirements.militaryFamilyReadiness, <h3 className={styles.sectionHeading}>Military Family Readiness</h3>)}
      {displayNestedList(siteSurveyRequirements.supportOfMilitaryFamilies, <p>Support of Military Families</p>)}
      {(siteSurveyRequirements?.costOneTime?.length > 0
        || siteSurveyRequirements?.costRecurring?.length > 0
        || siteSurveyRequirements?.costAdditional?.length > 0) && <h3 className={styles.sectionHeading}>Cost</h3>}
      {displayNestedList(siteSurveyRequirements.costOneTime, <p>One Time Costs</p>)}
      {displayNestedList(siteSurveyRequirements.costRecurring, <p>Recurring Costs</p>)}
      {displayNestedList(siteSurveyRequirements.costAdditional, <p>Additional Costs</p>, { additionalCosts: true })}
    </>
  );
}

export default RiskAssessmentCriteriaDisplay;
