import { useFormState } from 'react-final-form';
import useCachedData from '../../hooks/useCachedData';

const WeAreHereDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });

  const fileKey = initialValues?.weAreHere?.fileKey;
  const options = {params: { docId: fileKey } };
  const { data: uploadedImageSrc } = useCachedData(fileKey && '/documents/image', options);

  return (
      <>
        {uploadedImageSrc && (
          <>
            <img src={uploadedImageSrc} />
          </>
        )}
      </>
  );
}

export default WeAreHereDisplay;
