import { useEffect, useRef } from 'react';
import { useForm, useField } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import cn from 'classnames';
import hasValue from '../../utils/hasValue';
import styles from './Form.module.scss';
import isPlainObject from 'lodash/isPlainObject';

/**
 * FieldArray is a wrapper for react-final-form-arrays
 * Provides the UI controls of add and remove
 * Also gives values for min and max length
 * @param {Function} children - render props pattern allows you to build your array fields
 * @param {Array} ignoredKeys - array of strings, field names that are ignored during cleanup
 * @param {Array} initialValue - prepopulate your field array with a value
 * @param {Number} maxLength - limit the entries in your array
 * @param {Number} minLength - the number of empty entries to start with, cannot delete entries at this amount
 * @param {String} name - the name of the field
 */
const FieldArray = ({ children, className, ignoredKeys, initialValue, isNested, maxLength = Infinity, minLength = 1, name }) => {
  // this ref required for unmount cleanup to receive the latest state
  const valueRef = useRef();
  const form = useForm();

  // removes empty array entries on unmount and before the form submits
  const cleanupEntries = () => {
    const cleaned = [];
    if (Array.isArray(valueRef.current)) {
      valueRef.current?.forEach((item) => {
        if (isPlainObject(item)) {
          const entries = Object.entries(item);
          // if at least one entry has a value and that field is not to be ignored, preserve this array item
          const shouldKeepItem = entries.some(([entryName, value]) => {
            return (!ignoredKeys || !ignoredKeys.includes(entryName)) && hasValue(value);
          });

          shouldKeepItem && cleaned.push(item);
        } else {
          item && cleaned.push(item);
        }
      });
    }

    form.change(name, cleaned);

    return true;
  };

  useField(name, { beforeSubmit: cleanupEntries });
  const { fields, meta } = useFieldArray(name, { initialValue });

  const isMaxLength = fields.length >= maxLength;
  const isMinLength = fields.length <= minLength;

  const add = (values) => {
    fields.length < maxLength && fields.push(values || {});
  };
  const remove = (index) => () => {
    !isMinLength && fields.remove(index);
  };

  useEffect(() => {
    fields.length < minLength && fields.push({});
  }, [fields.length, minLength]);

  useEffect(() => {
    valueRef.current = fields.value;
  }, [fields.value])

  useEffect(() => {
    if (!isNested && !form.destroyOnUnregister) {
      return cleanupEntries;
    }
  }, []);
  return children && (
    <div className={cn(styles.fieldArray, className, {[styles.nestedFieldArray]: isNested})}>
      {children({
        add,
        fields,
        isMaxLength,
        isMinLength,
        meta,
        remove,
      })}
    </div>);
}

export default FieldArray;
