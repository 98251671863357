import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavLink from './NavLink';
import styles from './NavBar.module.scss';
import Search from '../Search';
import MagnifyingGlass from '../svg/MagnifyingGlass';
import MeatBallMenu from './MeatBallMenu';
import Logo from '../svg/Logo';
import Anchor from '../Anchor';
import AuthorizedContent from '../AuthorizedContent';

// our top-most navigation
const NavBar = () => {
  const location = useLocation();
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    setShowSearch(params.has('showSearch'));
  }, [location]);

  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.leftSection}>
            <Anchor className={styles.logoLink} href="/dashboard">
              <Logo className={styles.logo} />
              <span className={styles.logoText}>Saber</span>
            </Anchor>


            <NavLink to="/dashboard">
              Dashboard
            </NavLink>
            <NavLink to="/bars">
              Basing Actions
            </NavLink>
            <AuthorizedContent authorizedRoles='SAM'>
              <NavLink to="/review-center">
                Review Center
              </NavLink>
            </AuthorizedContent>
            <NavLink to="/resources">
              Resources
            </NavLink>
            <NavLink to="/contacts">
              Contacts
            </NavLink>
        </div>
        <div className={styles.rightSection}>
          <button className={styles.search} type='button' onClick={() => setShowSearch(true)}><MagnifyingGlass /></button>
          <MeatBallMenu />
        </div>
      </div>
      {showSearch && <Search close={() => setShowSearch(false)} />}
    </>
  );
}

export default NavBar;
