import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import cn from 'classnames';
import Close from '@material-ui/icons/Close';
import CloseButton from '../buttons/CloseButton';
import BaseTextField from '../Form/BaseTextField';
import useCachedData from '.././hooks/useCachedData';
import debounce from 'lodash/debounce';
import styles from './Search.module.scss';
import Button from '../buttons/Button';
import SearchResults from './SearchResults';
import axios from '../../utils/sharedAxios';
import getBaseOptions from '../../utils/getBaseOptions';
import escapeRegExp from '../../utils/escapeRegExp';
import { AuthContext } from '../Utility/Authentication';

const placeholder = 'Enter keywords to search for a Basing Action';
const defaultNumDays = 0;

// global search panel found in the top nav bar
const Search = ({ close }) => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const { data: searchConfig = {} } = useCachedData('/search/config');
  const { data: bases } = useCachedData('/content/bases');
  const { data: stateOptions } = useCachedData('/content/states');
  const { data: contacts } = useCachedData('/search/contacts');
  const [baseOptions, setBaseOptions] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [searchType, setSearchType] = useState();
  const [daysToShow, setDaysToShow] = useState(defaultNumDays);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [selectedPredefined, setSelectedPredefined] = useState(null);
  const [searchInUse, setSearchInUse] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [results, setResults] = useState(null);
  const [filteredResults, setFilteredResults] = useState([]);
  const [isFetchingResults, setIsFetchingResults] = useState(null);
  const [sortByLastUpdated, setSortByLastUpdated] = useState(true);
  const [sortAscending, setSortAscending] = useState(true);
  const { endpoint, preDefined = [], queries } = searchConfig;

  const callSearch = async (endpoint, criteria, query, days) => {
    setIsFetchingResults(true);
    const c = criteria ? `criteria=${criteria}` : '';
    const q = query ? `&q=${query}` : ''
    const d = days ? `&days=${days}` : '';
    const { data } = await axios.get(`${endpoint}?${c}${q}${d}`);
    if(data && (criteria === 'barAttachments' || criteria === 'environmentalDocs')) {
      setResults(filterByEquity(data));
    }
    else if(data) {
      setResults(data);
    }
    setIsFetchingResults(false);
  }

  const filterByEquity = (array) => {
    return array.filter((elem) => user?.equity?.bars?.includes(elem.barId))
  }

  const debouncedFetch = useCallback(debounce(async (criteria, query, days) => {
    query = query?.trim ? query.trim() : query;
    await callSearch(endpoint, criteria, query, days);
  }, 400), [endpoint]);

  const clearInput = () => {
    setInputValue('');
    setSelectedSuggestion(null);
  }

  const resetSelections = () => {
    setInputValue('');
    setSelectedSuggestion(null);
    setDaysToShow(defaultNumDays);
    setResults(null);
  }
  const clearResults = () => {
    setResults(null);
  }

  const onTypeSelectChange = (option) => {
    setSelectedType(option);
    setSearchType(option);
    setSelectedPredefined(null);
    setSearchInUse(true);
    resetSelections();
  }

  const onSuggestionSelectChange = (option) => {
    setSearchInUse(true);
    setSearchType(selectedType);
    setSelectedSuggestion(option);
    clearResults();
  }

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  }

  const onSelectFilter = (option) => () => {
    setSearchInUse(false);
    setSearchType(option);
    setSelectedPredefined(option.value);
    resetSelections();
    setDaysToShow(30);
  }

  const useSuggestions = selectedType && (selectedType.value === 'state' || selectedType.value === 'installation');
  const showResults = Array.isArray(results);

  const getSearchOptions = () => {
    let searchOptions = [];

    if (selectedType.value) {
      if (selectedType.value === 'state') {
        searchOptions = stateOptions;
      }

      if (selectedType.value === 'installation') {
        searchOptions = baseOptions;
      }
    }

    return searchOptions;
  }

  useEffect(() => {
    if (results) {
      let sortedResults = results;

      if (sortedResults.length) {
        if (sortByLastUpdated) {
          sortedResults.sort((a, b) => {
            if (a.updatedAt === b.updatedAt) {
              return 0;
            }

            if (sortAscending) {
              return a.updatedAt > b.updatedAt ? -1 : 1;
            } else {
              return b.updatedAt > a.updatedAt ? -1 : 1;
            }
          });
        }

        if (!sortByLastUpdated) {
          sortedResults.sort((a, b) => {
            if (a.title === b.title) {
              return 0;
            }

            if (sortAscending) {
              return a.title > b.title ? -1 : 1;
            } else {
              return b.title > a.title ? -1 : 1;
            }
          });

        }
      }

      setFilteredResults(sortedResults);
    }
  }, [results, sortByLastUpdated, sortAscending]);

  useEffect(() => {
    if (endpoint && searchType) {
      if (inputValue.length) {
        setSearchType(selectedType);
        setSearchInUse(true);
        setSelectedPredefined(null);
        setDaysToShow(defaultNumDays);
      }

      if (searchType.value === 'action' && inputValue.length >= 3) {
        debouncedFetch.cancel();
        debouncedFetch(searchType.value, inputValue, daysToShow);
      }
      else if ((searchType.value === 'installation' || searchType.value === 'state') && selectedSuggestion) {
        debouncedFetch.cancel();
        debouncedFetch(searchType.value, selectedSuggestion.value, daysToShow);
      }
      else if (searchType.value === 'contact' && contacts) {
        const re = new RegExp(escapeRegExp(inputValue), 'i');
        setResults(contacts.filter(contact => contact.searchKey.toLowerCase().match(re)));
      }
      else if (selectedPredefined) {
        debouncedFetch.cancel();
        debouncedFetch(searchType.value, null, daysToShow);
      }
      else if (inputValue.length === 0) {
        clearResults();
      }
    }

  }, [endpoint, inputValue, searchType, selectedPredefined, selectedSuggestion, daysToShow])

  useEffect(() => {
    if (bases) {
      setBaseOptions(getBaseOptions(bases));
    }
  }, [bases]);

  useEffect(() => {
    if (queries && !searchType) {
      setSelectedType(queries[0]);
      setSearchType(queries[0]);
    }
  }, [queries]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (preDefined.length && params.has('selectedPredefined')) {
      const value = params.get('selectedPredefined');
      const filter = preDefined.find(item => item.value === value);
      filter && onSelectFilter(filter)();
    }
  }, [location, preDefined]);

  useEffect(() => {
    document.body.classList.add(styles.body);

    return () => {
      queries && setSearchType(queries[0]);
      setDaysToShow(defaultNumDays);
      setResults([]);
      setSearchInUse(true);
      clearInput();
      document.body.classList.remove(styles.body);
    }
  }, []);

  return (
    <div className={cn(styles.search, {
      [styles.showingResults]: showResults
    })}>
      <div className={styles.searchTop}>
        <CloseButton className={styles.closeButton} onClick={close} />
        <p className={styles.sectionlabel}>Search By</p>
        <form className={styles.form} autoComplete='off'>
          <Select
            className={cn(styles.typeSelect, {
              [styles.searchInUse]: searchInUse
            })}
            classNamePrefix='reactSelect'
            id='type'
            name='type'
            onChange={onTypeSelectChange}
            value={selectedType}
            options={queries} />

          <div className={styles.queryWrapper}>
            {useSuggestions ? (
              <Select
                className={cn(styles.suggestionSelect)}
                classNamePrefix='reactSelect'
                clearValue={clearInput}
                id='query'
                name='query'
                isSearchable
                noOptionsMessage={({ inputValue }) => `"${inputValue}" does not match any suggestions`}
                onChange={onSuggestionSelectChange}
                value={selectedSuggestion}
                options={getSearchOptions()}
                placeholder={placeholder} />
            ) : (
              <BaseTextField name='query' className={styles.input} onChange={onInputChange} placeholder={placeholder} value={inputValue} />
            )}
            {(inputValue || selectedSuggestion) && <button className={styles.clearInputButton} type='button' onClick={clearInput}><Close />Clear Search</button>}
          </div>
        </form>
      </div>

      <div className={styles.searchBottom}>
        <p className={styles.sectionlabel}>Or show last 30 days of</p>
        <div className={styles.filterButtons}>
          {preDefined.map((option) => {
            const selected = selectedPredefined === option.value;
            return (
              <Button key={option.value} className={cn(styles.filterButton, {
                [styles.selectedFilter]: selected
              })} onClick={selected ? null : onSelectFilter(option)}>{option.label}</Button>
            );
          })}
        </div>
      </div>
      {isFetchingResults && <p>LOADING</p>}
      {results && <SearchResults
        results={filteredResults}
        searchType={searchType}
        sortByLastUpdated={sortByLastUpdated}
        setSortByLastUpdated={setSortByLastUpdated}
        setSortAscending={setSortAscending}
        sortAscending={sortAscending}
        daysToShow={daysToShow}
        setDaysToShow={setDaysToShow} />}
    </div>
  );
}

export default Search;