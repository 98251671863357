import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Anchor from '../Anchor';
import Button from '../buttons/Button';
import Header from '../Header';
import dateFormats from '../../constants/dateFormats';
import useCachedData from '../hooks/useCachedData';
import styles from './GenerateMaterials.module.scss';
import pluralize from '../../utils/pluralize';
import { usePdf } from '../Pdf/PDFContext';

const GenerateMaterialsHeader = ({ disableGenerateButton, review, generatePdf }) => {
  const history = useHistory();
  const {isGeneratingReviewPdf} = usePdf();
  const { data: steps } = useCachedData('/content/steps');
  const stepLabel = steps?.[review.step] || '';
  const onClickGenerate = async () => {
    if(generatePdf) {
      generatePdf();
    }
  };

  const renderLeftContent = () => (
    <>
      <Anchor className={styles.goBackButton} onClick={() => history.goBack()}>{'< Back to Review Center'}</Anchor>
      <h2 className={styles.title}>Generate Materials for Review</h2>
      <p className={styles.reviewInfoText}>{`${moment(review.date).format(dateFormats.short)} ${stepLabel}, ${review.bars.length} ${pluralize(review.bars.length, 'Scheduled Basing Action', 'Scheduled Basing Actions')}`}</p>
    </>
  );

  const renderRightContent = () => (
    <Button disabled={disableGenerateButton} onClick={onClickGenerate} primaryAlt disabled={isGeneratingReviewPdf[review.reviewId]} loading={isGeneratingReviewPdf[review.reviewId]}>Generate Materials</Button>
  );

  return (
    <Header
      leftContent={renderLeftContent()}
      rightContent={renderRightContent()} />
  );
};

export default GenerateMaterialsHeader;