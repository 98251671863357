import React, { useEffect, useState } from 'react';
import axios from '../utils/sharedAxios';

export const SessionContext = React.createContext({
  warning: false,
  expired: false
});

const millisecondsUntilWarning = 1000 * 60 * 29; // 29 minutes
const millisecondsUntilExpired = 1000 * 60 * 30; // 30 minutes

let warningTimer;
let expiredTimer;

const Session = ({ children }) => {
  const [warning, setWarning] = useState(false);
  const [expired, setExpired] = useState(false);
  const [expirationDate, setExpirationDate] = useState(0);

  const setTimers = () => {
    const now = new Date();
    const dateOfExpiration = now.valueOf() + millisecondsUntilExpired;
    clearTimeout(warningTimer);
    clearTimeout(expiredTimer);
    setWarning(false);
    setExpired(false);

    warningTimer = setTimeout(() => {
      setWarning(true);
    }, millisecondsUntilWarning);

    expiredTimer = setTimeout(() => {
      setExpired(true);
    }, millisecondsUntilExpired);

    setExpirationDate(dateOfExpiration);
  }

  const interceptResponse = (response) => {
    if (response?.data?.url) {
      const entries = Object.entries(response.data);

      if (entries.length === 1 && entries[0][0] === 'url') {
        window.location.assign(entries[0][1]);
        return;
      }
    }

    setTimers();
  }

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        interceptResponse(response);

        return response;
      }
    );
  }, [])

  return (
    <SessionContext.Provider value={{ warning, expired, expirationDate }}>
      {children}
    </SessionContext.Provider>
  )
}

export default Session;