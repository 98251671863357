import React from 'react';
import cn from 'classnames';
import { useFormState } from 'react-final-form';
import styles from '../../GenerateMaterials.module.scss';

const RollCallDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });
  
  const renderRollCallList = (row) => {
    if (initialValues?.rollCall?.[row]) {
      return (
        <ul className={styles.rollCallList}>
          {initialValues.rollCall[row].split(/\n|\r|↵/gm).map((item, index) => (
            <li className={cn({[styles.blankListItem]: !item})} key={index}>{item}</li>
          ))}
        </ul>
      )
    }
  }
  return (
    <div className={styles.fieldRow}>
      {renderRollCallList('firstRow')}
      {renderRollCallList('secondRow')}
    </div>
  );
};

export default RollCallDisplay;