import React from 'react';
import UploadField from '../../Form/UploadField';
import Grid from '../../Grid';

const EisSummaryEdit = ({deleteFile}) => {
  return (
    <Grid columns={[2, '6fr 1fr']}>
      <UploadField name={`eisSummaryOfFindings`} deleteFile={deleteFile}/>
    </Grid>
  );
}

export default EisSummaryEdit;
