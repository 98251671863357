import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import ContentView from '../ContentView';
import GenerateMaterialsHeader from './GenerateMaterialsHeader';
import GenerateMaterialsSideNav from './GenerateMaterialsSideNav';
import genMaterialsComponentsMap from './genMaterialsComponentsMap';
import { titleMap } from './genMaterialsViews';
import FormViewWrapper from '../FormViewWrapper';
import useCachedData from '../hooks/useCachedData';
import Spinner from '../Spinner';
import axios from '../../utils/sharedAxios';

// form view for preparing read-ahead materials
const GenerateMaterials = ({ review, reviews, generatePdf, isGeneratingPdf }) => {
  const { data: orderedViews = [] }  = useCachedData('/content/generateMaterialsOrderedViews');
  const [activeViewKey, setActiveViewKey] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const disableGenerateButton = isEditing;
  const ActiveViewComponent = activeViewKey ? genMaterialsComponentsMap[activeViewKey] : null;
  const { pageTitle } = activeViewKey ? titleMap[activeViewKey] : {};

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const { rollCall, ...rest } = values;

    const postRollCall = rollCall ?  axios.post('/reviews/roll-call', rollCall) : null;
    if(rest.mfr && rest.mfr.length) {
      rest.mfr = rest.mfr.map((section) => section.bars).flat()
    }
    const postGenMaterial = axios.post('/reviews/generate-material', rest);

    const [rollCallResponse, getMaterialResponse] = await Promise.all([postRollCall, postGenMaterial])
      .catch(err => {
        console.log(err)
      });

    let savedValues = {};
    if (getMaterialResponse) {
      const formattedMFR = formatMFRResponse(getMaterialResponse.data.mfr);
      savedValues = { ...getMaterialResponse.data, mfr: formattedMFR };
    }

    if (rollCallResponse) {
      savedValues.rollCall = rollCallResponse.data;
    }

    setInitialValues(savedValues);
    setIsEditing(false);
    setIsLoading(false);
  };

  const formatMFRResponse = (mfr) => {
    if (Array.isArray(mfr)) {
      const mfrObject = {}
      mfr.reduce((acc, currentValue) => {
        const needApprovalFrom = currentValue.needApprovalFrom;

        if (!acc[needApprovalFrom]) {
          acc[needApprovalFrom] = [];
        }

        acc[needApprovalFrom].push(currentValue);

        return acc;
      }, mfrObject);

      const mfrArray = Object.entries(mfrObject).map(([needApprovalFrom, bars]) => {
        return ({
          bars,
          needApprovalFrom
        });
      });

      return mfrArray;
    }
  }

  // effects
  useEffect(() => {
    const getInitialValues = async () => {
      setIsLoading(true);
      const response = await axios.get(`/reviews/find-material/${review.reviewId}`)
        .catch(err => {
          console.log(err);
        });

      let queue = [];
      const {data: decisionsData} = await axios.get(`/reviews/decision-pending-mfr/${review.reviewId}`).catch(err => {
        console.log(err);
        return {}
      });
      if(decisionsData?.length) {
        decisionsData.sort((a, b) => a.currentPhase - b.currentPhase);
        const queueObject = decisionsData.reduce((acc, bar) => {
          const { needApprovalFrom } = bar;
          if (!acc[needApprovalFrom]) {
            acc[needApprovalFrom] = [];
          }
          acc[needApprovalFrom].push(bar);
          return acc;
        }, {});

        queue = Object.entries(queueObject).map(([needApprovalFrom, bars]) => {
          return {
            needApprovalFrom,
            bars,
          }
        });
      }

      if (response?.data) {
        const rollCallResponse = await axios.get('/reviews/roll-call');
        setInitialValues({
          ...response.data,
          rollCall: rollCallResponse.data,
          mfr: queue
        });
      } else {
        setInitialValues({
          mfr: queue,
          reviewPackageId: review.reviewId
        });
        setIsEditing(true);
      }

      setIsLoading(false);
    }

    getInitialValues();
  }, []);

  useEffect(() => {
    if (orderedViews.length > 0) {
      setActiveViewKey(orderedViews[0]);
    }
  }, [orderedViews])

  const generatePdfDeck = async () => {
    if(generatePdf) {
      generatePdf();
    }
  }

  const renderActiveView = () => ActiveViewComponent && <ActiveViewComponent isEditing={isEditing} review={review} reviews={reviews} setInitialValues={setInitialValues} />;

  return (
    <>
      <Form onSubmit={handleSubmit} initialValues={initialValues} subscription={{ initialValues: true, submitting: true }}>
        {({ handleSubmit }) => (
          <ContentView
            headerContent={<GenerateMaterialsHeader
              review={review}
              disableGenerateButton={disableGenerateButton}
              generatePdf={generatePdfDeck}
            />}
            sidebarContent={
              <GenerateMaterialsSideNav activeViewKey={activeViewKey} setActiveViewKey={setActiveViewKey} />
            }
            mainContent={
              <form onSubmit={handleSubmit} noValidate>
                <FormViewWrapper
                  activeView={renderActiveView}
                  handleCancel={() => setIsEditing(false)}
                  handleEdit={() => setIsEditing(true)}
                  isEditing={isEditing}
                  reviewId={review.reviewId}
                  title={pageTitle} />
                <Field component='input' type='hidden' name='reviewPackageId' initialValue={review.reviewId} />
              </form>
            }
          />
        )}
      </Form>
      <Spinner on={isLoading} />
    </>
  );
}

export default GenerateMaterials;