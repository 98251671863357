import React from 'react';
import useCompletionStatus from '../hooks/useCompletionStatus';
import NavListItemIcon from './NavListItemIcon';

const CompletionIcon = ({ view, mappingEndpoint }) => {
  const { started, completed } = useCompletionStatus(view, mappingEndpoint);

  return <NavListItemIcon warning={started} success={completed}/>;
}

export default CompletionIcon;
