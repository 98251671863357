import useCachedData from "./hooks/useCachedData"

// shows the name of a group based on its groupId
const DisplayContactGroup = ({ groupId }) => {
  const { data: groups } = useCachedData('/users/groups');
  const foundGroup = groups ? groups.find(group => group.id === groupId) : null;

  return foundGroup ? foundGroup.name : null;

}

export default DisplayContactGroup;