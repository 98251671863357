import React, { useState } from 'react';
import Header from '../Header';
import styles from './Reporting.module.scss';
import { reportingViewMapping, reportingViewKeys } from '../../constants/reportingViewMapping';
import reportingComponentsMap from '../../constants/reportingComponentsMap';
import ContentView from '../ContentView';
import ReportingSideNav from './ReportingSideNav';
import useDocumentTitle from '../hooks/useDocumentTitle';
import useRedirectUnauthorizedUsers from '../hooks/useRedirectUnauthorizedUsers';

const Reporting = () => {
  useDocumentTitle('Reporting');
  useRedirectUnauthorizedUsers({ authorizedRoles: ['SAM', 'LEE'] });
  const [activeView, setActiveView] = useState(reportingViewKeys.onDemandReports);

  const ActiveViewComponent = activeView && reportingComponentsMap[activeView] || null;
  const pageTitle = reportingViewMapping && activeView && reportingViewMapping[activeView].pageTitle;

  return (
    <ContentView
      headerContent={
        <Header leftContent={<h1>Reporting</h1>} />
      }
      sidebarContent={<ReportingSideNav activeView={activeView} setActiveView={setActiveView} />}
      mainContent={
        <>
          <h1 className={styles.title}>{pageTitle}</h1>
          <ActiveViewComponent
            setActiveView={setActiveView}
          />
        </>
      }
    />
  );

}

export default Reporting;