import React from 'react';
import cn from 'classnames';
import styles from './Modal.module.scss';

const ModalFooter = ({ className, children }) => {
  return (
    <footer className={cn(styles.modalFooter, className)}>{children}</footer>
  )
}

export default ModalFooter;