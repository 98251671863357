import { useEffect, useState } from 'react';
import axios from "../../utils/sharedAxios";

// fetches and returns data, no cache
export default function useFetchData(endpoint, options) {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState();
  const optionsString = (options && Object.values(options).length) ? JSON.stringify(options) : '';

  const fetchData = async () => {
    setIsFetching(true);
    const response = await axios.get(endpoint, options);

    setData(response.data);
    setIsFetching(false);
  }

  useEffect(() => {
    endpoint && fetchData();
  }, [endpoint, optionsString]);

  return { data, isFetching };
}
