import { Form } from 'react-final-form';
import Button from '../../../buttons/Button';
import ContactFields, { validateContactFields } from '../../../ContactFields';
import SelectField from '../../../Form/SelectField';
import Modal from '../../../Modal';
import ModalFooter from '../../../Modal/ModalFooter';
import ContactRoleDescription from './ContactRoleDescription';
import styles from '../../Admin.module.scss'
import ErrorText from '../../../ErrorText';
import useRoleOptions from '../../../hooks/useRoleOptions';

const defaultInitialValues = { roleId: null };

const validate = values => {
  const errors = validateContactFields(values);

  if (!values.contactGroup) {
    errors.contactGroup = 'This is a required field';
  }

  return errors;
}

const ContactFormModal = ({ contact, groupOptions, handleSave, openDeleteModal, onClose, title }) => {
  const roleOptions = useRoleOptions();
  const contactOnlyOption = [{ label: 'Contact Only', value: null }];
  const userRoleOptions = (!contact || !contact.uuid) ? contactOnlyOption : roleOptions;
  const initialValues = contact || defaultInitialValues;

  return (
    <Modal className={styles.modalMedium} onClose={onClose} title={title}>
      <Form onSubmit={handleSave} initialValues={initialValues} subscription={{ submitFailed: true, error: true, submitting: true, values: true }} validate={validate}>
        {({ submitFailed, error, handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <ContactFields />
              <hr />
              <SelectField name='roleId' label='Role' options={userRoleOptions} disabled={!contact || !contact.uuid} />
              <ContactRoleDescription />

              {contact.uuid && (values.roleId?.value === 4) && <SelectField name='office' label='Office' fieldDataEndpoint='/content/leadBasingOffices' />}

              <SelectField name='contactGroup' label='Group' options={groupOptions} required />

              {submitFailed && error && <ErrorText>{error}</ErrorText>}
              <ModalFooter>
                <div className={styles.modalButtons}>
                  <div>
                    <Button type="submit" primary disabled={submitting}>SAVE</Button>
                    <Button onClick={onClose} secondary disabled={submitting}>CANCEL</Button>
                  </div>


                  {contact?.id && <Button onClick={openDeleteModal} danger>DELETE CONTACT</Button>}
                </div>
              </ModalFooter>
            </form>
          )
        }}
      </Form>
    </Modal>
  );
}

export default ContactFormModal;