import React, { useState } from 'react';
import { useFormState } from 'react-final-form';
import cn from 'classnames';
import useCachedData from '../../hooks/useCachedData';
import getBaseName from '../../../utils/getBaseName';
import styles from './SummaryOfFindingsContd.module.scss';
import formStyles from '../../Form/Form.module.scss';
import DisplayFacility from '../DisplayFacility';
import NoBasesMsg from './NoBasesMsg';

// formerly "SummaryOfFindingsDisplay"
const SummaryOfFindingsContdDisplay = ({ expeditionLevel, setActiveView }) => {
  const { data: bases } = useCachedData('/content/bases');
  const { data: facilityData } = useCachedData('/content/facilityRequirement');
  const { initialValues = {} } = useFormState({ subscription: { initialValues: true } });
  const { candidateBases = [], recommendedCandidates = [], siteSurveyRequirements = {}, summaryOfFindings = {} } = initialValues;
  const [baseSelections] = useState((expeditionLevel >= 3 ? [...((candidateBases.length ? candidateBases : recommendedCandidates) || [])] : [...candidateBases]).filter((b) => b.baseId));
  let incrementalIndex = -1;

  const renderBases = (requirementId) => {
    return (
      baseSelections.map(({ baseId }) => {
        const key = `${requirementId ? requirementId : incrementalIndex}~${baseId}`;
        const findings = summaryOfFindings[key] || {};
        const { note } = findings;
        const riskFactor =  findings.riskFactor || '';

        return (
          <div key={key} className={styles.row}>
            <div className={styles.zebraRow}>
              <div className={cn(styles.riskFactor, { [styles[riskFactor.toLowerCase()]]: riskFactor })}>{riskFactor}</div>

              <ul className={styles.note}>
                {note && <li>{note}</li>}
              </ul>

            </div>

            <div className={styles.base}>{getBaseName(baseId, bases)}</div>
          </div>
        );
      })
    );
  }

  const renderRequirement = (LabelComponent) => (props) => {
    const { id: requirementId, requirement, subRequirements = [] } = props;
    const displayRequirement = requirement?.label || requirement;
    ++incrementalIndex;
    return (
      <React.Fragment key={requirementId}>
        <div className={styles.row}>
          <div className={styles.zebraRow}>
            <span className={styles.requirementText}>
              {LabelComponent ? <LabelComponent {...props} /> : displayRequirement}
            </span>
          </div>
        </div>

        {subRequirements.length ? (
          subRequirements.map(renderRequirement)
        ) : (
          renderBases(requirementId)
        )}
      </React.Fragment>
    );
  };

  const renderRequirements = (type, label, LabelComponent) => {
    const requirements = siteSurveyRequirements[type] || [];

    return (
      <>
        {label && (
          <div className={styles.row}>
            <div className={styles.zebraRow}>
              <span className={styles.requirementsLabel}>{label}</span>
            </div>
          </div>
        )}
        {requirements.map(renderRequirement(LabelComponent))}
      </>
    );
  };

  const renderTable = (label, types = [], children) => {
    let showTable = false;
    let siteSurveyRequirementsTypes = [];

    types.forEach(type => {
      const siteSurveyRequirementsType = siteSurveyRequirements[type];

      if (siteSurveyRequirementsType && siteSurveyRequirementsType.length) {
        showTable = true;
        siteSurveyRequirementsTypes.push(siteSurveyRequirementsType);
      }
    });

    if (showTable) {
      return (
        <div className={styles.requirementsTable}>
          {label && <h3 className={formStyles.sectionHeading}>{label}</h3>}
          {children}
        </div>
      );
    }

    return null;
  }

  const getFacilityOption = (facilityValue) => facilityData?.options && facilityData.options.find(({ value }) => value === facilityValue);

  const renderFacilityLabel = (requirement) => {
    const { quantity, requirement: facility, totalAreaSqft} = requirement;

    const { areaType, unitType } = getFacilityOption(facility) || {};
    return (
      <DisplayFacility
        areaType={areaType}
        quantity={quantity}
        selection={facility}
        totalAreaSqft={totalAreaSqft}
        unitType={unitType}/>
    );
  }

  return baseSelections.length ? (
    <>
      {renderTable('Mission', ['mission'], renderRequirements('mission'))}

      {renderTable('Joint and All Domain Training', ['jointAndAllDomainTraining'], renderRequirements('jointAndAllDomainTraining'))}

      {renderTable('Airspace and Training Areas', ['airspaceAndTrainingAreas'], renderRequirements('airspaceAndTrainingAreas'))}

      {renderTable('Capacity', ['facility', 'baseSupportTail', 'communitySupport'], (
        <>
          {renderRequirements('facility', 'Facilities', renderFacilityLabel)}
          {renderRequirements('baseSupportTail', 'Base Support Tail')}
          {renderRequirements('communitySupport', 'Community Support')}
        </>
      ))}

      {renderTable('Environmental', ['environmental'], renderRequirements('environmental'))}

      {renderTable('Military Family Readiness', ['militaryFamilyReadiness', 'supportOfMilitaryFamilies'], (
        <>
          {renderRequirements('militaryFamilyReadiness')}
          {renderRequirements('supportOfMilitaryFamilies', 'Support of Military Families')}
        </>
      ))}

      {renderTable('Cost', ['costOneTime', 'costRecurring', 'costAdditional'], (
        <>
          {renderRequirements('costOneTime')}
          {renderRequirements('costRecurring')}
          {renderRequirements('costAdditional')}
        </>
      ))}
    </>
  ) :
   <NoBasesMsg expeditionLevel={expeditionLevel} setActiveView={setActiveView} />
};

export default SummaryOfFindingsContdDisplay;
