import React from 'react';
import Modal from '../Modal';
import ModalFooter from '../Modal/ModalFooter';
import DetailsText from '../DetailsText';
import Button from '../buttons/Button';
import useCachedData from '../hooks/useCachedData';
import styles from './PreActionInterceptModal.module.scss';
import { Form } from 'react-final-form';
import SelectField from '../Form/SelectField';
import CheckboxField from '../Form/CheckboxField';

const PreActionInterceptModal = ({ onClose, onContinue }) => {
  const { data: AFI } = useCachedData('/content/AFI_10_503');

  const validate = values => {
    const errors = {};

    if (values.isUnclassified?.length === 0) {
      errors.isUnclassified = 'Only unclassified actions are permitted in SABER';
    }
    if (!values.expeditionLevel) {
      errors.expeditionLevel = 'This field is required';
    }

    return errors;
  }
  return (
    <Modal className={styles.modal} onClose={onClose} title='Please Read Before Continuing'>
      <Form onSubmit={onContinue} subscription={{ invalid: true, submitting: true, values: true }} validate={validate}>
        {({ form, handleSubmit }) => {
          const { invalid, submitting, values } = form.getState();
          return (
            <>
              <div className={styles.modalContent}>
                <ol className={styles.questionsList}>
                  <li className={styles.questionListItem}>
                    <p className={styles.question}>Is this action unclassified?</p>
                    <CheckboxField label='Is this action unclassified?' name='isUnclassified' required value='Yes' />
                  </li>

                  <li className={styles.questionListItem}>
                    <p className={styles.question}>At what phase is your action entering the Strategic Basing Process?</p>
                    <SelectField name='expeditionLevel' fieldDataEndpoint='/content/phaseOptions' required />
                    {(values?.expeditionLevel?.value > 1) && <DetailsText className={styles.expediteWarning}>Selecting this phase will mark your action as being Expedited</DetailsText>}
                  </li>

                  <li className={styles.questionListItem}>
                    <p className={styles.question}>Does your basing action meet one AFI 10-503 criteria?</p>
                    <ul className={styles.afiList}>
                      {AFI?.options && AFI.options.map(({ label }, index) => <li key={index}>{label}</li>)}
                    </ul>
                  </li>
                </ol>

                <DetailsText>If any of these conditions are not met, please contact SAF IEIB for how to proceed: <a href="mailto:SAF.IEIB.Workflow.Org@us.af.mil">SAF.IEIB.Workflow.Org@us.af.mil</a></DetailsText>
              </div>

              <ModalFooter>
                <Button onClick={onClose} secondary>Cancel</Button>
                <Button onClick={handleSubmit} primary disabled={invalid || submitting}>Continue</Button>
              </ModalFooter>
            </>
          );
        }}
      </Form>
    </Modal>
  )
}

export default PreActionInterceptModal;