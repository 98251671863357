import { Box as ThemeUIBox } from 'theme-ui';

const Box = ({ children, className }) => {
  return (
    <ThemeUIBox className={className}>
      {children}
    </ThemeUIBox>
  );
}

export default Box;
