import React from 'react';
import Label from './Label';
import SelectField from './SelectField';
import TextField from './TextField';

/**
 * DependentField takes the data returned from the api
 * and determines which field type to render
 * @param {Object} fieldObject - the data returned from the api, should contain a type, label, options if applicable
 * @param {String} name - the name of the field
 * @param {Boolean} required - the UI, not the api, determines if it's required
 */
const DependentField = ({ enableFallback, fieldObject, name, required }) => {
  if (fieldObject) {
    if (fieldObject.type === 'dropdown') {
      return (
        <div>
          <Label text={fieldObject.label} required/>
          <SelectField name={name} options={fieldObject.options} required={required} />
        </div>
      );
    }
    if (fieldObject.type === 'input' || enableFallback) {
      return (
        <div>
          <Label text={fieldObject.label} required/>
          <TextField name={name} required={required} />
        </div>
        
      );
    }
  }

  return null;

}

export default DependentField;
