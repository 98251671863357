import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../Header';
import styles from './Admin.module.scss';
import { adminViewKeys, adminViewMapping } from '../../constants/adminViewMapping';
import adminComponentsMap from '../../constants/adminComponentsMap';
import ContentView from '../ContentView';
import AdminSideNav from './AdminSideNav';
import axios from '../../utils/sharedAxios';
import useDocumentTitle from '../hooks/useDocumentTitle';
import useRedirectUnauthorizedUsers from '../hooks/useRedirectUnauthorizedUsers';

const setInitialActiveView = search => {
  const params = new URLSearchParams(search);
  let view = adminViewKeys.newAccountRequests;

  if (params.has('activeView')) {
    view = params.get('activeView');
  }

  return view;
}

const Admin = () => {
  useRedirectUnauthorizedUsers({ authorizedRoles: 'SAM' });
  useDocumentTitle('Admin');
  const { search } = useLocation();
  const [requests, setRequests] = useState([]);
  const [actionRequests, setActionRequests] = useState([]);
  const [shouldInvalidate, setShouldInvalidate] = useState(false);
  const [activeView, setActiveView] = useState(setInitialActiveView(search));

  useEffect(() => {
    if (shouldInvalidate) {
      setShouldInvalidate(false);
    }
  }, [shouldInvalidate]);


  useEffect(() => {
    const fetchRequests = async () => {
      const { data } = await axios.get('/users/pendingApproval');
      const { data: actionData } = await axios.get('/bars/list-submitted')
      setRequests(data);
      setActionRequests(actionData);
    }

    fetchRequests();
  }, []);

  const ActiveViewComponent = activeView && adminComponentsMap[activeView] || null;
  const pageTitle = adminViewMapping && activeView && adminViewMapping[activeView].pageTitle;

  return (
    <ContentView
      headerContent={
        <Header leftContent={<h1>Admin</h1>} />
      }
      sidebarContent={<AdminSideNav activeView={activeView} setActiveView={setActiveView} newRequestsCount={requests.length} newActionsCount={actionRequests.length} />}
      mainContent={
        <>
          <h1 className={styles.title}>
            {pageTitle}
            {activeView === adminViewKeys.newAccountRequests && requests.length > 0 ? (
              ` (${requests.length})`
            ) : null}
            {/* insert a count for new action requests here */}
          </h1>
          <ActiveViewComponent
            requests={requests}
            actionRequests={actionRequests}
            setActiveView={setActiveView}
            setRequests={setRequests}
            setActionRequests={setActionRequests}
          />
        </>
      }
    />
  );

}

export default Admin;