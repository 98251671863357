import { useEffect, useState } from 'react';
import Table from '../Table';
import DisplayPhone from '../DisplayPhone';
import styles from './Contacts.module.scss';
import Anchor from '../Anchor';

const ContactsContent = ({ groupedContacts, selectedGroup }) => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if (groupedContacts && selectedGroup) {
      const users = groupedContacts[selectedGroup]?.users || [];
      users.sort((a, b) => {
        if (a.lastName < b.lastName) {
          return -1
        }
        if (a.lastName > b.lastName) {
          return 1;
        }
        return 0;
      });
      setContacts(users);
    }
  }, [groupedContacts, selectedGroup]);

  const isBasingOffice = groupedContacts?.[selectedGroup]?.isBasingOffice;
  return (
    <>
      <h2 className={styles.title}>{isBasingOffice ? 'Basing Office' : selectedGroup} Contacts</h2>
      <p className={styles.selectedGroup}>{isBasingOffice && selectedGroup}</p>
      <Table>
        <thead>
          <tr>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Rank</th>
            <th>Job Title</th>
            <th>Office Symbol</th>
            <th>NIPR Email</th>
            <th>SIPR Email</th>
            <th>DSN Phone</th>
            <th>Commercial Phone</th>
            <th>Secure Phone</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map(contact => (
            <tr key={contact.id}>
              <td>{contact.lastName}</td>
              <td>{contact.firstName}</td>
              <td>{contact.userRank}</td>
              <td>{contact.capacity}</td>
              <td>{contact.officeSymbol}</td>
              <td>{contact.niprEmail && <Anchor href={`mailto:${contact.niprEmail}`}>{contact.niprEmail}</Anchor>}</td>
              <td>{contact.siprEmail}</td>
              <td><DisplayPhone number={contact.dsnPhone} /></td>
              <td><DisplayPhone number={contact.commercialPhone} /></td>
              <td><DisplayPhone number={contact.securePhone} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default ContactsContent;