import React from 'react';
import { useFormState } from 'react-final-form';
import phaseAbbreviations from '../../../../constants/phaseAbbreviations';
import PhaseLegend from '../../PhaseLegend';
import { dictionaryValue } from '../../../Dictionary/DictionaryContext'
import styles from './UpcomingDecisions.module.scss'

const PendingMFRDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });

  return (
    <>
      {initialValues.mfr?.map(({ needApprovalFrom, bars }, index) => (
        <React.Fragment key={needApprovalFrom}>
          <div className={styles.flexTable}>
            <h3 className={styles.barTitleFlex}>{needApprovalFrom === 'null' ? 'No Approval Requested' : dictionaryValue(needApprovalFrom)}</h3>
            {index === 0 && <h3 className={styles.statusFlex}>Status</h3>}
          </div>
            {bars && bars.length > 0 && (
              <ul>
                {bars.map(({ currentPhase, decisionMfrStatus, title }, index) => (
                  <li key={index}>
                    <div className={styles.flexTable}>
                      <div className={styles.barTitleFlex}>
                        <b>{phaseAbbreviations[currentPhase]?.short}</b> - {title}
                      </div>
                      <div className={styles.statusFlex}>{decisionMfrStatus}</div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
        </React.Fragment>
      ))}
      <PhaseLegend />
    </>
  );
};

export default PendingMFRDisplay;