import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../Grid';
import Box from '../../Box';
import FieldArray from '../../Form/FieldArray';
import TextField from '../../Form/TextField';
import SelectField from '../../Form/SelectField';
import { Field } from 'react-final-form';
import CheckboxField from '../../Form/CheckboxField';
import CloseButton from '../../buttons/CloseButton';
import AddButton from '../../buttons/AddButton';

const CostField = ({ addBtnLabel, costType, fieldDataEndpoint, name }) => {
  const templateColumns = [2, '1fr 1fr'];
  return (
    <FieldArray name={name} ignoredKeys={['isRecurring', 'type', 'points']}>
      {({ fields, add, isMaxLength, isMinLength, remove }) => (
        <>
          {fields.map((name, index) => (
            <div key={name}>
              <Grid columns={templateColumns} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
                <Box>
                  {costType === 'custom' ? <TextField name={`${name}.cost`} /> : <SelectField name={`${name}.cost`} fieldDataEndpoint={fieldDataEndpoint} />}
                </Box>
                {costType === 'custom' ? (
                  <Box><CheckboxField name={`${name}.isRecurring`} labelText='Recurring Cost' /></Box>
                ) : (
                  <Field component='input' type='hidden' name={`${name}.isRecurring`} defaultValue={costType === 'recurring'} />
                )}
              </Grid>
              <Field component='input' type='hidden' name={`${name}.type`} defaultValue={costType} />
            </div>
          ))}
          {!isMaxLength && <AddButton onClick={()=> add()} secondary>Add {addBtnLabel} Cost</AddButton>}
        </>
      )}
    </FieldArray>
  );
}

CostField.propTypes = {
  costType: PropTypes.oneOf(['custom', 'oneTime', 'recurring']),
};

export default CostField;
