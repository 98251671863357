import React from 'react';

const MagnifyingGlass = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="26.87" height="26.873">
    <g>
      <g stroke="#fff" strokeMiterlimit="10" strokeWidth="3" fill="none" transform="rotate(45 6.718 16.217)">
        <circle cx="9.5" cy="9.5" r="8"/>
      </g>
      <path fill="#fff" d="M6.367 18.388l2.121 2.12-6.364 6.365-2.121-2.121z"/>
    </g>
  </svg>
);

export default MagnifyingGlass;
