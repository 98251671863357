
import React from 'react';
import Table from '../Table';
import AuthorizedContent from '../AuthorizedContent';
import DisplayDate from '../DisplayDate';
import DisplayFile from '../DisplayFile';
import dateFormats from '../../constants/dateFormats';
import CloseButton from '../buttons/CloseButton';
import styles from './Resources.module.scss';
import SortHeader from '../Table/SortHeader';

const ResourcesContent = ({ onClickSort, onDelete, resources, activeSortKey, sortReversed }) => {
  const renderResouceRow = (resource) => (
    <tr key={resource.uuid}>
      <td><AuthorizedContent authorizedRoles='sam'><CloseButton onClick={() => onDelete(resource.uuid)} /></AuthorizedContent></td>
      <td><DisplayFile uuid={resource.uuid} isResource /></td>
      <td>{resource.isTemplate ? 'Template' : 'Document'}</td>
      <td>{resource.type}</td>
      <td className={styles.fileType}>{resource.fileType}</td>
      <td><DisplayDate date={resource.createdAt} format={dateFormats.short} /></td>
    </tr>
  );

  return (
    <Table>
      <thead>
        <tr>
          <th />{/* intentionally empty th cell */}
          <SortHeader activeSortKey={activeSortKey} sortKey='fileName' label='File Name' onClickSort={onClickSort} sortReversed={sortReversed} />
          <SortHeader activeSortKey={activeSortKey} sortKey='isTemplate' label='Resource' onClickSort={onClickSort} sortReversed={sortReversed} />
          <SortHeader activeSortKey={activeSortKey} sortKey='type' label='Type' onClickSort={onClickSort} sortReversed={sortReversed} />
          <SortHeader activeSortKey={activeSortKey} sortKey='fileType' label='File Type' onClickSort={onClickSort} sortReversed={sortReversed} />
          <SortHeader activeSortKey={activeSortKey} sortKey='createdAt' label='Date Added' onClickSort={onClickSort} sortReversed={sortReversed} />
        </tr>
      </thead>
      <tbody>
        {resources.map(renderResouceRow)}
      </tbody>
    </Table>
  );
}

export default ResourcesContent;