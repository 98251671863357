import React, { useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import Grid from '../../Grid';
import FieldArray from '../../Form/FieldArray';
import SelectField from '../../Form/SelectField';
import AddButton from '../../buttons/AddButton';
import CloseButton from '../../buttons/CloseButton';
import useCachedData from '../../hooks/useCachedData';
import Label from '../../Form/Label';
import styles from './CandidateDecision.module.scss';
import Wysiwig from '../../Form/Wysiwyg';
import getBaseName from "../../../utils/getBaseName";
import getBaseOptions from '../../../utils/getBaseOptions';

const BaseSelector = ({ disabledSelect, disabledSelectStatus, initialValue, name }) => {
    const { data: bases } = useCachedData('/content/bases');
    const [baseOptions, setBaseOptions] = useState([]);
    useEffect(() => {
        if (bases) {
            const formattedBases = getBaseOptions(bases);
            setBaseOptions(formattedBases);
        }
    }, [bases])

    return (
        <>
            <FieldArray name={name} initialValue={initialValue} >
                {({ fields, add, remove, isMaxLength, isMinLength }) => (
                    <div>
                        {fields.map((name, index) => (
                            <div className={styles.entry} key={name}>
                                <Grid columns={[2]} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
                                    <Box>
                                        <Label text='Location' required />
                                        {disabledSelect ? (
                                            getBaseName(fields.value[index].baseId.value, bases)
                                        ) : (
                                            <SelectField name={`${name}.baseId`} options={baseOptions} isSearchable />
                                        )}
                                    </Box>
                                    {!disabledSelectStatus && (
                                        <Box>
                                            <SelectField label='Decision' name={`${name}.status`} fieldDataEndpoint='/content/rationaleForDecision' required />
                                        </Box>
                                    )}
                                </Grid>
                                <Grid>
                                    <Box>
                                        <Label text='Rationale' required />
                                        <Wysiwig name={`${name}.rationale`} />
                                    </Box>
                                </Grid>

                            </div>
                        ))}
                        {!isMaxLength && <AddButton onClick={() => add()} secondary>Add Location</AddButton>}
                    </div>
                )}
            </FieldArray>
        </>)
}


export default BaseSelector;
