import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useHistory, useParams } from 'react-router-dom';
import arrayMutators from 'final-form-arrays'
import Header from '../../components/Header';
import SideNav from '../../components/SideNav';
import axios from "../../utils/sharedAxios";
import formatIncomingValues from '../../utils/formatIncomingValues';
import barUpdate from '../../utils/barUpdate';
import useCachedData from '../hooks/useCachedData';
import { viewMapping } from '../../constants/steps';
import barComponentsMap from '../../constants/barComponentsMap';
import ContentView from '../ContentView';
import FormViewWrapper from '../FormViewWrapper';
import Comments from './Comments';
import SubmitBarButton from './SubmitBarButton';
import useRedirectUnauthorizedUsers from '../hooks/useRedirectUnauthorizedUsers';
import ConfirmDeleteDraftModal from './ConfirmDeleteDraftModal';
import Button from '../buttons/Button';
import Modal from '../Modal';
import ModalFooter from '../Modal/ModalFooter';
import Spinner from '../Spinner';
import styles from './CreateBar.module.scss';
import useDocumentTitle from '../hooks/useDocumentTitle';

const CreateBar = () => {
  useDocumentTitle('Create a Basing Action');
  const history = useHistory();
  const { id: barId } = useParams();
  useRedirectUnauthorizedUsers({ barId });
  const { data: keys } = useCachedData('/content/barViewKeys');
  const [initialValues, setInitialValues] = useState({});
  const [activeView, setActiveView] = useState('');
  const [isEditing, setIsEditing] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showSubmitSuccess, setShowSubmitSuccess] = useState(false);
  const [barStatus, setBarStatus] = useState(null);
  const [expeditionLevel, setExpeditionLevel] = useState(null);
  const [submittedDate, setSubmittedDate] = useState(null);

  const onSave = async (values) => {
    const latestInitialValues = await barUpdate({ values, barId, barStatus, phase: 0, expeditionLevel });
    latestInitialValues && setInitialValues(latestInitialValues);
    setIsEditing(false);
  }

  const deleteBar = () => {
    axios.get(`/bars/delete/${barId}`)
      .then(() => {
        setShowDeleteModal(false);
        setShowDeleteSuccess(true);
      })
  }

  const onCloseDeleteSuccess = () => history.replace('/dashboard');
  const onCloseSubmitSuccess = () => history.replace('/dashboard');

  const createBar = (e) => {
    axios.get(`/bars/submit/${barId}`)
      .then(() => {
        setShowSubmitSuccess(true);
        history.replace('/dashboard');
      })
      .catch(err => {
        console.log(err)
      });
  }

  const changeActiveView = (key) => {
    setActiveView(key);
    setIsEditing(true);
  }

  const handleCancel = (form) => () => {
    form.restart();
    setTimeout(() => setIsEditing(false));
  }

  useEffect(() => {
    const findBarById = async () => {
      await axios.get(`/bars/find/${barId}`)
        .then(result => {
          if (result.data.currentPhase > 0) {
            history.replace(`/bars/detail/${barId}`);
          }
          if (result.data.barInfo) {
            setInitialValues(formatIncomingValues(result.data.barInfo));
            setBarStatus(result.data.status);
            setExpeditionLevel(result.data.expeditionLevel || 1);
            setSubmittedDate(result.data.submittedDate);
          }
        })
        .catch(() => {
          history.replace('/bars/invalid-action');
        });
    };

    barId && findBarById();
  }, []);

  useEffect(() => {
    keys && setActiveView(keys.basicInformation);
  }, [keys]);

  const ActiveViewComponent = activeView ? barComponentsMap[activeView] : null;
  const renderActiveView = () => ActiveViewComponent && <ActiveViewComponent isEditing={isEditing} expeditionLevel={expeditionLevel} status={barStatus} submittedDate={submittedDate} setActiveView={setActiveView} />;
  const pageTitle = viewMapping && activeView && viewMapping[activeView].pageTitle;
  const sideNavPhase = expeditionLevel > 1 ? expeditionLevel : 0;

  return (
    <>
      <Form initialValues={initialValues} mutators={{ ...arrayMutators }} onSubmit={onSave} subscription={{ submitting: true }}>
        {({ form, handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <ContentView
              headerContent={
                <Header
                  leftContent={<h1>Create Basing Action</h1>}
                  rightContent={
                    <>
                      <Button danger onClick={() => setShowDeleteModal(true)}>Delete Draft</Button>
                      <SubmitBarButton submit={(e) => createBar(e)} expeditionLevel={expeditionLevel} />
                    </>
                  }
                />
              }
              sidebarContent={
                expeditionLevel ? <SideNav editing={isEditing} activeView={activeView} expeditionLevel={expeditionLevel} phase={sideNavPhase} setActiveView={changeActiveView} createBar /> : <div />
              }
              mainContent={
                <FormViewWrapper
                  activeView={renderActiveView}
                  handleCancel={handleCancel(form)}
                  handleEdit={() => setIsEditing(true)}
                  isEditing={isEditing}
                  title={pageTitle}
                  setActiveView={changeActiveView}>
                  {!isEditing && <Comments barId={barId} />}
                </FormViewWrapper>
              }
            />
            <Spinner on={submitting} />
          </form>
        )}
      </Form>
      {showDeleteModal && <ConfirmDeleteDraftModal handleDeleteBar={deleteBar} onClose={() => setShowDeleteModal(false)} />}
      {showDeleteSuccess && (
        <Modal title='Action Deleted' onClose={onCloseDeleteSuccess}>
          <p>This action has been deleted from SABER</p>
          <ModalFooter>
            <Button onClick={onCloseDeleteSuccess} primary>Close</Button>
          </ModalFooter>
        </Modal>
      )}
      {showSubmitSuccess && (
        <Modal title='Success' className={styles.modal}  onClose={() => setShowSubmitSuccess(false)}>
          <p>A basing action officer will review the information provided; you will recieve a notification when the action
           submisssion has been approved, or if any changes are necessary.</p>
          <ModalFooter>
            <Button onClick={onCloseSubmitSuccess} primary>Close</Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export default CreateBar;
