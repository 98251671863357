export const reportingViewKeys = {
  onDemandReports: 'onDemandReports',
};

export const reportingViewMapping = {
  onDemandReports: {
    navTitle: 'On Demand Reports',
    pageTitle: 'On Demand Reports',
  }
};
