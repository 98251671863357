import Button from '../../../buttons/Button';
import axios from '../../../../utils/sharedAxios';
import { Form } from 'react-final-form';
import TextField from '../../../Form/TextField';
import ErrorText from '../../../ErrorText';
import TextArea from '../../../Form/TextArea';
import Label from '../../../Form/Label';

const DatabaseQuery = () => {

  const onSubmit = async (values, form) => {
    const response = await axios.post('/admin/db/look', values);
    response && response.data && console.log(response.data);
    setTimeout(form.reset);
  }

  return (
    <>
      <ErrorText>
        This page to be removed before final launch
      </ErrorText>

      <Form onSubmit={onSubmit} subscription={{}}>{({ handleSubmit }) => (
        <>
          <TextField type='password' name='pass' label='Password' required />

          <Label text='Query' required />
          <TextArea name='query' required />

          <div>
            <Button primary onClick={handleSubmit}>Submit</Button>
          </div>
        </>
      )}</Form>
    </>
  );
};


export default DatabaseQuery;