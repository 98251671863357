// please keep imports and exports alphabetized
import AdditionalBackup from '../components/bars/backups/AdditionalBackup';
import AdditionalInfo from '../components/bars/backups/AdditionalInfo';
import AdditionalMfrBackup from '../components/bars/backups/AdditionalMfrBackup';
import AdditionalCongressionalEngagements from '../components/bars/additionalCongressionalEngagements';
import Attachments from '../components/bars/attachments';
import Background from '../components/bars/background/Background';
import BasicInformation from '../components/bars/basicInformation/BasicInformation';
import BasingCriteria from '../components/bars/basingCriteria/BasingCriteria';
import BasingCriteriaDefinition from '../components/bars/basingCriteriaDefinition/BasingCriteriaDefinition';
import BasingDecisionPackage from '../components/bars/basingDecisionPackage';
import CandidateDecision from '../components/bars/candidateDecision/CandidateDecision';
import CongressionalDates from '../components/bars/congressionalDates/CongressionalDates';
import CongressionalLandscape from '../components/bars/congressionalLandscape/CongressionalLandscape';
import EisSummary from '../components/bars/eisSummary';
import Enterprise from '../components/bars/enterprise/Enterprise';
import EnterpriseScores from '../components/bars/enterpriseWideLook/EnterpriseScores';
import EnterpriseWideLook from '../components/bars/enterpriseWideLook/EnterpriseWideLook'
import FinalDecision from '../components/bars/finalDecision/FinalDecision';
import MfrHealthcare from '../components/bars/backups/MfrHealthcare';
import MfrHousing from '../components/bars/backups/MfrHousing';
import PreferredLocationDecision from '../components/bars/preferredLocationDecision';
import RecommendedCandidates from '../components/bars/recommendedCandidates/RecommendedCandidates';
import Requirements from '../components/bars/requirements/Requirements';
import RiskAssessmentCriteria from '../components/bars/riskAssessmentCriteria/RiskAssessmentCriteria';
import SafNotes from '../components/bars/safNotes/SafNotes';
import SiteSurveyCriteria from '../components/bars/siteSurveyCriteria/SiteSurveyCriteria';
import SomfProfessionalLicensure from '../components/bars/backups/SomfProfessionalLicensure';
import SomfPublicEducation from '../components/bars/backups/SomfPublicEducation';
import StageSetter from '../components/bars/stageSetter/StageSetter';
import StrategyImplications from '../components/bars/strategyImplications'
import SummaryOfFindings from '../components/bars/summaryOfFindings/SummaryOfFindings';
import SummaryOfFindingsContd from '../components/bars/summaryOfFindingsContd/SummaryOfFindingsContd';
import TwoLetterCoord from '../components/bars/twoLetterCoord';
import WayAhead from '../components/bars/wayAhead/WayAhead';
import WeAreHere from '../components/bars/weAreHere/WeAreHere';

export default {
  additionalBackup: AdditionalBackup,
  additionalInfo: AdditionalInfo,
  additionalMfrBackup: AdditionalMfrBackup,
  additionalCongressionalEngagements: AdditionalCongressionalEngagements,
  attachments: Attachments,
  background: Background,
  basicInformation: BasicInformation,
  basingCriteria: BasingCriteria,
  basingCriteriaDefinition: BasingCriteriaDefinition,
  basingDecisionPackage: BasingDecisionPackage,
  candidateDecision: CandidateDecision,
  congressionalDates: CongressionalDates,
  congressionalLandscape: CongressionalLandscape,
  eisSummaryOfFindings: EisSummary,
  enterprise: Enterprise,
  enterpriseComponents: EnterpriseWideLook,
  enterpriseComponentScores: EnterpriseScores,
  finalDecision: FinalDecision,
  mfrHealthcare: MfrHealthcare,
  mfrHousing: MfrHousing,
  preferredLocationDecision: PreferredLocationDecision,
  recommendedCandidates: RecommendedCandidates,
  requirements: Requirements,
  riskAssessmentCriteria: RiskAssessmentCriteria,
  safNotes: SafNotes,
  siteSurveyCriteria: SiteSurveyCriteria,
  somfProfessionalLicensure: SomfProfessionalLicensure,
  somfPublicEducation: SomfPublicEducation,
  stageSetter: StageSetter,
  strategyImplications: StrategyImplications,
  summaryOfFindings: SummaryOfFindings,
  summaryOfFindingsContd: SummaryOfFindingsContd,
  twoLetterCoord: TwoLetterCoord,
  wayAhead: WayAhead,
  weAreHere: WeAreHere
}
