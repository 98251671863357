import { useEffect, useState } from 'react';
import moment from 'moment';
import axios from '../../utils/sharedAxios';
import useCachedData from '../hooks/useCachedData';
import styles from './Dashboard.module.scss';
import AuthorizedContent from '../AuthorizedContent';
import DisplayFile from '../DisplayFile';
import Plus from '../svg/Plus';
import Minus from '../svg/Minus';
import Warn from '../svg/Warn';
import Download from '../svg/Download';
import DisplayBarLink from '../DisplayBarLink';
import Tooltip from '../Tooltip';

const ReviewRow = ({ review }) => {
  const [expanded, setExpanded] = useState(false);
  const { data: steps = {} } = useCachedData('/content/steps');
  const mDate = moment(review.date);

  return (
    <div className={styles.reviewRow}>
      <button
        className={styles.reviewToggle}
        type='button'
        disabled={review.bars.length === 0}
        onClick={() => review.bars.length > 0 && setExpanded(!expanded)}>{expanded ? (
          <Minus className={styles.reviewToggleIcon}/>
         ) : (
          <Plus className={styles.reviewToggleIcon}/>
         )}</button>
      <div className={styles.reviewDate}>
        <span className={styles.reviewDateDay}>{mDate.date()}</span>
        <span className={styles.reviewDateMonth}>{mDate.format('MMM')}</span>
      </div>
      <div className={styles.reviewInfo}>
        <p className={styles.reviewStep}>{steps[review.step]}</p>
        {!expanded && <div className={styles.reviewBarsCount}>{review.bars.length} Basing Actions</div>}
        {expanded && review.bars.length > 0 && review.bars.map(bar => (
          <div className={styles.reviewBar} key={bar.barId}><DisplayBarLink barId={bar.barId}>{bar.title}</DisplayBarLink></div>
        ))}
      </div>
      {!!review.isPublished && review.docId && <Tooltip content='Download Read-Ahead Materials for this review'><DisplayFile uuid={review.docId} label={<Download />} /></Tooltip>}
      <AuthorizedContent authorizedRoles='SAM'>{(!review.docId || !review.isPublished) && <Tooltip content='No Read-Ahead Materials are published for this review'><Warn /></Tooltip>}</AuthorizedContent>
    </div>
  )
}

const UpcomingReviews = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const result = await axios.get('/reviews/all');
      const { data: allReviews } = result;
      const futureReviews = [];

      Object.entries(allReviews).forEach(([_, item]) => {
        if (item.future.length > 0) {
          futureReviews.push(...item.future);
        }
      });

      futureReviews.sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        }
        if (a.date > b.date) {
          return 1;
        }

        return 0;
      });

      setReviews(futureReviews);
    }

    fetchReviews();
  }, []);

  return (
    <>
      <h5 className={styles.panelHeading}>UPCOMING REVIEWS</h5>
      <div className={styles.tile}>
        {reviews.length > 0 ? reviews.map(review => (
          <ReviewRow key={review.reviewId} review={review} />
        )) : (
          <p className={styles.noResults}>No scheduled reviews</p>
        )}
      </div>
    </>
  )
}

export default UpcomingReviews;