import styles from './NotAllowed.module.scss';

// error page for users attempting to view a page they lack access to
const NotAllowed = () => {
  return (
    <div className={styles.notAllowed}>
      <h3>You don't have authorization to view that page.</h3>
    </div>
  )
}

export default NotAllowed;