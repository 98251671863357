export const adminViewKeys = {
  newAccountRequests: 'newAccountRequests',
  contactsGroups: 'contactsGroups',
  newActionRequests: 'newActionRequests',
  actionEquity: 'actionEquity',
  actionsOfInterest: 'actionsOfInterest',
  databaseQuery: 'databaseQuery',
  staticResources: 'staticResources'
};

export const adminViewMapping = {
  newAccountRequests: {
    navTitle: 'New Account Requests',
    pageTitle: 'New Account Requests',
  },
  contactsGroups: {
    navTitle: 'Contacts & Groups',
    pageTitle: 'Contacts & Groups',
  },
  newActionRequests: {
    navTitle: 'New Action Requests',
    pageTitle: 'New Action Requests',
  },
  actionEquity: {
    navTitle: 'Action Equity',
    pageTitle: 'Action Equity',
  },
  actionsOfInterest: {
    navTitle: 'Actions of Interest',
    pageTitle: 'Actions of Interest',
  },
  databaseQuery: {
    navTitle: 'Database Query',
    pageTitle: 'Database Query'
  },
  staticResources: { //  THIS VIEW INTENTIONALLY HIDDEN - ddiaz
    navTitle: 'Static Resources',
    pageTitle: 'Static Resources'
  }
};
