import React from 'react';
import omit from 'lodash/omit'
/**
 * BaseTextField is a reusable text input that handles a tiny bit of logic
 * such as readonly and disabling the onChange
 * It is the basis for TextField and TextField
 * @param {String} className
 * @param {Boolean} disabled
 * @param {String} name
 * @param {Function} onChange
 * @param {Boolean} required
 * @param {String} type
 */
const BaseTextField = ({ className, disabled, name, onChange, readOnly, required, type = 'text', value, ...rest }) => {
  return (
    <input className={className}
      disabled={disabled}
      id={name}
      name={name}
      onChange={!disabled ? onChange : null}
      readOnly={readOnly || disabled}
      required={required}
      type={type}
      value={value}
      {...omit(rest, ['fieldDataEndpoint'])} />
  );
}

export default BaseTextField;
