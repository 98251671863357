const getBaseName = (id, bases) => {
  let name = id;
  
  if (bases) {
    const base = bases.find(item=> item.id === id);
    name = base ? base.baseName : id;
  }
  
  return name;
}

export default getBaseName;