import React, { useEffect, useRef, useState } from 'react';
import { useField } from 'react-final-form';
import cn from 'classnames';
import Button from '../buttons/Button';
import UploadButton from '../buttons/UploadButton';
import DisplayStaticResource from '../DisplayStaticResource';
import useCachedData from '../hooks/useCachedData';
import styles from './Form.module.scss';

const UploadField = ({ accept = 'image/*', className, name, deleteFile, removeLabel = 'Remove', staticResourceType, type }) => {
  const uploadedImage = useField(`${name}.fileKey`);
  const options = {params: { docId: uploadedImage.input.value } };
  const { data: uploadedImageSrc } = useCachedData(uploadedImage.input.value && '/documents/image', options);
  const previewImage = useField(`${name}.previewImage`, { type: 'file' });
  const previewImageName = useField(`${name}.previewImageName`);
  const imageType = useField(`${name}.type`);
  const [previewUrl, setPreviewUrl] = useState(null);
  const imgSrc = previewUrl ? previewUrl : uploadedImage.input.value && uploadedImageSrc;
  const inputRef = useRef(null);

  const onInputChange = (e) => {
    previewImage.input.onChange(e.target.files[0]);
  };

  const onClickUpload = () => inputRef.current.click();

  const onClickRemove = () => {
    setPreviewUrl(null);
    uploadedImage.input.onChange('');
    previewImage.input.onChange('');
    previewImageName.input.onChange('');
    imageType.input.onChange(null);
    inputRef.current.value = '';
    if(deleteFile && uploadedImageSrc && uploadedImage.input.value){
      deleteFile(uploadedImage.input.value);
    }
  }

  useEffect(() => {
    if (previewImage.input.value) {
      setPreviewUrl(URL.createObjectURL(previewImage.input.value));
      previewImageName.input.onChange(previewImage.input.value.name);
      imageType.input.onChange(type);
    }
  }, [previewImage.input.value]);

  return (
    <div className={className}>
      <input name='image' type='file' onChange={onInputChange} accept={accept} ref={inputRef} style={{ display: 'none' }} />
      <div className={styles.uploadFieldActions}>
        {staticResourceType && <DisplayStaticResource className={cn(styles.uploadFieldAction, styles.staticResourceLink)} type={staticResourceType} label='Get Template' />}
        {imgSrc && <Button className={styles.uploadFieldAction} onClick={onClickRemove} secondary>{removeLabel}</Button>}
        {!imgSrc && <UploadButton className={styles.uploadFieldAction} onClick={onClickUpload}/>}
      </div>
      {imgSrc && <img className={styles.mapImage} src={imgSrc} />}



    </div>
  );
}

export default UploadField;