import React from 'react';
import styles from './GenerateMaterials.module.scss';

const BarsList = ({ bars }) => {
  return (bars && bars.length > 0) ? (
    <div className={styles.barsList}>
      <h3>Basing Actions</h3>
      <ul>
        {bars.map(({ barId, title }) => <li key={barId}>{title}</li>)}
      </ul>
    </div>
  ) : (<h3>No Actions Scheduled</h3>);
};

export default BarsList;