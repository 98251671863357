import React from 'react';
import moment from 'moment';
import dateFormats from '../../constants/dateFormats';
import useCachedData from '../hooks/useCachedData';
import NavListHeading from '../SideNav/NavListHeading';
import NavList from '../SideNav/NavList';
import NavListItem from '../SideNav/NavListItem';
import NavListItemIcon from '../SideNav/NavListItemIcon';
import pluralize from '../../utils/pluralize';

const ReviewCenterSideNav = ({ reviews, selectedStep, selectedReview, setSelectedReview }) => {
  const { data: steps = {} } = useCachedData('/content/steps');
  const stepName = steps[selectedStep];

  const renderListItem = (item, isDetermination) => {
    const { reviewId, date, bars = [] } = item;
    const isActive = selectedReview && (reviewId === selectedReview.reviewId);

    return (
      <NavListItem
        key={reviewId}
        icon={isDetermination && <NavListItemIcon warning />}
        isActive={isActive}
        onClick={() => setSelectedReview(item)}>
          {`${moment(date).format(dateFormats.short)}: ${bars.length} ${pluralize(bars.length, 'Action', 'Actions')}`}
      </NavListItem>
    );
  };
  
  if (reviews && selectedStep && reviews[selectedStep]) {
    const { future, past } = reviews[selectedStep];
    return (
      <>
        {past.length > 0 && (
          <>
            <NavListHeading>{stepName} Determinations</NavListHeading>
            <NavList>
              {past.map(item => renderListItem(item, true))}
            </NavList>
            
          </>
        )}
        {future.length > 0 && (
          <>
            <NavListHeading>{stepName} Reviews</NavListHeading>
            <NavList>
              {future.map(item => renderListItem(item))}
            </NavList>
          </>
        )}
      </>
    );
  }

  return null;
}

export default ReviewCenterSideNav;