import React from 'react';
import useCachedData from '../../hooks/useCachedData';
import BackupSlide from './BackupSlide';

const AdditionalMfrBackup = (props) => {
  const { data: keys } = useCachedData('/content/barViewKeys');
  
  return (
    <BackupSlide titleKey={keys && keys.additionalMfrBackup} {...props} />
  );
}
export default AdditionalMfrBackup;
