const Edit = ({ className }) => (
  <svg className={className} data-name="Group 2648" xmlns="http://www.w3.org/2000/svg" width="18.716" height="18.718" viewBox="0 0 18.716 18.718">
    <rect id="Rectangle_3540" data-name="Rectangle 3540" width="13" height="3" transform="translate(7.402 9.192) rotate(-45)" fill="currentColor"/>
    <path id="Path_2238" data-name="Path 2238" d="M2,0,0,1.5,2,3Z" transform="translate(5.281 11.313) rotate(-45)" fill="currentColor"/>
    <rect id="Rectangle_3541" data-name="Rectangle 3541" width="1" height="16" transform="translate(0 2.718)" fill="currentColor"/>
    <path id="Path_2239" data-name="Path 2239" d="M0,0H11L10,1H0Z" transform="translate(0 2.718)" fill="currentColor"/>
    <path id="Path_2237" data-name="Path 2237" d="M0,2,1,1V8H0Z" transform="translate(11 10.718)" fill="currentColor"/>
    <rect id="Rectangle_3539" data-name="Rectangle 3539" width="12" height="1" transform="translate(0 17.718)" fill="currentColor"/>
  </svg>
);

export default Edit;