import React, { useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import Grid from '../../Grid';
import { Box } from 'theme-ui';
import SelectField from '../../Form/SelectField';
import DependentField from '../../Form/DependentField';

const TypeOfActionFields = () => {
  const { input: { value } } = useField('typeOfAction');
  const [secondaryField, setSecondaryField] = useState({});

  useEffect(() => {
    setSecondaryField(value.secondaryField);
  }, [value]);

  return (
    <Grid columns={[2]}>
      <Box>
        <SelectField label='Type of Action' name='typeOfAction' fieldDataEndpoint='/content/actionTypes' required />
      </Box>
      <Box>
        <DependentField enableFallback fieldObject={secondaryField} name='typeOfActionSecondaryField' required />
      </Box>
    </Grid>
  );
}

export default TypeOfActionFields;
