import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from '../../utils/sharedAxios';
import {downloadPdf} from '../../utils/download';
import moment from 'moment';
import PDFModal from '../ReviewCenter/PDFModal';

export const PDFContext = React.createContext({});

/**
 * Wraps a section of the application in a react context to hold pdf backend calls in progress.
 */
export const PDFProvider = ({ children }) => {
    const [isGeneratingBarPdf, setIsGeneratingBarPdf] = useState({})
    const reviewPdfRef = useRef({})
    const [isGeneratingReviewPdf, setIsGeneratingReviewPdf] = useState({})
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [pdfSuccess, setPdfSuccess] = useState(false);

    const onPdfModalClose = () => {
        setShowPdfModal(false)
        setPdfSuccess(false)
    }

    const generateReviewPdf = async (review) => {
        console.log('generating review pdf', review)
        let result = false;
        if(review && review.reviewId) {
            reviewPdfRef.current[review.reviewId] = true
            setIsGeneratingReviewPdf({...isGeneratingReviewPdf, [review.reviewId]: true});
            const barIds = review.bars.map(bar => bar.barId);
            const { data } = await axios.post('/bars/export-pdf', {reviewPackageId: review.reviewId, reviewStep: review.step, reviewDate: review.date, barIds, timestamp: new Date()} )
                .catch((err) =>{
                    console.log(err)
                    return {}
                });
            if (data && data.uuid) {
                setPdfSuccess(data)
                result = true;
            }
            else {
                setPdfSuccess(false)
            }
            setShowPdfModal(true)
            reviewPdfRef.current[review.reviewId] = false
            //the ref holds the truth
            setIsGeneratingReviewPdf({...reviewPdfRef.current});
        }
        return result;
    }

    const generateBarPdf = (barId, title) => {
        setIsGeneratingBarPdf({...isGeneratingBarPdf, [barId]: true})
        axios.get('/bars/single-bar-pdf', {params: {barId: barId}, responseType: 'blob'}).then((res) => {
            downloadPdf(`Preview - ${title} - ${moment().format('D MMM YYYY')}.pdf`, res.data)
            setIsGeneratingBarPdf({...isGeneratingBarPdf, [barId]: false})
          }).catch((err) => {
            console.log(err)
            //potentially add some error notification here
            setIsGeneratingBarPdf({...isGeneratingBarPdf, [barId]: false})
          })
    }
    return (
        <PDFContext.Provider value={{
            isGeneratingBarPdf,
            isGeneratingReviewPdf,
            generateBarPdf,
            generateReviewPdf
        }}>
        {showPdfModal && <PDFModal onClose={onPdfModalClose} success={pdfSuccess} />}
        {children}
        </PDFContext.Provider>
    )
}

export const usePdf = () => {
    const context = useContext(PDFContext)
    if(context === undefined) {
        throw new Error('usePdf hook must be used within the PDFProvider component')
    }
    return context;
}