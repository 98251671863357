import React, {useEffect, useState} from 'react';
import axios from '../../utils/sharedAxios'
import ContentView from '../ContentView';
import Header from '../Header';
import useDocumentTitle from '../hooks/useDocumentTitle';
import ContactsContent from './ContactsContent';
import ContactsSideNav from './ContactsSideNav';

// Contacts is a page that lists user and non-user contact info
const Contacts = () => {
  useDocumentTitle('Contacts');
  const [groupedContacts, setGroupedContacts] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const buildGroupedContacts = (groups, users) => {
    const grouping = {};

    users.reduce((acc, user) => {
      if (user.contactGroup) {
        const contactGroup = groups.find(group => group.id === user.contactGroup);

        if (!acc[contactGroup.name]) {
          acc[contactGroup.name] = {
            isBasingOffice: contactGroup.isBasingOffice,
            users: []
          };
        }

        acc[contactGroup.name].users.push(user);
      }

      return acc;
    }, grouping);

    setGroupedContacts(grouping);
  }

  useEffect(() => {
    const fetchContacts = async () => {
      const { data: groups } = await axios.get('/users/groups');
      const { data: users } = await axios.get('/users/all');
      buildGroupedContacts(groups, users);
    }

    fetchContacts();

  }, []);

  useEffect(() => {
    if (groupedContacts) {
      setSelectedGroup(Object.keys(groupedContacts).sort()[0]);
    }
  }, [groupedContacts]);

  return(
    <ContentView
      headerContent={<Header leftContent={<h1>Contacts</h1>} />}
      mainContent={<ContactsContent groupedContacts={groupedContacts} selectedGroup={selectedGroup} />}
      sidebarContent={<ContactsSideNav groupedContacts={groupedContacts} selectedGroup={selectedGroup} setSelectedGroup={setSelectedGroup} />}
    />
  );
}

export default Contacts;
