import axios from "./sharedAxios";

// these upload functions manage images and documents on Basing Actions that need to be uploaded to AWS S3

export const uploadImages = (values, barId, phase, fieldKeys) => {
    const promises = fieldKeys.map(async (fieldKey) => {
        let fd = new FormData();
        fd.append('barId', barId);
        fd.append('file', _.get(values, `${fieldKey}.previewImage`));
        const result = await axios.post('/documents/upload', fd);
        if(result.status === 200 || result.status === 204){
            await axios.post('/documents/addBarDocument', {docId: result.data.Key, fileName: _.get(values, `${fieldKey}.previewImageName`), barId, phase, description: _.get(values, `${fieldKey}.description`) || '', type: _.get(values, `${fieldKey}.type`)});
            _.set(values, `${fieldKey}.fileKey`, result.data.Key);

            _.unset(values, `${fieldKey}.previewImage`);
            _.unset(values, `${fieldKey}.previewImageName`);
        }
    });
    return Promise.all(promises);
}

export const uploadDocs = async (values, barId, phase, fieldKeys) => {
    return Promise.all(fieldKeys.map(async (fieldKey) => {
        const docValue = _.get(values, fieldKey);
        if(_.isArray(docValue)){
            return Promise.all(
                docValue.map(async (doc, index) => {
                    if(doc.file){
                        let fd = new FormData();
                        fd.append('barId', barId);
                        fd.append('file', doc.file);

                        const upload = await axios.post('/documents/upload', fd);
                        if(upload.status === 200 || upload.status === 204){
                            const uploadDataKey = upload.data.Key || upload.data.key; // AWS sending 'Key' for large files, 'Key' and 'key' for smaller files
                            await axios.post('/documents/addBarDocument', {docId: uploadDataKey, fileName: doc.file.name, barId, phase, description: '', type: doc.type, packageType: doc.package});
                            //set some values so the display does not need to reload data
                            _.set(values, `${fieldKey}[${index}].uuid`, uploadDataKey);
                            _.set(values, `${fieldKey}[${index}].originalFileName`, doc.file.name);
                            _.set(values, `${fieldKey}[${index}].phase`, phase);
                            _.set(values, `${fieldKey}[${index}].createdAt`, new Date());

                            _.unset(values, `${fieldKey}[${index}].file`);
                        }
                    }
                })
            )
        }
    }))
}