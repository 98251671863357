import React from 'react';
import DocumentListRow from './DocumentListRow';
import Table from '../Table';
import styles from './DocumentList.module.scss';
import DetailsText from '../DetailsText';

const DocumentList = ({docList, title = 'Documents', remove, fields, deleteFile}) => {
    const removeFile = (docId) => {
        const index = fields?.value.findIndex((doc) => doc.uuid === docId);
        if(remove && index >= 0){
            remove(index)();
        }
        if(docId && deleteFile){
            deleteFile(docId)
        }
    }

    const isEmpty = !docList || !docList.length;

    return (
        <Table className={styles.documentList} noHeadBorder={isEmpty}>
            {title && (
                <thead>
                    <tr>
                        <th>{title}</th>

                        {!isEmpty && (
                            <>
                                <th>Phase</th>
                                <th>Uploaded On</th>
                                <th>Uploaded By</th>
                            </>
                        )}
                    </tr>
                </thead>
            )}
            <tbody>
            {docList?.map && docList.map(d => {

                if (!d.file) {
                    return <DocumentListRow originalFileName={d.originalFileName} phase={d.phase} uuid={d.uuid} removeFile={remove && removeFile} key={d.uuid} />

                }
            })}
            </tbody>
            {(!docList || docList.length < 1) && (
                <tfoot>
                    <tr>
                        <td><DetailsText className={styles.noAttachments}>No Attachments</DetailsText></td>
                    </tr>
                </tfoot>
            )}
        </Table>
    )
}

export default DocumentList;