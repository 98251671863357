import React from 'react';
import TextArea from '../../../Form/TextArea';
import styles from '../../GenerateMaterials.module.scss';

const RollCallEdit = () => {
  return (
    <div className={styles.fieldRow}>
      <TextArea className={styles.tallTextArea} name='rollCall.firstRow' />
      <TextArea className={styles.tallTextArea} name='rollCall.secondRow' />
    </div>

  );
};

export default RollCallEdit;