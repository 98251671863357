import React from 'react';
import { useFormState } from 'react-final-form';
import DisplayDate from '../../DisplayDate';
import Table from '../../Table';
import DisplayFile from '../../DisplayFile';
import styles from './AdditionalCongressionalEngagements.module.scss';
import dateFormats from '../../../constants/dateFormats';

const AdditionalCongressionalEngagementsDisplay = () => {
  const { initialValues: { congressionalEngagementDocuments = [] } = {} } = useFormState({ subscription: { initialValues: true } });

  const renderEngagementTable = () => {
    return (
      <Table className={styles.engagementTable}>
        <thead>
          <tr>
            <th>Document</th>
            <th className={styles.dateCell}>Date</th>
            <th>Phase</th>
            <th className={styles.dateCell}>Uploaded On</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {congressionalEngagementDocuments.map((data, index) => (
            <tr key={index}>
              <td><DisplayFile uuid={data.uuid} fileName={data.originalFileName} /></td>
              <td className={styles.dateCell}>{data.engagementDate && <DisplayDate date={data.engagementDate} format={dateFormats.short} />}</td>
              <td>{data.phase}</td>
              <td className={styles.dateCell}><DisplayDate date={data.updatedAt} format={dateFormats.short} /></td>
              <td>{data.note}</td>
            </tr>
          ))}

        </tbody>
      </Table>
    )
  }
  return congressionalEngagementDocuments.length > 0 ? renderEngagementTable() : null;
}

export default AdditionalCongressionalEngagementsDisplay;
