import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import cn from 'classnames';
import Table from '../../Table';
import Collapsible from '../../Collapsible';
import Grid from '../../Grid';
import pluralize from '../../../utils/pluralize';
import styles from './BasingCriteria.module.scss';
import formStyles from '../../Form/Form.module.scss';
import RichTextDisplay from '../../Form/RichTextDisplay';
import ReadOnlyFacilityField from '../../Form/ReadOnlyFacilityField';

const BasingCriteriaDisplay = () => {
  const [grandTotalLimit, setGrandTotalLimit] = useState(100);
  const { initialValues } = useFormState({ subscription: { initialValues: true } });
  const {
    baseSupportTailCriteriaTotalPoints,
    basingCriteriaGrandTotalPoints,
    capacityTotalPoints,
    costCriteriaTotalPoints,
    environmentRequirementTotalPoints,
    facilityRequirements,
    facilityCriteriaTotalPoints,
    militaryFamilyReadinessTotalPoints,
    missionCriteria,
    missionRequirements,
    missionRequirementTotalPoints,
    supportOfMilitaryFamiliesTotalPoints,
  } = initialValues;

  // supportOfMilitaryFamiliesTotalPoints
  useEffect(() => {
    setGrandTotalLimit(supportOfMilitaryFamiliesTotalPoints > 0 ? 120 : 100);
  }, [supportOfMilitaryFamiliesTotalPoints]);

  const renderTableHead = () => <thead><tr><th /><th /></tr></thead>;

  const renderToggleHeading = (title, points) => (
    <Grid columns={[2, '9fr 1fr']}>
      <h3 className={cn(styles.groupHeading, formStyles.sectionHeading)}>{title}</h3>
      <span>{points ? `${points} ${pluralize(points, 'Point', 'Points')}`: ''}</span>
    </Grid>
  );

  const renderParentRow = (definition, point) => (
    <tr className={styles.parentRow}>
      <td>{definition}</td>
      <td>{point}</td>
    </tr>
  );

  const renderChildRow = (definition, point) => (
    <tr className={styles.childRow}>
      <td>{definition}</td>
      <td>{point}</td>
    </tr>
  );

  return (
    <>
      <h3 className={formStyles.sectionHeading}>Enterprise</h3>
      <RichTextDisplay html={initialValues?.enterpriseDefinition?.definition} />

      {/* MISSION REQUIREMENTS TABLE*/}
      {(missionRequirementTotalPoints || missionRequirements) && (
        <Collapsible
          toggle={renderToggleHeading('Mission', missionRequirementTotalPoints)}>
          <Table className={styles.table}>
            {renderTableHead()}
            <tbody>
              {missionRequirements && missionRequirements.map(({ requirement, point, subRequirements }, index) => (
                <React.Fragment key={index}>
                  {renderParentRow(requirement, point)}
                  {subRequirements && subRequirements.map(({ subRequirement, point }, index) => (
                    <React.Fragment key={index}>
                      {renderChildRow(subRequirement, point)}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </Collapsible>
      )}

      {/* CAPACITY TABLE*/}
      {(capacityTotalPoints ||
        facilityCriteriaTotalPoints ||
        facilityRequirements ||
        missionCriteria?.baseSupportTailCriteria) && (
        <Collapsible
          toggle={renderToggleHeading('Capacity', capacityTotalPoints)}>
          <Table className={styles.table}>
            {renderTableHead()}
            <tbody>
              {renderParentRow('Facilities', facilityCriteriaTotalPoints)}

              {facilityRequirements && facilityRequirements.map(({ point }, index) => (
                <React.Fragment key={index}>
                  {renderChildRow(<ReadOnlyFacilityField name={`facilityRequirements[${index}]`} />, point)}
                </React.Fragment>
              ))}

              {renderParentRow('Base Support Tail', baseSupportTailCriteriaTotalPoints)}
              {missionCriteria?.baseSupportTailCriteria && missionCriteria.baseSupportTailCriteria.map(({ definition, point }, index) => (
                <React.Fragment key={index}>
                  {renderChildRow(definition, point)}
                </React.Fragment>
              ))}
              {missionCriteria?.additionalBaseSupportTailCriteria && missionCriteria.additionalBaseSupportTailCriteria.map(({ definition, point }, index) => (
                <React.Fragment key={index}>
                  {renderChildRow(definition, point)}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </Collapsible>
      )}

      {/* ENVIRONMENTAL TABLE */}
      {(environmentRequirementTotalPoints ||
        missionCriteria?.environmentalCriteria) && (
        <Collapsible
          toggle={renderToggleHeading('Environmental', environmentRequirementTotalPoints)}>
          <Table className={styles.table}>
            {renderTableHead()}
            <tbody>
              {missionCriteria?.environmentalCriteria &&
                missionCriteria.environmentalCriteria.map(({ definition, point }, index) => (
                <React.Fragment key={index}>
                  {renderChildRow(definition, point)}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </Collapsible>
      )}

      {/* COST TABLE */}
      {missionCriteria?.costCriteria && (
        <Collapsible
          toggle={renderToggleHeading('Cost', costCriteriaTotalPoints)}>
          <Table className={styles.table}>
            {renderTableHead()}
            <tbody>
              {missionCriteria?.costCriteria &&
                missionCriteria.costCriteria.map(({ definition, point }, index) => (
                <React.Fragment key={index}>
                  {renderChildRow(definition, point)}
                </React.Fragment>
              ))}
              {missionCriteria?.additionalCosts &&
                missionCriteria.additionalCosts.map(({ definition, point }, index) => (
                <React.Fragment key={index}>
                  {renderChildRow(definition, point)}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </Collapsible>
      )}

      {/* MILITARY FAMILY READINESS TABLE*/}
      {(missionCriteria?.militaryFamilyReadiness || missionCriteria?.supportOfMilitaryFamilies) && (
        <Collapsible
          toggle={renderToggleHeading('Military Family Readiness', militaryFamilyReadinessTotalPoints)}>
          <Table className={styles.table}>
            {renderTableHead()}
            <tbody>
              {missionCriteria?.militaryFamilyReadiness &&
                missionCriteria.militaryFamilyReadiness.map(({ definition, point, children }, index) => (
                <React.Fragment key={index}>
                  {renderParentRow(definition, point)}
                </React.Fragment>
              ))}

              {renderParentRow('Support of Military Families Points', supportOfMilitaryFamiliesTotalPoints)}
              {missionCriteria?.supportOfMilitaryFamilies &&
                missionCriteria.supportOfMilitaryFamilies.map(({ definition, point, children }, index) => (
                <React.Fragment key={index}>
                  {renderChildRow(definition, point)}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </Collapsible>
      )}

      <p className={styles.grandTotal}>Total <span className={styles.grandTotalValue}>{basingCriteriaGrandTotalPoints}/{grandTotalLimit}</span></p>
    </>
  )
}

export default BasingCriteriaDisplay;
