import React, { useContext, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import cn from 'classnames';
import useReconciledValue from '../hooks/useReconciledValue';
import { DictionaryContext } from '../Dictionary/DictionaryContext';
import useCachedData from '../hooks/useCachedData';
import styles from './Select.module.scss'

const Select = ({
  className,
  disabled,
  error,
  fieldDataEndpoint,
  isClearable = true,
  isSearchable = false,
  multiple,
  name,
  onChange,
  options,
  placeholderText = '',
  value,
  zIndex,
  round,
  ...props
 }) => {
  const dictionary = useContext(DictionaryContext);
  const [finalOptions, setFinalOptions] = useState([]);
  const { data: fieldData } = useCachedData(fieldDataEndpoint);

  const getValueFromDictionary = (value) => {
    if (value) {
      return dictionary && dictionary[value] || value;
    }

    return null;
  }

  const buildOptions = arr => arr.map(({ label, value, ...rest }) => ({
    label: label || getValueFromDictionary(value), // generally, labels are not provided
    value,
    ...rest
  }));

  useEffect(() => {
    if (fieldData?.options?.length) {
      setFinalOptions(buildOptions(fieldData.options));
    }
    if (options?.length) {
      setFinalOptions(buildOptions(options));
    }
  }, [fieldData, options]);

  useReconciledValue(finalOptions, value, onChange);

  return (
    <ReactSelect
      className={cn({
        [styles.roundSelect]: round,
        [styles.error]: error
      }, className)}
      classNamePrefix='reactSelect'
      isClearable={isClearable}
      isDisabled={disabled}
      isMulti={multiple}
      isSearchable={isSearchable}
      id={name}
      name={name}
      onChange={onChange}
      readOnly={disabled}
      value={value || null}
      options={finalOptions}
      placeholder={placeholderText}
      {...props} />
  );
}

export default Select;
