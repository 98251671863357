import React from 'react';
import cn from 'classnames';
import { useField } from 'react-final-form';
import Label from './Label';
import BaseTextField from './BaseTextField';
import ErrorIndicator from './ErrorIndicator';
import styles from './Form.module.scss';

const TextField = ({ className, disabled, label, name, placeholder, required, type = 'text', format, parse, ...props }) => {
  const { input, meta } = useField(name, { type, format, parse });

  return (
    <div className={cn(styles.fieldControl, className)}>
      {label && <Label htmlFor={name} required={required} text={label} />}

      <div className={styles.inputContainer}>
        {meta.touched && (meta.error || meta.submitError) && <ErrorIndicator />}
        <BaseTextField className={cn(styles.input, { [styles.inputError]: meta.touched && meta.error })}
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          {...props}
          {...input} />
      </div>
    </div>
  );
}

export default TextField;
