import { Grid as ThemeUIGrid } from 'theme-ui';
import cn from 'classnames';
import styles from './Grid.module.scss';

// our own styled grid
// absoluteChild is an absolute position item such as an "x" to delete a row
const Grid = ({ absoluteChild, children, columns, className, gap = 1 }) => {
  return (
    <ThemeUIGrid className={cn(styles.grid, className)} gap={gap} columns={columns}>
      {children}
      {absoluteChild && <div className={styles.absoluteChild}>{absoluteChild}</div>}
    </ThemeUIGrid>
  );
}

export default Grid;
