import { Form } from 'react-final-form';
import Button from '../../../buttons/Button';
import ContactFields from '../../../ContactFields';
import Modal from '../../../Modal';
import ModalFooter from '../../../Modal/ModalFooter';
import styles from '../../Admin.module.scss'

const EditRequestModal = ({ request, handleEditRequest, onClose }) => {
  return (
    <Modal className={styles.modalMedium} onClose={onClose} title='Edit Contact'>
      <Form onSubmit={handleEditRequest} initialValues={request} subscription={{}}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <ContactFields />
            <ModalFooter>
              <Button type="submit" primary>SAVE</Button>
              <Button onClick={onClose} secondary>CANCEL</Button>
            </ModalFooter>
          </form>
        )}
      </Form>
    </Modal>
  );
}

export default EditRequestModal;