import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../Utility/Authentication';

// redirects to the "not-allowed" route if a user lacks the required roles
const useRedirectUnauthorizedUsers = ({ authorizedRoles, barId }) => {
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const replaceWithNotAllowed = () => history.replace('/not-allowed');

  useEffect(() => {
    !user.role && replaceWithNotAllowed();

    if (authorizedRoles) {
      if (Array.isArray(authorizedRoles)) {
        const lowerCaseRoles = authorizedRoles.map(role => role.toLowerCase());
        !lowerCaseRoles.includes(user.role.toLowerCase()) && replaceWithNotAllowed();
      }

      if (typeof authorizedRoles === 'string') {
        authorizedRoles.toLowerCase() !== user.role.toLowerCase() && replaceWithNotAllowed();
      }
    }

    if (barId) {
      const hasEquity = user.role === 'SAM' || user.role === 'KRIS' || user.role === 'LEE' || user.equity.bars.includes(parseInt(barId, 10));
      !hasEquity && replaceWithNotAllowed();
    }

  }, [user.role, user.equity, authorizedRoles, barId]);

}

export default useRedirectUnauthorizedUsers;