import React, { useEffect, useState } from 'react';
import styles from './StepNav.module.scss';
import cn from 'classnames';
import useCachedData from '../../hooks/useCachedData';
import ChevronLeft from '../../svg/ChevronLeft';
import ChevronRight from '../../svg/ChevronRight';
import DisplayDate from '../../DisplayDate';
import { now } from 'lodash';
import dateFormats from '../../../constants/dateFormats';

const StepNav = ({
  changePhaseInView,
  changeStepInView,
  editing,
  phaseInView,
  reviewDates,
  snapshots,
  stepInView,
}) => {
  const { data: stepData } = useCachedData('/content/stepOptions');
  const [visibleSteps, setVisibleSteps] = useState([]);
  const [steps, setSteps] = useState([]);
  const [firstVisiblePhase, setFirstVisiblePhase] = useState(1);
  const [lastVisiblePhase, setLastVisiblePhase] = useState(1);
  const lastStepIndex = steps ? steps.length - 1 : 0;

  useEffect(() => {
    if (stepData && !steps.length) {
      setSteps(stepData.options);
    }
  }, [stepData]);

  // build visible steps
  useEffect(() => {
    let displayedSteps = [];
    let availableSnapshots = [];

    if (snapshots && snapshots.length) {
      availableSnapshots = snapshots.filter(snapshot => snapshot.phase === phaseInView);
    }

    if (steps.length) {
      displayedSteps = steps.map(({ label, value }, index) => {
        const snapshot = availableSnapshots[index];
        const active = snapshot && snapshot.step === stepInView;
        const foundReviewDate = reviewDates && reviewDates.find(item => item.phase === phaseInView && item.step === value);
        return ({
          active,
          enabled: snapshot && !snapshot.skipped && (!editing || active),
          reviewDate: foundReviewDate && foundReviewDate.reviewDate,
          skipped: snapshot && snapshot.skipped,
          stepNumber: snapshot && snapshot.step,
          label,
          value,
        });
      });
    }

    setVisibleSteps(displayedSteps);

  }, [phaseInView, snapshots, stepInView, steps, editing]);

  useEffect(() => {
    let first = 4;
    let last = 1;

    if (snapshots && snapshots.length) {
      snapshots.forEach(({ phase, skipped }) => {
        first = !skipped ? Math.min(phase, first) : first;
        last = Math.max(phase, last);
      });
    }

    setFirstVisiblePhase(first);
    setLastVisiblePhase(last);
  }, [snapshots]);

  const handleClickPrevPhase = () => {
    changePhaseInView(phaseInView - 1);
  }

  const handleClickNextPhase = () => {
    changePhaseInView(phaseInView + 1);
  }

  const handleClickStep = (stepNumber) => () => {
    changeStepInView(stepNumber);
  };
  const snapshotDateString = (date) => <span>{now() < date ? 'Scheduled' : '✅ Presented' } <DisplayDate date={date} format={dateFormats.short} /></span>;

  return (
    <div className={styles.stepNav}>
      <div className={styles.navigation} role='navigation'>
        {phaseInView > firstVisiblePhase && <button className={styles.prevPhaseButton} onClick={handleClickPrevPhase} type='button' disabled={editing}><ChevronLeft /></button>}

        <div className={styles.steps} role='tablist'>
          {visibleSteps.map(({ active, enabled, label, reviewDate, skipped, stepNumber, value  }, index) => {
            const stepClassName = cn(styles.step, {
              [styles.active]: active,
              [styles.enabled]: enabled,

            });
            return (
              <div key={value} role='tab' className={stepClassName} onClick={enabled ? handleClickStep(stepNumber) : null} tabIndex={0}>
                {(index === 0) && (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178.036 78">
                    <path id="Path_1869" data-name="Path 1869" d="M0,0H168l10.036,39L168,78H0Z" />
                  </svg>
                )}
                {(index === lastStepIndex) && (
                  <svg viewBox="153.846 189.36 168 78" width="168" height="78">
                    <path id="Path_1868" data-name="Path 1868" d="M 153.846 189.36 L 321.846 189.36 L 321.846 267.36 L 153.846 267.36 L 163.846 228.36 L 153.846 189.36 Z"></path>
                  </svg>

                )}
                {(index !== 0 && index < lastStepIndex) && (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 178 78">
                    <path id="Path_1868" data-name="Path 1868" d="M0,0H168l10,39L168,78H0L10,39Z"/>
                  </svg>
                )}
                <div className={styles.stepContent}>
                  <p className={styles.stepTitle}>{label}</p>
                  {reviewDate && <span className={styles.stepSubTitle}>{snapshotDateString(reviewDate)}</span>}
                  {!reviewDate && skipped && <span className={styles.stepSubTitle}>Skipped</span>}
                </div>
              </div>
            );
          })}
        </div>

        {phaseInView < lastVisiblePhase && <button className={styles.nextPhaseButton} onClick={handleClickNextPhase} type='button' disabled={editing}><ChevronRight /></button>}
      </div>
    </div>
  );
}

export default StepNav;
