import { useEffect, useState } from 'react';
import Table from '../../../Table';
import EditButton from '../../../buttons/EditButton';
import BasingActionFilters from '../../../BasingActionFilters';
import Anchor from '../../../Anchor';
import adminStyles from '../../Admin.module.scss';
import styles from './ActionEquity.module.scss';
import EditEquityModal from './EditEquityModal';
import axios from '../../../../utils/sharedAxios';
import SortHeader from '../../../Table/SortHeader';

const ActionEquity = () => {
  const [bars, setBars] = useState([]);
  const [filters, setFilters] = useState({});
  const [filteredBars, setFilteredBars] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingBar, setEditingBar] = useState(null);
  const [activeSortKey, setActiveSortKey] = useState(null);
  const [sortReversed, setSortReversed] = useState(false);

  const fetchEquityList = async () => {
    const { data: barsList } = await axios.get('/equity/list');
    setBars(barsList);
  }

  const openEditModal = (id) => () => {
    setEditingBar(bars.find(bar => bar.id === id));
    setShowEditModal(true);
  }

  const closeEditModal = () => {
    setShowEditModal(false);
    setEditingBar(null);
  }

  const handleEditEquity = async (values) => {
    const majcoms = values.equityInterest.map(item => item.value).join(',');
    await axios.get(`/equity/update/${editingBar.id}`, {
      params: {
        majcoms
      }
    });
    setShowEditModal(false);
    setBars(bars.map(bar => {
      if (bar.id === editingBar.id) {
        return ({
          ...bar,
          equityInterest: majcoms.split(',')
        });
      }

      return bar;
    }))
    setEditingBar(null);
  };

  const onClickSort = (key) => {
    if (activeSortKey === key) {
      setSortReversed(sortReversed => !sortReversed);
    } else {
      setActiveSortKey(key);
      setSortReversed(false);
    }
  }

  useEffect(() => {
    if (bars.length) {
      const filtered = bars.filter(bar => {
        if (filters.phase && bar.currentPhase !== filters.phase) {
          return false;
        }
        if (filters.step && bar.currentStep !== filters.step) {
          return false;
        }
        if (filters.status && bar.status !== filters.status) {
          return false;
        }

        if (filters.leadBasingOffice && bar.leadMajcom !== filters.leadBasingOffice) {
          return false;
        }

        if (filters.actionOfficer && bar.assignee !== filters.actionOfficer) {
          return false;
        }

        return true;
      });

      if (activeSortKey) {
        filtered.sort((a, b) => {
          if (a[activeSortKey] < b[activeSortKey]) {
            return -1;
          }

          if (a[activeSortKey] > b[activeSortKey]) {
            return 1;
          }
          return 0;
        });

        sortReversed && filtered.reverse();
      }

      setFilteredBars(filtered);
    }
  }, [filters, bars, activeSortKey, sortReversed]);

  useEffect(() => {
    fetchEquityList();
  }, []);

  return (
    <>
      <h3 className={adminStyles.subTitle}>Manage action visibility</h3>

      <p className={adminStyles.bodyText}>Actions are only visible to Basing Office AOs who are part of the action’s lead basing office, or have equity in the action as discussed below.</p>
      <p className={adminStyles.bodyText}><b>Location Equity:</b> Lead or tenant basing offices for a base that is part of the enterprise, a candidate, or a preferred / alternative location. This only applies to the current phase of the action; if a base drops off the list between phases, the basing office no longer has equity.</p>
      <p className={adminStyles.bodyText}><b>Equity Interest:</b> Basing offices that have been manually granted equity, via this page.</p>
      <BasingActionFilters onFilterChange={setFilters} />
      <Table>
        <thead>
          <tr>
            <th>Edit</th>
            <SortHeader activeSortKey={activeSortKey} sortKey='title' label='Title' onClickSort={onClickSort} sortReversed={sortReversed} />
            <SortHeader activeSortKey={activeSortKey} sortKey='leadMajcom' label='Lead' onClickSort={onClickSort} sortReversed={sortReversed} />
            <th>Location Equity</th>
            <th>Equity Interest</th>
          </tr>
        </thead>
        <tbody>
          {filteredBars.length > 0 ? filteredBars.map(bar => (
            <tr key={bar.id}>
              <td><EditButton onClick={openEditModal(bar.id)} /></td>
              <td><Anchor href={`/bars/detail/${bar.id}`} light>{bar.title}</Anchor></td>
              <td>{bar.leadMajcom}</td>
              <td className={styles.cellLocationEquity}>{bar.locationEquityList && bar.locationEquityList.join(', ')}</td>
              <td className={styles.cellEquityInterest}>{bar.equityInterest && bar.equityInterest.join(', ')}</td>
            </tr>
          )) : (
            <tr><td colSpan={5}>No Records Found.</td></tr>
          )}

        </tbody>
      </Table>

      {showEditModal && <EditEquityModal bar={editingBar} handleEditEquity={handleEditEquity} onClose={closeEditModal} />}
    </>

  )
};

export default ActionEquity;