import { useState } from 'react';
import Modal from '../../../Modal';
import { Form } from 'react-final-form';
import ModalFooter from '../../../Modal/ModalFooter';
import Button from '../../../buttons/Button';
import ErrorText from '../../../ErrorText';
import ReplaceModalForm from './ReplaceModalForm';
import axios from '../../../../utils/sharedAxios';

const ReplaceModal = ({ onClose, onSuccess, title, type }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      let key = null;
      let fd = new FormData();
      fd.append('file', values.file?.file);
      const upload = await axios.post('/documents/upload', fd);

      if(upload.status === 200 || upload.status === 204){
        key = upload.data.Key || upload.data.key; // AWS sending 'Key' for large files, 'Key' and 'key' for smaller files
      } else {
        throw new Error('File upload failed')
      }

      console.log(values)
      const response = await axios.post('/staticResources/replace', { fileName: values.file.originalFileName, type, uuid: key });

      onSuccess(type);
    }
    catch (err) {
      console.error(err);
    }
    finally {
      onClose();
    }
  };


  const validate = values => {
    const errors = {};

    if (!values.file) {
      errors.file = 'A file is required';
    }

    return errors;
  }
  return (
    <Modal title={title} onClose={onClose}>
      <Form onSubmit={onSubmit} validate={validate}>
        {({ handleSubmit, invalid }) => <ReplaceModalForm handleSubmit={handleSubmit} invalid={invalid} loading={loading} onClose={onClose} type={type} />}
      </Form>
    </Modal>
  )
}

export default ReplaceModal;