import React from 'react';
import Wysiwig from '../../Form/Wysiwyg';
import UploadField from '../../Form/UploadField';
import styles from './Backups.module.scss';
import formStyles from '../../Form/Form.module.scss';

const BackupSlideEdit = ({titleKey, deleteFile}) => {
  return (
    <div>
      <UploadField name={`backups.${titleKey}`} deleteFile={deleteFile}/>
      <div className={styles.header}><h3 className={formStyles.sectionHeading}>Description</h3></div>
      <Wysiwig name={`backups.${titleKey}.description`} />
    </div>
  );
}

export default BackupSlideEdit;
