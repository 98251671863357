import Anchor from '../Anchor';
import Envelope from '../svg/Envelope';
import styles from './Footer.module.scss';
import { sprintNumber } from '../../utils/config';

const emailRecipient = 'saber.support.workflow@us.af.mil';
const emailSubject = 'SABER - Report a Problem';
const href = encodeURI(`mailto:${emailRecipient}?subject=${emailSubject}`);

// the global footer for the application
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <p className={styles.saberText}>SABER | The Strategic Air&Space Basing Enterprise Repository <small>- {sprintNumber}</small></p>
        <p><Anchor className={styles.mailto} href={href} target='_blank'><Envelope />Need some help? Email {emailRecipient}</Anchor></p>
      </div>
    </footer>
  );
}

export default Footer;