import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from "./components/Routes";
import NavBar from './components/NavBar';
import { DictionaryProvider } from './components/Dictionary/DictionaryContext';
import './App.scss';
import TimeOutModal from './components/TimeOutModal';
import Footer from './components/Footer';
import { PDFProvider } from './components/Pdf/PDFContext';

const App = () => {
  return (
    <DictionaryProvider>
      <PDFProvider>
        <Router>
          <div id='app-root'>
            <NavBar />
            <Routes />
            <Footer />
            <TimeOutModal />
          </div>
        </Router>
      </PDFProvider>
    </DictionaryProvider>
  );
}

export default App;
