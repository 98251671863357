import { useEffect, useState } from 'react';
import { Field, Form } from "react-final-form"
import Button from "../../../buttons/Button"
import Card from "../../../Card";
import DisplayDate from '../../../DisplayDate';
import SelectField from "../../../Form/SelectField"
import EditButton from "../../../buttons/EditButton";
import styles from './NewAccountRequests.module.scss';
import useCachedData from "../../../hooks/useCachedData";
import useRoleOptions from '../../../hooks/useRoleOptions';

const RequestCard = ({ request, openEditModal, handleApproveRequest, openDenyRequestModal }) => {
  const roleOptions = useRoleOptions();
  const { data: groups } = useCachedData('/users/groups');
  const [groupOptions, setGroupOptions] = useState([]);

  const validate = (values) => {
    const errors = {};

    if (!values.roleId) {
      errors.roleId = 'Role is required';
    }

    if (!values.group) {
      errors.group = 'Group is required';
    }

    if (values.roleId?.value === 4 && !values.office) {
      errors.office = 'Office is required';
    }

    return errors;
  };

  useEffect(() => {
    groups && setGroupOptions(groups.map(group => ({ label: group.name, value: group.id })));
  }, [groups]);

  return (
    <Card details={<>Submitted on <DisplayDate date={request.createdAt} /></>}>
      <EditButton className={styles.cardEditButton} onClick={() => openEditModal(request.id)} />

      <h3>{request.userRank} {request.firstName} {request.lastName}</h3>
      <p>{request.niprEmail}</p>
      <p>{request.siprEmail}</p>
      <p>{request.commercialPhone}</p>
      <p>{request.dsnPhone}</p>
      <p>{request.securePhone}</p>
      <p>{request.reasonForApplying}</p>

      <Form onSubmit={handleApproveRequest} validate={validate} subscription={{ invalid: true, values: true }}>
        {({ handleSubmit, invalid, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              {/* <Label text='Role/Group' /> */}
              <SelectField className={styles.select} name='roleId' options={roleOptions} placeholderText='Role' isClearable={false} label='Role' required />
              {values.roleId?.value === 4 && <SelectField className={styles.select} name='office' fieldDataEndpoint='/content/leadBasingOffices' placeholderText='Office' label='Office' required />}
              <SelectField className={styles.select} name='group' options={groupOptions} placeholderText='Contact Group' label='Contact Group' required />

              <Field component='input' type='hidden' initialValue={request.id} name='userId' />

              <div className={styles.buttonRow}>
                <Button type="submit" primary disabled={invalid}>APPROVE</Button>
                <Button onClick={() => openDenyRequestModal(request.id)} secondary>DENY</Button>
              </div>
            </form>
          )
        }}
      </Form>
    </Card>
  );
}

export default RequestCard;