import { useContext, useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import moment from 'moment';
import useFields from '../hooks/useFields';
import { AuthContext } from '../Utility/Authentication';

/**
 * subscribes to fields,
 * and changes a date field and userId field if those fields
 * stray from their initial values
 */
const useUpdatedByField = (onName, byName, fields = []) => {
  const { user: { id }} = useContext(AuthContext);
  const fieldsState = useFields(fields);
  const date = useField(onName);
  const user = useField(byName);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (fieldsState) {
      const anyUpdated = Object.values(fieldsState).some(({ meta }) => meta.dirty);

      setIsUpdated(anyUpdated);
    }
  }, [fieldsState]);

  useEffect(() => {
    if (isUpdated) {
      const now = moment().format();
      date.input.onChange(now);
      user.input.onChange(id);
    } else {
      date.input.onChange(date.meta.initial);
      user.input.onChange(user.meta.initial);
    }
  }, [isUpdated])

  return isUpdated;
}

export default useUpdatedByField;