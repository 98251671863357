import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Admin from '../Admin';
import CreateBar from '../bars/CreateBar';
import NotFound from '../NotFound'
import Dashboard from '../Dashboard';
import BarDetail from '../bars/BarDetail'
import Contacts from '../Contacts';
import Register from '../Utility/Register';
import BasingActionLanding from '../BasingActionLanding';
import ReviewCenter from '../ReviewCenter';
import Resources from '../Resources';
import Reporting from '../Reporting';
import { AuthContext } from '../Utility/Authentication';
import { useContext, useEffect } from 'react';
import NotAllowed from '../NotAllowed';
import InvalidAction from '../InvalidAction';

// main hub for the application's routes
const Routes = () => {
    const {onLinkChange} = useContext(AuthContext);
    const trackPageChanges = () => {
        let location = useLocation();
        useEffect(() => {
            if(onLinkChange) {
                onLinkChange(location.pathname)
            }
        }, [location]);
    }
    trackPageChanges();
    return (
        <Switch>
            <Route path='/dashboard' exact component={Dashboard} />
            <Route path='/admin' exact component={Admin} />
            <Route path='/bars/create/:id' exact component={CreateBar} />
            <Route path='/bars/detail/:id' exact component={BarDetail} />
            <Route path='/bars/invalid-action' exact component={InvalidAction} />
            <Route path='/bars' component={BasingActionLanding} />
            <Route path='/contacts' exact component={Contacts} />
            <Route path='/review-center' component={ReviewCenter} />
            <Route path='/resources' component={Resources} />
            <Route path='/register' exact component={Register} />
            <Route path='/reporting' exact component={Reporting} />
            <Route path="/" exact>
                <Redirect to="/dashboard" />
            </Route>
            <Route path='/not-allowed' exact component={NotAllowed} />
            <Route component={NotFound} />
        </Switch>
    );
}
export default Routes;

