import { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { getIn } from 'final-form';
import hasValue from '../../utils/hasValue';
import useCachedData from './useCachedData';

// Create Bar validation, traffic lights, submit button, all rely on this hook to determine if sections are completed
const useCompletionStatus = (views, mappingEndpoint) => {
  const { data: completionMapping } = useCachedData(mappingEndpoint);
  const { initialValues } = useFormState({ subscription: { initialValues: true } });
  const [started, setStarted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [sections, setSections] = useState([]);

  const getIsFieldCompleted = (fieldName, some) => {
    if (typeof fieldName === 'string') {
      const value = getIn(initialValues, fieldName);

      return hasValue(value);
    }

    if (fieldName.arrayFieldName) {
      const { arrayFieldName, keys = [] } = fieldName;
      const array = getIn(initialValues, arrayFieldName);

      if (Array.isArray(array)) {
        if (some) {
          return array.some(item => keys.some(key => hasValue(item[key])));
        } else {
          return array.length > 0 && array.every(item => keys.every(key => hasValue(item[key])));
        }
      }

      return false;
    }

    // one-off completion check because summaryOfFindings is complex, and relies on dynamic values from another view
    if (fieldName.summaryOfFindings) {
      const { keys = [] } = fieldName;
      const summaryOfFindings = getIn(initialValues, 'summaryOfFindings');
      const siteSurveyRequirements = getIn(initialValues, 'siteSurveyRequirements');
      const recommendedCandidates = getIn(initialValues, 'recommendedCandidates');

      let siteSurveyLength = 0;

      if (siteSurveyRequirements) {
        Object.values(siteSurveyRequirements).forEach(arr => siteSurveyLength += arr.length);
      }

      if (summaryOfFindings && recommendedCandidates?.length && siteSurveyLength) {
        const values = Object.values(summaryOfFindings);
        const requiredLength = siteSurveyLength * recommendedCandidates.length;

        if (some) {
          return values.some(value => keys.some(key => hasValue(value[key])));
        } else {
          return values.length >= requiredLength && values.every(value => keys.every(key => hasValue(value[key])));
        }
      }

      return false;
    }

    // for objects with dynamic keys that produce arrays of values
    // ex: { dynamicObjectKeyArrays: 'siteSurveyRequirements', keys: ['optimalValue', 'suitableValue', 'limitsValue'] }
    //     siteSurveyRequirements[dynamic_key][0].optimalValue, siteSurveyRequirements[dynamic_key][0].suitableValue, etc
    if (fieldName.dynamicObjectKeyArrays) {
      const { dynamicObjectKeyArrays, keys = [] } = fieldName;
      const obj = getIn(initialValues, dynamicObjectKeyArrays);

      if (obj && obj.constructor === Object) {
        const values = Object.values(obj);

        if (some) {
          return values.some(valueArray => valueArray.some(value => keys.some(key => hasValue(value[key]))));
        } else {
          return values.every(valueArray => {
            return valueArray.every(value => {
              if (value.subRequirements) { // Mission items can have subRequirements
                return value.subRequirements.every(subValue => keys.every(key => hasValue(subValue[key])));
              } else {
                return keys.every(key => hasValue(value[key]));
              }
            });
          });
        }
      }

      return false;
    }
  }

  const getSectionCompletion = (section) => {
    const { required = [], optional = [] } = section;
    const isStarted = (!required.length && !optional.length) || (required.some((fieldName) => getIsFieldCompleted(fieldName, true)) || optional.some((fieldName) => getIsFieldCompleted(fieldName, true)));
    const isCompleted = isStarted && required.every((fieldName) => getIsFieldCompleted(fieldName));

    return { isStarted, isCompleted };
  };

  useEffect(() => {
    if (completionMapping) {
      if (!Array.isArray(views)) {
        setSections([completionMapping[views]]);
      } else {
        setSections(views.map(view => completionMapping[view]));
      }
    }
  }, [views, completionMapping]);

  useEffect(() => {
    if (sections.length) {
      let numStarted = 0;
      let numCompleted = 0;

      sections.forEach(section => {
        const { isStarted, isCompleted } = getSectionCompletion(section);
        isStarted && numStarted++;
        isCompleted && numCompleted++;
      });

      setStarted(numStarted > 0);
      setCompleted(numCompleted === sections.length);
    }

  }, [initialValues, sections]);

  return { started, completed };
}

export default useCompletionStatus;