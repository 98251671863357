import React, { useEffect, useState } from 'react';
import styles from './ReviewCenter.module.scss';
import cn from 'classnames';
import useCachedData from '../hooks/useCachedData';
import pluralize from '../../utils/pluralize';
import Tooltip from '../Tooltip';

const StepNav = ({
  changeSelectedStep,
  reviews,
  selectedStep,
}) => {
  const { data: stepData } = useCachedData('/content/stepOptions');
  const [visibleSteps, setVisibleSteps] = useState([]);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (stepData && !steps.length) {
      setSteps(stepData.options);
    }
  }, [stepData]);

  // build visible steps
  useEffect(() => {
    let displayedSteps = [];

    if (reviews && steps.length) {
      displayedSteps = steps.map(({ label, value }, index) => {
        const stepNumber = index + 1;
        const stepReviews = reviews[stepNumber];
        const hasDeterminations = !!(stepReviews.past.length);
        const enabled = hasDeterminations || !!(stepReviews.future.length);

        return ({
          active: selectedStep === stepNumber,
          enabled,
          hasDeterminations,
          numFutureReviews: stepReviews.future.length,
          stepNumber,
          label,
          value,
        });
      });
    }

    setVisibleSteps(displayedSteps);

  }, [reviews, selectedStep, steps]);

  const handleClickStep = (stepNumber) => () => {
    changeSelectedStep(stepNumber);
  };

  return (
    <nav className={styles.stepNav}>
      <div className={styles.steps} role='tablist'>
        {visibleSteps.map(({ active, enabled, hasDeterminations, numFutureReviews, stepNumber, label, value  }) => {
          const stepClassName = cn(styles.step, {
            [styles.active]: active,
            [styles.enabled]: enabled
          });

          return (
            <button type='button' key={value} role='tab' className={stepClassName} onClick={enabled ? handleClickStep(stepNumber) : null} tabIndex={0}>
              <p className={styles.stepTitle}>{label}{numFutureReviews > 0 && (<span className={styles.noWrap}>{` (${numFutureReviews} ${pluralize(numFutureReviews, 'Review', 'Reviews')})`}</span>)}</p>
              {numFutureReviews === 0 && <p className={styles.stepSubTitle}>No Reviews Scheduled</p>}
              {hasDeterminations && <Tooltip content='There are actions that need determinations' className={styles.hasDeterminations} />}
            </button>
          );
        })}
      </div>
    </nav>
  );
}

export default StepNav;