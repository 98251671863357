import React from 'react';
import { useFormState } from 'react-final-form';
import DocumentList from '../../DocumentList';
import Anchor from '../../Anchor';

const AttachmentsDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });
  const { attachments, environmentalDocumentHref, environmentalDocumentType } = initialValues;
  const docs = attachments || [];
  const phoneBook = docs ? docs.filter((d) => d.type === 'phoneBook') : [];
  const siteSurveyAuthLetter = docs ? docs.filter((d) => d.type === 'siteSurveyAuthLetter') : [];
  const siteSurveyReport = docs ? docs.filter((d) => d.type === 'siteSurveyReport') : [];
  const congressionalNotificationPlan = docs ? docs.filter((d) => d.type === 'congressionalNotificationPlan') : [];
  const publicAffairsGuidance = docs ? docs.filter((d) => d.type === 'publicAffairsGuidance') : [];
  const environmental = docs ? docs.filter((d) => d.type === 'environmental') : [];
  const bdm = docs ? docs.filter((d) => d.type === 'bdm') : [];
  const additionalAttachments = docs ? docs.filter((d) => d.type === 'additionalAttachments') : [];

  if (environmentalDocumentHref) {
    environmental.push({
      originalFileName: <Anchor href={environmentalDocumentHref} light target='_blank'>{environmentalDocumentHref}</Anchor>
    });
  }
  return (
    <>
      <DocumentList docList={phoneBook} title='Phone Book' />
      <DocumentList docList={siteSurveyAuthLetter} title='Site Survey Authorization Letter' />

      <DocumentList docList={siteSurveyReport} title='Site Survey Report' />

      <DocumentList docList={congressionalNotificationPlan} title='Congressional Notification Plan' />

      <DocumentList docList={publicAffairsGuidance} title='Public Affairs Guidance' />

      <DocumentList title={environmentalDocumentType || 'Environmental Documents'} docList={environmental} />

      <DocumentList docList={bdm} title='BDM' />

      <DocumentList docList={additionalAttachments} title='Additional Attachments' />
    </>
  )
}

export default AttachmentsDisplay;
