import React from 'react';
import { useFormState } from 'react-final-form';
import Label from '../../Form/Label';
import Box from '../../Box';
import Grid from '../../Grid';
import DocumentList from '../../DocumentList';
import styles from './TwoLetterCoord.module.scss';
import dateFormats from '../../../constants/dateFormats';
import DisplayDate from '../../DisplayDate';
const TwoLetterCoordDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });
  const {twoLetterCoord} = initialValues;
  const endorsements = twoLetterCoord?.endorsements || [];
  const docs = twoLetterCoord?.docs || [];
  const esss = docs.filter((d) => d.type === 'esss');
  const mfr = docs.filter((d) => d.type === 'mfr');
  const briefing = docs.filter((d) => d.type === 'briefing');
  const cnp = docs.filter((d) => d.type === 'cnppag');
  const env = docs.filter((d) => d.type === 'environmental');
  const additional = docs.filter((d) => d.type === 'additional');
  const adjCoordPackage = docs.filter((d) => d.type === 'adjCoordPackage');

  const templateColumns = [5, '2fr 2.5fr 2.5fr 3fr 3fr'];
  return (
    <>
      <div className={styles.topGrid}>
        <Grid columns={[2, '3fr 1fr']}>
          <Box><Label text='Review Notes' /></Box>
          <Box><Label text='Review Date' /></Box>
          {twoLetterCoord && twoLetterCoord.lastReviewNotes ? <div>{twoLetterCoord.lastReviewNotes}</div> : <div></div>}
          {twoLetterCoord && twoLetterCoord.lastReviewDate && <div>{<DisplayDate date={twoLetterCoord.lastReviewDate} format={dateFormats.short}/>}</div>}
        </Grid>
      </div>

      <Grid columns={templateColumns}>
          <Box>
            <Label text='Sent To' />
          </Box>
          <Box>
            <Label text='Sent On' />
          </Box>
          <Box>
            <Label text='Endorsed On' />
          </Box>
          <Box>
            <Label text='Endorsed By' />
          </Box>
          <Box>
            <Label text='Notes' />
          </Box>
        </Grid>
      {endorsements.map((endorsement, index) => (
        <Grid columns={templateColumns} key={index}>
          <Box>{endorsement.sentTo}</Box>
          <Box>{endorsement.sentOn && <DisplayDate date={endorsement.sentOn} format={dateFormats.short}/>}</Box>
          <Box>{endorsement.endorsedOn && <DisplayDate date={endorsement.endorsedOn}format={dateFormats.short}/>}</Box>
          <Box>{endorsement.endorsedBy}</Box>
          <Box>{endorsement.notes}</Box>
        </Grid>
      ))}
      <div className={styles.bigSpacer}></div>
      <DocumentList docList={esss} title='ESSS' />
      <DocumentList docList={mfr} title='MFR' />
      <DocumentList docList={briefing} title='Briefing' />
      <DocumentList docList={cnp} title='CNP/PAG' />
      <DocumentList docList={env} title='Environmental Documents' />
      <DocumentList docList={additional} title='Additional Documents' />
      <hr />
      <div className={styles.smallSpacer}><DocumentList docList={adjCoordPackage} title='Adjudicated Coordination Package' /></div>
    </>
  )
}

export default TwoLetterCoordDisplay;