export default {
  1: {
    short: 'BC',
    long: 'Criteria and Enterprise Definition',
    key: 'criteria'
  },
  2: {
    short: 'CL',
    long: 'Candidates',
    key: 'candidates'
  },
  3: {
    short: 'PL',
    long: 'Preferred Location',
    key: 'preferredLocation'
  },
  4: {
    short: 'FD',
    long: 'Final Decision',
    key: 'finalDecision'
  }
};