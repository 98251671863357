import React, { useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import Grid from '../../Grid';
import { Box } from 'theme-ui';
import SelectField from '../../Form/SelectField';
import DependentField from '../../Form/DependentField';

const ProponentFields = () => {
  const { input: { value } } = useField('proponent');
  const proponentSecondaryField = useField('proponentSecondaryField');
  const [secondaryField, setSecondaryField] = useState(null);
  const [secondaryFieldString, setSecondaryFieldString] = useState(null);

  // This stringified comparison prevents clearing an initialValue when the component has just loaded.
  // Also ensures that we clear the value when the parent field causes the secondary field's options to change.
  useEffect(() => {
    const newSecondaryFieldString = secondaryField ? JSON.stringify(secondaryField) : null;
    setSecondaryFieldString(newSecondaryFieldString);
    setSecondaryField(value.secondaryField || null);

    if (secondaryFieldString && secondaryFieldString !== newSecondaryFieldString) {
      proponentSecondaryField.input.onChange('');
    }
  }, [secondaryFieldString, secondaryField, value])

  return (
    <Grid columns={[2]}>
      <Box>
          <SelectField label='Proponent' name='proponent' fieldDataEndpoint='/content/proponents' required />
      </Box>
      <Box>
        <DependentField fieldObject={secondaryField} name='proponentSecondaryField' required />
      </Box>
    </Grid>
  );
}

export default ProponentFields;
