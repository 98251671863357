import { useEffect, useState } from 'react';
import cn from 'classnames';
import DisplayDate from '../DisplayDate';
import Modal from '../Modal';
import axios from '../../utils/sharedAxios';
import dateFormats from '../../constants/dateFormats';
import styles from './Dashboard.module.scss'
import Anchor from '../Anchor';

const AllDrafts = ({ office = '' }) => {
  const [bars, setBars] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchBars = async () => {
      const { data } = await axios.get('/bars/all_with_title?status=draft');

      if (data) {
        // sorted by date descending
        const sortedData = data.sort((a, b) => {
          if (a.updatedAt < b.updatedAt) {
            return 1;
          }
          if (a.updatedAt > b.updatedAt) {
            return -1;
          }

          return 0;
        });

        setBars(sortedData);
      }
    }

    fetchBars();
  }, []);

  return (
    <>
      <div className={cn(styles.chip, { [styles.active]: bars.length > 0 })} onClick={bars.length > 0 ? () => setShowModal(true) : null}>
        <h6 className={styles.chipHeading}>{office || 'ALL'} DRAFTS</h6>
        <p className={styles.chipValue}>{bars.length || 0}</p>
      </div>

      {showModal && <Modal className={styles.allDraftsModal} onClose={() => setShowModal(false)} title='All Drafts'>
        {bars.map(bar => (
          <div className={styles.draftItem} key={bar.id}>
            <Anchor className={styles.draftItemLink} href={`/bars/create/${bar.id}`} light>{bar.title || 'Untitled Basing Action'}</Anchor>
            <span>Saved <DisplayDate date={bar.updatedAt} format={dateFormats.short} /></span>
          </div>
        ))}
      </Modal>}
    </>
  );
}

export default AllDrafts;