import React from 'react';
import cn from 'classnames';
import Button from '../buttons/Button';
import styles from './FormViewWrapper.module.scss';
import { useFormState } from 'react-final-form';

// Applies edit/save/cancel options to a form
const FormViewWrapper = ({
  activeView,
  children,
  handleCancel,
  handleEdit,
  isEditing,
  canEdit = true,
  title,
}) => {
  const { submitting } = useFormState({ subscription: { submitting: true }})

  return (
    <section className={styles.formViewWrapper}>
      <div className={styles.heading}>
        <h1 className={styles.title}>{title}</h1>
        {!isEditing && canEdit && <Button type="button" onClick={handleEdit} secondary>Edit</Button>}
      </div>

      <div className={cn(styles.content, { [styles.editing]: isEditing, [styles.preview]: !isEditing })}>
        {activeView && activeView()}
        {isEditing && (
          <div className={styles.buttonRow}>
            <Button type="submit" primary disabled={submitting}>SAVE</Button>
            <Button onClick={handleCancel} secondary disabled={submitting}>CANCEL</Button>
          </div>
        )}
        {children}
      </div>
    </section>
  );
}

export default FormViewWrapper;
