import React from 'react';
import Table from '../../Table';
import styles from './WeaponsSystemDisplay.module.scss';

const WeaponsSystemDisplay = ({ initialValues }) => {
  return (
    <>
      {initialValues.currentWeaponsSystems && (
        <Table className={styles.table}>
          <thead>
            <tr>
              <th>Current Weapons Systems</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {initialValues.currentWeaponsSystems.map((item, index) => (
              <tr key={index}>
                <td>{item.weaponSystem}</td>
                <td>{item.units}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {initialValues.proposedWeaponsSystems && (
        <Table className={styles.table}>
          <thead>
            <tr>
              <th>Proposed Weapons Systems</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {initialValues.proposedWeaponsSystems.map((item, index) => (
              <tr key={index}>
                <td>{item.weaponSystem}</td>
                <td>{item.units}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
  </>
  );
}

export default WeaponsSystemDisplay;
