import { useState } from 'react'
import cn from 'classnames';
import Plus from '../svg/Plus';
import Minus from '../svg/Minus';
import styles from './Collapsible.module.scss';

// a toggled hidden content container using a button has a heading
const Collapsible = ({ children, className, defaultOpen = false, toggle }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className={cn(styles.collapsible, className, { [styles.open]: isOpen, [styles.closed]: !isOpen })}>
      {toggle && (
        <button className={styles.toggle} onClick={() => setIsOpen(!isOpen)} tabIndex={0} type='button'>
          <div className={styles.toggleIcon}>{isOpen ? <Minus className={styles.icon} />: <Plus className={styles.icon}/>}</div>
          <div className={styles.toggleContent}>{toggle}</div>
        </button>
      )}
      <div className={styles.children}>{children}</div>
    </div>
  )
}

export default Collapsible;