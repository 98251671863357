export default {
  SAM: {
    label: 'SAF AO'
  },
  LEE: {
    label: 'Leadership'
  },
  ERIN: {
    label: 'Basing Office AO'
  },
  KRIS: {
    label: 'Cross Functional'
  }
};