import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import DisplayUser from '../DisplayUser';
import DisplayDate from '../DisplayDate';
import Anchor from '../Anchor';
import Grid from '../Grid';
import Box from '../Box';
import dateFormats from '../../constants/dateFormats';
import SelectField from '../Form/SelectField';
import Button from '../buttons/Button';
import axios from '../../utils/sharedAxios';
import pluralize from '../../utils/pluralize';
import styles from './ReviewCenterContent.module.scss';
import DisplayBarLink from '../DisplayBarLink';
import phaseAbbreviations from '../../constants/phaseAbbreviations';
import useCachedData from '../hooks/useCachedData';
import { useHistory } from 'react-router';
import {usePdf} from '../Pdf/PDFContext'
import Tooltip from '../Tooltip';
import { CircularProgress } from '@material-ui/core';

// Main content area for the Review Center page
const ReviewCenterContent = ({ changeDate, deleteReview, addBasingAction, onDeterminationSuccess, review = null, reviewIsDetermination, removeBar, goToGenerateMaterials, generatePdf, isLoading }) => {
  const [reviewData, setReviewData] = useState({});
  const { data: generatedMaterials } = useCachedData(reviewData?.docId ? '/documents/document' : '', { params: { docId: reviewData.docId } })
  const [isPublishing, setIsPublishing] = useState(false);
  const {isGeneratingReviewPdf } = usePdf()

  const onSubmit = async (values) => {
    const promises = Object.entries(values.bars)
      .filter(([_, val]) => val.phase && val.step)
      .map(([key, val]) => {
        const [id] = key.split('~');
        const phase = val.phase.value;
        const step = val.step.value;

        return axios.post('/reviews/determination', {
          reviewPackageId: review.reviewId,
          barId: parseInt(id, 10),
          nextPhase: phase,
          nextStep: step
        });
      });

    await Promise.all(promises);
    await onDeterminationSuccess(review.reviewId);
  }

  const validate = (values) => {
    const errors = {};

    if (!values.bars || Object.values(values.bars).every(({ phase, step }) => !(phase?.value && step?.value))) {
      errors._error = 'Must complete fields for at least one BAR';
    }

    return errors;
  }

  const findReviewData = async () => {
    const { data } = await axios.get(`/reviews/find/${review.reviewId}`);
    setReviewData(data);
  }
  const publish = async () => {
    setIsPublishing(true);
    await axios.get(`reviews/publish/${review.reviewId}`)
      .then(async () => {
        await findReviewData();
        setIsPublishing(false);
      });
  }

  const unpublish = async () => {
    setIsPublishing(true);
    await axios.get(`reviews/un-publish/${review.reviewId}`)
      .then(async () => {
        await findReviewData();
        setIsPublishing(false);
      });
  }

  useEffect(() => {
    review && review.reviewId && findReviewData();
  }, [review]);

  if (isLoading) {
    return null;
  }
  if (review) {
    const { bars = [], date, reviewId } = review;
    const disableActions = isPublishing || isGeneratingReviewPdf[reviewId];
    return (
      <>
        {reviewIsDetermination ? (
          bars.length > 0 ? (
            <Form onSubmit={onSubmit} subscription={{ invalid: true, submitting: true }} validate={validate} destroyOnUnregister>
              {({ form, handleSubmit }) => {
                const { invalid } = form.getState();
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid columns={[3, '2fr 1fr 1fr']}>
                      <Box><h4 className={styles.determinationLabel}>Basing Actions</h4></Box>
                      <Box><h4 className={styles.determinationLabel}>Next Phase</h4></Box>
                      <Box><h4 className={styles.determinationLabel}>Next Step</h4></Box>
                    </Grid>

                    {bars.map(bar => (
                      <Grid className={styles.determinationBar} key={bar.barId} columns={[3, '2fr 1fr 1fr']}>
                        <Box>{phaseAbbreviations[bar.currentPhase].short} - <DisplayBarLink barId={bar.barId}>{bar.title}</DisplayBarLink></Box>
                        <Box><SelectField className={styles.determinationSelectFields} name={`bars[${bar.barId}~][phase]`} fieldDataEndpoint='/content/phaseOptions' placeholderText='Select Phase' /></Box>
                        <Box><SelectField className={styles.determinationSelectFields} name={`bars[${bar.barId}~][step]`} fieldDataEndpoint='/content/stepOptions' placeholderText='Select Step' /></Box>
                      </Grid>
                    ))}
                    <div>
                      <Button type="submit" primary disabled={invalid}>SUBMIT</Button>
                    </div>
                  </form>
                )
              }}
            </Form>
          ) : (
           <>
            <div className={styles.titleRow}>
              <h2 className={styles.title}>
                <DisplayDate date={date} format={dateFormats.short}/>
              </h2>
            </div>
            <p>This scheduled review has expired without any basing actions applied.</p>
            <Button onClick={() => deleteReview(review.reviewId)} primary>Dismiss</Button>
           </>
          )
        ) : (
          <>
            <div className={styles.titleRow}>
              <h2 className={styles.title}>
                <DisplayDate date={date} format={dateFormats.short}/>
                {`: ${bars.length} ${pluralize(bars.length, 'Action', 'Actions')}`}
              </h2>
              <div className={styles.titleButtons}>
                <Anchor onClick={changeDate}>Change Date</Anchor>
                {!bars.length && <Anchor onClick={() => deleteReview(review.reviewId)}>Delete</Anchor>}
              </div>
            </div>

            <ul className={styles.bars}>
              {bars.map(bar => (
                <li key={bar.reviewPackageBarId} className={styles.bar}>
                  <DisplayBarLink className={styles.barTitle} barId={bar.barId}>{bar.title}</DisplayBarLink>

                  <div className={styles.titleButtons}>
                    <Anchor className={styles.barRemove} onClick={() => removeBar(review.reviewId, bar.reviewPackageBarId)}>Remove</Anchor>
                  </div>
                </li>
              ))}
            </ul>


            <Button primary onClick={addBasingAction}>Add a Basing Action</Button>

            <div className={styles.readAhead}>
              <h3 className={styles.readAheadTitle}>Read-Ahead Materials</h3>
              {generatedMaterials ? (
                <div className={styles.generateMaterials}>
                  <div>
                    <Anchor className={styles.barTitle} onClick={goToGenerateMaterials} light>{generatedMaterials.originalFileName ? generatedMaterials.originalFileName.replace(/(\.pdf)$/, '') : ''}</Anchor>
                    <p>Generated
                      {generatedMaterials.uploadedBy ? (<> by <DisplayUser userId={generatedMaterials.uploadedBy} /></>) : ''}
                      {generatedMaterials.createdAt ? (<> on <DisplayDate date={generatedMaterials.createdAt} format={dateFormats.short} /></>) : null}
                      {review.isOutOfDate ? (<> - <span className={styles.outOfDate}>Out of date!</span></>) : null}
                    </p>

                  </div>
                  <div className={styles.titleButtonContainer}>
                  <div className={styles.titleButtons}>
                    <Anchor href={`${process.env.REACT_APP_API_URL}/documents/download?${new URLSearchParams({docId: generatedMaterials.uuid, fileName: generatedMaterials.originalFileName}).toString()}`} disabled={disableActions} download>Download</Anchor>
                    {reviewData.isPublished ? (
                      <Anchor onClick={unpublish} disabled={disableActions}>Unpublish</Anchor>
                    ) : (
                      <Anchor onClick={publish} disabled={disableActions}>Publish</Anchor>
                    )}
                    {bars.length > 0 &&
                      <>
                        <Anchor onClick={() => generatePdf(review)} disabled={disableActions}>Regenerate
                        </Anchor>

                      </>
                    }
                  </div>
                  {disableActions &&
                    <span className={styles.spinner}>
                      <Tooltip content='Your PDF is being generated'>
                        <CircularProgress style={{ height: 15, width: 15 }} />
                      </Tooltip>
                    </span>
                  }
                  </div>


                </div>
              ) : (
                <div className={styles.titleButtonContainer}>
                  <Anchor className={styles.generateMaterials} onClick={goToGenerateMaterials}>Generate Materials</Anchor>
                  {disableActions &&
                    <Tooltip content='Your PDF is being generated'>
                      <CircularProgress style={{ height: 15, width: 15, marginLeft: 10 }} />
                    </Tooltip>
                  }
                </div>
              )}
            </div>

          </>
        )}
      </>
    );
  }
  return (
    <h2>There are no scheduled reviews!</h2>
  );
}

export default ReviewCenterContent;