import AuthorizedContent from '../AuthorizedContent';
import styles from './Dashboard.module.scss';
import NewAccounts from './NewAccounts';
import NewActions from './NewActions';

const RequestsPanel = () => (
  <AuthorizedContent authorizedRoles='SAM'>
    <div className={styles.panel}>
      <h5 className={styles.panelHeading}>REQUESTS</h5>
      
        <div className={styles.chipRows}>
          <NewAccounts />
          <NewActions />
        </div>        
    </div>
  </AuthorizedContent>
);

export default RequestsPanel;
