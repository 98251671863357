import has from 'lodash/has';

// stripValuesFromObject is used to convert options objects ({ value: x, label: 'x' }) into flat values (x)
const stripValueFromObject = (obj) => {
  if (obj && has(obj, 'value')) {
    return obj.value;
  }

  return obj;
}

const stripValuesFromObject = (data) => {
  Object.entries(data)
  .reduce((acc, [key, value]) => {
    value = stripValueFromObject(value);
    acc[key] = value;

    return acc;
  }, data);

  return data;
};

export default stripValuesFromObject;