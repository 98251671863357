import React from 'react';
import moment from 'moment';
import Header from '../Header';
import Button from '../buttons/Button';
import StepNav from './StepNav';
import dateFormats from '../../constants/dateFormats';
import styles from './ReviewCenterHeader.module.scss';
import pluralize from '../../utils/pluralize';

const ReviewCenterHeader = ({
  changeSelectedStep,
  nextReviewDate,
  reviews,
  scheduleReview,
  selectedStep,
  totalActions,
}) => {
  return (
    <Header
      leftContent={
        <>
          <h1 className={styles.title}>Review Center</h1>
          <p className={styles.subTitle}>{nextReviewDate && (`${totalActions} ${pluralize(totalActions, 'Action', 'Actions')} Currently Scheduled, Next Review ${moment(nextReviewDate).format(dateFormats.short)}`)}</p>
        </>
      }
      rightContent={
        <Button onClick={scheduleReview} primaryAlt>Schedule a Review</Button>
      }>
        <StepNav changeSelectedStep={(n) => changeSelectedStep(n)} reviews={reviews} selectedStep={selectedStep} />
    </Header>
  )
}

export default ReviewCenterHeader;