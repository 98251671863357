import TopMatrixCell from './TopMatrixCell';
import styles from './BarLanding.module.scss';
import useCachedData from '../hooks/useCachedData';

const percentileClasses = {
    1: styles.percentileOne,
    2: styles.percentileTwo,
    3: styles.percentileThree,
    4: styles.percentileFour,
    5: styles.percentileFive,
    6: styles.percentileSix,
    7: styles.percentileSeven,
    8: styles.percentileEight,
    9: styles.percentileNine,
    10: styles.percentileTen,
};

const TopMatrix = ({ barTable, barSpread, changeFilter, filters, resetFilters }) => {
    const { data: steps } = useCachedData('/content/steps');
    const { data: phases } = useCachedData('/content/phases');
    const stepsArr = Object.values(steps || {});
    const phaseArr = Object.values(phases || {});


    const getBarSpreadClasses = (count) => {
        if (barSpread.length > 0) {
            const sortedSpread = barSpread.sort().filter(n => n > 0);
            const p = (100 * sortedSpread.reduce((acc, v) => acc + (v < count ? 1 : 0) + (v === count ? 0.5 : 0), 0)) / sortedSpread.length;
            const key = Math.ceil(p/10);

            if (key > 0) {
                return percentileClasses[key];
            }
        }
        return null;
    }
    const setPhaseAndStepFilter = (phase, step) => {
        resetFilters();
        changeFilter('phase', phase);
        changeFilter('step', step);
        changeFilter('status', 'active');
    }

    const numberOfBarsInThisState = (phase, step) =>  barTable[phase] && barTable[phase][step];

    const chevronStart = () => (
        <svg className={styles.topChevronStart} xmlns="http://www.w3.org/2000/svg" width="10" height="48" viewBox="0 0 10 48">
            <path d="M0,0H10V48H0L10,24Z" />
        </svg>
    );

    const chevronEnd = () => (
        <svg className={styles.topChevronEnd} xmlns="http://www.w3.org/2000/svg" width="10" height="48" viewBox="0 0 10 48">
            <path d="M0,0L10,24L0,48Z"/>
        </svg>
    );
    const Steps = () => {
        return <div className={styles.row}>
                <div className={styles.topStepCell}>{chevronEnd()}</div>
                {stepsArr.map((step, index) => {
                    const stepIndex = index+1;
                    return (
                        <div key={index} className={styles.topStepCell} onClick={() => setPhaseAndStepFilter(null, stepIndex)}>
                            {chevronStart()}
                            <span className={styles.phaseCellTitle}>{step}</span>
                            {index !== stepsArr.length -1 && chevronEnd()}
                        </div>
                    );
                })}
        </div>

    }


    const Matrix = () => {
        return phaseArr.map((phase, i) => {
            const phaseIndex = i+1;

            return (
                <div key={phase} className={styles.row}>
                    <div className={styles.phaseCell} onClick={() => setPhaseAndStepFilter(phaseIndex, null)}>
                        <div className={styles.phaseCellText}>{phase}</div>
                    </div>

                    {stepsArr.map((step, j) => {
                        const stepIndex = j+1;
                        const total = numberOfBarsInThisState(phaseIndex, stepIndex);
                        const phaseSelected = filters.phase === phaseIndex;
                        const stepSelected = filters.step === stepIndex;
                        const isSelected = (phaseSelected && !filters.step) || (stepSelected && !filters.phase) || (phaseSelected && stepSelected);
                        const otherSelected = !isSelected && (filters.phase || filters.step);

                        return (
                            <TopMatrixCell
                                key={step}
                                barTable={barTable}
                                className={getBarSpreadClasses(total)}
                                isSelected={isSelected}
                                otherSelected={otherSelected}
                                phase={phase}
                                step={step}
                                onClick={() => setPhaseAndStepFilter(phaseIndex, stepIndex)}
                                total={total}
                            />
                        )
                    })}
                </div>
            )
        });
    }

    return (
        <div className={styles.topMatrix}>
            <Steps/>
            <Matrix/>
        </div>
    );
}

export default TopMatrix;
