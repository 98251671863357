import React from 'react';
import Wysiwig from '../../Form/Wysiwyg';
import styles from './StrategyImplications.module.scss';
import formStyles from '../../Form/Form.module.scss';

const StrategyEdit = () => {
  // This view will change depending on phase/state
  return (
    <div>
      <h3 className={formStyles.sectionHeading}>Strategic Posture</h3>
      <Wysiwig className={styles.wysiwyg} name='strategicPosture' />
      <h3 className={formStyles.sectionHeading}>Programming Implications</h3>
      <Wysiwig className={styles.wysiwyg} name='programmingImplications' />
      
    </div>
  );
}

export default StrategyEdit;