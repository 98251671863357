import React from 'react';
import { useFormState } from 'react-final-form';
import cn from 'classnames';
import DisplayDate from '../../DisplayDate';
import DisplayUser from '../../DisplayUser';
import Table from '../../Table';
import styles from './CongressionalDates.module.scss';
import formStyles from '../../Form/Form.module.scss';
import Collapsible from '../../Collapsible';
import dateFormats from '../../../constants/dateFormats';

const empty = '--';

const CongressionalDatesDisplay = () => {
  const { initialValues: { congressionalDates = {} } = {} } = useFormState({ subscription: { initialValues: true } });

  const renderCongressionalDateGroup = ({ plannedDate, plannedDateUpdatedOn, plannedDateUpdatedBy, actualDate, actualDateUpdatedOn, actualDateUpdatedBy, note, phaseLabel }, phaseTitle) => (
    <Collapsible key={phaseLabel} className={styles.dateGroup} toggle={<h3 className={cn(styles.dateGroupToggle, formStyles.sectionHeading)}>{phaseTitle}</h3>}>
      <div className={styles.dateGroupDisplay}>
        <Table className={styles.datesTable}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Planned/Actual</th>
              <th>Updated</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{plannedDate ? <DisplayDate date={plannedDate} format={dateFormats.short} /> : empty}</td>
              <td>Planned</td>
              <td>{plannedDateUpdatedOn ? (<><DisplayDate date={plannedDateUpdatedOn} format={dateFormats.short}/> by <DisplayUser userId={plannedDateUpdatedBy} /></>) : empty}</td>
            </tr>
            <tr>
              <td>{actualDate ? <DisplayDate date={actualDate} format={dateFormats.short} /> : empty}</td>
              <td>Actual</td>
              <td>{actualDateUpdatedOn ? (<><DisplayDate date={actualDateUpdatedOn} format={dateFormats.short}/> by <DisplayUser userId={actualDateUpdatedBy} /></>) : empty}</td>
            </tr>
          </tbody>
        </Table>
        <div className={styles.notesGroup}>
          <p className={styles.notesLabel}>Notes</p>
          <p className={styles.notesDisplay}>{note || empty}</p>
        </div>
      </div>
    </Collapsible>
  );

  return (
    <>
      {renderCongressionalDateGroup(congressionalDates.criteria|| {}, 'Criteria and Enterprise Definition')}
      {renderCongressionalDateGroup(congressionalDates.candidates|| {}, 'Candidates')}
      {renderCongressionalDateGroup(congressionalDates.preferredLocation|| {}, 'Preferred Location')}
      {renderCongressionalDateGroup(congressionalDates.finalDecision|| {}, 'Final Decision')}
    </>
  );
}

export default CongressionalDatesDisplay;
