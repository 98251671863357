import React from 'react';
import { FormSpy } from 'react-final-form';
import RichTextDisplay from '../../Form/RichTextDisplay';
import styles from '../../Form/Form.module.scss';

const BackgroundDisplay = () => {
  return (
    <FormSpy subscription={{ initialValues: true }}>
      {({ initialValues }) => (
        <>
          {initialValues.overView && (
            <>
              <h3 className={styles.sectionHeading}>Overview</h3>
              <RichTextDisplay html={initialValues.overView} />
            </>
          )}
          {initialValues.significantViewOfOthers && (
            <>
              <h3 className={styles.sectionHeading}>Significant Views of Others</h3>
              <RichTextDisplay html={initialValues.significantViewOfOthers} />
            </>
          )}
          {initialValues.fundingAndManpowerStatus && (
            <>
              <h3 className={styles.sectionHeading}>Status of Funding and Manpower</h3>
              <RichTextDisplay html={initialValues.fundingAndManpowerStatus} />
            </>
          )}
        </>
      )}
    </FormSpy>
  )
}

export default BackgroundDisplay;
