import React from 'react';
import NavListHeading from '../SideNav/NavListHeading';
import NavList from '../SideNav/NavList';
import NavListItem from '../SideNav/NavListItem';
import CompletionIcon from '../SideNav/CompletionIcon';
import { titleMap } from './genMaterialsViews';
import useCachedData from '../hooks/useCachedData';

const GenerateMaterialsSideNav = ({ activeViewKey, setActiveViewKey }) => {
  const { data: orderedViews = [] }  = useCachedData('/content/generateMaterialsOrderedViews');

  const renderListItem = (key) => (
    <NavListItem key={key}
      icon={<CompletionIcon view={key} mappingEndpoint='/content/generateMaterialsCompletionMapping' />}
      isActive={key === activeViewKey}
      onClick={() => setActiveViewKey(key)}>
      {titleMap[key].navTitle}
    </NavListItem>
  );

  return (
    <>
      <NavListHeading>Package Slides</NavListHeading>
      <NavList>
        {orderedViews.map(renderListItem)}
      </NavList>
    </>
  );
};

export default GenerateMaterialsSideNav;