
import useCachedData from '../hooks/useCachedData';
import DisplayFacility from './DisplayFacility';

const FacilityDisplay = ({ facilityKey = 'facility', quantity, totalAreaSqft, notes, ...props }) => {
  const { data: facilityData } = useCachedData('/content/facilityRequirement');
  const getFacilityOption = (facility) => facilityData?.options && facilityData.options.find(({ value }) => value === facility);
  const facility = props[facilityKey];
  let facilityObject = {};
  let selection = '';

  // distinguish between live values and formatted values
  if (typeof facility === 'string') {
    facilityObject = getFacilityOption(facility) || {};
    selection = facility;
  } else if (facility.value) {
    facilityObject = facility;
    selection = facility.value;
  }

  const { areaType, unitType } = facilityObject;

  return (
    <DisplayFacility
      areaType={areaType}
      notes={notes}
      quantity={quantity}
      selection={selection}
      totalAreaSqft={totalAreaSqft}
      unitType={unitType}
      />
  )
}

export default FacilityDisplay;