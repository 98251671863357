import React from 'react';
import { useFormState } from 'react-final-form';
import Label from '../../Form/Label';
import Grid from '../../Grid';
import Box from '../../Box';
import styles from './BasingDecisionPackage.module.scss';
import DocumentList from '../../DocumentList';
import AuthorizedContent from '../../AuthorizedContent';
import Table from '../../Table';
import DisplayFile from '../../DisplayFile';
import DisplayUser from '../../DisplayUser';
import useCachedData from '../../hooks/useCachedData';
import DisplayDate from '../../DisplayDate';
import dateFormats from '../../../constants/dateFormats';

const BasingDecisionPackageDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });
  const { data: phases } = useCachedData('/content/phases');
  const { basingDecisionPackage, signedDecisionPackage } = initialValues;
  const endorsements = basingDecisionPackage?.endorsements || [];
  const docs = basingDecisionPackage?.docs || [];
  const esss = docs.filter((d) => d.type === 'esss');
  const mfr = docs.filter((d) => d.type === 'mfr');
  const briefing = docs.filter((d) => d.type === 'briefing');
  const cnp = docs.filter((d) => d.type === 'cnppag');
  const env = docs.filter((d) => d.type === 'environmental');
  const additional = docs.filter((d) => d.type === 'additional');
  const preSignaturePackage = docs.filter((d) => d.type === 'preSignaturePackage');
  const filteredSignedDecisionPackages = signedDecisionPackage.filter(pkg => pkg?.docs?.length > 0 || pkg.lastReviewDate);
  const templateColumns = [5, '2fr 2.5fr 2.5fr 3fr 3fr'];

  return (
    <>
      <div className={styles.topGrid}>
        <Grid columns={[2, '3fr 1fr']}>
          <Box><Label text='Review Notes' /></Box>
          <Box><Label text='Review Date' /></Box>
          {basingDecisionPackage && basingDecisionPackage.lastReviewNotes ? <div>{basingDecisionPackage.lastReviewNotes}</div> : <div></div>}
          {basingDecisionPackage && basingDecisionPackage.lastReviewDate && <div><DisplayDate date={basingDecisionPackage.lastReviewDate} format={dateFormats.short}/></div>}
        </Grid>
      </div>

      <Grid columns={templateColumns}>
          <Box>
            <Label text='Sent To' />
          </Box>
          <Box>
            <Label text='Sent On' />
          </Box>
          <Box>
            <Label text='Endorsed On' />
          </Box>
          <Box>
            <Label text='Endorsed By' />
          </Box>
          <Box>
            <Label text='Notes' />
          </Box>
        </Grid>
      {endorsements.map((endorsement, index) => (
        <Grid columns={templateColumns} key={index}>
          <Box>{endorsement.sentTo}</Box>
          <Box>{endorsement.sentOn && <DisplayDate date={endorsement.sentOn} format={dateFormats.short}/>}</Box>
          <Box>{endorsement.endorsedOn && <DisplayDate date={endorsement.endorsedOn} format={dateFormats.short}/>}</Box>
          <Box>{endorsement.endorsedBy}</Box>
          <Box>{endorsement.notes}</Box>
        </Grid>
      ))}
      <div className={styles.bigSpacer}></div>
      <div className={styles.smallSpacer}><DocumentList docList={esss} title='ESSS' /></div>
      <div className={styles.smallSpacer}><DocumentList docList={mfr} title='MFR' /></div>
      <div className={styles.smallSpacer}><DocumentList docList={briefing} title='Briefing' /></div>
      <div className={styles.smallSpacer}><DocumentList docList={cnp} title='CNP/PAG' /></div>
      <div className={styles.smallSpacer}><DocumentList docList={env} title='Environmental Documents' /></div>
      <div className={styles.smallSpacer}><DocumentList docList={additional} title='Additional Documents' /></div>
      <div className={styles.smallSpacer}><DocumentList docList={preSignaturePackage} title='Pre Signature Package' /></div>
      <hr />

      <AuthorizedContent authorizedRoles='SAM'>
        <div className={styles.smallSpacer}>
          <Table>
            <thead>
              <tr>
                <th>Signed Decision Package</th>
                {filteredSignedDecisionPackages.length > 0 && (
                  <>
                    <th>Decision Date</th>
                    <th>Phase</th>
                    <th>Uploaded On</th>
                    <th>Uploaded By</th>
                  </>
                )}

              </tr>
            </thead>
            <tbody>
              {filteredSignedDecisionPackages.map(pkg => {
                const { createdAt, docs, lastReviewDate, phase } = pkg;
                const doc = docs && docs.length ? docs[0] : null;
                return (
                  <tr key={phase}>
                    <td>{(doc && doc.uuid) && <DisplayFile uuid={doc.uuid} />}</td>
                    <td>{lastReviewDate && <DisplayDate date={lastReviewDate} format={dateFormats.short} />}</td>
                    <td>{phases && phase && phases[phase]}</td>
                    <td>{createdAt && <DisplayDate date={createdAt} format={dateFormats.short} />}</td>
                    <td>{(doc && doc.uploadedBy) && <DisplayUser userId={doc.uploadedBy} />}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </AuthorizedContent>
    </>
  )
}

export default BasingDecisionPackageDisplay;