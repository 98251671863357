import { Form } from 'react-final-form';
import SelectField from '../../../Form/SelectField';
import Button from '../../../buttons/Button';
import Modal from '../../../Modal';
import ModalFooter from '../../../Modal/ModalFooter';
import styles from '../../Admin.module.scss';

const EditEquityModal = ({ bar, handleEditEquity, onClose }) => {
  return (
    <Modal className={styles.modalMedium} onClose={onClose} title='Equity Interest'>
      <Form onSubmit={handleEditEquity} initialValues={bar} subscription={{}}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <h4 className={styles.modalSubTitle}>{bar.title}</h4>
            <p>In addition to the Lead Basing Office and basing offices with Location Equity, these basing offices can view this basing action:</p>
            <SelectField name='equityInterest' placeholder='Basing Office(s)' fieldDataEndpoint='/content/leadBasingOffices' multiple />

            <ModalFooter>
              <Button type="submit" primary>SAVE</Button>
              <Button onClick={onClose} secondary>CANCEL</Button>
            </ModalFooter>
          </form>
        )}
      </Form>
    </Modal>
  );
}

export default EditEquityModal;