import { useEffect, useState } from 'react';
import { Form } from "react-final-form"
import Button from "../../../buttons/Button"
import Card from "../../../Card";
import DisplayDate from '../../../DisplayDate';
import SelectField from "../../../Form/SelectField"
import Label from '../../../Form/Label';
import useCachedData from '../../../hooks/useCachedData';

const ActionRequestCard = ({ request, handleApproveRequest, openRejectRequestModal }) => {
  const { data: safAos } = useCachedData('/users/safAos');
  const [safOptions, setSafOptions] = useState([]);

  useEffect(()=>{
    safAos && setSafOptions(safAos.map(saf => ({ label: saf.firstName + ' ' + saf.lastName, value: saf.id })));
  }, [safAos]);

  const validate = (values) => {
    const errors = {};

    if (!values.safAo) {
      errors.safAo = 'A SAF AO is required';
    }

    if (!values.phase) {
      errors.phase = 'Phase is required';
    }

    if (!values.step) {
      errors.step = 'Step is required'
    }

    return errors;
  };

  return (
    <Card details={<>Submitted on <DisplayDate date={request.barCreatedAt} /></>}>

      <h3><a href={`/bars/create/${request.barId}`}>{request.barTitle}</a></h3>
      <h4>{request.firstName + ' ' + request.lastName}</h4>
      <p>NIPR: {request.niprEmail}</p>
      <p>SIPR: {request.siprEmail}</p>
      <p>DSN: {request.dsnPhone}</p>
      <p>Comm: {request.commercialPhone}</p>
      <p>Secure: {request.securePhone}</p>

      <Form onSubmit={(vals) => handleApproveRequest({...vals, id: request.barId})} validate={validate} subscription={{ invalid: true }}>
        {({ form, handleSubmit }) => {
          const { invalid } = form.getState();
          return (
            <form onSubmit={handleSubmit}>
              <Label text='SAF Action Officer/Phase/Step' />
              <SelectField name='safAo' options={safOptions} placeholderText='SAF Action Officer' />
              <SelectField name='phase' fieldDataEndpoint='/content/phaseOptions' placeholderText='Phase' />
              <SelectField name='step' fieldDataEndpoint='/content/stepOptions' placeholderText='Step' />

              <div>
                <Button type="submit" primary disabled={invalid}>Accept</Button>
                <Button onClick={() => openRejectRequestModal(request.barId)} secondary>Reject</Button>
              </div>
            </form>
          )
        }}
      </Form>
    </Card>
  );
}

export default ActionRequestCard;