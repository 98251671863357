import React from 'react';
import cn from 'classnames';
import styles from './BasingCriteriaDefinition.module.scss';
import { useFormState } from 'react-final-form';
import Table from '../../Table';
import Collapsible from '../../Collapsible';
import Grid from '../../Grid';
import formStyles from '../../Form/Form.module.scss';
import pluralize from '../../../utils/pluralize';
import ReadOnlyFacilityField from '../../Form/ReadOnlyFacilityField';

const CriteriaDefinitionDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });

  const {
    costCriteriaTotalPoints,
    capacityTotalPoints,
    environmentRequirementTotalPoints,
    facilityRequirements,
    facilityCriteriaTotalPoints,
    baseSupportTailCriteriaTotalPoints,
    militaryFamilyReadinessTotalPoints,
    missionCriteria,
    missionRequirements,
    missionRequirementTotalPoints,
    supportOfMilitaryFamiliesTotalPoints
  } = initialValues;

  const renderTableHead = () => <thead><tr><th /><th /></tr></thead>;

  const renderToggleHeading = (title, points) => (
    <Grid columns={[2, '9fr 1fr']}>
      <h3 className={cn(styles.groupHeading, formStyles.sectionHeading)}>{title}</h3>
      <span>{points ? `${points} ${pluralize(points, 'Point', 'Points')}`: ''}</span>
    </Grid>
  );

  const renderCategoryRow = (category, points) => (
    <tr className={styles.categoryRow}>
      <td><b>{category}</b></td>
      <td>{points}</td>
    </tr>
  );

  const renderSubRequirementRow = (subreq, point) => (
    <tr className={styles.subRequirementRow}>
      <td className={styles.subreq}>{subreq}</td>
      <td>{point}</td>
    </tr>
  );

  const renderDefinitionRow = (label, definition, point) => (definition || point) ? (
    <tr>
      <td><li>{label}{definition}</li></td>
      <td>{point}</td>
    </tr>
  ) : null;

  const renderDefinitions = (subRequirement, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue, index, hideHeader) => {
    return(
    <React.Fragment key={index}>
      {!hideHeader && renderSubRequirementRow(subRequirement, point)}
      {renderDefinitionRow('Optimal: ', optimalValueDescription, optimalValue)}
      {renderDefinitionRow('Suitable: ', suitableValueDescription, suitableValue)}
      {renderDefinitionRow('Limits: ', limitsValueDescription, limitsValue)}
    </React.Fragment>
    );
  }

  return (
    <>
      <Collapsible
        toggle={renderToggleHeading('Mission', missionRequirementTotalPoints)}>
        <Table className={styles.table}>
          {renderTableHead()}
          <tbody>
            {missionRequirements && missionRequirements.map(({ requirement, point, subRequirements, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue }, index) => (
              <React.Fragment key={index}>
                {renderCategoryRow(requirement, point)}
                {subRequirements ? subRequirements.map(({ subRequirement, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue }, index) => (
                  renderDefinitions(subRequirement, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue, index)
                )):
                renderDefinitions(requirement, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue, index, true) //hide header, solo req is unique case
                }
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </Collapsible>

      <Collapsible
        toggle={renderToggleHeading('Capacity', capacityTotalPoints)}>
        <Table className={styles.table}>
          {renderTableHead()}
          <tbody>
            {renderCategoryRow('Facilities', facilityCriteriaTotalPoints)}

            {facilityRequirements && facilityRequirements.length &&
            facilityRequirements.map(({ point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue }, index) => (
              <React.Fragment key={index}>
                <tr><td colSpan={2}><ReadOnlyFacilityField name={`facilityRequirements[${index}]`} /></td></tr>
                {renderDefinitions(null, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue, index, true)}
              </React.Fragment>

            ))}

            {renderCategoryRow('Base Support Tail', baseSupportTailCriteriaTotalPoints)}
            {missionCriteria?.baseSupportTailCriteria &&
            missionCriteria.baseSupportTailCriteria.map(({ definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue }, index) => (
              renderDefinitions(definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue, index)
            ))}
            {missionCriteria?.additionalBaseSupportTailCriteria &&
            missionCriteria.additionalBaseSupportTailCriteria.map(({ definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue }, index) => (
              renderDefinitions(definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue, index)
            ))}
          </tbody>
        </Table>
      </Collapsible>

      <Collapsible
        toggle={renderToggleHeading('Environmental', environmentRequirementTotalPoints)}>
        <Table className={styles.table}>
          {renderTableHead()}
          <tbody>
            {missionCriteria?.environmentalCriteria &&
              missionCriteria.environmentalCriteria.map(({ definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue }, index) => (
                renderDefinitions(definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue, index)
              ))}
          </tbody>
        </Table>
      </Collapsible>

      <Collapsible
        toggle={renderToggleHeading('Cost', costCriteriaTotalPoints)}>
        <Table className={styles.table}>
          {renderTableHead()}
          <tbody>
            {missionCriteria?.costCriteria &&
              missionCriteria.costCriteria.map(({ definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue }, index) => (
                renderDefinitions(definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue, index)
                ))}
            {missionCriteria?.additionalCosts &&
              missionCriteria.additionalCosts.map(({ definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue }, index) => (
                renderDefinitions(definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue, index)
                ))}
          </tbody>
        </Table>
      </Collapsible>

      <Collapsible
        toggle={renderToggleHeading('Military Family Readiness', militaryFamilyReadinessTotalPoints)}>
        <Table className={styles.table}>
          {renderTableHead()}
          <tbody>
            {missionCriteria?.militaryFamilyReadiness && missionCriteria.militaryFamilyReadiness.map(({ definition, point, children, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue }, index) => {
              if(children && children.length){
                return(
                <React.Fragment key={index}>
                  {renderCategoryRow(definition, point)}
                  {children.map(({ definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue }, index) => (
                    renderDefinitions(definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue, index)
                  ))}
                </React.Fragment>
                )
              }
              return renderDefinitions(definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue, index);
            })}
            {supportOfMilitaryFamiliesTotalPoints > 0 && renderCategoryRow('Support of Military Families', supportOfMilitaryFamiliesTotalPoints)}
            {missionCriteria?.supportOfMilitaryFamilies && missionCriteria.supportOfMilitaryFamilies.map(({ definition, point, children, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue }, index) => {
              return renderDefinitions(definition, point, optimalValueDescription, optimalValue, suitableValueDescription, suitableValue, limitsValueDescription, limitsValue, index);
            })}
          </tbody>
        </Table>
      </Collapsible>
    </>
  )
}

export default CriteriaDefinitionDisplay;
