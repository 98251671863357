// sidebar and content title for all bar detail views
export const viewMapping = {
  additionalBackup: {
    navTitle: 'Additional Backup',
    pageTitle: 'Additional Backup',
  },
  additionalCongressionalEngagements: {
    navTitle: 'Additional Congressional Engagements',
    pageTitle: 'Additional Congressional Engagements'
  },
  additionalInfo: {
    navTitle: 'Additional Info',
    pageTitle: 'Additional Information',
  },
  additionalMfrBackup: {
    navTitle: 'MFR Backup',
    pageTitle: 'Additional MFR Backup',
  },
  attachments: {
    navTitle: 'Attachments',
    pageTitle: 'Attachments',
  },
  background: {
    navTitle: 'Background',
    pageTitle: 'Background',
  },
  basicInformation: {
    navTitle: 'Basic Information',
    pageTitle: 'Basic Information',
  },
  basingCriteria: {
    navTitle: 'Basing Criteria',
    pageTitle: 'Basing Criteria',
  },
  basingCriteriaDefinition: {
    navTitle: 'Criteria Definitions',
    pageTitle: 'Criteria Definitions',
  },
  basingDecisionPackage: {
    navTitle: 'Basing Decision Package',
    pageTitle: 'Basing Decision Package',
  },
  candidateDecision: {
    navTitle: 'Candidate Decision',
    pageTitle: 'Candidate Decision',
  },
  congressionalDates: {
    navTitle: 'Congressional Dates',
    pageTitle: 'Congressional Dates',
  },
  congressionalLandscape: {
    navTitle: 'Congressional Landscape',
    pageTitle: 'Congressional Landscape',
  },
  eisSummaryOfFindings: {
    navTitle: 'EIS Findings',
    pageTitle: 'EIS Summary Of Findings',
  },
  enterprise: {
    navTitle: 'Enterprise Definition',
    pageTitle: 'Enterprise Definition',
  },
  enterpriseComponents: {
    navTitle: 'Enterprise Components',
    pageTitle: 'Enterprise Wide Look Components',
  },
  enterpriseComponentScores: {
    navTitle: 'Enterprise Scores',
    pageTitle: 'Enterprise Wide Look Components Scores',
  },
  finalDecision: {
    navTitle: 'Final Decision',
    pageTitle: 'Final Decision'
  },
  mfrHealthcare: {
    navTitle: 'MFR Healthcare',
    pageTitle: 'MFR Healthcare Backup',
  },
  mfrHousing: {
    navTitle: 'MFR Housing',
    pageTitle: 'MFR Housing Backup',
  },
  myNotes: {
    navTitle: 'My Notes',
    pageTitle: 'My Notes',
  },
  preferredLocationDecision: {
    navTitle: 'Preferred Location',
    pageTitle: 'Preferred Location Decision',
  },
  recommendedCandidates: {
    navTitle: 'Candidates',
    pageTitle: 'Recommended Candidates',
  },
  requirements: {
    navTitle: 'Requirements',
    pageTitle: 'Requirements',
  },
  riskAssessmentCriteria: {
    navTitle: 'Risk Assessment',
    pageTitle: 'Risk Assessment Criteria',
  },
  safNotes: {
    navTitle: 'SAF Notes',
    pageTitle: 'SAF Notes'
  },
  siteSurveyCriteria: {
    navTitle: 'Site Survey Criteria',
    pageTitle: 'Site Survey Criteria',
  },
  somfPublicEducation: {
    navTitle: 'SoMF PECD',
    pageTitle: 'SoMF Public Education Criteria Definitions Backup',
  },
  somfProfessionalLicensure: {
    navTitle: 'SoMF PLCD',
    pageTitle: 'SoMF Professional Licensure Criteria Definitions Backup',
  },
  stageSetter: {
    navTitle: 'Stage Setter',
    pageTitle: 'Stage Setter',
  },
  strategyImplications: {
    navTitle: 'Strategy & Programming',
    pageTitle: 'Strategy & Programming Implications',
  },
  summaryOfFindings: {
    navTitle: 'Summary of Findings',
    pageTitle: 'Summary of Findings',
  },
  summaryOfFindingsContd: {
    navTitle: "Summary of Findings (Cont'd)",
    pageTitle: "Summary of Findings (Cont'd)",
  },
  twoLetterCoord:{
    navTitle: '2-Letter Coord',
    pageTitle: '2-Letter Coord Package',
  },
  wayAhead: {
    navTitle: 'Way Ahead',
    pageTitle: 'Way Ahead',
  },
  weAreHere: {
    navTitle: 'We Are Here',
    pageTitle: 'We Are Here',
  }
};
