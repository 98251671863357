import actionEquity from '../components/Admin/views/ActionEquity';
import actionsOfInterest from '../components/Admin/views/ActionsOfInterest';
import contactsGroups from '../components/Admin/views/ContactsGroups';
import databaseQuery from '../components/Admin/views/DatabaseQuery';
import newAccountRequests from '../components/Admin/views/NewAccountRequests';
import newActionRequests from '../components/Admin/views/NewActionRequests';
import staticResources from '../components/Admin/views/StaticResources';

export default {
  actionEquity,
  actionsOfInterest,
  contactsGroups,
  databaseQuery,
  newAccountRequests,
  newActionRequests,
  staticResources
};