import React, { useState } from 'react';
import NavList from './NavList';
import NavListItem from './NavListItem';
import NavListHeading from './NavListHeading';
import CompletionIcon from './CompletionIcon';
import { viewMapping } from '../../constants/steps';
import useCachedData from '../hooks/useCachedData';
import Download from '../svg/Download';
import Tooltip from '../Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './SideNav.module.scss';
import { usePdf } from '../Pdf/PDFContext';
import _ from 'lodash';
import { useParams } from 'react-router';
import { useFormState } from 'react-final-form';

// create bar and bar detail dynamic left side bar
const SideNav = ({ activeView, createBar, expeditionLevel, phase, setActiveView, bar, editing, step = 0 }) => {
  const { data: keys } = useCachedData('/content/barViewKeys');
  const { data: { orderedViews = [], actionDetails = [] } = {} } = useCachedData('/content/getOrderedViews', { params: { expeditionLevel, phase, step }});
  const showDownload = !editing && (createBar || (phase === bar?.currentPhase && step === bar?.currentStep))
  const {generateBarPdf, isGeneratingBarPdf} = usePdf();
  const { initialValues = {} } = useFormState({ subscription: { initialValues: true } });
  const createBarId = useParams()

  const renderListItem = (key, showCompletionIcon) => (
    <NavListItem key={key}
      icon={showCompletionIcon ? <CompletionIcon view={key} mappingEndpoint='/content/editingCompletionMapping' /> : null}
      isActive={key === activeView}
      onClick={() => setActiveView(key)}>
      {viewMapping[key].navTitle}
    </NavListItem>
  );

  const downloadPdf = (e) => {
    e.preventDefault();
    if(createBar) {
      generateBarPdf(createBarId.id, initialValues.title)
    }
    else {
      bar && generateBarPdf(bar.id, bar.title)
    }
  }

  if (keys) {
    return (
      <>
        <div className={styles.actionContents}>
          <NavListHeading>ACTION CONTENTS</NavListHeading>
          {showDownload && !_.get(isGeneratingBarPdf, createBar ? createBarId.id : bar?.id) &&
            <Tooltip content='Download a preview of this Basing Action'>
              <Download className={styles.downloadButton} onClick={(e) => downloadPdf(e)} />
            </Tooltip>}
          {showDownload && _.get(isGeneratingBarPdf, createBar ? createBarId.id : bar?.id) &&
            <Tooltip content='Your PDF is being generated'>
              <CircularProgress style={{ color: 'inherit', height: 15, width: 15, marginLeft: 10 }} />
            </Tooltip>}
        </div>
        <NavList>
          {orderedViews.map((key) => renderListItem(key, createBar))}
        </NavList>

        <NavListHeading>ACTION DETAILS</NavListHeading>
        <NavList>
          {actionDetails.map((key) => renderListItem(key))}
        </NavList>
      </>
    );
  }

  return null;
}

export default SideNav;
