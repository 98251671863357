import { Form } from 'react-final-form';
import Button from '../../../buttons/Button';
import TextArea from '../../../Form/TextArea'
import Modal from '../../../Modal';
import ModalFooter from '../../../Modal/ModalFooter';
import styles from '../../Admin.module.scss';

const DenyRequestModal = ({ handleDenyRequest, onClose }) => {
  const validate = (values) => {
    const errors = {};

    if (!values.denyMessage) {
      errors.denyMessage = 'Must provide a reason for denying this account request.'
    }

    return errors;
  }

  return (
    <Modal className={styles.modalMedium} onClose={onClose} title='Deny Account Request'>
      <Form onSubmit={handleDenyRequest} validate={validate} subscription={{ invalid: true }}>
        {({ form, handleSubmit }) => {
          const { invalid } = form.getState();

          return (
            <form onSubmit={handleSubmit} noValidate>
              <p>Please explain why this account cannot be approved. We will send the submitter an email with your explanation.</p>
              <TextArea name='denyMessage' />
              <ModalFooter>
                <Button type="submit" disabled={invalid} primary>SAVE</Button>
                <Button onClick={onClose} secondary>CANCEL</Button>
              </ModalFooter>
            </form>
          )
        }}
      </Form>
    </Modal>
  );
}

export default DenyRequestModal;
