import React from 'react';
import { useFormState } from 'react-final-form';
import RichTextDisplay from '../../Form/RichTextDisplay';

const StrategyDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });
  return (
    <>
      {initialValues.strategicPosture && (
        <>
          <h4>Strategic Posture</h4>
          <RichTextDisplay html={initialValues.strategicPosture} />
        </>
      )}


      {initialValues.programmingImplications && (
        <>
          <h4>Programming Implications</h4>
          <RichTextDisplay html={initialValues.programmingImplications} />
        </>
      )}
    </>
  )
}

export default StrategyDisplay;