import { FORM_ERROR } from 'final-form';
import PhoneField from './Form/PhoneField';
import TextField from './Form/TextField';

// reused standard fields for contacts and users creation
export const validateContactFields = (values, errors = {}) => {
  if (!values.firstName) {
    errors.firstName = 'This is a required field';
  }
  if (!values.lastName) {
    errors.lastName = 'This is a required field';
  }
  if (!values.userRank) {
    errors.userRank = 'This is a required field';
  }
  if (!values.officeSymbol) {
    errors.officeSymbol = 'This is a required field';
  }
  if (!values.capacity) {
    errors.capacity = 'This is a required field';
  }
  if (!values.niprEmail) {
    errors.niprEmail = 'This is a required field';
  }
  if (!values.commercialPhone) {
    errors.commercialPhone = 'This is a required field';
  }
  if (Object.keys(errors).length) {
    errors[FORM_ERROR] = 'Please fill the required fields';
  }

  return errors;
}

const ContactFields = () => (
  <>
    <TextField name='firstName' label='First Name' required />

    <TextField name='lastName' label='Last Name' required />

    <TextField name='userRank' label='Rank'  required />

    <TextField name='officeSymbol' label='Office Symbol' required placeholder='e.g. SAF/IEIB, ACC/A5/8'/>

    <TextField name='capacity' label='Job Title'  required />

    <TextField name='niprEmail' label='NIPR Email' required />

    <TextField name='siprEmail' label='SIPR Email' />

    <PhoneField name='commercialPhone' label='Commercial Phone' required />

    <PhoneField name='securePhone' label='Secure Phone' />

    <PhoneField name='dsnPhone' label='DSN Phone' />
  </>
);

export default ContactFields;
