import React, { useState } from 'react';
import { Field, useField } from 'react-final-form';
import TextField from '../../Form/TextField';
import SelectField from '../../Form/SelectField';
import Box from '../../Box';
import Grid from '../../Grid';
import useCachedData from '../../hooks/useCachedData';
import getBaseName from '../../../utils/getBaseName';
import styles from './SummaryOfFindingsContd.module.scss';
import FacilityDisplay from '../FacilityDisplay';
import Anchor from '../../Anchor';
import NoBasesMsg from './NoBasesMsg';

// formerly "SummaryOfFindingsEdit"
const SummaryOfFindingsContdEdit = ({ expeditionLevel, setActiveView }) => {
  const { data: bases } = useCachedData('/content/bases');
  const { data: keys } = useCachedData('/content/barViewKeys');
  const templateColumns = [3, '661fr 125fr 110fr'];
  const { input: candidateBases } = useField('candidateBases');
  const { input: recommendedCandidates } = useField('recommendedCandidates');
  const { input: siteSurveyRequirements } = useField('siteSurveyRequirements');
  const [baseSelections ] = useState((expeditionLevel >= 3 ? [...((candidateBases.value.length ? candidateBases.value : recommendedCandidates.value) || [])] : [...candidateBases.value]).filter(b => b?.baseId));
  
  let incrementalIndex = -1;

  const renderLabelRow = (label) => (
    <Grid columns={templateColumns}>
      <Box><h3>{label}</h3></Box>
      <Box>Rating</Box>
    </Grid>
  );

  const renderBases = (requirementId) => {
    return baseSelections && Array.isArray(baseSelections) && (
      baseSelections.map(({ baseId }) => {
        if (typeof baseId === 'object' && baseId?.value) {
          baseId = baseId.value;
        }
        const prefix = `summaryOfFindings[${requirementId ? requirementId : incrementalIndex}~${baseId}]`;

        return (
          <Grid key={prefix} columns={templateColumns}>
            <Box><TextField name={`${prefix}.note`} /></Box>
            <Box><SelectField fieldDataEndpoint='/content/ratings' name={`${prefix}.riskFactor`} /></Box>
            <Box>{getBaseName(baseId, bases)}</Box>
            <Field component='input' type='hidden' name={`${prefix}.baseId`} initialValue={baseId} />
            <Field component='input' type='hidden' name={`${prefix}.requirementId`} initialValue={requirementId} />
            <Field component='input' type='hidden' name={`${prefix}.requirementIndex`} initialValue={incrementalIndex} />
          </Grid>
        );
      })
    );
  }

  const renderRequirement = ({ id: requirementId, subRequirements = [], ...props }, displayAsFacility) => {
    const displayRequirement = props.requirement?.label || props.requirement;
    ++incrementalIndex;

    return (
      <Grid key={requirementId}>
        <p className={styles.requirementLabel}>{displayAsFacility ? <FacilityDisplay {...props} facilityKey='requirement' /> : displayRequirement}</p>

        {subRequirements.length ? (
          subRequirements.map(subRequirement => renderRequirement(subRequirement))
        ) : (
          renderBases(requirementId)
        )}
      </Grid>
    );
  };

  const renderRequirements = (type, label, displayAsFacility) => {
    const requirements = siteSurveyRequirements.value[type];
    if (requirements) {
      return (
        <>
          {label && renderLabelRow(label)}
          {requirements.map((requirement) => renderRequirement(requirement, displayAsFacility))}
        </>
      );
    }

    return null;
  };

  return baseSelections.length ? (
    <>
      {renderRequirements('mission', 'Mission Requirements')}

      {renderRequirements('jointAndAllDomainTraining', 'Joint and All Domain Training')}

      {renderRequirements('airspaceAndTrainingAreas', 'Airspace and Training Areas')}

      {renderRequirements('facility', 'Capacity', true)}
      {renderRequirements('baseSupportTail')}
      {renderRequirements('communitySupport')}

      {renderRequirements('environmental', 'Environmental')}

      {renderRequirements('militaryFamilyReadiness', 'Military Family Readiness')}
      {renderRequirements('supportOfMilitaryFamilies')}

      {renderRequirements('costOneTime', 'Cost')}
      {renderRequirements('costRecurring')}
      {renderRequirements('costAdditional')}
    </>
  )
  :
  <NoBasesMsg expeditionLevel={expeditionLevel} setActiveView={setActiveView} />
}

export default SummaryOfFindingsContdEdit;