import React from 'react';
import cn from 'classnames';
import styles from './Button.module.scss';
import CircularProgress from '@material-ui/core/CircularProgress';

const Button = ({ children, className, danger, disabled, loading, name, onClick, primary, primaryAlt, secondary, secondaryAlt, type = 'button', value }) => {
  return (
    <button
      className={cn(styles.button, className, {
        [styles.primary]: primary,
        [styles.secondary]: secondary,
        [styles.primaryAlt]: primaryAlt,
        [styles.secondaryAlt]: secondaryAlt,
        [styles.danger]: danger,
      })}
      disabled={disabled}
      name={name}
      onClick={onClick}
      type={type}
      value={value}>
        {children}
        {loading && <CircularProgress style={{ color: 'inherit', height: 14, width: 14, marginLeft: 10 }} />}
    </button>
  )
}

export default Button;
