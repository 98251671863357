import React, { useEffect, useState } from 'react';
import DateField from '../../Form/DateField';
import useCachedData from '../../hooks/useCachedData';
import Label from '../../Form/Label';
import styles from './CandidateDecision.module.scss';
import BaseSelector from "../candidateDecision/BaseSelector";
import getBaseOptions from '../../../utils/getBaseOptions';

const CandidateDecisionEdit = () => {
    const { data: bases } = useCachedData('/content/bases');
    const [baseOptions, setBaseOptions] = useState([]);

    useEffect(() => {
        if (bases) {
            const formattedBases = getBaseOptions(bases);

            setBaseOptions(formattedBases);
        }
    }, [bases])


    return (
        <>
            <h3 className={styles.sectionHeading}>Approved Bases</h3>
            <Label text='Approved On' required />
            <DateField name='candidateBaseApprovalDate' />
            <BaseSelector name='candidateBases' disabledSelectStatus />
        </>
    );
}

export default CandidateDecisionEdit;
