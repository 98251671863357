import React from 'react';
import { useFormState } from 'react-final-form';
import styles from '../../Form/Form.module.scss';

const WayAheadDisplay = () => {
  const { initialValues } = useFormState();
  const { wayAheadActionEndorsement, wayAheadTimeTable } = initialValues;
  return (
    <>
      {wayAheadTimeTable?.length > 0 && wayAheadTimeTable.map(({ fiscalYear, milestone, quarter }, index) => (
        <p key={index}>
          <span>{`${quarter || ''}QFY${fiscalYear || ''}`}: </span>
          {milestone}
        </p>
      ))}

      {wayAheadActionEndorsement && (
        <>
          <h3 className={styles.sectionHeading}>Action Endorsement</h3>
          <p>{wayAheadActionEndorsement}</p>
        </>
      )}
    </>
  )
}

export default WayAheadDisplay;
