import Agenda from './views/Agenda';
import BasingActionQueue from './views/BasingActionQueue';
import Cover from './views/Cover';
import UpcomingDecisions from './views/UpcomingDecisions';
import PendingCongressionalNotifications from './views/PendingCongressionalNotifications';
import RollCall from './views/RollCall';


export default {
  agenda: Agenda,
  basingActionQueue: BasingActionQueue,
  cover: Cover,
  upcomingDecisions: UpcomingDecisions,
  pendingCongressionalNotifications: PendingCongressionalNotifications,
  rollCall: RollCall,
}