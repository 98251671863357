import axios from "axios";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import AddButton from "../../../buttons/AddButton";
import Button from "../../../buttons/Button";
import CloseButton from "../../../buttons/CloseButton";
import SelectField from "../../../Form/SelectField";
import Modal from "../../../Modal";
import ModalFooter from "../../../Modal/ModalFooter";
import styles from "./ActionsOfInterest.module.scss"
import adminStyles from '../../Admin.module.scss';

const ActionsOfInterest = () => {
    const [allBars, setAllBars] = useState(null);
    const [selectedBars, setSelectedBars] = useState([]);
    const [barOptions, setBarOptions] = useState([]);
    const [showEditListModal, setShowEditListModal] = useState(false);

    const toLabelValue = (bar) => ({label: bar.title, value: bar.id})

    const getBars = async () => {
        const response = await axios.get('/admin/action-of-interest/list/bars');
        response.data && setAllBars(response.data);
    }

    const getCurrentActionsOfInterest = async () => {
        const response = await axios.get('/admin/action-of-interest/list/actions');
        if(response?.data && response.data.map){
            setSelectedBars(response.data.map(toLabelValue));
        }
    }

    useEffect(()=>{
        getCurrentActionsOfInterest();
        getBars();
    },[]);

    useEffect(()=>{
        allBars && setBarOptions(allBars.map(toLabelValue));
    },[allBars]);

    const updateList = async (values) => {
        const {bars} = values;
        await axios.get('/admin/action-of-interest/update', { params: { barIds: bars.map((bar) => bar.value).join(',') } })
        setSelectedBars([...bars])
        setShowEditListModal(false);
    }

    const remove = async (id) => {
        await axios.get('/admin/action-of-interest/remove', { params: { barIds: id } })
        setSelectedBars(selectedBars.filter((sb) => sb.value !== id))
    }

    return <>
        <h3 className={adminStyles.subTitle}>Manage actions for Leadership</h3>
        <div className={styles.explanation}>These actions will appear on the dashboard of Leadership users</div>
        {selectedBars && selectedBars.length > 0 && selectedBars.map((bar) =>
            <div key={bar.value} className={styles.row}>
                <CloseButton className={styles.closeBtn} onClick={() => remove(bar.value)} />
                <span className={styles.barTitle}>{bar.label}</span>
            </div>
        )}
        <AddButton onClick={()=> setShowEditListModal(true)} secondary>Add Basing Action</AddButton>

        {showEditListModal && <Modal className={styles.modal} onClose={() => setShowEditListModal(false)} title='Actions of Interest'>
            <Form onSubmit={updateList} initialValues={{bars: selectedBars}} subscription={{}}>
                {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <SelectField name='bars' label='Basing Actions' multiple={true} placeholderText='Type action title, or select from list' options={barOptions} isSearchable={true} />
                    <ModalFooter>
                        <Button type="submit" primary>SAVE</Button>
                        <Button onClick={() => setShowEditListModal(false)} secondary>CANCEL</Button>
                    </ModalFooter>
                </form>
                )}
            </Form>
        </Modal>}
    </>
};

export default ActionsOfInterest;