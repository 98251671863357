import React from 'react';
import DateField from '../../Form/DateField';
import Label from '../../Form/Label';
import { Field, useForm } from 'react-final-form';
import TextField from '../../Form/TextField';
import FieldArray from '../../Form/FieldArray';
import FileUploadField from '../../Form/FileUploadField';
import AddButton from '../../buttons/AddButton';
import CloseButton from '../../buttons/CloseButton';
import Grid from '../../Grid';
import Box from '../../Box';

const templateColumnsB = [3, '2fr 3fr 3fr'];
const AdditionalCongressionalEngagementsEdit = ({ deleteFile, barPhase }) => {
  const formApi = useForm();

  const onRemoveRow = (remove, index) => () => {
    const formState = formApi.getState();
    const uuid = formState.values.congressionalEngagementDocuments[index].uuid;
    remove(index)();
    deleteFile && uuid && deleteFile(uuid);
  }

  return (
    <FieldArray name='congressionalEngagementDocuments' minLength={0} ignoredFields={['phase']}>
      {({ add, fields, remove }) => (
        <>
          <Grid columns={templateColumnsB}>
            <Box>
              <Label text='Date' />
            </Box>
            <Box>
              <Label text='Note' />
            </Box>
            <Box>
              <Label text='Document'/>
            </Box>
          </Grid>
          {fields.map((name, index) => {
            return (
              <Grid key={name} columns={templateColumnsB} absoluteChild={<CloseButton onClick={onRemoveRow(remove, index)} />}>
                <Box>
                  <DateField name={`${name}.engagementDate`} />
                </Box>
                <Box>
                  <TextField name={`${name}.note`} />
                </Box>
                <Box>
                  <FileUploadField name={name} deleteFile={deleteFile} />
                </Box>
                <Field component='input' type='hidden' name={`${name}.phase`} initialValue={barPhase} />
                <Field component='input' type='hidden' name={`${name}.id`} />
              </Grid>
            );
          })}

          <Grid><AddButton onClick={() => add()} secondary>Add Row</AddButton></Grid>
        </>
      )}
    </FieldArray>
  );
}

export default AdditionalCongressionalEngagementsEdit;