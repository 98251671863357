import React from 'react';
import BarsList from '../../BarsList';
import Wysiwyg from '../../../Form/Wysiwyg';
import Label from '../../../Form/Label';

const AgendaEdit = ({ review }) => {
  return (
    <>
      <BarsList bars={review.bars} />
      
      <Label text={<h3>Congressional Announcements</h3>} />
      <Wysiwyg name='congressionalAnnouncement' />

      <Label text={<h3>Opening Comments</h3>} />
      <Wysiwyg name='openingComments' />
    </>
  );
};

export default AgendaEdit;