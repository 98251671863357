import React from 'react';
import { useFormState } from 'react-final-form';
import RichTextDisplay from '../../Form/RichTextDisplay';
import useCachedData from '../../hooks/useCachedData';
import styles from './RecommendedCandidates.module.scss';

const RecommendedCandidatesDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });
  const { data: bases } = useCachedData('/content/bases');
  return (
    <>
      {initialValues.recommendedCandidates && initialValues.recommendedCandidates.map((candidate, index) => (
        <div key={index} className={styles.displayCandidate}>
          <h4>{bases ? bases.find((b) => b.id === candidate.baseId)?.baseName : ''}</h4>
          <RichTextDisplay html={candidate.rationale} />
        </div>
      ))}
    </>
  )
}

export default RecommendedCandidatesDisplay;
