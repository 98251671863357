import React from 'react';
import cn from 'classnames';
import styles from './Header.module.scss';

/**
 * Basic page header component
 */
const Header = (props) => {
    const { children, className, leftContent, rightContent } = props;

    return (
        <header className={cn(styles.header, className)}>
            <div className={styles.content}>
                {leftContent && <div className={styles.leftContent}>{leftContent}</div>}
                {rightContent && <div className={styles.rightContent}>{rightContent}</div>}
            </div>

            {children && <div className={styles.bottomContent}>{children}</div>}
        </header>
    );
}

export default Header;
