const Upload = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15">
  <g id="Group_3544" data-name="Group 3544" transform="translate(-1301.637 -138)">
    <rect id="Rectangle_178" data-name="Rectangle 178" width="12" height="2" transform="translate(1301.637 151)"/>
    <g id="Group_3545" data-name="Group 3545" transform="translate(2615.274 288) rotate(180)">
      <rect id="Rectangle_179" data-name="Rectangle 179" width="2" height="7" transform="translate(1306.637 138)"/>
      <path id="Polygon_1" data-name="Polygon 1" d="M5,0l5,5H0Z" transform="translate(1312.637 150) rotate(180)"/>
    </g>
  </g>
</svg>
);

export default Upload;