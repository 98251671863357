import React from 'react';
import { useFormState } from 'react-final-form';
import useCachedData from '../../hooks/useCachedData';
import DisplayDate from '../../DisplayDate';
import styles from './PreferredLocationDecision.module.scss';
import getBaseName from '../../../utils/getBaseName';
import RichTextDisplay from '../../Form/RichTextDisplay';
import dateFormats from '../../../constants/dateFormats';
import formStyles from '../../Form/Form.module.scss';

const PreferredLocationDecisionDisplay = () => {
  const { initialValues: { preferredLocationDecisionDate, preferredLocationDecision } = {} } = useFormState({ subscription: { initialValues: true } });
  const { data: bases } = useCachedData('/content/bases');
  const preferredLoc = preferredLocationDecision ? preferredLocationDecision.find((loc) => loc.type === 'preferred') : null;
  const alternativeLocs = preferredLocationDecision ? preferredLocationDecision.filter((loc) => loc.type !== 'preferred') : [];

  return (
    <>
        {preferredLocationDecisionDate && <div className= {styles.margin}><h3 className={formStyles.sectionHeading}>Dept of the Air Force approved on <DisplayDate date={preferredLocationDecisionDate} format={dateFormats.short} /></h3></div>}

        <h3>Preferred Location</h3>
        <ul className= {styles.margin}>
          {preferredLoc && (
            <li>
              <p>{getBaseName(preferredLoc.baseId, bases)}</p>
              <ul>
                <li>
                  <RichTextDisplay html={preferredLoc.rationale} />
                </li>
              </ul>
            </li>
          )}
        </ul>

        <h3>Alternative Locations</h3>
        <ul>
            {alternativeLocs && alternativeLocs.map((loc) => (
              <li key={loc.baseId}>
                <p>{getBaseName(loc.baseId, bases)}</p>
                <ul>
                    <li>
                      <RichTextDisplay html={loc.rationale} />
                    </li>
                </ul>
              </li>
            ))}
        </ul>
    </>
  )
}

export default PreferredLocationDecisionDisplay;
