import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import axios from '../../utils/sharedAxios';
import styles from './Dashboard.module.scss'

const MyActions = ({ office = '', userId }) => {
  const history = useHistory();
  const [bars, setBars] = useState([]);

  useEffect(() => {
    const fetchBars = async () => {
      const { data } = await axios.get(office ? '/bars/all_with_title' : '/bars/assigned');
      data && setBars(data);
    }

    fetchBars();
  }, []);

  const handleClick = () => history.push(office ? `/bars?leadBasingOffice=${office}` : `/bars?safActionOfficer=${userId}`);

  return (
    <div className={cn(styles.chip, { [styles.active]: bars.length > 0 })} onClick={handleClick}>
      <h6 className={styles.chipHeading}>{office || 'MY'} ACTIONS</h6>
      <p className={styles.chipValue}>{bars.length || 0}</p>
    </div>
  );
}

export default MyActions;