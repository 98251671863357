import React from 'react';
import cn from 'classnames';
import styles from './Form.module.scss';
import RequiredIndicator from './RequiredIndicator';

// standard field label
const Label = ({ error, htmlFor, required, text, className }) => {
  return (
    <label className={cn(styles.label, { error, required }, className)} htmlFor={htmlFor}>
      {text}
      {text && required && <RequiredIndicator />}
    </label>
  );
}

export default Label;
