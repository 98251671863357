import React from 'react';
import useCachedData from '../../hooks/useCachedData';
import Anchor from '../../Anchor';
import styles from './SummaryOfFindingsContd.module.scss';

const NoBasesMsg = ({ expeditionLevel, setActiveView }) => {
    const { data: keys } = useCachedData('/content/barViewKeys');
    return (
        <>
            <b className={styles.noBasesMsg}>In order to use this page, please add bases on <Anchor onClick={() => {
            setActiveView(expeditionLevel >= 3 ? keys.recommendedCandidates : keys.candidateDecision)
            }}>{expeditionLevel >= 3 ? 'Recommended Candidates' : 'Candidate Decision'}.</Anchor></b>
        </>
    )
}
export default NoBasesMsg;