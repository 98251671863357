import React from 'react';
import { useField } from 'react-final-form';
import cn from 'classnames';
import styles from './Form.module.scss';

const TextArea = ({ className, name }) => {
  const { input } = useField(name);
  return (
    <textarea className={cn(styles.textarea, className)} {...input} />
  );
}

export default TextArea;
