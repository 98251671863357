import { useEffect, useState } from 'react';
import cn from 'classnames';
import AuthorizedContent from '../AuthorizedContent';
import dashboardStyles from './Dashboard.module.scss';
import styles from './ActionsOfInterestList.module.scss';
import axios from '../../utils/sharedAxios';
import DisplayDate from '../DisplayDate';
import dateFormats from '../../constants/dateFormats';
import DisplayBarLink from '../DisplayBarLink';
import DisplayUser from '../DisplayUser';

const ActionsOfInterestList = () => {
  const [actions, setActions] = useState([]);

  const retrieveData = async () => {
    axios.all([
      axios.get('/admin/action-of-interest/list/actions'),
      axios.get('/users/bars_watching_with_titles')
    ]).then(([{ data: priorityActions = [] }, { data: watchedActions = [] }]) => {
      if (watchedActions?.length || priorityActions?.length) {
        const priorityIds = []
        const actionsArray = priorityActions.map(action => {
          priorityIds.push(action.id);

          return ({ ...action, priority: true });
        });

        watchedActions.forEach((action) => {
          !priorityIds.includes(action.id) && actionsArray.push({ ...action, watched: true });
        });

        actionsArray.sort((a, b) => {
          if (a.createdBy > b.createdBy) {
            return -1;
          }
          if (a.createdBy < b.createdBy) {
            return 1;
          }

          return 0;
        });

        setActions(actionsArray);
      }
    });
  }

  useEffect(()=> {
    retrieveData();
  }, [])

  return (
    <AuthorizedContent authorizedRoles='LEE'>
      <div>
        <h5 className={dashboardStyles.panelHeading}>ACTIONS OF INTEREST</h5>
        <div className={cn(dashboardStyles.tile, styles.list)}>
          {actions.length > 0 ?
            actions.map((action) => (
              <div key={action.id} className={styles.rowEntry}>
                <span className={styles.linkContainer}>
                  <DisplayBarLink className={styles.link} barId={action.id} linkText={action.title}>{action.title}</DisplayBarLink>
                </span>

                {action.priority && (<span className={styles.rightText}>Flagged High Priority <DisplayDate date={action.createdAt} format={dateFormats.short} /> by <DisplayUser userId={action.addedBy} /></span>)}
                {action.watched && (<span className={styles.rightText}>Watched on <DisplayDate date={action.createdAt} format={dateFormats.short} /></span>)}

              </div>
            )) : (
              <div>No Actions Found</div>
            )
          }
        </div>
      </div>
    </AuthorizedContent>

  );
}

export default ActionsOfInterestList;