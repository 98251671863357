import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import useCachedData from '../../hooks/useCachedData';
import RichTextDisplay from '../../Form/RichTextDisplay';
import getBaseName from '../../../utils/getBaseName';
const statusSortValues = {
    preferred: -1,
    alternative: 0,
    NCF: 1
};

// formerly "RationaleForDecisionDisplay"
const SummaryOfFindingsDisplay = () => {
    const { initialValues: { rationaleForDecisionLocations, rationaleForBaseDecisionOverview } = {} } = useFormState({ subscription: { initialValues: true } });
    const { data: bases } = useCachedData('/content/bases');
    const { data: { options: statuses = [] } = {} } = useCachedData('/content/rationaleForDecision');
    const [sortedCandidates, setSortedCandidates] = useState([]);

    useEffect(() => {
        if (rationaleForDecisionLocations?.length) {
            setSortedCandidates(rationaleForDecisionLocations.sort((a, b) => statusSortValues[a.status] - statusSortValues[b.status]));
        }
    }, [rationaleForDecisionLocations]);

    const getStatusLabel = (status) => {
        const found = statuses.find(item => item.value === status);

        if (found) {
            return found.label;
        }

        return status;
    }

    return (
        <>
            <div><h1>Overview</h1></div>
            <RichTextDisplay html={rationaleForBaseDecisionOverview} />
            {sortedCandidates && sortedCandidates.map(({ baseId, rationale, status }, index) => (
                <div key={index}>
                    <div>
                        <h3><span>{getBaseName(baseId, bases)}</span> - <span>{getStatusLabel(status)}</span></h3>
                    </div>
                    <div>
                        <RichTextDisplay html={rationale} />
                    </div>
                </div>
            ))}
        </>
    )
}

export default SummaryOfFindingsDisplay;
