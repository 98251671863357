import axios from './sharedAxios';

export const watchBar = async (barId, watchList, setWatchList) => {
    const result = await axios.get(`/userbar/watch/${barId}`);
    const id = parseInt(barId);
    if (result.data.success === true && !watchList.includes(id)) {
        setWatchList([...watchList, id]);
    }
}

export const unwatchBar = async (barId, watchList, setWatchList) => {
    const result = await axios.get(`/userbar/unwatch/${barId}`);
    const id = parseInt(barId);
    if (result.data.success === true) {
        setWatchList(watchList.filter(_barId =>  id !== _barId))
    }
}