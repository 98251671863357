import React, { useEffect, useState } from 'react';
import ContentView from '../ContentView';
import useFetchData from '../hooks/useFetchData';
import DeleteResourceModal from './DeleteResourceModal';
import ResourcesContent from './ResourcesContent';
import ResourcesHeader from './ResourcesHeader';
import UploadResourceModal from './UploadResourceModal';
import axios from '../../utils/sharedAxios';
import useDocumentTitle from '../hooks/useDocumentTitle';

// resource page is for general file uploads that are not associated with any one action or review
const defaultSelectedFilter = '-'
const Resources = () => {
  useDocumentTitle('Resources');
  const { data: allResources } = useFetchData('/resources/all');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(defaultSelectedFilter);
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState([])
  const [activeSortKey, setActiveSortKey] = useState();
  const [sortReversed, setSortReversed] = useState(false);
  const [deletingResource, setDeletingResource] = useState(null);
  const [deletingResourceName, setDeletingResourceName] = useState(null);

  const onUploadSuccess = (resource) => {
    setResources([resource, ...resources]);
  };

  const onClickSort = (key) => {
    if (activeSortKey === key) {
      setSortReversed(sortReversed => !sortReversed);
    } else {
      setActiveSortKey(key);
      setSortReversed(key === 'createdAt' ? true : false);
    }
  }

  const onDelete = (uuid) => {
    setDeletingResource(uuid);
  }

  const onConfirmDelete = async () => {
    const uuid = deletingResource;

    await axios.post('/resources/delete', { uuid });
    setDeletingResource(null);
    setDeletingResourceName(null);
    setResources(resources.filter(resource => resource.uuid !== uuid));
  }

  const onCloseDeleteModal = () => {
    setDeletingResource(null);
    setDeletingResourceName(null);
  }

  useEffect(() => {
    setResources(allResources);
  }, [allResources]);

  useEffect(() => {
    if (resources) {
      let refinedResources = [...resources];

      if (selectedFilter !== defaultSelectedFilter) {
        refinedResources = refinedResources.filter(item => {
          if (selectedFilter === 'document') {
            return !item.isTemplate;
          }
          else if (selectedFilter === 'template') {
            return item.isTemplate;
          }
          else {
            return item.type === selectedFilter
          }
        });
      }

      if (activeSortKey) {
        refinedResources.sort((a, b) => {
          if (a[activeSortKey] < b[activeSortKey]) {
            return -1;
          }

          if (a[activeSortKey] > b[activeSortKey]) {
            return 1;
          }

          return 0;
        });

        sortReversed && refinedResources.reverse();
      }

      setFilteredResources(refinedResources);
    }
  }, [resources, selectedFilter, activeSortKey, sortReversed]);

  useEffect(() => {
    if (deletingResource) {
      const { fileName } = resources.find(resource => resource.uuid === deletingResource);
      setDeletingResourceName(fileName);
    }
  }, [resources, deletingResource]);

  return (
    <>
      <ContentView
        headerContent={<ResourcesHeader
          setSelectedFilter={(option) => setSelectedFilter(option.value)}
          setShowUploadModal={setShowUploadModal} />
        }
        mainContent={<ResourcesContent
          onClickSort={onClickSort}
          onDelete={onDelete}
          resources={filteredResources}
          selectedFilter={selectedFilter}
          activeSortKey={activeSortKey}
          sortReversed={sortReversed} />
        }
      />
      {showUploadModal && (
        <UploadResourceModal
          onClose={() => setShowUploadModal(false)}
          onSuccess={onUploadSuccess} />
      )}

      {deletingResource && (
        <DeleteResourceModal fileName={deletingResourceName} onConfirmDelete={onConfirmDelete} onClose={onCloseDeleteModal} />
      )}
    </>
  )
}

export default Resources;