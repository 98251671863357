const getBaseOptions = (bases) => {
  return bases ? bases.sort((a, b) => {
    if (a.baseName < b.baseName) {
      return -1;
    }
    if (a.baseName > b.baseName) {
      return 1;
    }
    return 0;
  }).map(({ id, baseName, state }) => ({ label: `${baseName}, ${state}`, value: id })) : [];
}

export default getBaseOptions;