import React from 'react';
import Modal from '../Modal';
import ModalFooter from '../Modal/ModalFooter';
import Button from '../buttons/Button';
import styles from './Resources.module.scss';

const DeleteResourceModal = ({ fileName, onClose, onConfirmDelete }) => {
  return (
    <Modal className={styles.uploadResourceModal} onClose={onClose} title='Delete Resource'>
      <p>Are you sure you want to delete <i>{fileName}</i>? This action cannot be undone.</p>
      
      <ModalFooter className={styles.buttonRow}>
        <Button type='submit' onClick={onConfirmDelete} primary>DELETE</Button>
        <Button onClick={onClose} secondary>CANCEL</Button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteResourceModal;