import React from 'react';
import { useFormState } from 'react-final-form';
import RichTextDisplay from '../../Form/RichTextDisplay';

const SafNotesDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });

  return (
      <>
        {initialValues?.safNote && <RichTextDisplay html={initialValues.safNote} />}
      </>
  );
}

export default SafNotesDisplay;
