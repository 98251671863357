import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withRouter, Link } from 'react-router-dom';

import styles from './NavBar.module.scss';

const NavLink = (props) => {
  const { to, location, className, children } = props;
  const active = location.pathname.startsWith(to);

  return (
    <Link to={to} className={cn(styles.navlink, className, { [styles.active]: active })}>
      {children}
    </Link>
  );
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired
};

export default withRouter(NavLink);
