import { useField } from 'react-final-form';

// final form multi-field
// based on this idea: https://codesandbox.io/s/pyrwplknom
const useFields = (names, config, fieldsState) => {
  if (!names.length) {
    return fieldsState || {};
  }

  const [name, ...remainingNames] = names || [];
  const fieldState = useField(name, config, fieldsState);

  return useFields(remainingNames, config, { ...fieldsState, [name]: fieldState });
}

export default useFields;