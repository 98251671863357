import { useState } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Button from '../../../buttons/Button';
import AddButton from '../../../buttons/AddButton';
import CloseButton from '../../../buttons/CloseButton';
import FieldArray from '../../../Form/FieldArray';
import SelectField from '../../../Form/SelectField';
import RadioField from '../../../Form/RadioField';
import Grid from '../../../Grid';
import Box from '../../../Box';
import axios from '../../../../utils/sharedAxios';
import useCachedData from '../../../hooks/useCachedData';
import styles from '../../Reporting.module.scss'
import Spinner from '../../../Spinner';
import {downloadCsv} from '../../../../utils/download.js'

const templateColumns = [2, '4fr 1fr'];
const initialValues = {
  allActions: 1
};

const CongressionalEngagementReport = ({ barOptions }) => {
  const { data: stateOptions } = useCachedData('/content/states');
  const [loading, setLoading] = useState(false);

  const onFormSubmit = async (values) => {
    setLoading(true);
    const body = {};

    if (values.allActions === 0 || values.allActions === 1) {
      body.bars = values.bars ? values.bars.map(({ bar }) => bar.value) : [];
      const result = await axios.post('/reports/congressionalByBar', body);
      if(result && result.data && typeof result.data === 'string'){
        downloadCsv('report.csv', result.data);
      }
    }

    if (values.allActions === 2 && values.states) {
      body.states = values.states.map(({ state }) => state.value);
      const result = await axios.post('/reports/congressionalByStates', body);
      if(result && result.data && typeof result.data === 'string'){
        downloadCsv('report.csv', result.data);
      }
    }

    // await axios.post('/reports/actionAgingReport', body);
    setLoading(false);
  };

  const validate = (values) => {
    const errors = {};

    if (values.allActions === 0 && (!values.bars || values.bars.every(item => !item?.bar?.value))) {
      errors._error = 'No actions selected';
    }

    if (values.allActions === 2 && (!values.states || values.states.every(item => !item?.state?.value))) {
      errors._error = 'No state selected';
    }

    return errors;
  }

  return (
    <>
      <h3 className={styles.subTitle}>Congressional Engagement Report</h3>
      <p className={styles.bodyText}>Generates a report that shows the lists of all Congressional Engagements.</p>

      <Form onSubmit={onFormSubmit} mutators={{ ...arrayMutators }} initialValues={initialValues} subscription={{ submitting: true, values: true, invalid: true  }} validate={validate} destroyOnUnregister>
        {({ form, handleSubmit }) => {
          const { invalid, submitting, values } = form.getState();

          return (
            <>
              <RadioField className={styles.radio} name='allActions' horizontal={false} options={[
                {label: 'All Actions', value: 1},
                {label: 'By State', value: 2},
              ]} required />

              {values.allActions === 2 && (
                <FieldArray name='states' className={styles.fieldArray}>
                  {({ fields, add, remove, isMinLength }) => (
                    <>
                      {fields.map((name, index) => (
                        <Grid key={name} columns={templateColumns} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
                          <Box><SelectField name={`${name}.state`} options={stateOptions} /></Box>
                        </Grid>
                      ))}
                      <AddButton onClick={()=> add()} secondary>Add an State</AddButton>
                    </>
                  )}
                </FieldArray>

              )}

              <RadioField className={styles.radio} name='allActions' horizontal={false} options={[
                {label: 'Select Actions', value: 0},
              ]} required />

              {values.allActions === 0 && (
                <FieldArray name='bars'>
                  {({ fields, add, remove, isMinLength }) => (
                    <>
                      {fields.map((name, index) => (
                        <Grid key={name} columns={templateColumns} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
                          <Box><SelectField name={`${name}.bar`} options={barOptions} /></Box>
                        </Grid>
                      ))}
                      <AddButton onClick={()=> add()} secondary>Add an Action</AddButton>
                    </>
                  )}
                </FieldArray>

              )}



              <Button className={styles.submit} onClick={handleSubmit} primary disabled={invalid || submitting}>GENERATE AND DOWNLOAD</Button>
            </>
          );
        }}
      </Form>
      <Spinner on={loading} />
    </>
  );
}

export default CongressionalEngagementReport;