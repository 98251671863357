import React, {useState, useEffect, useContext} from 'react';
import styles from './Comments.module.scss';
import axios from '../../../utils/sharedAxios';
import TextArea from '../../shared/TextArea';
import Button from '../../buttons/Button';
import AddButton from '../../buttons/AddButton';
import { AuthContext } from '../../Utility/Authentication';

const Comments = (props) => {
  const {user} = useContext(AuthContext);
  const {barId, phase = 0, step = 0, barSubmitter, isBarDraft} = props;
  const [comments, setComments] = useState([]);
  const [edittingValues, setEdittingValues] = useState({});
  const [isEditting, setIsEditting] = useState({});
  const [newComment, setNewComment] = useState('');
  const [isAddingComment, setIsAddingComment] = useState(false);

  useEffect(()=> {
    axios.get(`/comments/thread/${barId}/${phase}/${step}`)
      .then(result => {
          if (!result.data.errors) {
            setComments(result.data);
          }
      }).catch(err => {
        console.log(err);
      });
  }, [barId, phase, step]);

  const deleteComment = (commentId) => {
    axios.delete(`/comments/${commentId}`).then(result => {
        if (!result.data.errors) {
            const newComments = comments.map((c)=> {
                if(c.id !== commentId){
                    return c
                }
                return {...c, deleted: true}
            })
            setComments(newComments);
        }
    }).catch(err => {
        console.log(err);
    });
  }

  const updateComment = (commentId) => {
      axios.post('/comments/edit', {id: commentId, comment: edittingValues[commentId]}).then(result => {
        if (!result.data.errors) {
            const newComments = comments.map((c)=> {
                if(c.id !== commentId){
                    return c
                }
                return {...c, comment: edittingValues[commentId]}
            })
            setComments(newComments);
            setIsEditting({isEditting, [commentId]: false});
        }
    })
    .catch(err => {
        console.log(err);
    });
  }

  const addComment = () => {
    axios.post('/comments/add', {comment: newComment, barId, phase, step}).then(result => {
        if (!result.data.errors) {
            const newComment = {
                ...result.data,
                updatedAt: new Date()
            }
            setComments([...comments, newComment])
            setNewComment('');
            setIsAddingComment(false);
        }
    })
    .catch(err => {
        console.log(err);
    });
  }
  const canAddComments = isBarDraft ? user.id === barSubmitter || user.role === 'SAM' : true;
  return(
    <div className={styles.comments}>
      <div className={styles.commentsHeader}>Comments</div>
      {comments.map((comment)=>{
          const canEdit = (comment.userId === user.id && comment.phase == phase && comment.phase == step) || user.role === 'SAM';
          const date = new Date(comment.updatedAt)

          if (isEditting[comment.id]) {
              return (
                <div key={comment.id} className={styles.comment}>
                    <TextArea value={edittingValues[comment.id]} onChange={(e) => setEdittingValues({...edittingValues, [comment.id]: e.target.value})}/>

                    <div className={styles.buttonRow}>
                        <Button className={styles.saveCommentButton} onClick={() => updateComment(comment.id)} primary>Save</Button>
                        <Button secondary onClick={() => {
                            setIsEditting({...isEditting, [comment.id]: false});
                            setEdittingValues({...edittingValues, [comment.id]: comment.comment})
                        }}>CANCEL</Button>
                    </div>
                </div>
              )
          }

          return (
            <div key={comment.id} className={styles.comment}>
                <div className={styles.commentBox}>
                    {comment.deleted ? (
                        <div>Comment has been removed</div>
                    ) : (
                        <>
                            {canEdit && (
                                <span className={styles.commentBoxActions}>
                                    <Button onClick={() => {
                                        setIsEditting({...isEditting, [comment.id]: true});
                                        setEdittingValues({...edittingValues, [comment.id]: comment.comment});
                                        }} secondary>Edit</Button>
                                    <span>{' | '}</span>
                                    <Button onClick={()=> deleteComment(comment.id)} secondary>Delete</Button>
                                </span>
                            )}
                            <p className={styles.commentBody}>{comment.comment}</p>
                            <div className={styles.commentFooter}>{`${date.toDateString()} at ${date.toLocaleTimeString()} by ${comment.firstName} ${comment.lastName}`}</div>
                        </>
                    )}
                </div>
            </div>
      )})}
      {isAddingComment &&
      <div className={styles.buttonRow}>
            <TextArea onChange={(e) => setNewComment(e.target.value)}/>
            <div className={styles.buttonRow}>
                <Button className={styles.saveCommentButton} onClick={addComment} primary>Save</Button>
                <Button secondary onClick={() => {setIsAddingComment(false); setNewComment('')}}>CANCEL</Button>
            </div>
      </div>}

      {canAddComments && !isAddingComment && <AddButton  onClick={() => setIsAddingComment(true)} secondary>Add Comment</AddButton>}
  </div>
  );
}

export default Comments;
