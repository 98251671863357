import React from 'react';
import cn from 'classnames';
import { FormSpy } from 'react-final-form';
import { dictionaryValue } from '../../Dictionary/DictionaryContext'
import formStyles from '../../Form/Form.module.scss';
import styles from './BasicInformation.module.scss';
import DisplayDate from '../../DisplayDate';
import dateFormats from '../../../constants/dateFormats';

const BasicInformationDisplay = ({ status, submittedDate }) => {
  return (
    <FormSpy subscription={{ initialValues: true }}>
      {({ initialValues }) => (
        <>
          {initialValues.title && (
            <div className={styles.titleWrapper}>
              <h3 className={cn(styles.title, formStyles.sectionHeading)}>{initialValues.title}</h3>
            </div>
          )}

          {initialValues.leadBasingOffice && (
            <>
              <h4>Lead Basing Office</h4>
              <p>{dictionaryValue(initialValues.leadBasingOffice)}</p>
            </>
          )}

          {initialValues.proponent && (
            <>
              <h4>Proponent</h4>
              <p>{dictionaryValue(initialValues.proponent)}{initialValues.proponentSecondaryField && (<>: {dictionaryValue(initialValues.proponentSecondaryField)}</>)}</p>
            </>
          )}

          {initialValues.typeOfAction && (
            <>
              <h4>Type of Action</h4>
              <p>{dictionaryValue(initialValues.typeOfAction)}: {dictionaryValue(initialValues.typeOfActionSecondaryField)}</p>
            </>
          )}

          {(
            initialValues.proponentPointOfContactName ||
            initialValues.proponentPointOfContactOrganization ||
            initialValues.proponentPointOfContactPhoneNumber ||
            initialValues.proponentPointOfContactEmail
            ) && (
              <>
                <h4>Requestor</h4>
                <p>{initialValues.proponentPointOfContactName}</p>
                <p>{initialValues.proponentPointOfContactOrganization}</p>
                <p>{initialValues.proponentPointOfContactPhoneNumber}</p>
                <p>{initialValues.proponentPointOfContactEmail}</p>
              </>
          )}

          {initialValues.anticipatedEnvironmentalAnalysis && (
            <>
              <h4>Anticipated level of the required Environmental Analysis</h4>
              <p>{initialValues.anticipatedEnvironmentalAnalysis}</p>
            </>
          )}
          {!isNaN(initialValues.isCommandedByGeneral) && (
            <>
              <h4>Is the unit commanded by a General Officer?</h4>
              <p>{initialValues.isCommandedByGeneral ? 'Yes' : 'No'}</p>
            </>
          )}

          {!isNaN(initialValues.isMajorWeaponSystem) && (
            <>
              <h4>Does this action involve the establishment or move of a Major Weapon System?</h4>
              <p>{initialValues.isMajorWeaponSystem ? 'Yes' : 'No'}</p>
            </>
          )}

          {submittedDate && status !== 'draft' && (
            <>
              <h4>Submitted date</h4>
              <p><DisplayDate date={submittedDate} format={dateFormats.long} /></p>
            </>
          )}
        </>
      )}
    </FormSpy>
  )
}

export default BasicInformationDisplay;
