import React from 'react';
import useCachedData from '../../hooks/useCachedData';
import BackupSlide from './BackupSlide';

const AdditionalInfo = (props) => {
  const { data: keys } = useCachedData('/content/barViewKeys');
  
  return (
    <BackupSlide titleKey={keys && keys.additionalInfo} {...props} />
  );
}

export default AdditionalInfo;
