import React, { useState, useEffect } from 'react';
import Select from '../Select';
import Button from '../buttons/Button';
import stripValuesFromObject from '../../utils/stripValuesFromObject';
import styles from './BasingActionFilters.module.scss'
import useFetchSAFOptions from '../hooks/useFetchSAFOptions';

// Filters based on the properties of basing actions
// use onFilterChange to communicate the active filters to another component
// this is NOT used on BarLanding because the matrix section is not compatible yet
const BasingActionFilters = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({});
  const safActionOfficers = useFetchSAFOptions();

  const handleFilterChange = filter => option => {
    setFilters({
      ...filters,
      [filter]: option
    });
  };

  const resetFilters = () => setFilters({});

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(stripValuesFromObject({...filters}));
    }
  }, [filters, onFilterChange]);

  return(
    <div className={styles.container}>
      <Select className={styles.filter} round onChange={handleFilterChange('phase')} value={filters.phase} fieldDataEndpoint='/content/phaseOptions' placeholderText='All Phases' />
      <Select className={styles.filter} round onChange={handleFilterChange('step')} value={filters.step} fieldDataEndpoint='/content/stepOptions' placeholderText='All Steps' />
      <Select className={styles.filter} round onChange={handleFilterChange('status')} value={filters.status} fieldDataEndpoint='/content/status' placeholderText='All Statuses' />
      <Select className={styles.filter} round onChange={handleFilterChange('leadBasingOffice')} value={filters.leadBasingOffice} fieldDataEndpoint='/content/leadBasingOffices' placeholderText='All Lead Basing Offices' />
      <Select className={styles.filter} round onChange={handleFilterChange('actionOfficer')} value={filters.actionOfficer} options={safActionOfficers} placeholderText='All SAF AOs' />

      <Button onClick={resetFilters} secondary>Reset Filters</Button>
    </div>
  )
}

export default BasingActionFilters;

