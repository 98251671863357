import cn from 'classnames';
import { useLocation } from "react-router";
import Anchor from "../Anchor";
import styles from './NavBar.module.scss';

const MeatBallMenuItem = ({ children, href }) => {
  const location = useLocation();
  const active = location.pathname.startsWith(href);

  return <Anchor className={cn(styles.menuItem, { [styles.menuItemActive]: active })} href={href} light>{children}</Anchor>;
}

export default MeatBallMenuItem;