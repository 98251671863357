import isEmpty from 'lodash/isEmpty';
import { uploadDocs, uploadImages } from './uploadImages';
import formatPayload from './formatPayload';
import axios from './sharedAxios';
import { imageFields, docFields } from '../constants/uploadFields';
import formatIncomingValues from './formatIncomingValues';

// updates bar data for CreateBar and BarDetail
const barUpdate = async ({ barId, barStatus, phase, values, expeditionLevel }) => {
  const imageUploads = [], docUploads = [];

  imageFields.forEach((field) => {
    _.get(values, `${field}.previewImage`) && imageUploads.push(field);
  })
  imageUploads.length > 0 && await uploadImages(values, barId, phase, imageUploads);

  docFields.forEach((field) => {
    _.get(values, field) && docUploads.push(field);
  })
  docUploads.length > 0 && await uploadDocs(values, barId, phase, docUploads);

  const formattedPayload = formatPayload(values, barId, barStatus, expeditionLevel);
  try {
    const result = await axios.post('/bars/update', formattedPayload)
      .then(response => {
        if (response.data && response.data.error) {
          throw new Error(response.data.error);
        }

        return response;
      });

    // congressional dates
    if (formattedPayload.barInfo.congressionalDates) {
      await axios.post('/bars/cong-dates', { id: barId, dates: formattedPayload.barInfo.congressionalDates })
        .then(congressionalDatesResponse => {
          result.data.barInfo.congressionalDates = congressionalDatesResponse.data;
        })
    }
    const packages = {};

    if (formattedPayload.barInfo.twoLetterCoord){
        packages.twoLetterCoord = formattedPayload.barInfo.twoLetterCoord;
    }

    if(formattedPayload.barInfo.basingDecisionPackage){
      packages.basingDecisionPackage = formattedPayload.barInfo.basingDecisionPackage;
    }

    if(formattedPayload.barInfo?.signedDecisionPackage){
      packages.signedDecisionPackage = formattedPayload.barInfo.signedDecisionPackage;
    }

    if (formattedPayload.barInfo.congressionalEngagementDocuments) {
      packages.congressionalEngagementDocuments = formattedPayload.barInfo.congressionalEngagementDocuments;
    }

    if (!isEmpty(packages)) {
      await axios.post('/bars/updatePackages', {...packages, id: barId}).then(p => {
        result.data.barInfo.congressionalEngagementDocuments = p.data?.congressionalEngagementDocuments;
      })
      // .catch(err => {
      //     console.log(err)
      // });
    }

    return formatIncomingValues(result.data.barInfo);
  } catch(err) {
    console.error(err);
    return false;
  }

}

export default barUpdate;