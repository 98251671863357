import phaseAbbreviations from '../constants/phaseAbbreviations';

// formats basing action data coming from the api so that they are shaped to work with the ui
// formatters here should have
// an opposing formatter in formatPayload.js
const formatters = {
  weaponSystem: (payload) => {
    if (payload.weaponSystem) {
      payload.weaponSystem.reduce((acc, value) => {
        const key = value.currentOrProposed === 'current' ? 'currentWeaponsSystems' : 'proposedWeaponsSystems';

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(value);

        return acc;
      }, payload)
    }

    delete payload.weaponSystem;

    return payload;
  },

  costRequirements: (payload) => {
    if (payload.costRequirements) {
      const costRequirements = {};
      const getItemIsCustomRecurring = (item) => {
        return item.type === 'customRecurring';
      }
      const getItemType = (item) => {
        if (item.type === 'customRecurring' || item.type === 'customOneTime') {
          return 'custom'
        }
        return item.type;
      }

      payload.costRequirements.reduce((acc, cost) => {
        const isRecurring = getItemIsCustomRecurring(cost);
        const type = getItemType(cost);
        if (!acc[type]) {
          acc[type] = [];
        }

        acc[type].push({
          ...cost,
          isRecurring
        });

        return acc;
      }, costRequirements);

      payload.costRequirements = costRequirements;
    }

    return payload;
  },

  missionCriteria: (payload) => {
    if (payload.missionCriteria) {
      const newMissionCriteria = {};

      payload.missionCriteria.reduce((acc, {type, ...rest}) => {
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push({ ...rest});

        return acc;
      }, newMissionCriteria);

      payload.missionCriteria = newMissionCriteria;
    }

    return payload;
  },

  enterpriseComponents: (payload) => {
    const enterprise = payload.enterpriseComponents;
    if(enterprise &&
        (enterprise.missionFileKey || enterprise.capacityFileKey || enterprise.environmentalFileKey || enterprise.costFileKey || enterprise.somfFileKey || enterprise.componentScoresFileKey)){
        const newObj = {
        mission: {fileKey: enterprise.missionFileKey}, capacity: {fileKey: enterprise.capacityFileKey},
        environmental: {fileKey: enterprise.environmentalFileKey}, cost: {fileKey: enterprise.costFileKey},
        somf: {fileKey: enterprise.somfFileKey},
        componentScores: {fileKey: enterprise.componentScoresFileKey}};
        payload.enterpriseComponents = newObj;
    }
    return payload;
  },

  summaryOfFindings: (payload) => {
    if (payload.summaryOfFindings) {
      const requirementIds = [];

      payload.siteSurveyRequirements.forEach(({ id, subRequirements = [] }) => {
        id && requirementIds.push(id);

        if (subRequirements.length) {
          subRequirements.forEach(subReq => {
            subReq.id && requirementIds.push(subReq.id);
          });
        }
      });

      payload.summaryOfFindings = payload.summaryOfFindings.reduce((acc, { baseId, requirementId, requirementIndex, ...rest }) => {
        const uniqueKey = `${requirementId ? requirementId : requirementIndex}~${baseId}`;

        acc[uniqueKey] = {
          ...rest,
          requirementId,
          baseId,
        };
        return acc;
      }, {});
    }

    return payload;
  },

  siteSurveyRequirements: (payload) => {
    if (payload.siteSurveyRequirements) {
      const requirements = {};

      payload.siteSurveyRequirements.reduce((acc, requirement) => {
        if (requirement) {
          const { type } = requirement;

          if (!acc[type]) {
            acc[type] = [];
          }
          acc[type].push({ ...requirement});
        }

        return acc;
      }, requirements);

      payload.siteSurveyRequirements = requirements;
    }

    return payload;
  },

  backups: (payload) => {
    if(payload.backups && _.isArray(payload.backups)){
      const newObj = {};
      payload.backups.map((backup) => {
        newObj[backup.type] = {...backup};
      });
      payload.backups = newObj;
    }
  },

  eisSummaryOfFindings: (payload) => {
    if(payload.eisSummaryOfFindingsDoc){
      payload.eisSummaryOfFindings = {fileKey: payload.eisSummaryOfFindingsDoc}
    }
    return payload;
  },

  congressionalDates: (payload) => {
    if (payload?.congressionalDates) {
      const congressionalDatesObject = {};
      payload.congressionalDates.reduce((acc, item) => {
        const key = phaseAbbreviations[item.phase].key;
        acc[key] = {...item};
        return acc;
      }, congressionalDatesObject)

      payload.congressionalDates = congressionalDatesObject;
    }
    return payload;
  },

  weAreHere: (payload) => {
    if (payload.weAreHere) {
      payload.weAreHere = { fileKey: payload.weAreHere };
    }

    return payload;
  }
};

/**
 * formatIncomingValues takes the incoming response and
 * applies formatters to specific areas where Backend and UI data shapes differ.
 */
const formatIncomingValues = (payload) => {
  if (payload) {
    [
      formatters.weaponSystem,
      formatters.costRequirements,
      formatters.missionCriteria,
      formatters.enterpriseComponents,
      formatters.summaryOfFindings,
      formatters.siteSurveyRequirements,
      formatters.backups,
      formatters.eisSummaryOfFindings,
      formatters.congressionalDates,
      formatters.weAreHere
    ].forEach(rule => rule(payload));

    return payload;
  }
}

export default formatIncomingValues;
