import React, { useEffect, useState } from 'react';
import Header from '../../Header';
import Button from '../../buttons/Button';
import EditButton from '../../buttons/EditButton';
import StepNav from './StepNav';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useCachedData from '../../hooks/useCachedData';
import styles from './BarDetail.module.scss';
import axios from '../../../utils/sharedAxios';
import EditStatusModal from './EditStatusModal';
import SideNav from '../../SideNav';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import formatIncomingValues from '../../../utils/formatIncomingValues';
import {viewMapping} from '../../../constants/steps';
import barComponentsMap from '../../../constants/barComponentsMap';
import { unwatchBar, watchBar } from '../../../utils/watchBar';
import Flag from '../../shared/flagIcon';
import cn from 'classnames';
import ContentView from '../../ContentView';
import FormViewWrapper from '../../FormViewWrapper';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import Comments from '../Comments';
import useRedirectUnauthorizedUsers from '../../hooks/useRedirectUnauthorizedUsers';
import Spinner from '../../Spinner';
import barUpdate from '../../../utils/barUpdate';
import SafReviewCheckbox from '../../SafReviewCheckbox';
import AuthorizedContent from '../../AuthorizedContent';
import Tooltip from '../../Tooltip';

const BarDetail = () => {
  useDocumentTitle('Basing Action Detail');
  const history = useHistory();
  const { id } = useParams();
  useRedirectUnauthorizedUsers({ barId: id });
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [shouldLoadBar, setShouldLoadBar] = useState(true);
  const { data: keys } = useCachedData('/content/barViewKeys');
  const { data: phaseOptions } = useCachedData('/content/phaseOptions');
  const { data: stepOptions } = useCachedData('/content/stepOptions');
  const [fetchingBarOverview, setFetchingBarOverview] = useState(false);
  const [barOverview, setBarOverview] = useState(null);
  const { currentPhase, currentStep, isReviewedBySaf, owningOffice, reviewDates, snapshots, status } = barOverview || {};
  const [bar, setBar] = useState({});
  const [phaseInView, setPhaseInView] = useState(1);
  const [stepInView, setStepInView] = useState(1);
  const [showEditStatusModal, setShowEditStatusModal] = useState(false);
  const expeditionLevel = barOverview?.expeditionLevel || 1;
  const [activeView, setActiveView] = useState('');
  const [permissions, setPermissions] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [watchedBars, setWatchedBars] = useState([]);
  const [barStatus, setBarStatus] = useState(null);
  const [initialStateHasBeenApplied, setInitialStateHasBeenApplied] = useState(false);
  const [submittedDate, setSubmittedDate] = useState(null);

  const fetchBarOverview = async () => {
    if (!fetchingBarOverview) {
      setFetchingBarOverview(true);
      const { data } = await axios.get(`/bars/overview/${id}`);
      data && setBarOverview(data);
      setFetchingBarOverview(false);
    }
  };

  useEffect(() => {
    id && fetchBarOverview();
  }, [id]);

  useEffect(() => {
    barOverview?.currentPhase === 0 && history.replace(`/bars/create/${barOverview.id}`);
  }, [barOverview]);

  useEffect(()=>{
    if(keys && barOverview) {
      const params = new URLSearchParams(search);

      const phase = (!initialStateHasBeenApplied && params.has('phase')) ? parseInt(params.get('phase')) : barOverview.currentPhase;
      const step = (!initialStateHasBeenApplied && params.has('step')) ? parseInt(params.get('step')) : barOverview.currentStep;
      let nextActiveView = keys.weAreHere; // should this still be stage setter?

      if (!initialStateHasBeenApplied && params.has('activeView')) {
        nextActiveView = params.get('activeView');
      }

      if (initialStateHasBeenApplied && activeView) {
        nextActiveView = activeView;
      }

      setPhaseInView(phase);
      setStepInView(step);
      setActiveView(nextActiveView);
      setInitialStateHasBeenApplied(true);
    }
  }, [barOverview, keys, search, initialStateHasBeenApplied])

  useEffect(()=> {
    (async() => {
      const watchedBars = await axios.get('/userbar/watching')
      setWatchedBars(watchedBars.data)
    })();
  }, []);

  useEffect(()=>{
    if (shouldLoadBar) {
      const func = async () => {
        await axios.get(`/bars/find/${id}`)
          .then(async ({ data: bar }) => {
              setBar(formatIncomingValues(bar.barInfo));
              setBarStatus(bar.status);
              setSubmittedDate(bar.submittedDate);
              const permissions = await axios.get('/content/getBarPermissions', {
                params: {
                  createdBy: bar.createdBy,
                  owningOffice: bar.owningOffice
                }
              });
              setPermissions(permissions.data);
              setIsLoading(false);
              setShouldLoadBar(false);
          })
          .catch(() => {
            history.replace('/bars/invalid-action');
          });
      }
      func();
    }
  }, [shouldLoadBar])

  useEffect(() => {
    if (snapshots && snapshots.length) {
      const foundSnapshot = snapshots.find(snapshot => snapshot.phase === phaseInView && snapshot.step === stepInView);
      if (foundSnapshot) {
        foundSnapshot.snapshotId && axios.get(`/bars/snapshot/${foundSnapshot.snapshotId}`)
          .then(result => {
            result.data && setBar(formatIncomingValues(result.data));
          });
      }
    }

  }, [phaseInView, stepInView])

  const changePhaseInView = (n) => {
    setPhaseInView(n);
  }

  const changeStepInView = (n) => {
    setStepInView(n);
  }

  const onMoveSuccess = () => {
    fetchBarOverview();
  }

  const renderPhaseTitle = (n) => {
    let phase = null;
    if (phaseOptions && !isNaN(n)) {
      phase = phaseOptions.options[n - 1]?.label;
    }

    return phase;
  }

  const renderStepTitle = (n) => {
    let step = null;
    if (stepOptions && !isNaN(n)) {
      step = stepOptions.options[n - 1]?.label;
    }

    return step;
  }

  const viewedPhaseText = renderPhaseTitle(phaseInView);

  const editBtnTooltip = 'Edit the current Phase, Step, Status, or SAF Action Officer';

  const renderLeftContent = () => (
    <div className={styles.leftContent}>
      <h3 className={styles.phaseStep}>{renderPhaseTitle(currentPhase)}{' / '}{renderStepTitle(currentStep)} <AuthorizedContent authorizedRoles='SAM'><Tooltip content={editBtnTooltip}><EditButton className={styles.editButton} onClick={() => setShowEditStatusModal(true)} /></Tooltip></AuthorizedContent></h3>



      {barOverview && <h1 className={styles.title} title={barOverview.title}>{barOverview.title}</h1>}
      {viewedPhaseText && <p className={styles.viewedPhase}>Viewed Phase: {viewedPhaseText}</p>}
    </div>
  );

  const renderRightContent = () => (
    <div className={styles.rightContent}>
      {watchedBars.includes(parseInt(id)) || watchedBars.includes(id) ?
        <Button className={styles.watchButtonPadding} secondaryAlt onClick={() => {unwatchBar(id, watchedBars, setWatchedBars)}}><Flag className={cn(styles.flagIcon, styles.selectedFlag)}/> WATCHED</Button> :
        <Button className={styles.watchButtonPadding} secondaryAlt onClick={() => {watchBar(id, watchedBars, setWatchedBars)}}><Flag className={styles.flagIcon} /> WATCH</Button>}

      <SafReviewCheckbox barId={id} isReviewedBySaf={isReviewedBySaf} owningOffice={owningOffice} />
    </div>
  );

  const deleteFile = (docId) => {
    setDeleteFiles([...deleteFiles, docId]);
  }

  const handleCancel = (form) => () => {
      form.restart();
      setTimeout(() => setIsEditing(false));
      setDeleteFiles([]);
  }

  const onSave = async (values) => {
    const latestInitialValues = await barUpdate({ values, barId: id, barStatus, phase: phaseInView, expeditionLevel });
    if (latestInitialValues) {
      setBar(latestInitialValues);
      deleteFiles.length > 0 && await axios.post('/documents/deleteDocuments', deleteFiles)
      fetchBarOverview();
      setIsEditing(false);
    } else {
      console.warn('Something went wrong. BAR not updated.')
    }
  }

  const ActiveViewComponent = (activeView && permissions[keys[activeView]]?.canView) ? barComponentsMap[activeView] : null;
  const canEdit = (phaseInView === currentPhase && stepInView === currentStep) && activeView && permissions[keys[activeView]]?.canEdit;
  const editing = canEdit && isEditing;
  const renderActiveView = () => ActiveViewComponent && <ActiveViewComponent isEditing={editing} barPhase={currentPhase} deleteFile={deleteFile} expeditionLevel={expeditionLevel} status={barStatus} submittedDate={submittedDate} setActiveView={setActiveView}/>;
  const pageTitle = viewMapping && activeView && viewMapping[activeView].pageTitle;
  return (
    <>
      <Form initialValues={bar} mutators={{ ...arrayMutators }} onSubmit={onSave} subscription={{ submitting: true }}>
        {({ form, handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
              <ContentView
                headerContent={
                  <Header
                  leftContent={renderLeftContent()}
                  rightContent={renderRightContent()}>
                  <StepNav
                    changePhaseInView={changePhaseInView}
                    changeStepInView={changeStepInView}
                    editing={editing}
                    phaseInView={phaseInView}
                    reviewDates={reviewDates}
                    snapshots={snapshots}
                    stepInView={stepInView} />
                </Header>
                }
                sidebarContent={expeditionLevel && <SideNav bar={{title: bar.title, currentPhase, currentStep, id}} editing={editing} expeditionLevel={expeditionLevel} phase={phaseInView} step={stepInView} activeView={activeView} setActiveView={setActiveView} />}
                mainContent={
                  <FormViewWrapper
                    activeView={renderActiveView}
                    canEdit={canEdit}
                    handleCancel={handleCancel(form)}
                    handleEdit={() => setIsEditing(true)}
                    isEditing={editing}
                    title={pageTitle}>
                    {!editing && <Comments barId={id} phase={phaseInView} step={stepInView} />}
                  </FormViewWrapper>
                } />
            <Spinner on={submitting || isLoading} />
          </form>
        )}
      </Form>

      {showEditStatusModal && <EditStatusModal
        barId={id}
        initialValues={{
          assignee: barOverview.assignee,
          expeditionLevel,
          phase: phaseOptions.options[currentPhase - 1].value,
          step: stepOptions.options[currentStep - 1].value,
          status
        }}
        expeditionLevel={expeditionLevel}
        onClose={() => setShowEditStatusModal(false)}
        onMoveSuccess={onMoveSuccess} />
      }
    </>
  );

}

export default BarDetail;