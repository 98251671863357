import React from 'react';
import { useFormState } from 'react-final-form';
import useCachedData from '../../hooks/useCachedData';
import RichTextDisplay from '../../Form/RichTextDisplay';
import DisplayDate from '../../DisplayDate';
import dateFormats from '../../../constants/dateFormats';
import getBaseName from '../../../utils/getBaseName';
import styles from '../../Form/Form.module.scss';


const CandidateDecisionDisplay = () => {
  const { initialValues: { candidateBaseApprovalDate, candidateBases } = {} } = useFormState({ subscription: { initialValues: true } });
  const { data: bases } = useCachedData('/content/bases');

  return (
    <>
      {candidateBaseApprovalDate && <h3 className={styles.sectionHeading}>Dept of the Air Force approved on <DisplayDate date={candidateBaseApprovalDate} format={dateFormats.short} /></h3>}
      {candidateBases && (
        <ul>
          {candidateBases.map(({ baseId, rationale }, index) => (
            <li key={index}>
              <p>{getBaseName(baseId, bases)}</p>
              <ul>
                <li>
                  <RichTextDisplay html={rationale}/>
                </li>
              </ul>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

export default CandidateDecisionDisplay;
