import { useEffect, useState } from 'react';
import Anchor from '../../../Anchor';
import Button from '../../../buttons/Button';
import CardWrapper from '../../../CardWrapper';
import Modal from '../../../Modal';
import ModalFooter from '../../../Modal/ModalFooter';
import DenyRequestModal from './DenyRequestModal';
import EditRequestModal from './EditRequestModal';
import RequestCard from './RequestCard';
import { adminViewKeys } from '../../../../constants/adminViewMapping';
import axios from '../../../../utils/sharedAxios';
import styles from '../../Admin.module.scss';

const NewAccountRequests = ({ requests, setActiveView, setRequests }) => {
  const [editingId, setEditingId] = useState(null);
  const [editingRequest, setEditingRequest] = useState(null);
  const [denyingUserId, setDenyingUserId] = useState(null);
  const [showApprovedSuccess, setShowApprovedSuccess] = useState(false);
  const [showDeniedSuccess, setShowDeniedSuccess] = useState(false);

  const removeRequestFromView = (userId) => setRequests(requests.filter(request => request.id !== userId));

  const openEditModal = (userId) => setEditingId(userId);

  const closeEditModal = () => setEditingId(null);

  const openDenyRequestModal = (userId) => setDenyingUserId(userId);

  const closeDenyRequestModal = () => setDenyingUserId(null);

  const handleApproveRequest = async (values) => {
    const { group, office = null, roleId, userId } = values;
    await axios.post('/admin/approve-user', { userId, group: group.value, roleId: roleId.value, office: office?.value });
    removeRequestFromView(userId);
    setShowApprovedSuccess(true);
  }

  const handleDenyRequest = async (values) => {
    await axios.post('/admin/deny-user', { userId: denyingUserId, denyMessage: values.denyMessage })
      .then(() => {
        removeRequestFromView(denyingUserId);
        setDenyingUserId(null);
        setShowDeniedSuccess(true);
      });
  }

  const handleEditRequest = async (values) => {
    await axios.post('/users/edit-user', { ...values })
      .then(() => {
        const updatedRequests = [...requests];
        const index = updatedRequests.findIndex(request => request.id === editingId);

        updatedRequests[index] = { ...values };
        setRequests(updatedRequests);
        closeEditModal();
      });
  }

  useEffect(() => {
    if (editingId) {
      const req = requests.find(request => request.id === editingId);
      req && setEditingRequest(req);
    } else {
      setEditingRequest(null);
    }
  }, [editingId]);

  return (
    <>
      <h3 className={styles.subTitle}>Approve or Deny requests for new user accounts</h3>

      <p className={styles.bodyText}>Assign new users to a group and a role. Groups are managed in the <Anchor onClick={() => setActiveView(adminViewKeys.contactsGroups)}>Contacts</Anchor> section of Admin.</p>

      <p className={styles.tinyHeading}>Roles</p>
      <p className={styles.bodyText}><b>SAF AO:</b> That’s you! Can create, view, edit, and manage any basing action, schedule reviews, generate read-ahead materials, upload resources, generate reports, and perform admin functions (like this).</p>
      <p className={styles.bodyText}><b>Leadership:</b> Can view any basing action and generate reports.</p>
      <p className={styles.bodyText}><b>Basing Office AO:</b> Can submit basing actions, edit actions created by their basing office, and view details for actions they have equity in.</p>
      <p className={styles.bodyText}><b>Cross-Functional:</b> Can view details for all actions, with limited editing abilities.</p>

      {requests.length > 0 ? (
        <CardWrapper>
          {requests.map(request => (
            <RequestCard
              key={request.id}
              handleApproveRequest={handleApproveRequest}
              openDenyRequestModal={openDenyRequestModal}
              openEditModal={openEditModal}
              request={request} />
          ))}
        </CardWrapper>
      ) : (
        <h3>There are no new account requests</h3>
      )}

      {editingId && (
        <EditRequestModal
          handleEditRequest={handleEditRequest}
          onClose={closeEditModal}
          request={editingRequest} />
      )}

      {denyingUserId && (
        <DenyRequestModal
          handleDenyRequest={handleDenyRequest}
          onClose={closeDenyRequestModal} />
      )}

      {showApprovedSuccess && <Modal className={styles.modalMedium} onClose={() => setShowApprovedSuccess(false)} title='Account Approved!'>
        <p>We will let the submitter know you approved their account.</p>
        <ModalFooter>
          <Button onClick={() => setShowApprovedSuccess(false)} primary>Back to Admin</Button>
        </ModalFooter>
      </Modal>}

      {showDeniedSuccess && <Modal className={styles.modalMedium} onClose={() => setShowDeniedSuccess(false)} title='Account Denied'>
        <p>The submitter has been notified.</p>
        <ModalFooter>
          <Button onClick={() => setShowDeniedSuccess(false)} primary>Back to Admin</Button>
        </ModalFooter>
      </Modal>}
    </>
  );
}

export default NewAccountRequests;