import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { dictionaryValue } from '../../Dictionary/DictionaryContext'
import WeaponsSystemDisplay from './WeaponsSystemDisplay';
import ManpowerDisplay from './ManpowerDisplay';
import RichTextDisplay from '../../Form/RichTextDisplay';
import styles from '../../Form/Form.module.scss'
import useCachedData from '../../hooks/useCachedData';

const StageSetterDisplay = () => {
  const { initialValues = {} } = useFormState({ subscription: { initialValues: true } });
  const { data: missionTypes } = useCachedData('/content/missionTypes');
  const [missionType, setMissionType] = useState(null);

  useEffect(() => {
    if (initialValues.missionType && missionTypes?.options) {
      const foundOption = missionTypes.options.find(option => option.value === initialValues.missionType);
      foundOption?.label && setMissionType(foundOption.label);
    }
  }, [missionTypes, initialValues.missionType])

  return (
    <>
      {(initialValues.needApprovalFrom || initialValues?.AFI_10_503?.length) && (
        <>
          <h3 className={styles.sectionHeading}>Purpose Statement</h3>
          <ul className={styles.purposeStatement}>
            {(initialValues.needApprovalFrom || initialValues.toObtainApprovalOf) && (
              <li>To obtain {dictionaryValue(initialValues.needApprovalFrom) || '[missing data]'} approval of {initialValues.toObtainApprovalOf}</li>
            )}

            {initialValues.AFI_10_503 && initialValues.AFI_10_503.map((def) => (
              <li key={def}>AFI 10-503, para. {dictionaryValue(def)}</li>
            ))}
          </ul>
        </>
      )}


      {initialValues.strategicNarrative && (
        <>
          <h3 className={styles.sectionHeading}>Strategic Narrative</h3>
          <RichTextDisplay html={initialValues.strategicNarrative} />
        </>
      )}

      {missionType && (
        <>
          <h3 className={styles.sectionHeading}>Type of Mission</h3>
          <ul>
            <li>{missionType}</li>
          </ul>
        </>
      )}

      {(initialValues.currentWeaponsSystems || initialValues.proposedWeaponsSystems || initialValues.manpower) && (
        <>
          <h3 className={styles.sectionHeading}>Force Structure</h3>

          <WeaponsSystemDisplay initialValues={initialValues} />

          <ManpowerDisplay initialValues={initialValues} />
        </>
      )}

      {initialValues.whereManpower && (
        <>
          <h3 className={styles.sectionHeading}>Where is the manpower coming from?</h3>
          <RichTextDisplay html={initialValues.whereManpower} />
        </>
      )}
    </>
  )
}

export default StageSetterDisplay;
