import React from 'react';
import styles from './ContentView.module.scss';

// use this for any view that has a header, sidebar, and main
const ContentView = ({ headerContent, mainContent, sidebarContent }) => {
   return (
    <div className={styles.contentView}>
      {headerContent}
      
      <div className={styles.container}>
          {sidebarContent && <nav className={styles.sideNav}>
            {sidebarContent}
          </nav>}
          
          <main className={styles.mainContent}>
            {mainContent}
          </main>
      </div>
    </div>
  );
}

export default ContentView;