import React, { useState, useEffect } from 'react';
import Table from '../Table';
import styles from './BarLanding.module.scss'
import Flag from '../shared/flagIcon.js';
import cn from 'classnames';
import useCachedData from '../hooks/useCachedData';
import { unwatchBar, watchBar } from '../../utils/watchBar';
import DisplayBarLink from '../DisplayBarLink';
import DisplayDate from '../DisplayDate';
import dateFormats from '../../constants/dateFormats';
import Tooltip from '../Tooltip';

const BarsTable = (props) => {
  const {bars, watchedBars, setWatchedBars} = props;
  const isWatchingBar = (barId) => watchedBars && watchedBars.includes(barId);
  const { data: steps } = useCachedData('/content/steps');
  const { data: phases } = useCachedData('/content/phases');
  const { data: statusOptions } = useCachedData('/content/status');
  const [statuses, setStatuses] = useState({})
  const [sortColumn, setSortColumn] = useState({column: 'title', isAscending: false});
  const [sortedBars, setSortedBars] = useState([]);

  useEffect(() => {
    if(statusOptions && statusOptions.options) {
      setStatuses(statusOptions.options.reduce((acc, obj) => {
        let key = obj.value;
        if (!acc[key]) {
          acc[key] = obj.label
        }
        return acc
      }, {}))
    }
  }, [statusOptions])

  useEffect(()=> {
    if(bars && Array.isArray(bars)){
      const barsCopy = [...bars];
      barsCopy.sort((a, b) => {
        let aValue = a[sortColumn.column], bValue = b[sortColumn.column];
        if(sortColumn.column === 'safAo'){
          aValue = a.safActionOfficer ? a.safActionOfficer.firstName + ' ' + a.safActionOfficer.lastName: '';
          bValue = b.safActionOfficer ? b.safActionOfficer.firstName + ' ' + b.safActionOfficer.lastName : '';
        }
        else if(sortColumn.column === 'currentPhase'){
          aValue = phases[aValue];
          bValue = phases[bValue];
        }
        else if(sortColumn.column === 'currentStep'){
          aValue = steps[aValue];
          bValue = steps[bValue];
        }
        else if(sortColumn.column === 'watch'){
          aValue = !isWatchingBar(a.id);
          bValue = !isWatchingBar(b.id);
        }
        // null values don't compare properly so convert to a comparable
        aValue = aValue === null || aValue === undefined ? '' : aValue;
        bValue = bValue === null || bValue === undefined ? '' : bValue;

        if (aValue < bValue) {
          return -1;
        }
        if (aValue > bValue) {
          return 1;
        }

        return 0;
      })
      if(sortColumn.isAscending){
        barsCopy.reverse();
      }
      setSortedBars(barsCopy);
    }
  }, [sortColumn, bars, setSortedBars]);

  const renderRow = (title, safAo, phase, step, barId, scheduledDate, status) => {
    const isWatching = isWatchingBar(barId);
    const watchingTooltip = isWatching ? 'You are watching this action' : 'Watch this action';

    return (
      <tr key={barId}>
        <td className={styles.watchColumn}>
          <Tooltip content={watchingTooltip}>
            <Flag onClick={()=> isWatching ? unwatchBar(barId, watchedBars, setWatchedBars) : watchBar(barId, watchedBars, setWatchedBars)}
            className={cn(styles.flagIcon, isWatching ? styles.selectedFlagIcon : '')} />
          </Tooltip>
        </td>
        <td className={styles.titleColumn}><DisplayBarLink barId={barId}>{title}</DisplayBarLink></td>
        <td className={styles.safColumn}>{safAo ? safAo.firstName + ' ' + safAo.lastName : ''}</td>
        <td className={styles.statusColumn}>{status ? (statuses[status] ? statuses[status] : status) : ''}</td>
        <td className={styles.phaseStepColumn}>{phases ? phases[phase] || phase : ''}</td>
        <td className={styles.phaseStepColumn}>{steps ? steps[step] || step : ''}</td>
        <td className={cn(styles.numeric)}>{scheduledDate ? <DisplayDate date={scheduledDate} format={dateFormats.short} /> : '--'}</td>
    </tr>
    );
  }

  const handleSortClick = (column) => {
    if(sortColumn.column === column){
      setSortColumn({...sortColumn, isAscending: !sortColumn.isAscending});
    }
    else{
      setSortColumn({column, isAscending: false});
    }
  }

  const caretStyle = (column) => (sortColumn.column === column && sortColumn.isAscending) ? styles.caretUp : styles.caretDown;

  return (
    <Table>
      <thead className={styles.tableHead}>
        <tr>
            <th className={styles.watchColumn} onClick={()=> handleSortClick('watch')}>Watch <div className={caretStyle('watch')}></div></th>
            <th className={styles.titleColumn} onClick={()=> handleSortClick('title')}>Title <div className={caretStyle('title')}></div></th>
            <th className={styles.safColumn} onClick={()=> handleSortClick('safAo')}>SAF Action Officer <div className={caretStyle('safAo')}></div></th>
            <th className={styles.statusColumn} onClick={()=> handleSortClick('status')}>Status <div className={caretStyle('status')}></div></th>
            <th className={styles.phaseStepColumn} onClick={()=> handleSortClick('currentPhase')}>Phase <div className={caretStyle('currentPhase')}></div></th>
            <th className={styles.phaseStepColumn} onClick={()=> handleSortClick('currentStep')}>Step <div className={caretStyle('currentStep')}></div></th>
            <th className={styles.numeric} onClick={()=> handleSortClick('scheduledDate')}>Scheduled Date <div className={caretStyle('scheduledDate')}></div></th>
        </tr>
      </thead>
      <tbody>
          {sortedBars && sortedBars.map((bar) => {
            return renderRow(bar.title, bar.safActionOfficer, bar.currentPhase, bar.currentStep, bar.id, bar.scheduledDate, bar.status);
          })}
      </tbody>
    </Table>
  )
}
export default BarsTable;