import { useEffect, useState } from 'react'
import useCachedData from './useCachedData';
import ROLES from '../../constants/roles';

const useRoleOptions = () => {
  const [roleOptions, setRoleOptions] = useState([]);
  const { data: roles } = useCachedData('/users/roles');

  useEffect(() => {
    if (ROLES && roles && roles.length) {
      const options = roles.map(role => {
        const { label } = ROLES[role.name];

        return ({
          value: role.id,
          label: label || role.name
        });
      })

      setRoleOptions(options);
    }
  }, [roles]);

  return roleOptions;
}

export default useRoleOptions;