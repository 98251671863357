import React from 'react';
import { Field } from 'react-final-form';
import TextArea from '../../Form/TextArea';
import SelectField from '../../Form/SelectField';
import Label from '../../Form/Label';
import getYearOptions from '../../../utils/getYearOptions';
import styles from './Requirements.module.scss';

const QUARTERS_OPTIONS = [
  { value: '1' },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { label: 'CLASS', value:'0'}
];

const YEARS_OPTIONS = getYearOptions();

const TimingField = () => {
  const fieldSet = (name, topLabel, definitionLabel, capability) => (
    <>
      <Label text={topLabel} />
      <div className={styles.timingField}>
        <div className={styles.fiscalQuarter}>
          <Label text='Quarter' required />
          <SelectField name={`${name}.quarter`} options={QUARTERS_OPTIONS} />
          <Label text='Fiscal Year' required />
          <SelectField name={`${name}.fiscalYear`} options={YEARS_OPTIONS} />
        </div>
        <div className={styles.timingDefinition}>
          <Label text={definitionLabel} required />
          <TextArea name={`${name}.definition`} />
        </div>
        <Field component='input' name={`${name}.capability`} type='hidden' initialValue={capability} />
      </div>
    </>
  );

  return (
    <div>
      {fieldSet('timeRequirements[0]', 'Initial Operational Capability', 'IOC Definition', 'IOC')}
      {fieldSet('timeRequirements[1]', 'Full Operational Capability', 'FOC Definition', 'FOC')}
    </div>
  );
}

export default TimingField;
