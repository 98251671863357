import { Field } from 'react-final-form';
import ModalFooter from '../../../Modal/ModalFooter';
import Button from '../../../buttons/Button';
import FileUploadField from '../../../Form/FileUploadField';
import Label from '../../../Form/Label';

const ReplaceModalForm = ({ handleSubmit, invalid, loading, onClose }) => {
  return (
    <form>

      <Label text='Select a file' required />
      <FileUploadField buttonText='Choose a File' name='file' />

      <ModalFooter>
        <Button danger type='submit' onClick={handleSubmit} disabled={invalid || loading} loading={loading}>Replace</Button>
        <Button primary onClick={onClose} disabled={loading}>Cancel</Button>
      </ModalFooter>
    </form>
  );
}

export default ReplaceModalForm;