import React, { useEffect, useState } from 'react';
import { Field, useField } from 'react-final-form';
import SelectField from '../Form/SelectField';
import ModalFooter from '../Modal/ModalFooter';
import Button from '../buttons/Button';
import useCachedData from '../hooks/useCachedData';
import RadioField from '../Form/RadioField';
import TextField from '../Form/TextField';
import FileUploadField from '../Form/FileUploadField';
import Label from '../Form/Label';
import getFileType from '../../utils/getFileType';
import styles from './Resources.module.scss';

const radioOptions = [
  { label: 'Document', value: 'document' },
  { label: 'Template', value: 'template' }
];

const UploadResourceForm = ({ handleSubmit, invalid, loading, onClose }) => {
  const { data: { options: documentOptions } = {} } = useCachedData('/content/documentOptions');
  const { data: { options: templateOptions } = {} } = useCachedData('/content/templateOptions');
  const [resourceTypeOptions, setResourceTypeOptions] = useState([]);
  const resource = useField('resource');
  const type = useField('type');
  const file = useField('file');
  const fileName = useField('fileName');
  const fileType = useField('fileType');
  const fileExtension = useField('fileExtension');

  const formatFileName = (value, ) => {
    if (value) {
      const chunks = value.split('.');
      chunks.length > 1 && chunks.pop();
      return chunks.join('.');
    }

    return value
  }

  const parseFileName = (value) => {
    return value ? [value, fileExtension.input.value].join('.') : value;
  }

  useEffect(() => {
    if (resource.input.value === 'document') {
      setResourceTypeOptions(documentOptions);

    }
    if (resource.input.value === 'template') {
      setResourceTypeOptions(templateOptions);
    }

    type.input.onChange('');
  }, [resource.input.value, documentOptions, templateOptions]);

  useEffect(() => {
    let originalFileName = file.input.value.originalFileName;
    if (originalFileName) {
      const chunks = originalFileName.split('.');
      const extension = chunks.length > 1 && chunks[chunks.length - 1];
      const customFileName = chunks.join('.');

      fileName.input.onChange(customFileName)
      fileType.input.onChange(getFileType(file.input.value.file));
      extension && fileExtension.input.onChange(extension);
    }
  }, [file.input.value])

  const getSelectFieldPlaceholder = () => {
    const { label } = radioOptions.find(option => option.value === resource.input.value) || {};
    return `Select ${label} Type`;
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <RadioField className={styles.formField} label='1. Choose your resource' name='resource' initialValue={'document'} options={radioOptions} required />

      <SelectField className={styles.formField} label='2. Choose your resource type' name='type' options={resourceTypeOptions} placeholderText={getSelectFieldPlaceholder()} required />

      <Label text='3. Select your file' required />
      <FileUploadField className={styles.formField} name='file' buttonText='Choose File' />

      <TextField className={styles.formField} label='4. Name your resource' name='fileName' placeholder='Enter resource name here' required format={formatFileName} parse={parseFileName} />

      <Field component='input' type='hidden' name='fileType' />
      <Field component='input' type='hidden' name='fileExtension' />

      <ModalFooter className={styles.buttonRow}>
        <Button type='submit' onClick={handleSubmit} primary disabled={invalid || loading} loading={loading}>UPLOAD</Button>
        <Button onClick={onClose} secondary>CANCEL</Button>
      </ModalFooter>
    </form>
  );
}

export default UploadResourceForm;
