import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import useCachedData from '../../../hooks/useCachedData';
import dateFormats from '../../../../constants/dateFormats';
import DisplayDate from '../../../DisplayDate';
import phaseAbbreviations from '../../../../constants/phaseAbbreviations';
import PhaseLegend from '../../PhaseLegend';
import axios from '../../../../utils/sharedAxios';

const BasingActionQueue = ({ review, reviews }) => {
  const { data: steps = {} } = useCachedData('/content/steps');
  const [basingActionQueue, setBasingActionQueue] = useState([])
  const formApi = useForm();

  useEffect(() => {
    formApi.change('confirmedBasingActionQueue', 1)
  }, []);

  useEffect(() =>{
    if(review) {
      axios.get(`/reviews/basing-action-queue`, {params: {reviewDate: review.date, reviewStep: review.step }}).then((response) => {
        setBasingActionQueue(response.data)
      }).catch(err => {
        console.log(err);
        return []
      });
    }
  }, [review])

  return (
    <>
      {basingActionQueue.map(({ stepNumber, dates }) => (
        <React.Fragment key={stepNumber}>
          <h3>{steps[stepNumber]}</h3>
          {dates.map(({ date, bars }) => (
            <React.Fragment key={date}>
              <h4><DisplayDate date={date} format={dateFormats.short} /></h4>
              <ul>
                {bars.map(({ currentPhase, needApprovalFrom, title }, index) => (
                  <li key={index}>{phaseAbbreviations[currentPhase]?.short} {title} {needApprovalFrom && (`(${needApprovalFrom})`)}</li>
                ))}
              </ul>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
      <PhaseLegend />
    </>
  );
};

export default BasingActionQueue;