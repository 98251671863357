import cn from 'classnames';
import Button from '../buttons/Button';
import styles from './Table.module.scss';

const SortHeader = ({ activeSortKey, sortKey, label, onClickSort, sortReversed }) => (
  <th>
    <Button className={cn(styles.sortHeaderButton, {
      [styles.sortActive]: activeSortKey === sortKey,
      [styles.sortAscending]: activeSortKey === sortKey && sortReversed
    })} onClick={() => onClickSort(sortKey)}>{label}</Button>
  </th>
);

export default SortHeader;