import React, { useContext } from 'react';
import Grid from '../../Grid';
import { Box } from 'theme-ui';
import ProponentFields from './ProponentFields';
import TitleField from './TitleField';
import RadioField from '../../Form/RadioField';
import SelectField from '../../Form/SelectField';
import TextField from '../../Form/TextField';
import PhoneField from '../../Form/PhoneField';
import TypeOfActionFields from './TypeOfActionFields';
import DetailsText from '../../DetailsText';
import { AuthContext } from '../../Utility/Authentication';
import styles from './BasicInformation.module.scss';
import formStyles from '../../Form/Form.module.scss';
import { useForm } from 'react-final-form';

const BasicInformationEdit = () => {
  // This view will change depending on phase/state
  const { user } = useContext(AuthContext);
  const form = useForm();
  const { initialValues } = form.getState();
  const initialLeadBasingOffice = initialValues.leadBasingOffice || (user.role === 'ERIN' && user.office) || null;

  return (
    <div>
      <TitleField />

      <Grid columns={[3]} gap={20}>
        <Box>
          <SelectField label='Lead Basing Office' name='leadBasingOffice' fieldDataEndpoint='/content/leadBasingOffices' required initialValue={initialLeadBasingOffice} isClearable={false} disabled={user.role !== 'SAM'} />
        </Box>
      </Grid>

      <ProponentFields />

      <div className={styles.requestorHeading}>
        <h3 className={formStyles.sectionHeading}>Requestor</h3>
        <DetailsText>Proponent Action Officer</DetailsText>
      </div>

      <Grid columns={[2]} gap={20}>
        <Box>
          <TextField label='Name' name='proponentPointOfContactName' required />
        </Box>
      </Grid>
      <Grid columns={[2]} gap={20}>
        <Box>
          <TextField label='Organization' name='proponentPointOfContactOrganization' required />
        </Box>
      </Grid>
      <Grid columns={[2]} gap={20}>
        <Box>
          <PhoneField label='Phone Number' name='proponentPointOfContactPhoneNumber' required />
        </Box>
      </Grid>
      <Grid columns={[2]} gap={20}>
        <Box>
          <TextField label='Email' name='proponentPointOfContactEmail' required />
        </Box>
      </Grid>

      <TypeOfActionFields />

      <Grid columns={[2]} gap={20}>
        <Box>
          <SelectField label='Anticipated level of the required Environmental Analysis' name='anticipatedEnvironmentalAnalysis' fieldDataEndpoint='/content/environmentalAnalysisLevels' required />
        </Box>
      </Grid>

      <RadioField label='Is the unit commanded by a General Officer?' name='isCommandedByGeneral' options={[
        {label: 'Yes', value: 1},
        {label: 'No', value: 0},
      ]} required />

      <RadioField label='Does this action involve the establishment or move of a Major Weapon System?' name='isMajorWeaponSystem' options={[
        {label: 'Yes', value: 1},
        {label: 'No', value: 0},
      ]} required />
    </div>
  );
}

export default BasicInformationEdit;
