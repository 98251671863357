import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import axios from '../../utils/sharedAxios';
import styles from './Dashboard.module.scss'
import { adminViewKeys } from '../../constants/adminViewMapping';

const NewActions = () => {
  const history = useHistory();
  const [bars, setBars] = useState([]);

  useEffect(() => {
    const fetchBars = async () => {
      const { data } = await axios.get('/bars/list-submitted');
      data && setBars(data);
    }

    fetchBars();
  }, []);
  
  const handleClick = () => history.push(`/admin?activeView=${adminViewKeys.newActionRequests}`);

  return (
    <div className={cn(styles.chip, { [styles.active]: bars.length > 0 })} onClick={handleClick}>
      <h6 className={styles.chipHeading}>NEW ACTIONS</h6>
      <p className={styles.chipValue}>{bars.length || 0}</p>
    </div>
  );
}

export default NewActions;