import React from 'react';
import useCachedData from '../../hooks/useCachedData';
import BackupSlide from './BackupSlide';

const SomfProfessionalLicensure = (props) => {
  const { data: keys } = useCachedData('/content/barViewKeys');
  
  return (
    <BackupSlide titleKey={keys && keys.somfProfessionalLicensure} {...props} />
  );
}

export default SomfProfessionalLicensure;
