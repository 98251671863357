import React from 'react';
import cn from 'classnames';
import styles from './NavListItem.module.scss';

const NavListItemIcon = ({ warning, success }) => (
  <i className={cn({
    [styles.iconEmpty]: !warning && !success,
    [styles.iconWarning]: (warning && !success),
    [styles.iconSuccess]: success
  })} />
);

export default NavListItemIcon;
