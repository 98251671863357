import React, { useEffect, useState } from 'react';
import Wysiwig from '../../Form/Wysiwyg';
import Label from '../../Form/Label';
import SelectField from '../../Form/SelectField'
import FieldArray from '../../Form/FieldArray';
import DetailsText from '../../DetailsText';
import useCachedData from '../../hooks/useCachedData';
import UploadField from '../../Form/UploadField';
import getBaseOptions from '../../../utils/getBaseOptions';
import AddButton from '../../buttons/AddButton';
import CloseButton from '../../buttons/CloseButton';
import Grid from '../../Grid';
import styles from '../../Form/Form.module.scss';

const EnterpriseEdit = ({deleteFile}) => {
  const { data: bases } = useCachedData('/content/bases');
  const [baseOptions, setBaseOptions] = useState([]);

  useEffect(() => {
    if (bases) {
      const formattedBases = getBaseOptions(bases);

      setBaseOptions(formattedBases);
    }
  }, [bases])

  return (
    <div>
      <Label text={<h3 className={styles.sectionHeading}>Definition</h3>} required />
      <DetailsText>Include significant views of others (Senior Leaders, other services, other MAJCOMs)<br />
        Identify all requirements which limit an Enterprise Wide Look (Access to appropriate ranges, Munitions Storage Areas, runway/taxiway requirements, recruiting regions)<br />
        List the enterprise locations</DetailsText>
      <Wysiwig name='enterpriseDefinition.definition' />

      <Label text={<h3 className={styles.sectionHeading}>Locations</h3>} required />
      <DetailsText>Locations that meet the enterprise definition</DetailsText>
      <Label text='Enter Location' />
      <FieldArray name='enterpriseDefinition.locations'>
        {({ fields, add, remove, isMinLength }) => (
          <div>
            {fields.map((name, index) => (
              <Grid key={name} columns={[1]} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
                <SelectField name={name} options={baseOptions} isSearchable />
              </Grid>
            ))}

            {<Grid><AddButton onClick={() => add()} secondary>Add Location</AddButton></Grid>}
          </div>
        )}
      </FieldArray>

      <h3 className={styles.sectionHeading}>Map</h3>
      <DetailsText>Please upload an image file.  Allowable image types are .PNG, .JPEG, and .JPG</DetailsText>
      <UploadField name='enterpriseDefinition' deleteFile={deleteFile} />

    </div>
  );
}

export default EnterpriseEdit;
