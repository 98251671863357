import React from 'react';
import { Field, useField } from 'react-final-form';
import AddButton from '../../buttons/AddButton';
import CloseButton from '../../buttons/CloseButton';
import DateField from '../../Form/DateField';
import FieldArray from '../../Form/FieldArray';
import TextField from '../../Form/TextField';
import Label from '../../Form/Label';
import UploadDocsField from '../../Form/UploadDocsField';
import Grid from '../../Grid';
import Box from '../../Box';
import DocumentList from '../../DocumentList';
import styles from './BasingDecisionPackage.module.scss';
import AuthorizedContent from '../../AuthorizedContent';
import SignedDecisionPackage from './SignedDecisionPackage';

const BasingDecisionPackageEdit = ({ barPhase, deleteFile }) => {
  const { input: { value: docs } } = useField('basingDecisionPackage.docs');
  const esss = docs ? docs.filter((d) => d.type === 'esss') : [];
  const mfr = docs ? docs.filter((d) => d.type === 'mfr') : [];
  const briefing = docs ? docs.filter((d) => d.type === 'briefing') : [];
  const cnp = docs ? docs.filter((d) => d.type === 'cnppag') : [];
  const env = docs ? docs.filter((d) => d.type === 'environmental') : [];
  const additional = docs ? docs.filter((d) => d.type === 'additional') : [];
  const preSignaturePackage = docs ? docs.filter((d) => d.type === 'preSignaturePackage') : [];

  const templateColumns = [5, '2fr 2.5fr 2.5fr 3fr 3fr'];

  return (
    <div>
      <Grid columns={[2, '6fr 1fr']}>
        <Box>
          <Label text='Last Review Notes' />
        </Box>
        <Box>
          <Label text='Last Review Date' />
        </Box>
        <TextField name='basingDecisionPackage.lastReviewNotes' />
        <DateField name='basingDecisionPackage.lastReviewDate' />
        <Field name='basingDecisionPackage.phase' component='input' type='hidden' initialValue={barPhase} />
      </Grid>

      <FieldArray name='basingDecisionPackage.endorsements' >
      {({ fields, add, isMaxLength, isMinLength, remove }) => (
        <>
        <Grid columns={templateColumns}>
          <Box>
            <Label text='Sent To' />
          </Box>
          <Box>
            <Label text='Sent On' />
          </Box>
          <Box>
            <Label text='Endorsed On' />
          </Box>
          <Box>
            <Label text='Endorsed By' />
          </Box>
          <Box>
            <Label text='Notes' />
          </Box>
        </Grid>
        {fields.map((name, index) => (
            <Grid key={name} columns={templateColumns} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
              <TextField name={`${name}.sentTo`} />
              <DateField name={`${name}.sentOn`} />
              <DateField name={`${name}.endorsedOn`} />
              <TextField name={`${name}.endorsedBy`} />
              <TextField name={`${name}.notes`} />
            </Grid>
          ))}
          {!isMaxLength && <Grid><AddButton onClick={() => add()} secondary>Add Row</AddButton></Grid>}
        </>
      )}
      </FieldArray>

      <FieldArray name='basingDecisionPackage.docs' initialValue={docs} minLength={0}>
      {({ fields, add, remove }) => {
        return(
        <>
          <DocumentList docList={esss} title='ESSS' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'basingDecisionPackage.docs'} add={add} remove={remove} fields={fields} docType='esss' packageType='basingDecisionPackage' />
          <DocumentList docList={mfr} title='MFR' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'basingDecisionPackage.docs'} add={add} remove={remove} fields={fields} docType='mfr' packageType='basingDecisionPackage' />
          <DocumentList docList={briefing} title='Briefing' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'basingDecisionPackage.docs'} add={add} remove={remove} fields={fields} docType='briefing' packageType='basingDecisionPackage' />
          <DocumentList docList={cnp} title='CNP/PAG' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'basingDecisionPackage.docs'} add={add} remove={remove} fields={fields} docType='cnppag' packageType='basingDecisionPackage' />
          <DocumentList docList={env} title='Environmental Documents' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'basingDecisionPackage.docs'} add={add} remove={remove} fields={fields} docType='environmental' packageType='basingDecisionPackage' />
          <DocumentList docList={additional} title='Additional Documents' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'basingDecisionPackage.docs'} add={add} remove={remove} fields={fields} docType='additional' packageType='basingDecisionPackage' />
          <DocumentList docList={preSignaturePackage} title='Pre Signature Package' remove={remove} fields={fields} deleteFile={deleteFile} />
          <UploadDocsField name={'basingDecisionPackage.docs'} add={add} remove={remove} fields={fields} docType='preSignaturePackage' packageType='basingDecisionPackage' />
          <hr />

          <AuthorizedContent authorizedRoles='SAM'>
            <h3 className={styles.signedDecisionPackageHeading}>Signed Decision Package</h3>
            <FieldArray minLength={4} maxLength={4} name='signedDecisionPackage'>{({ fields }) => {
              const fieldProps = [
                {
                  label: 'Criteria and Enterprise Definition',
                  phase: 1,
                  docType: 'criteria'
                },
                {
                  label: 'Candidates',
                  phase: 2,
                  docType: 'candidates'
                },
                {
                  label: 'Preferred Location',
                  phase: 3,
                  docType: 'preferredLocation'
                },
                {
                  label: 'Final Decision',
                  phase: 4,
                  docType: 'finalDecisions'
                }
              ]
              return fields.map((fieldName, index) => (
                <SignedDecisionPackage
                  key={index}
                  name={fieldName}
                  remove={remove}
                  deleteFile={deleteFile}
                  {...fieldProps[index]} />
              ))
            }}</FieldArray>
          </AuthorizedContent>
        </>
      )}}
      </FieldArray>
    </div>
  );
}

export default BasingDecisionPackageEdit;