import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Anchor.module.scss';

// renders an anchor or a button depending on its props
const Anchor = ({ children, className, disabled, download, href = '', light, onClick, target = '_self', ...props }) => {
  const style = cn(styles.anchor, {
    [styles.light]: light,
    [styles.disabled]: disabled,
  }, className);

  const rel = target !== '_self' ? 'noopener noreferrer' : null;

  if (onClick) {
    return (
      <button className={style} onClick={disabled ? null : onClick} type='button' {...props}>{children}</button>
    );
  }

  if (download) {
    return (
      <a className={style} href={disabled ? undefined : href} target={target} rel={rel} title={href} download={download} {...props}>{children}</a>
    );
  }

  if (href.startsWith('/')) {
    return <Link className={style} disabled={disabled} to={href} target={target} rel={rel} title={href} {...props}>{children}</Link>
  }
  
  return <a className={style} disabled={disabled} href={href} target={target} rel={rel} title={href} {...props}>{children}</a>
  
}

export default Anchor;