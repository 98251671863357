import React from 'react';
import MilestoneField from './MilestoneField';
import TextField from '../../Form/TextField';
import RequiredIndicator from '../../Form/RequiredIndicator';
import styles from '../../Form/Form.module.scss';

const WayAheadEdit = () => {
  return (
    <div>
      <MilestoneField />
      <h3 className={styles.sectionHeading}>Action Endorsement<RequiredIndicator /></h3>
      <TextField name='wayAheadActionEndorsement' />
    </div>
  );
}

export default WayAheadEdit;