import React from 'react';
import dateFormats from '../../constants/dateFormats';
import CloseButton from '../buttons/CloseButton';
import DisplayDate from '../DisplayDate';
import DisplayFile from '../DisplayFile';
import DisplayUser from '../DisplayUser';
import useCachedData from '../hooks/useCachedData';
import styles from './DocumentList.module.scss';

const DocumentListRow = ({ originalFileName, phase, removeFile, uuid }) => {
  const { data: phases } = useCachedData('/content/phases');
  const { data: { createdAt, uploadedBy } = {} } = uuid ? useCachedData('/documents/document', { params: { docId: uuid } }) : {};

  return (
    <tr>
      <td>{uuid ? <DisplayFile uuid={uuid} /> : originalFileName}</td>
      <td>{phases && phase && phases[phase]}</td>
      <td>{createdAt && <DisplayDate date={createdAt} format={dateFormats.short} />}</td>
      <td>
        {uploadedBy && <DisplayUser userId={uploadedBy} />}
        {removeFile ? <CloseButton className={styles.closeButton} onClick={() => removeFile(uuid)} /> : <div></div>}
      </td>
    </tr>
  );
}

export default DocumentListRow;