import React from 'react';
import TextField from './TextField';

const onlyNumbers = string => string && (string.replace(/[^\d]/g, "")).slice(0, 10);

const parse = value => onlyNumbers(value);

const format = value => {
  if (!value) {
    return '';
  }

  const stripped = onlyNumbers(value);

  if (stripped.length <= 3) {
    return stripped;
  }

  if (stripped.length <= 7) {
    return `(${stripped.slice(0, 3)}) ${stripped.slice(3, 7)}`;
  }

  return `(${stripped.slice(0, 3)}) ${stripped.slice(3, 6)}-${stripped.slice(6, 10)}`;
}

const PhoneField = ({ placeholder = '(XXX) XXX-XXXX', ...props }) => <TextField format={format} parse={parse} type='tel' placeholder={placeholder} {...props} />

export default PhoneField;
