import { useEffect } from 'react';

// sets the title that appears on the browser tab
// used on a router-level components
const useDocumentTitle = (title, retainOnUnmount = false) => {
  const defaultTitle = 'SABER';

  useEffect(() => {
    document.title = `${defaultTitle} - ${title}`;
  }, [title]);

  useEffect(() => {
    return () => {
      if (!retainOnUnmount) {
        document.title = defaultTitle;
      }
    };
  }, []);
}

export default useDocumentTitle;