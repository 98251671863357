import React, { useRef } from 'react';
import { useField } from 'react-final-form';
import Button from '../buttons/Button';
import UploadButton from '../buttons/UploadButton';

const FileUploadField = ({ accept = '', buttonText = 'Upload', className, name, deleteFile }) => {
  const previewFile = useField(`${name}.file`);
  const fileName = useField(`${name}.originalFileName`);
  const uuid = useField(`${name}.uuid`);

  const inputRef = useRef(null);
  const onClickUpload = () => inputRef.current.click();

  const onInputChange = (e) => {
    previewFile.input.onChange(e.target.files[0]);
    fileName.input.onChange(e.target.files[0].name);
  };

  const onClickRemove = () => {
    previewFile.input.onChange('');
    fileName.input.onChange('');
    inputRef.current.value = '';

    if (deleteFile && uuid.input.value){
      deleteFile(uuid.input.value);
    }
  }

  const fileNameDisplay = fileName.input.value;

  return (
    <div className={className}>
      <input name='fileUpload' type='file' onChange={onInputChange} accept={accept} ref={inputRef} style={{ display: 'none' }} />
      {!fileNameDisplay && <UploadButton onClick={onClickUpload}>{buttonText}</UploadButton>}
      {fileNameDisplay && <span>{fileNameDisplay}</span>}

      <div>
        {fileNameDisplay && <Button onClick={onClickRemove} secondary>Remove</Button>}
      </div>

    </div>
  );
}

export default FileUploadField;