import { useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form';

// watches for changes in the length of Site Survey Criteria
// and deletes obsolete corresponding fields from SummaryOfFindings
const SiteSurveyValuesSpy = () => {
  const { input: siteSurveyRequirements } = useField('siteSurveyRequirements');
  const { input: summaryOfFindings = [] } = useField('summaryOfFindings');
  const [requirementsLength, setRequirementsLength] = useState(0);
  const [requirementIds, setRequirementIds] = useState([]);
  const formApi = useForm();

  const siteSurveyRequirementsValues = Object.values(siteSurveyRequirements.value).flat();
  let newRequirementsLength = siteSurveyRequirementsValues.length;
  
  siteSurveyRequirementsValues.forEach(item => {
    if (item.subRequirements) {
      newRequirementsLength += item.subRequirements.length
    };
  });

  if (newRequirementsLength !== requirementsLength) {
    setRequirementsLength(newRequirementsLength);
  }

  useEffect(() => {
    if (summaryOfFindings.value) {
      const presentIds = [];

      for (let key in siteSurveyRequirements.value) {
        siteSurveyRequirements.value[key].forEach(req => {
          if (req?.subRequirements?.length) {
            req.subRequirements.forEach(subreq => {
              presentIds.push(subreq.id);
            })
          } else {
            presentIds.push(req.id);
          }
        });        
      }
      
      const pendingDeletedIds = requirementIds.filter(id => presentIds.indexOf(id) < 0);
      
      if (pendingDeletedIds.length) {
        const summaryOfFindingsKeys = [];
        const summaryOfFindingsKeysToBatchDelete = [];
        
        Object.keys(summaryOfFindings.value)
          .forEach(key => summaryOfFindingsKeys.push(key));

        pendingDeletedIds.forEach(id => {
          const re = new RegExp(`${id}~`);
          const foundKeys = summaryOfFindingsKeys.filter(key => key.match(re));
  
          summaryOfFindingsKeysToBatchDelete.push(...foundKeys);
        });
        
        if (summaryOfFindingsKeysToBatchDelete.length) {
          formApi.batch(() => {
            summaryOfFindingsKeysToBatchDelete.forEach(key => {
              formApi.change(`summaryOfFindings[${key}]`, '');
            })
          });
        }
      }
      
      setRequirementIds([...presentIds]);
    }
  }, [requirementsLength]);

  return null;
}

export default SiteSurveyValuesSpy;