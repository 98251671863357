const titleMap = {
  cover: {
    navTitle: 'Cover',
    pageTitle: 'Cover',
  },
  rollCall: {
    navTitle: 'Roll Call',
    pageTitle: 'Roll Call'
  },
  agenda: {
    navTitle: 'Agenda',
    pageTitle: 'Agenda'
  },
  basingActionQueue: {
    navTitle: 'Basing Action Queue',
    pageTitle: 'Basing Action Queue'
  },
  upcomingDecisions: {
    navTitle: 'Upcoming Decisions',
    pageTitle: 'Upcoming Decisions'
  },
  pendingCongressionalNotifications: {
    navTitle: 'Pending Congressional Notif.',
    pageTitle: 'Pending Congressional Notifications'
  }
};

module.exports = {
  titleMap,
}
