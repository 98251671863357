import React, { useState } from 'react';
import { Form } from 'react-final-form';
import Modal from '../Modal';
import UploadResourceForm from './UploadResourceForm';
import axios from '../../utils/sharedAxios';
import styles from './Resources.module.scss';

const initialValues = {
  resource: 'document'
};

const UploadResourceModal = ({ onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);

  const uploadResource = async (values) => {
    let fd = new FormData();
    fd.append('file', values.file?.file);

    const upload = await axios.post('/documents/upload', fd);

    if(upload.status === 200 || upload.status === 204){
      return upload.data.Key || upload.data.key; // AWS sending 'Key' for large files, 'Key' and 'key' for smaller files
    } else {
      throw new Error('File upload failed')
    }
  }

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      values = {
        ...values,
        type: values.type.value
      };

      const key = await uploadResource(values);
      const response = await axios.post('/resources/add', {...values, uuid: key });

      onSuccess(response.data);
      onClose();
    }
    catch (err) {
      console.error(err);
    }
    finally {
      setLoading(false);
    }
  }

  const validate = (values) => {
    const errors = {};

    if (!values.type) {
      errors.type = 'A resource type is required';
    }
    if (!values.file) {
      errors.file = 'A file is required';
    }
    if (!values.fileName) {
      errors.fileName = 'A fileName is required';
    }

    return errors;
  }

  return (
    <Modal className={styles.uploadResourceModal} onClose={onClose} title='Upload Resource'>
      <Form onSubmit={onSubmit} subscription={{ invalid: true, submitting: true }} validate={validate} initialValues={initialValues}>
        {({ form, handleSubmit }) => {
          const { invalid } = form.getState();
          return (
          <UploadResourceForm handleSubmit={handleSubmit} invalid={invalid} onClose={onClose} loading={loading} />
        )}}
      </Form>
    </Modal>
  );
}

export default UploadResourceModal;