import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import useCachedData from '../hooks/useCachedData';
import cn from 'classnames';
import Label from './Label';
import styles from './Form.module.scss';
import { dictionaryValue } from '../Dictionary/DictionaryContext';

// a list of radio options
const RadioField = ({ className, fieldDataEndpoint, horizontal = true, label, name, onChange, options, required, showNoneOfTheAbove }) => {
  const [finalOptions, setFinalOptions] = useState([]);
  const { data: fieldData } = useCachedData(fieldDataEndpoint);

  useEffect(() => {
    if (fieldData?.options?.length) {
      setFinalOptions(fieldData.options)
    }
    if (options?.length) {
      setFinalOptions(options);
    }
  }, [fieldData, options]);

  const parseBoolean = (value) => (value === 'true');
  const getParse = (value) => {
    if (typeof value === 'boolean') {
      return parseBoolean;
    }
    if (typeof value === 'number') {
      return Number;
    }
    return val => val;
  }

  const customOnChange = input => onChange ? (
    (e) => {
      onChange(e);
      input.onChange(e);
    }
  ) : input.onChange;

  return (
    <div className={cn(styles.radioField, className)}>
      <Label text={label} required={required} />
      <div className={cn(styles.radioGroup, {
        [styles.radioGroupHorizontal]: horizontal,
        [styles.radioGroupVertical]: !horizontal,
      })}>
        {finalOptions.map(({ label: optionLabel, value }) => (
          <Field key={value} name={name} value={value} type='radio' parse={getParse(value)}>
            {({ input }) => (
              <label className={cn(styles.radioLabel, {[styles.radioLabelChecked]: input.checked})}>
                <input className={styles.radioInput} {...input}  onChange={customOnChange(input)}/>
                <span className={styles.radioLabelText}>{optionLabel || dictionaryValue(value)}</span>
              </label>
            )}
          </Field>
        ))}
        {showNoneOfTheAbove && (
          <Field name={name} value='' type='radio'>
            {({ input }) => (
              <label className={cn(styles.radioLabel, {[styles.radioLabelChecked]: input.checked})}>
                <input className={styles.radioInput} {...input} />
                <span className={styles.radioLabelText}>None of the Above</span>
              </label>
            )}
          </Field>
        )}
      </div>
    </div>
  );
}

export default RadioField;
