import { Form } from 'react-final-form';
import Button from '../../../buttons/Button';
import TextField from '../../../Form/TextField';
import CheckboxField from '../../../Form/CheckboxField';
import Modal from '../../../Modal';
import ModalFooter from '../../../Modal/ModalFooter';
import styles from '../../Admin.module.scss';

const CreateGroupModal = ({ handleCreateGroup, onClose }) => {
  return (
    <Modal className={styles.modalMedium} onClose={onClose} title='Create A New Group for Contacts'>
      <Form onSubmit={handleCreateGroup} subscription={{}}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <p>This group will be available when editing contacts, and will appear in the main Contacts section when at least one contact has been added.</p>
            <TextField name='name' placeholder='Group Name' />
            <CheckboxField name='isBasingOffice' labelText='This group is a Basing Office' />
            <ModalFooter>
              <Button type="submit" primary>SAVE</Button>
              <Button onClick={onClose} secondary>CANCEL</Button>
            </ModalFooter>
          </form>
        )}
      </Form>
    </Modal>
  );
}

export default CreateGroupModal;