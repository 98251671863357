import { useEffect } from 'react';

// finds an option object that matches a primitive value
const useReconciledValue = (options, value, onChange) => {

  useEffect(() => {
    if (options.length && onChange) {
      if (value === null || value === '' || typeof value === 'undefined') {
        const reconciledValue = options.find(option => String(option.value) === String(null));
        reconciledValue && onChange(reconciledValue);
      }
      else if (value) {
        if (Array.isArray(value) && value.some(item => typeof item !== 'object')) {
          // this covers the case for our multi select
          const reconciledValue = value.map(item => {
            if (typeof item !== 'object') {
              return options.find(option => String(option.value) === String(item)) || item;
            }

            return item
          });

          reconciledValue && onChange(reconciledValue);
        } else if (typeof value !== 'object' && options) {
          // handles are regular single select
          const reconciledValue = options.find(option => String(option.value) === String(value) || option.value === value) || value;
          reconciledValue && onChange(reconciledValue);
        }
      }
    }

  }, [value, options, onChange]);
};

export default useReconciledValue
