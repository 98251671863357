import React from 'react';
import { Field } from 'react-final-form';
import FieldArray from '../../Form/FieldArray';
import TextField from '../../Form/TextField';
import NumberField from '../../Form/NumberField';
import AddButton from '../../buttons/AddButton';
import CloseButton from '../../buttons/CloseButton';
import styles from './WeaponsSystemField.module.scss';

const WeaponsSystemField = ({ addButtonText, label, name, proposed }) => {
  return (
    <FieldArray name={name} ignoredKeys={['currentOrProposed']}>
      {({ fields, add, remove, isMaxLength, isMinLength }) => (
        <table className={styles.weaponSystem}>
          <tbody>
            <tr>
              <td>{label}</td>
              <td>Type</td>
              <td>Total</td>
            </tr>
            {fields.map((name, index) => (
              <tr key={name} className={styles.weaponRow}>
                  <td />
                  <td>
                    <TextField name={`${name}.weaponSystem`} />
                  </td>
                  <td>
                    <NumberField name={`${name}.units`} />
                    {!isMinLength && <CloseButton onClick={remove(index)} />}
                    <Field name={`${name}.currentOrProposed`} component='input' type='hidden' readOnly initialValue={proposed ? 'proposed' : 'current'}/>
                  </td>
              </tr>
            ))}
            {!isMaxLength && (
              <tr>
                <td />
                <td><AddButton onClick={()=> add()} secondary>{addButtonText}</AddButton></td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </FieldArray>
  )
}

export default WeaponsSystemField;
