import _ from 'lodash';
import { timeZonifyDate } from './timezone';

// formats basing action data to be sent to the api

const stripValueFromObject = (obj) => {
  if (obj && obj.value) {
    return obj.value;
  }

  return obj;
}

const stripQuarterFiscalYear = item => ({
  ...item,
  quarter: stripValueFromObject(item.quarter),
  fiscalYear: stripValueFromObject(item.fiscalYear)
});

const stripBaseIdStatus = item => ({
  ...item,
  baseId: stripValueFromObject(item.baseId),
  status: stripValueFromObject(item.status)
});

const sortBasesAndStatuses = (bases) => {
  const statusWeights = { preferred: 1, alternative: 2, NCF: 3 };
  // sort alpha
  bases = _.sortBy(bases, 'baseId.label');
  // sort status
  bases = bases.sort((a, b) => {
    const aStatus = a?.status?.value ? statusWeights[a.status.value] : 4;
    const bStatus = b?.status?.value ? statusWeights[b.status.value] : 4;

    return aStatus - bStatus;
  });

  return bases;
}

// with the exception of stripValues, formatters here should typically have
// an opposing formatter in formatIncomingValues.js
// formatters must return the payload!
const formatters = {
  // looks for objects with a value key and parses it into string
  // Objects would likely be { label: x, value: y }, so we just pluck 'value'
  stripValues: (payload) => {
    Object.entries(payload)
    .reduce((acc, [key, value]) => {
      value = stripValueFromObject(value);
      acc[key] = value;

      return acc;
    }, payload);

    return payload;
  },

  // the UI requires current and proposed be separate arrays
  // the backend requires they be a single array
  // this rule combines them
  weaponSystem: (payload) => {
    if (payload.currentWeaponsSystems || payload.proposedWeaponsSystems) {
      const weaponSystem = [];
      payload.currentWeaponsSystems && weaponSystem.push(...payload.currentWeaponsSystems);
      payload.proposedWeaponsSystems && weaponSystem.push(...payload.proposedWeaponsSystems);

      // filter out any empty entries
      // this can happen because we include a hidden input for currentOrProposed
      const filtered = weaponSystem.filter((system) => system.weaponSystem || system.units);

      // this is the key the backend is looking for
      payload.weaponSystem = filtered;

      delete payload.currentWeaponsSystems;
      delete payload.proposedWeaponsSystems;
    }

    return payload;
  },

  facilityRequirements: (payload) => {
    if (payload.facilityRequirements) {
      payload.facilityRequirements = payload.facilityRequirements.map(item => ({
        ...item,
        facility: stripValueFromObject(item.facility),
      }));
    }

    if (payload.siteSurveyCapacityRequirements) {
      payload.siteSurveyCapacityRequirements = payload.siteSurveyCapacityRequirements.map(item => ({
        ...item,
        facility: stripValueFromObject(item.facility),
      }));
    }
    return payload;
  },

  costRequirements: (payload) => {
    if (payload.costRequirements) {
      const costRequirements = [];
      const getItemType = (item) => {
        if (item.type === 'custom') {
          return item.isRecurring ? 'customRecurring' : 'customOneTime';
        }

        return item.type;
      }

      Object.values(payload.costRequirements).reduce((acc, cost) => {
        cost.forEach && cost.forEach(item => {
          acc.push({
            ...item,
            type: getItemType(item),
            cost: stripValueFromObject(item.cost),
          });
        });

        return acc;
      }, costRequirements);

      payload.costRequirements = costRequirements;
    }

    return payload;
  },

  timeRequirements: (payload) => {
    if (payload.timeRequirements) {
      payload.timeRequirements = payload.timeRequirements.map(stripQuarterFiscalYear);
    }

    return payload;
  },

  enterpriseDefinition: (payload) => {
    if (payload?.enterpriseDefinition?.locations) {
      payload.enterpriseDefinition.locations = _.sortBy(payload.enterpriseDefinition.locations, 'label');
      payload.enterpriseDefinition.locations = payload.enterpriseDefinition.locations.map(item => stripValueFromObject(item));
    }
    return payload;
  },

  recommendedCandidates: (payload) => {
    if (payload?.recommendedCandidates) {
      payload.recommendedCandidates = _.sortBy(payload.recommendedCandidates, 'baseId.label');
      payload.recommendedCandidates = payload.recommendedCandidates.map(item => ({...item, baseId: stripValueFromObject(item.baseId)}));
    }
    return payload;
  },

  missionCriteria: (payload) => {
    if (payload.missionCriteria) {
      const newMissionCriteria = [];
      Object.entries(payload.missionCriteria).forEach(([key, value]) => {
        value.forEach && value.forEach(({ definition, ...rest }) => {
          newMissionCriteria.push({
            type: key,
            definition: stripValueFromObject(definition),
            ...rest
          });
        })

      });

      payload.missionCriteria = newMissionCriteria;
    }

    return payload;
  },

  wayAheadTimeTable: (payload) => {
    if (payload.wayAheadTimeTable) {
      payload.wayAheadTimeTable = payload.wayAheadTimeTable.map(stripQuarterFiscalYear);
    }

    return payload;
  },

  candidateDecision: (payload) => {
    if (payload?.candidateBases) {
      payload.candidateBases = _.sortBy(payload.candidateBases, 'baseId.label');
      payload.candidateBases = payload.candidateBases.map(stripBaseIdStatus);
    }
    if(payload.candidateBaseApprovalDate) {
      payload.candidateBaseApprovalDate = timeZonifyDate(payload.candidateBaseApprovalDate, true)
    }
    return payload;
  },

  rationaleForDecisionLocations: (payload) => {
    if (payload?.rationaleForDecisionLocations) {
      payload.rationaleForDecisionLocations = sortBasesAndStatuses(payload.rationaleForDecisionLocations);
      payload.rationaleForDecisionLocations = payload.rationaleForDecisionLocations.map(stripBaseIdStatus);
    }
    return payload;
  },

  finalDecisionBases: (payload) => {
    if (payload?.finalDecisionBases) {
      payload.finalDecisionBases = payload.finalDecisionBases.map(stripBaseIdStatus);
    }
    return payload;
  },

  siteSurveyRequirements: (payload) => {
    if (payload.siteSurveyRequirements) {
      payload.siteSurveyIndexing = [];
      const requirements = [];

      Object.entries(payload.siteSurveyRequirements).reduce((acc, [type, valueArray]) => {
        const values = valueArray.map(value => {
          const requirementIndex = payload.siteSurveyIndexing.push(value.id) - 1;
          const formattedValue = { ...value, type, requirementIndex };

          if (value.requirement) {
            formattedValue.requirement = stripValueFromObject(value.requirement);
          }

          if (value?.subRequirements?.length) {

            formattedValue.subRequirements = value.subRequirements.map((item) => {
              const subRequirementIndex = payload.siteSurveyIndexing.push(item.id) - 1;
              const formattedSubRequirement = { ...item, type, requirementIndex: subRequirementIndex };


              if (item.requirement) {
                formattedSubRequirement.requirement = stripValueFromObject(item.requirement);
              }

              return formattedSubRequirement;
            });
          }

          return formattedValue;
        });

        acc.push(...values);

        return acc;
      }, requirements);

      payload.siteSurveyRequirements = requirements;
    }

    return payload;
  },

  summaryOfFindings: (payload, expeditionLevel) => {
    if (payload.summaryOfFindings && payload.siteSurveyIndexing) {
      let bases;
      if (expeditionLevel >= 3) {
        bases = [...((payload.candidateBases.length ? payload.candidateBases : payload.recommendedCandidates) || [])];
      } else {
        bases = [...payload.candidateBases];
      }
      bases = bases.filter((b) => b.baseId).map((b) => b.baseId)
      payload.summaryOfFindings = Object.values(payload.summaryOfFindings)
        .filter((data) => {
          if((data.note || data.riskFactor) && bases.includes(data.baseId)) {
            return true;
          }
          return false
        })
        .map(data => {
          let requirementIndex = payload.siteSurveyIndexing.indexOf(data.requirementId)

          return {
            ...data,
            requirementIndex,
            riskFactor: stripValueFromObject(data.riskFactor),
          }
        });
      delete payload.siteSurveyIndexing
    }
    return payload;
  },

  backups: (payload) => {
    if (payload?.backups) {
      payload.backups = Object.keys(payload.backups).map(field => ({...payload.backups[field], type: field}));
    }
    return payload;
  },

  preferredLocationDecisionDate: (payload) => {
    if(payload?.preferredLocationDecisionDate){
      payload.preferredLocationDecisionDate = timeZonifyDate(payload.preferredLocationDecisionDate, true);
    }
    return payload;
  },

  preferredLocationDecision: (payload) => {
    if(payload?.preferredLocationDecision && _.isArray(payload.preferredLocationDecision)){
      payload.preferredLocationDecision = sortBasesAndStatuses(payload.preferredLocationDecision);
      payload.preferredLocationDecision = payload.preferredLocationDecision
        .filter(pld => pld.baseId)
        .map((pld) => ({...pld, baseId: stripValueFromObject(pld.baseId)}));
    }
    return payload;
  },

  congressionalDates: (payload) => {
    if (payload?.congressionalDates) {
      payload.congressionalDates = Object.values(payload.congressionalDates).filter(item => ('plannedDateUpdatedOn' in item || 'actualDateUpdatedOn' in item || 'note' in item));
    }
    return payload;
  },

  weAreHere: (payload) => {
    if (payload.weAreHere) {
      payload.weAreHere = payload.weAreHere.fileKey;
    }

    return payload;
  }
};

/**
 * formatPayload runs through the values before submission
 * and applies a list of rules so that it is properly received by the back end.
 * @param {Object} values - the form values
 */
const formatPayload = (values, barId, barStatus, expeditionLevel) => {
  let payload = {...values};

  [
    formatters.stripValues,
    formatters.weaponSystem,
    formatters.facilityRequirements,
    formatters.costRequirements,
    formatters.timeRequirements,
    formatters.enterpriseDefinition,
    formatters.recommendedCandidates,
    formatters.missionCriteria,
    formatters.wayAheadTimeTable,
    formatters.candidateDecision,
    formatters.rationaleForDecisionLocations,
    formatters.finalDecisionBases,
    formatters.siteSurveyRequirements,
    formatters.summaryOfFindings,
    formatters.backups,
    formatters.preferredLocationDecisionDate,
    formatters.preferredLocationDecision,
    formatters.congressionalDates,
    formatters.weAreHere
  ].forEach(rule => rule(payload, expeditionLevel));

  return { id: barId, status: barStatus, barInfo: payload };
}

export default formatPayload;

