import React from 'react';
import { useFormState } from 'react-final-form';
import BarsList from '../../BarsList';
import RichTextDisplay from '../../../Form/RichTextDisplay';

const AgendaDisplay = ({ review }) => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });

  return (
    <>
      <BarsList bars={review.bars} />
      
      <h3>Congressional Announcements</h3>
      {initialValues.congressionalAnnouncement && <RichTextDisplay html={initialValues.congressionalAnnouncement} />}

      <h3>Opening Comments</h3>
      {initialValues.openingComments && <RichTextDisplay html={initialValues.openingComments} />}
    </>
  );
};

export default AgendaDisplay;