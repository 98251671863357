import React, { useRef } from 'react';
import { useField } from 'react-final-form';
import CloseButton from '../buttons/CloseButton';
import UploadButton from '../buttons/UploadButton';
import Grid from '../Grid';
import styles from './Form.module.scss';

const UploadDocsField = ({ name, add, remove, fields, docType, packageType }) => {
  const allUploadedDocs = useField(name);
  const uploadedDocs = allUploadedDocs?.input.value ? allUploadedDocs.input.value.filter(doc => doc.type && doc.type === docType) : [];
  const inputRef = useRef(null);

  const onInputChange = (e) => {
    const files = e.target.files;
    for (const file of files) {
      add({type: docType, file, package: packageType});
    }
    inputRef.current.value = '';
  };

  const onClickUpload = () => inputRef.current.click();

  const removeUnuploadedFile = (removeDoc) => {
    const index = fields?.value.findIndex((doc) => _.isEqual(doc.file, removeDoc.file) && doc.type === removeDoc.type);

    if(remove && index >= 0){
        remove(index)();
    }
  }

  return (
    <>
      <div className={styles.docListGrid}>
        {uploadedDocs.map((doc, index) =>
          doc.file ? (
            <Grid absoluteChild={<CloseButton className={styles.closeButton} onClick={() => removeUnuploadedFile(doc)} />}>
              <div className={styles.documentListEntry} key={index}>{doc.file.name}</div>
            </Grid>
           ) : null
        )}
      </div>

      <input name='doc' type='file' onChange={onInputChange} ref={inputRef} style={{ display: 'none' }} multiple/>
      <UploadButton className={styles.uploadsDocsFieldButton} onClick={onClickUpload} />

    </>
  );
}

export default UploadDocsField;