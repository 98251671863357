// returns options for a year select field
const STARTING_YEAR = 2009;
const YEARS_BEYOND_CURRENT = 21;

const getYearOptions = () => {
  const options = [];
  const latestYear = (new Date()).getFullYear() + YEARS_BEYOND_CURRENT;


  for (let i = STARTING_YEAR; i < latestYear; i++) {
    options.push({ value: String(i) });
  }
  options.push({ value: '0',label: "CLASS"});

  return options;
}

export default getYearOptions;