import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from "../../utils/sharedAxios";
import Button from '../buttons/Button';
import PreActionInterceptModal from './PreActionInterceptModal';
import AuthorizedContent from '../AuthorizedContent';
import ActionsOfInterestPanel from './ActionsOfInterestPanel';
import ApprovalsNotificationsPanel from './ApprovalsNotificationsPanel';
import ActionsOfInterestList from './ActionsOfInterestList';
import { AuthContext } from '../Utility/Authentication';
import styles from './Dashboard.module.scss';
import RequestsPanel from './RequestsPanel';
import UpcomingReviews from './UpcomingReviews';
import useDocumentTitle from '../hooks/useDocumentTitle';
import ActivityFeed from './ActivityFeed';

// application homepage, unique per user role
const Dashboard = () => {
  useDocumentTitle('Dashboard');
  const history = useHistory();
  const { user: { firstName = '', equity } = {}, refetchUserBars } = useContext(AuthContext);
  const [showPreActionModal, setShowPreActionModal] = useState(false);
  const [newBarId, setNewBarId] = useState(null);

  const handleInit = async ({ expeditionLevel }) => {
    await axios.post(`/bars/init`, { expeditionLevel: expeditionLevel.value })
      .then(async (result) => {
        setNewBarId(result.data.id);
        refetchUserBars();
      });
  }

  useEffect(() => {
    if (newBarId && equity && equity.bars.includes(newBarId)) {
      history.push(`/bars/create/${newBarId}`);
    }
  }, [equity, newBarId])
  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <h1 className={styles.title}>Hello, {firstName}</h1>

        <AuthorizedContent authorizedRoles={['SAM', 'ERIN']}>
          <Button className={styles.newActionButton} onClick={() => setShowPreActionModal(true)} primaryAlt>CREATE NEW ACTION</Button>
        </AuthorizedContent>
      </div>

      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <UpcomingReviews />
        </div>
        <div className={styles.contentRight}>
          <div className={styles.contentRightTop}>
            <ActionsOfInterestPanel />

            <RequestsPanel />

            <ApprovalsNotificationsPanel />
          </div>

          <AuthorizedContent authorizedRoles={['SAM', 'ERIN', 'KRIS']}>
            <ActivityFeed />
          </AuthorizedContent>

          <ActionsOfInterestList />
        </div>
      </div>

      {showPreActionModal && (
        <PreActionInterceptModal
          onClose={() => setShowPreActionModal(false)}
          onContinue={handleInit}
        />
      )}
    </div>
  );
}

export default Dashboard;
