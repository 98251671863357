import React, { useEffect } from 'react';
import Box from '../../Box';
import Grid from '../../Grid';
import FieldArray from '../../Form/FieldArray';
import PointsField from './PointsField';
import AddButton from '../../buttons/AddButton';
import CloseButton from '../../buttons/CloseButton';
import { useField } from 'react-final-form';
import getDecimal from '../../../utils/getDecimal';

const BasingCriteriaArrayField = ({ disabledCount = 0, fieldDataEndpoint, initialValue, lockDefinitions, maxLength, minLength, name, nested, primaryField: PrimaryField, parentTotalName }) => {
  const templateColumns = [2, '14fr 3fr'];
  const parentTotal = useField(parentTotalName);
  const { input } = useField(name);
  const ignoredKeys = ['point'];
  useEffect(() => {
    if (parentTotalName && Array.isArray(input.value) && input.value.length) {
      let total = 0;
      input.value.forEach(item => total += (item.point ? Number(item.point) : 0));
      parentTotal.input.value !== total && parentTotal.input.onChange(getDecimal(total));
    }
  }, [input.value, parentTotalName]);
  return (
    <Grid>
      <FieldArray name={name} maxLength={maxLength} minLength={minLength} ignoredKeys={ignoredKeys} initialValue={initialValue}>
        {({ fields, add, isMaxLength, isMinLength, remove }) => (
          <>
            {fields.map((name, index) => {
              const disabled = lockDefinitions || index < disabledCount;

              return (
                <React.Fragment key={name}>
                  <Grid columns={templateColumns} absoluteChild={!isMinLength && !disabled && <CloseButton onClick={remove(index)} />}>
                    <Box><PrimaryField name={`${name}.definition`} disabled={disabled} fieldDataEndpoint={fieldDataEndpoint} /></Box>
                    <Box><PointsField name={`${name}.point`} /></Box>
                  </Grid>
                  {nested && (
                    <Grid>
                      <FieldArray name={`${name}.children`} ignoredKeys={ignoredKeys} minLength={minLength} isNested>
                        {({ fields, isMinLength, remove }) => fields.map((name, index) => (
                          <Grid key={name} columns={templateColumns} absoluteChild={!isMinLength && !disabled &&<CloseButton onClick={remove(index)} />}>
                            <Box><PrimaryField name={`${name}.definition`} disabled={disabled} /></Box>
                            <Box><PointsField name={`${name}.point`} /></Box>
                          </Grid>
                        ))}
                      </FieldArray>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
            {!lockDefinitions && !isMaxLength && <Grid><AddButton onClick={()=> add()} secondary>Add Criteria</AddButton></Grid>}
          </>
        )}
      </FieldArray>
    </Grid>
  )
}

export default BasingCriteriaArrayField;
