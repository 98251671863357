import React from 'react';
import cn from 'classnames';
import Add from '@material-ui/icons/Add';
import styles from './AddButton.module.scss';

const AddButton = ({ children, className, disabled, name, onClick, type = 'button', value }) => {
  return (
    <button
      className={cn(styles.addButton, className)}
      disabled={disabled}
      name={name}
      onClick={onClick}
      type={type}
      value={value}>
        <Add />
        {children}
    </button>
  );
}

export default AddButton;
