import { useEffect, useState } from 'react';

// a simple state hook for situations when a cache item should be busted
const useCacheInvalidator = () => {
  const [shouldInvalidate, setShouldInvalidate] = useState(false);

  useEffect(() => {
    if (shouldInvalidate) {
      setShouldInvalidate(false);
    }
  }, [shouldInvalidate]);

  return { shouldInvalidate, setShouldInvalidate };
}

export default useCacheInvalidator;
