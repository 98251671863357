import React from 'react';
import { useFormState } from 'react-final-form';
import RichTextDisplay from '../../Form/RichTextDisplay';

const CongressionalLandscapeDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });

  return initialValues.congressionalLandscape ? <RichTextDisplay html={initialValues.congressionalLandscape} /> : null;
}

export default CongressionalLandscapeDisplay;
