import cn from 'classnames';
import styles from './DisplayFacility.module.scss';

const joinRealValues = (arr, separator) => arr.filter(i => !!i).join(separator);

const DisplayFacility = ({ areaType, className, notes, quantity, selection, totalAreaSqft, unitType }) => {
  const qtyString = joinRealValues([quantity, unitType], ' ');
  const areaString = joinRealValues([totalAreaSqft, areaType], ' ');
  const qtyAreaString = joinRealValues([qtyString, areaString], ', ');
  const facilityString = joinRealValues([selection, qtyAreaString], ': ');

  return (
    <div className={cn(styles.displayFacilityWrapper, className)}>
      <p>{facilityString}</p>
      {notes && <p>Notes: {notes}</p>}
    </div>

  )
}

export default DisplayFacility;