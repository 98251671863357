import Button from '../../../buttons/Button';
import Modal from '../../../Modal';
import ModalFooter from '../../../Modal/ModalFooter';
import styles from '../../Admin.module.scss'

const ConfirmDeleteContactModal = ({ contact, handleDeleteContact, onClose }) => {
  return (
    <Modal className={styles.modalMedium} onClose={onClose} title='Delete Contact?'>
      <p>Are you sure you want to delete {contact.userRank} {contact.firstName} {contact.lastName}? If they are a user, they will be notified and no longer able to access SABER.</p>
      
      <ModalFooter>
        <Button onClick={handleDeleteContact} danger>DELETE</Button>
        <Button onClick={onClose} secondary>CANCEL</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmDeleteContactModal;