import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import Modal from '../Modal';
import SelectField from '../Form/SelectField';
import DateField from '../Form/DateField';
import ModalFooter from '../Modal/ModalFooter';
import Button from '../buttons/Button';
import axios from '../../utils/sharedAxios';
import styles from './ReviewCenter.module.scss';
import useCachedData from '../hooks/useCachedData';
import moment from 'moment';
import FieldArray from '../Form/FieldArray';
import AddButton from '../buttons/AddButton';
import CloseButton from '../buttons/CloseButton';
import Grid from '../Grid';
import Label from '../Form/Label';
import { Box } from '@material-ui/core';
import { timeZonifyDate } from '../../utils/timezone';

// Modal for creating and editing reviews
const ReviewModal = ({ initialValues, onClose, onSuccess, title }) => {
  const { data: { options: phaseOptions } = {} } = useCachedData('/content/phaseOptions');
  const [barOptions, setBarOptions] = useState([]);

  useEffect(() => {
    const fetchAllBars = async () => {
      const { data: barData } = await axios.get('/bars/all_with_title?status=active');

      if (barData) {
        setBarOptions(barData.map(({ id, title }) => {
          return ({
            value: id,
            label: title
          })
        }));
      }
    }

    fetchAllBars();
  },[]);

  const onSubmit = async (values) => {
    const bars = values.bars?.map(({ barId, phase }) => ({ barId: barId.value, phase: phase.value })) || [];
    values = {...values, step: values.step.value, bars, date: timeZonifyDate(values.date)};
    const response = await axios.post('/reviews/schedule', {...values});
    const { id, step } = response.data;
    await onSuccess(step, id);
    onClose();
  }

  const validate = (values) => {
    const errors = {};
    if (!values.step) {
      errors.step = 'You must select a step'
    }

    if (!values.date) {
      errors.date = 'You must choose a date'
    }

    return errors;
  }

  const validateSelect = value => value ? undefined : 'This field is required';

  return (
    <>
      <Modal className={styles.reviewModal} onClose={onClose} title={title}>
        <Form onSubmit={onSubmit} initialValues={initialValues} mutators={{ ...arrayMutators }} subscription={{ invalid: true, submitting: true }} validate={validate}>
          {({ form, handleSubmit }) => {
            const { invalid } = form.getState();

            return (
              <form onSubmit={handleSubmit} noValidate>
                <SelectField name='step' label='Step' fieldDataEndpoint='/content/stepOptions' placeholderText='Select Step' required isClearable={false} />
                <DateField name='date' label='Date' required />
                <FieldArray name='bars' minLength={0}>
                  {({ add, fields, isMinLength, remove }) => (
                    <>
                      <Grid columns={[2]}>
                        <Box><Label text='Basing Action' /></Box>
                        <Box><Label text='Phase' /></Box>
                      </Grid>
                      {fields.map((name, index) => (
                        <Grid key={name} columns={[2]} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
                          <SelectField name={`${name}.barId`} isSearchable options={barOptions} placeholderText='Type action title, or select from list' isClearable={false} validate={validateSelect}/>
                          <SelectField name={`${name}.phase`} options={phaseOptions} isClearable={false} validate={validateSelect} />
                        </Grid>
                      ))}
                      {<AddButton onClick={()=> add()} secondary>Add Basing Action</AddButton>}
                    </>
                  )}
                </FieldArray>

                <Field component='input' type='hidden' name='id' />

                <ModalFooter className={styles.buttonRow}>
                  <Button type='submit' primary disabled={invalid}>SCHEDULE</Button>
                  <Button onClick={onClose} secondary>CANCEL</Button>
                </ModalFooter>
              </form>
            );
          }}
        </Form>
      </Modal>
    </>
  );
}

export default ReviewModal;