import React from 'react';
import { useFormState } from 'react-final-form';
import useCachedData from '../../hooks/useCachedData';
import _ from 'lodash';

const EisSummaryDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });

  const fileKey = _.get(initialValues, `eisSummaryOfFindings.fileKey`);
  const { data: fileSrc } = useCachedData(fileKey && '/documents/image', {params: { docId: fileKey } });
  return (
    <>
      {fileSrc && (
        <>
          <img src={fileSrc} />
        </>
      )}
    </>
  );
}

export default EisSummaryDisplay;
