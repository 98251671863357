import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import Display from './UpcomingDecisionsDisplay';
import Edit from './UpcomingDecisionsEdit';

const UpcomingDecisions = ({ isEditing, reviewId, ...props }) => {
  const formApi = useForm();

  useEffect(() => {
    formApi.change('confirmedDecisionsPendingMFR', 1)
  }, []);

  return isEditing ? <Edit {...props} /> : <Display {...props} />;
}
export default UpcomingDecisions