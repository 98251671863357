import React from 'react'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import MenuItem from "@material-ui/core/MenuItem";
import Select from 'react-select';
import styles from './Dropdown.module.scss';
import formStyles from '../Form/Form.module.scss';
import cn from 'classnames';

const DropDown = (props) => {
    let {name, headerLabel, value, setter, selectOptions, className} = props;
    selectOptions = [{label: headerLabel || 'None', value: ''}].concat(selectOptions);

    const handleChange = (event) => {
        setter(name, event.value);
    };

    return(
        <Select
            className={cn(styles.dropdown, formStyles.select, className)}
            classNamePrefix='reactSelect'
            isSearchable={false}
            id={name}
            name={name}
            value={value}
            onChange={handleChange}
            options={selectOptions}
            placeholder={headerLabel || 'Select'}
             />
    )
}

export default DropDown;
