import React from 'react';
import DisplayDate from '../../../DisplayDate';
import TextField from '../../../Form/TextField';
import TextArea from '../../../Form/TextArea';
import Label from '../../../Form/Label';
import useCachedData from '../../../hooks/useCachedData';
import dateFormats from '../../../../constants/dateFormats';
import styles from '../../GenerateMaterials.module.scss';

const CoverEdit = ({ review }) => {
  const { data: steps = {} } = useCachedData('/content/steps');

  return (
    <div className={styles.coverEdit}>
      <h3>{steps[review.step]}</h3>
      
      <p className={styles.coverDate}><DisplayDate date={review.date} format={dateFormats.short} /></p>
      
      <Label text='Controlled By' />
      <TextField name='controlledByOrg1' />

      <Label text='Controlled By' />
      <TextField name='controlledByOrg2' />

      <Label text='CUI Category' />
      <TextField name='cuiCategory' />

      <Label text='Distribution / Dissemination Control' />
      <TextField name='distributionControl' />

      <Label text='POC' />
      <TextField name='poc' />

      <Label text='Meeting Chair' />
      <TextArea name='meetingChair' />
    </div>

  );
};

export default CoverEdit;