import { useContext } from 'react';
import styles from './Dashboard.module.scss';
import AllDrafts from './AllDrafts';
import Watched from './Watched';
import MyActions from './MyActions';
import AuthorizedContent from '../AuthorizedContent';
import Priority from './Priority';
import { AuthContext } from '../Utility/Authentication';
import EquityActions from './EquityActions';

const ActionsOfInterestPanel = () => {
  const { user: { id, office = '' } = {} } = useContext(AuthContext);

  return (
    <AuthorizedContent authorizedRoles={['SAM', 'ERIN', 'KRIS']}>
      <div className={styles.panel}>
        <h5 className={styles.panelHeading}>ACTIONS OF INTEREST</h5>

        <AuthorizedContent authorizedRoles='SAM'>
          <div className={styles.chipColumns}>
            <div className={styles.chipRows}>
              <MyActions userId={id} />
              <AllDrafts />
            </div>

            <div className={styles.chipRows}>
              <Watched />
              <Priority />
            </div>
          </div>
        </AuthorizedContent>

        <AuthorizedContent authorizedRoles='ERIN'>
          <div className={styles.chipColumns}>
            <div className={styles.chipRows}>
              <MyActions office={office} />
              <AllDrafts office={office} />
            </div>

            <div className={styles.chipRows}>
              <EquityActions />
              <Watched />
            </div>
          </div>
        </AuthorizedContent>

        <AuthorizedContent authorizedRoles='KRIS'>
          <Watched />
        </AuthorizedContent>
      </div>
    </AuthorizedContent>

  );
}

export default ActionsOfInterestPanel;
