import { useField } from 'react-final-form';
import useCachedData from '../../../hooks/useCachedData';

const ContactRoleDescription = ({ roleFieldName = 'role' }) => {
  const { input: { value } } = useField(roleFieldName);
  const { data: roleDescriptions } = useCachedData('/content/roleDescriptions');
  let description = '';
  if (value?.value) {
    description = roleDescriptions[value.value]
  }
  
  return (
    <p>{description}</p>  
  )
}

export default ContactRoleDescription;