import React from 'react';
import DateField from '../../Form/DateField';
import Label from '../../Form/Label';
import { Field, useForm } from 'react-final-form';
import useUpdatedByField from '../../hooks/useUpdatedByField';
import styles from './CongressionalDates.module.scss';
import formStyles from '../../Form/Form.module.scss';
import TextArea from '../../Form/TextArea';
import phaseAbbreviations from '../../../constants/phaseAbbreviations';

const CongressionalDatesEdit = ({ deleteFile, barPhase }) => {
  // criteria
  useUpdatedByField('congressionalDates.criteria.plannedDateUpdatedOn', 'congressionalDates.criteria.plannedDateUpdatedBy', ['congressionalDates.criteria.plannedDate']);
  useUpdatedByField('congressionalDates.criteria.actualDateUpdatedOn', 'congressionalDates.criteria.actualDateUpdatedBy', ['congressionalDates.criteria.actualDate']);
  // candidates
  useUpdatedByField('congressionalDates.candidates.plannedDateUpdatedOn', 'congressionalDates.candidates.plannedDateUpdatedBy', ['congressionalDates.candidates.plannedDate']);
  useUpdatedByField('congressionalDates.candidates.actualDateUpdatedOn', 'congressionalDates.candidates.actualDateUpdatedBy', ['congressionalDates.candidates.actualDate']);
  // preferredLocation
  useUpdatedByField('congressionalDates.preferredLocation.plannedDateUpdatedOn', 'congressionalDates.preferredLocation.plannedDateUpdatedBy', ['congressionalDates.preferredLocation.plannedDate']);
  useUpdatedByField('congressionalDates.preferredLocation.actualDateUpdatedOn', 'congressionalDates.preferredLocation.actualDateUpdatedBy', ['congressionalDates.preferredLocation.actualDate']);
  // finalDecision
  useUpdatedByField('congressionalDates.finalDecision.plannedDateUpdatedOn', 'congressionalDates.finalDecision.plannedDateUpdatedBy', ['congressionalDates.finalDecision.plannedDate']);
  useUpdatedByField('congressionalDates.finalDecision.actualDateUpdatedOn', 'congressionalDates.finalDecision.actualDateUpdatedBy', ['congressionalDates.finalDecision.actualDate']);

  const renderCongressionalDateGroup = (phaseTitle, phaseNumber, phaseLabel) => (
    <div key={phaseLabel} className={styles.dateGroup}>
      <h3 className={formStyles.sectionHeading}>{phaseTitle}</h3>
      <div className={styles.dateGroupFields}>
        <div>
          <Label text='Planned Date' />
          <DateField name={`congressionalDates.${phaseLabel}.plannedDate`} />
          <Label text='Actual Date' />
          <DateField name={`congressionalDates.${phaseLabel}.actualDate`} />
        </div>
        <div className={styles.dateGroupNotes}>
          <Label text='Notes' />
          <TextArea name={`congressionalDates.${phaseLabel}.note`} />
        </div>
      </div>

      <Field component='input' type='hidden' name={`congressionalDates.${phaseLabel}.phase`} initialValue={phaseNumber} />
      <Field component='input' type='hidden' name={`congressionalDates.${phaseLabel}.phaseLabel`} initialValue={phaseLabel} />
      <Field component='input' type='hidden' name={`congressionalDates.${phaseLabel}.id`} />
    </div>
  );

  return (
    <>
      {Object.entries(phaseAbbreviations).map(([phaseNumber, { long, key }]) => renderCongressionalDateGroup(long, phaseNumber, key))}
    </>
  );
}

export default CongressionalDatesEdit;