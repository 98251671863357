import {Textarea} from 'theme-ui';
import React from 'react';

const TextArea = (props) => {
  const {onChange, value, width} = props;
  
  return(
    <Textarea
      style={{borderColor: '#3e45fb', resize: 'none', width: width ? width + 'px' : '648px', borderRadius: 0}}
      value={value}
      onChange={(e)=>{
        if(onChange){
          onChange(e);
        }
      }}
      rows={5}
    />
  )
}
export default TextArea;
