import React, { useEffect } from 'react';
import { useField } from 'react-final-form';
import FieldArray from '../../Form/FieldArray';
import ReadOnlyField from '../../Form/ReadOnlyField';
import Box from '../../Box';
import Grid from '../../Grid';
import PointsField from './PointsField';
import styles from './BasingCriteria.module.scss';

const templateColumns = [2, '14fr 3fr'];

const MissionRequirementsPointsField = () => {
  const parent = useField('missionRequirementTotalPoints');
  const { input } = useField('missionRequirements');

  useEffect(() => {
    if (Array.isArray(input.value) && input.value.length) {
      let total = 0;
      input.value.forEach(item => total += (item.point ? Number(item.point) : 0));
      parent.input.value !== total && parent.input.onChange(total);
    }
  }, [input.value]);


  return (
    <>
      <FieldArray name='missionRequirements' minLength={0}>
        {({ fields }) => (
          <div>
            {fields.map((name, index) => (
              <div key={index}>
                <Grid columns={templateColumns}>
                  <Box><ReadOnlyField className={styles.requirement} name={`${name}.requirement`} /></Box>
                  <Box><PointsField name={`${name}.point`} readOnly={input.value[index]?.subRequirements?.length} /></Box>
                </Grid>

                <Grid><SubRequirements name={`${name}.subRequirements`} parentTotalName={`${name}.point`} /></Grid>
              </div>
            ))}
          </div>
        )}
      </FieldArray>
    </>
  )
}

const SubRequirements = ({ name, parentTotalName }) => {
  const parent = useField(parentTotalName);
  const { input } = useField(name);

  useEffect(() => {
    if (Array.isArray(input.value) && input.value.length) {
      let total = 0;
      input.value.forEach(item => total += (item.point ? parseInt(item.point, 10) : 0));
      parent.input.value !== total && parent.input.onChange(total);
    }
  }, [input.value]);


  return (
    <FieldArray name={name} minLength={0} isNested>
      {({ fields }) => (
        <div>
          {fields.map((fieldName, index) => (
            <Grid key={index} columns={templateColumns}>
              <Box><ReadOnlyField className={styles.subRequirement} name={`${fieldName}.subRequirement`} /></Box>
              <Box><PointsField name={`${fieldName}.point`} /></Box>
            </Grid>
          ))}
        </div>
      )}
    </FieldArray>
  );
}

export default MissionRequirementsPointsField;
