import React from 'react';
import Box from '../../Box';
import Grid from '../../Grid';
import SelectField from '../../Form/SelectField';
import Label from '../../Form/Label';
import FieldArray from '../../Form/FieldArray';
import TextField from '../../Form/TextField';
import AddButton from '../../buttons/AddButton';
import CloseButton from '../../buttons/CloseButton';
import getYearOptions from '../../../utils/getYearOptions';

const QUARTERS_OPTIONS = [
  { value: '1' },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { value: '0', label: 'CLASS'}
];

const YEARS_OPTIONS = getYearOptions();

const MilestoneField = () => {
  const templateColumns = [5, '3fr 1fr 2fr 1fr 6fr']
  return (
    <FieldArray name='wayAheadTimeTable'>
      {({ fields, add, isMaxLength, isMinLength, remove }) => (
        <>
          <Grid columns={templateColumns}>
            <Box>
              <Label text='Quarter' required />
            </Box>
            <Box />
            <Box>
              <Label text='Fiscal Year' required />
            </Box>
            <Box />
            <Box>
              <Label text='Milestone' required />
            </Box>
          </Grid>
          {fields.map((name, index) => (
            <Grid key={name} columns={templateColumns} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
              <Box>
                <SelectField name={`${name}.quarter`} options={QUARTERS_OPTIONS} />
              </Box>
              <Box />
              <Box>
                <SelectField name={`${name}.fiscalYear`} options={YEARS_OPTIONS} />
              </Box>
              <Box />
              <Box>
                <TextField name={`${name}.milestone`} />
              </Box>
            </Grid>
          ))}
          {!isMaxLength && <Grid><AddButton onClick={()=> add()} secondary>Add Milestone</AddButton></Grid>}
        </>
      )}
    </FieldArray>
  );
}

export default MilestoneField;
