import { useContext } from 'react';
import Anchor from '../Anchor';
import { AuthContext } from '../Utility/Authentication';

// links to a bar, but disables if user does not have proper equity
const DisplayBarLink = ({ className, children, barId, initialState }) => {
  const {user} = useContext(AuthContext);
  const enabled = user?.role === 'ERIN' ? user?.equity?.bars?.includes(barId) : true;

  const search = initialState ? Object.entries(initialState).map(([key, val]) => `${key}=${val}`).join('&') : '';
  const href = `/bars/detail/${barId}${search ? `?${search}` : ''}`;

  return <Anchor className={className} href={href} disabled={!enabled} light>{children}</Anchor>;
}

export default DisplayBarLink;