import React from 'react';
import Table from '../../Table';

const ManpowerDisplay = ({ initialValues }) => {
    const parseAndSumFields = (fields = []) => {
        let total = 0;
        fields.forEach(field => {
            const value = parseInt(field);
            if (!isNaN(value)) {
                total += value;
            }
        });

        return total;
    }

  return (initialValues.manpower ? initialValues.manpower.map((item, index) => (
      <Table key={index}>
        <thead>
            <tr>
                <th>{item.currentOrProposed === 'proposed' ? 'Proposed Manpower' : 'Current Manpower'}</th>
                <th>Officer</th>
                <th>Enlisted</th>
                <th>Civilian</th>
                <th>Contractor</th>
                <th>Total</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Full Time: Active Duty/Res/Guard</td>
                <td>{item.ftOfficer || 0}</td>
                <td>{item.ftEnlisted || 0}</td>
                <td>{item.ftCivilian || 0}</td>
                <td>{item.ftContractor || 0}</td>
                <td>{item.ftTotal || 0}</td>
            </tr>
            <tr>
                <td>Part Time: Res/Guard</td>
                <td>{item.nonftOfficer || 0}</td>
                <td>{item.nonftEnlisted || 0}</td>
                <td>{item.nonftCivilian || 0}</td>
                <td>{item.nonftContractor || 0}</td>
                <td>{item.nonftTotal || 0}</td>
            </tr>
            <tr>
                <td colSpan={5}>Average Daily Student Load</td>
                <td>{item.AvgDailyStudentLoad || 0}</td>
            </tr>
            {item.currentOrProposed === 'proposed' && (
              <tr>
                  <td colSpan={5}>Base Support Tail</td>
                  <td>{item.baseSupportTail || 0}</td>
              </tr>
            )}
            <tr>
                <td>Total</td>
                <td>{parseAndSumFields([item.ftOfficer, item.nonftOfficer])}</td>
                <td>{parseAndSumFields([item.ftEnlisted, item.nonftEnlisted])}</td>
                <td>{parseAndSumFields([item.ftCivilian, item.nonftCivilian])}</td>
                <td>{parseAndSumFields([item.ftContractor, item.nonftContractor])}</td>
                <td>{parseAndSumFields([item.ftTotal, item.nonftTotal, item.baseSupportTail, item.AvgDailyStudentLoad])}</td>
            </tr>
        </tbody>
      </Table>
  )) : null );

}

export default ManpowerDisplay;
