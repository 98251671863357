import React from 'react';
import { useFormState } from 'react-final-form';
import FacilityDisplay from '../FacilityDisplay';
import ReadOnlyFacilityField from '../../Form/ReadOnlyFacilityField';
import styles from '../../Form/Form.module.scss';

const SiteSurveyCriteriaDisplay = () => {
  const { initialValues: { siteSurveyRequirements = {} } = {} } = useFormState({ subscription: { initialValues: true } });

  const displayNestedList = (requirements, label) => requirements?.length > 0 && (
    <>
      {label}
      <ul>
        {requirements.map(({ requirement, subRequirements = [] }, index) => (
          <li key={index}>
            {requirement}
            {subRequirements.length > 0 && (displayNestedList(subRequirements))}
          </li>
        ))}
      </ul>
    </>
  );

  const displayFacilities = (facilities, label) => facilities?.length > 0 && (
    <>
      {label}
      <ul>
        {facilities.map((facility, index) => (
          <li key={index}><FacilityDisplay {...facility} facilityKey='requirement'/></li>
        ))}
      </ul>
    </>
  );

  const displayAdditionalCosts = (requirements, label) => requirements?.length > 0 && (
    <>
      {label}
      <ul>
        {requirements.map(({ requirement, quantity }, index) => (
          <li key={index}>
            {requirement} ({quantity ? 'Recurring' : 'One-Time'})
          </li>
        ))}
      </ul>
    </>
  );

  return (
    <>
      {displayNestedList(siteSurveyRequirements.mission, <h3 className={styles.sectionHeading}>Mission</h3>)}
      {displayNestedList(siteSurveyRequirements.jointAndAllDomainTraining, <h3 className={styles.sectionHeading}>Joint and All Domain Training</h3>)}
      {displayNestedList(siteSurveyRequirements.airspaceAndTrainingAreas, <h3 className={styles.sectionHeading}>Airspace and Training Areas</h3>)}
      {siteSurveyRequirements?.facility?.length > 0 && <h3 className={styles.sectionHeading}>Capacity</h3>}
      {displayFacilities(siteSurveyRequirements.facility, <p>Facilities</p>)}
      {displayNestedList(siteSurveyRequirements.baseSupportTail, <p>Base Support Tail</p>)}
      {displayNestedList(siteSurveyRequirements.communitySupport, <p>Community Support</p>)}
      {displayNestedList(siteSurveyRequirements.environmental, <h3 className={styles.sectionHeading}>Environmental</h3>)}
      {displayNestedList(siteSurveyRequirements.militaryFamilyReadiness, <h3 className={styles.sectionHeading}>Military Family Readiness</h3>)}
      {displayNestedList(siteSurveyRequirements.supportOfMilitaryFamilies, <p>Support of Military Families</p>)}
      {(siteSurveyRequirements?.costOneTime?.length > 0
        || siteSurveyRequirements?.costRecurring?.length > 0
        || siteSurveyRequirements?.costAdditional?.length > 0) && <h3 className={styles.sectionHeading}>Cost</h3>}
      {displayNestedList(siteSurveyRequirements.costOneTime, <p>One Time Costs</p>)}
      {displayNestedList(siteSurveyRequirements.costRecurring, <p>Recurring Costs</p>)}
      {displayAdditionalCosts(siteSurveyRequirements.costAdditional, <p>Additional Costs</p>)}
    </>
  );
}

export default SiteSurveyCriteriaDisplay;