import React from 'react';
import phaseAbbreviations from '../../constants/phaseAbbreviations';
import styles from './GenerateMaterials.module.scss';

const PhaseLegend = () => (
  <div className={styles.phaseLegend}>
    <h4 className={styles.phaseLegendHeading}>Legend</h4>
    <ul className={styles.phaseList}>
      <li>
        <span className={styles.short}>{phaseAbbreviations[1].short}</span>
        <span className={styles.long}>{phaseAbbreviations[1].long}</span>
      </li>
      <li>
        <span className={styles.short}>{phaseAbbreviations[2].short}</span>
        <span className={styles.long}>{phaseAbbreviations[2].long}</span>
      </li>
      <li>
        <span className={styles.short}>{phaseAbbreviations[3].short}</span>
        <span className={styles.long}>{phaseAbbreviations[3].long}</span>
      </li>
      <li>
        <span className={styles.short}>{phaseAbbreviations[4].short}</span>
        <span className={styles.long}>{phaseAbbreviations[4].long}</span>
      </li>
    </ul>
  </div>
);

export default PhaseLegend;