import React, { Fragment, useState, useEffect } from 'react';
import { validatePermissionsInterval } from '../../utils/config';
import axios from '../../utils/sharedAxios';
import useCachedData from '../hooks/useCachedData';

// context for distributing user info to any react component that needs it
export const AuthContext = React.createContext({ uuid: null, id: null });

/**
 * React component that initiates authentication and fetches user info, then displays child content if the user has been registered.
 * Otherwise, forces the user to registration page
 * @param {(Component | Component[])} children any child component(s)
 * @return {(Fragment | null)} child component(s) if user is authenticated. Otherwise, redirects browser to registration
 *                             and returns null.
 */
function Authentication({ children }) {
  const { data: statuses } = useCachedData('/content/userStatuses');
  const [userData, setUserData] = useState(null);
  const [retrieveUserError, setRetrieveUserError] = useState(false);
  const [gettingInfo, setGettingInfo] = useState(false);

  useEffect(() => {
    // Fetch data for the authenticated user
    if(!gettingInfo && statuses){
      setGettingInfo(true);
      axios.get('/auth/getLoggedInUser', { params: { redirectUrl: window.location.pathname } }).then((result)=>{
        const {data} = result;
        if(data.url){
          window.location.replace(data.url);
        }
        getUserBarData(data);
      })
    }
  }, [gettingInfo, statuses]);

  const refetchUserBars = async () => {
    await axios.get('/search/my-bars').then((result)=>{
      const {data: bars} = result;
      if(!bars.errors){
        setUserData({...userData, equity: {bars, timeRetrieved: new Date()}});
      }
    });
  };

  const getUserBarData = (user) => {
    if(user.errors){
      setRetrieveUserError(user.errors);
    }
    else if(user.role === statuses.pendingApproval || user.role === statuses.unregistered) {
      setUserData(user);
      if(window.location.pathname !== '/register') {
        window.location.replace('/register');
      }
    }
    else{
      axios.get('/search/my-bars').then((result)=>{
        const {data: bars} = result;
        if(bars.errors){
          setUserData(user)
        }
        else{
          setUserData({...user, equity: {bars, timeRetrieved: new Date()}});
        }
      })
    }
  }

  const onChangeLink = () => {
    if(userData && (userData.role === statuses?.pendingApproval || userData.role === statuses?.unregistered)) {
      if(window.location.pathname !== '/register') {
        window.location.replace('/register');
      }
    }
    const d = new Date();
    const timeRetrieved = userData?.equity?.timeRetrieved ? new Date(userData.equity.timeRetrieved) : null;
    if(userData?.equity?.timeRetrieved && d - timeRetrieved >= validatePermissionsInterval){
      axios.get('/search/my-bars').then((result)=>{
        const {data: bars} = result;
        if(bars.errors){
          setUserData({...userData, equity: {bars: []}}); // no equity by default
        }
        else{
          setUserData({...userData, equity: {bars, timeRetrieved: new Date()}});
        }
      });
    }
  }

  if (retrieveUserError) {
    return (
      <div className="content">
        There was a problem while trying to sign you into the system
      </div>
    );
  }

  if ((gettingInfo && !userData) || !gettingInfo) {
    return (
      <div className="content">
        Loading...
      </div>
    );
  }

  // provide user data as a context
  return (
    <AuthContext.Provider value={{user: userData, onLinkChange: onChangeLink, refetchUserBars}}>
      {children}
    </AuthContext.Provider>
  );
}

export default Authentication;
