import React from 'react';
import { useFormState } from 'react-final-form';
import DisplayDate from '../../../DisplayDate';
import useCachedData from '../../../hooks/useCachedData';
import dateFormats from '../../../../constants/dateFormats';
import styles from '../../GenerateMaterials.module.scss';

const CoverDisplay = ({ review }) => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });
  const { data: steps = {} } = useCachedData('/content/steps');

  return (
    <>
      <h3>{steps[review.step]}</h3>
      
      <p className={styles.coverDate}><DisplayDate date={review.date} format={dateFormats.short} /></p>
      
      {initialValues.controlledByOrg1 && (<p>Controlled By: {initialValues.controlledByOrg1}</p>)}

      
      {initialValues.controlledByOrg2 && (<p>Controlled By: {initialValues.controlledByOrg2}</p>)}

      
      {initialValues.cuiCategory && (<p>CUI Category: {initialValues.cuiCategory}</p>)}

      
      {initialValues.distributionControl && (<p>Distribution / Dissemination Control: {initialValues.distributionControl}</p>)}

      
      {initialValues.poc && (<p>POC: {initialValues.poc}</p>)}

      
      {initialValues.meetingChair && (<p>Meeting Chair: {initialValues.meetingChair}</p>)}
    </>
  );
};

export default CoverDisplay;