import React, { useState, useEffect } from 'react';
import Wysiwig from '../../Form/Wysiwyg';
import Label from '../../Form/Label';
import useCachedData from '../../hooks/useCachedData';
import AddButton from '../../buttons/AddButton';
import SelectField from '../../Form/SelectField';
import FieldArray from '../../Form/FieldArray';
import styles from './RecommendedCandidates.module.scss';
import CloseButton from '../../buttons/CloseButton';
import Grid from '../../Grid';
import getBaseOptions from '../../../utils/getBaseOptions';

const RecommendedCandidatesEdit = () => {
  const { data: bases } = useCachedData('/content/bases');
  const [baseOptions, setBaseOptions] = useState([]);
  const templateColumns = [2, '15fr', '3fr'];
  useEffect(() => {
    if (bases) {
      const formattedBases = getBaseOptions(bases);
      
      setBaseOptions(formattedBases);
    }
  }, [bases])

  return (
    <>
      <FieldArray name='recommendedCandidates' minLength={1}>
        {({ fields, add, remove, isMinLength }) => (
          <>
            {fields.map((name, index) => {
              return (
                <div key={index} className={styles.location}>
                  <Grid columns={templateColumns} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
                  <SelectField label='Location: ' name={`${name}.baseId`} options={baseOptions} isSearchable /></Grid>
                  <Label text='Rationale: ' />
                  <Wysiwig name={`${name}.rationale`}/>
                  {index === fields.length - 1 && <AddButton onClick={()=> add()} secondary>Add Location</AddButton>}
              </div>
            )})}
          </>
        )}
      </FieldArray>
    </>
  )
}

export default RecommendedCandidatesEdit;
