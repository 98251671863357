const Download = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15">
    <g transform="translate(-1301.637 -138)">
      <rect width="12" height="2" transform="translate(1301.637 151)"/>
      <rect width="2" height="7" transform="translate(1306.637 138)"/>
      <path d="M5,0l5,5H0Z" transform="translate(1312.637 150) rotate(180)"/>
    </g>
  </svg>
);

export default Download;
