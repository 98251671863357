import React from 'react';
import FieldArray from '../../Form/FieldArray';
import TextField from '../../Form/TextField';
import Box from '../../Box';
import Grid from '../../Grid';
import AddButton from '../../buttons/AddButton';
import CloseButton from '../../buttons/CloseButton';
import styles from './Requirements.module.scss';
import Label from '../../Form/Label';

const MissionRequirementsField = () => {
  return (
    <FieldArray name='missionRequirements' ignoreKeys={['subRequirements']}>
      {({ fields, add, remove, isMaxLength, isMinLength }) => (
        <div className={styles.requirementRow}>
          <Label text='Mission Requirement' />
          {fields.map((name, index) => (
            <React.Fragment key={index}>
              <Grid key={index} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
                <Box>
                  <TextField name={`${name}.requirement`} placeholder='Enter Requirement' />
                </Box>
              </Grid>

              <Grid><SubRequirements name={`${name}.subRequirements`} /></Grid>
            </React.Fragment>
            ))}
            {!isMaxLength && <Grid><AddButton onClick={()=> add()} secondary>Add Requirement</AddButton></Grid>}
        </div>
      )}
    </FieldArray>
  )
}

const SubRequirements = ({ name }) => {
  return (
    <FieldArray name={name} minLength={0} isNested>
      {({ fields, add, remove, isMaxLength, isMinLength }) => (
        <div className={styles.subRequirementRow}>
          {fields.map((name, index) => (
            <Grid key={index} absoluteChild={!isMinLength && <CloseButton onClick={remove(index)} />}>
              <Box>
                <TextField name={`${name}.subRequirement`} placeholder='Enter Sub-Requirement' />
              </Box>
            </Grid>
            ))}
            {!isMaxLength && <Grid><AddButton onClick={()=> add()} secondary>Add Sub-Requirement</AddButton></Grid>}
        </div>
      )}
    </FieldArray>
  );
}

export default MissionRequirementsField;
