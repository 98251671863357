import Button from '../buttons/Button';
import useCachedData from '../hooks/useCachedData';
import useCompletionStatus from '../hooks/useCompletionStatus';

const SubmitBarButton = ({ expeditionLevel, submit }) => {
  const phase = expeditionLevel > 1 ? expeditionLevel : 0;
  const { data: { orderedViews = [] } = {} } = useCachedData('/content/getOrderedViews', { params: { expeditionLevel, phase, step: 0 }});
  const { completed } = useCompletionStatus(orderedViews, '/content/editingCompletionMapping');

  return (
    <Button onClick={submit} primaryAlt disabled={!completed}>Submit</Button>
  )
}

export default SubmitBarButton;