import { useEffect, useState } from 'react';
import axios from '../../utils/sharedAxios';

const cache = {};

// fetches and caches data with the pathname and options as a key
// key may point to a pending Promise or the resulting data
const useCachedData = (endpoint, options) => {
  const { invalidate, ...restOptions } = options || {};
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState();
  const optionsString = Object.values(restOptions).length ? JSON.stringify(restOptions) : '';
  const key = endpoint + optionsString;

  if (invalidate && cache[key]) {
    delete cache[key];
  }

  useEffect(() => {
     const fetchData = async () => {
      if (cache[key]) {
        setIsFetching(true);

        // the cache is either holding a promise or the result
        const data = await cache[key];

        setData(data);
        setIsFetching(false);
      } else {
        setIsFetching(true);
        // set the cache as the promise/result
        cache[key] = axios.get(endpoint, restOptions)
              .then(result => result.data);

        cache[key] = await cache[key];
        setData(cache[key]);
        setIsFetching(false);
      }
    }

    endpoint && fetchData();
    !endpoint && setData(undefined);
  }, [cache[key], endpoint, invalidate, optionsString]);

  return { data, isFetching };
}

export default useCachedData;
