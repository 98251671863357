import NavList from '../SideNav/NavList';
import NavListItem from '../SideNav/NavListItem';

const ContactsSideNav = ({ groupedContacts, selectedGroup, setSelectedGroup }) => {
  return (
    <NavList>
      {groupedContacts ? Object.keys(groupedContacts).sort().map(group => (
        <NavListItem key={group}
          onClick={() => setSelectedGroup(group)}
          isActive={selectedGroup === group}>
          {group}
        </NavListItem>
      )) : null}      
    </NavList>
  );
}

export default ContactsSideNav;