import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from '../../utils/sharedAxios';
import ContentView from '../ContentView';
import ReviewModal from './ReviewModal';
import ReviewCenterHeader from './ReviewCenterHeader';
import ReviewCenterSideNav from './ReviewCenterSideNav';
import ReviewCenterContent from './ReviewCenterContent';
import GenerateMaterials from '../GenerateMaterials';
import Spinner from '../Spinner';
import useDocumentTitle from '../hooks/useDocumentTitle';
import useRedirectUnauthorizedUsers from '../hooks/useRedirectUnauthorizedUsers';
import {usePdf} from '../Pdf/PDFContext'

// Main view for Reviews and Generate Materials
const ReviewCenter = () => {
  useDocumentTitle('Review Center');
  useRedirectUnauthorizedUsers({ authorizedRoles: 'SAM' });
  const { pathname, state: locationState = {} } = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const {generateReviewPdf} = usePdf()
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const [reviews, setReviews] = useState(null);
  const [nextReviewDate, setNextReviewDate] = useState(null);
  const [totalActions, setTotalActions] = useState(0);
  const [selectedStep, setSelectedStep] = useState(null);
  const [selectedReview, setSelectedReview] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [modalInitialValues, setModalInitialValues] = useState(null);
  const [updatedReviewId, setUpdatedReviewId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [reviewIsDetermination, setReviewIsDetermination] = useState(false);
  const [hasRendered, setHasRendered] = useState(false);
  const [showGenerateMaterialsView, setShowGenerateMaterialsView] = useState(false);

  const getAllReviews = async () => {
    setIsLoading(true);
    const result = await axios.get('/reviews/all');
    const { data: allReviews } = result;
    const dates = [];
    let numActions = 0;

    for (let step in allReviews) {
      if (allReviews[step].future.length) {
        const date = allReviews[step].future[0];
        date && dates.push(date);
        numActions += allReviews[step].future.length;
      }
    }

    const sortedDates = dates.sort((a, b) => {
      if (a.date < b.date) {
        return -1;
      }
      if (b.date < a.date) {
        return +1;
      }
      return 0;
    })

    const nextDate = sortedDates[0]?.date;
    nextDate && setNextReviewDate(nextDate);
    setTotalActions(numActions);
    await setReviews(allReviews);
    setIsLoading(false);
  }

  const getFirstReviewOfStep = (allReviews, step) => {
    if (allReviews[step].past.length) {
      return allReviews[step].past[0];
    }
    if (allReviews[step].future.length) {
      return allReviews[step].future[0];
    }

    return null;
  }

  const setFirstAvailableStepAndReview = () => {
    let preSelectedStep = null;
    let preSelectedReview = null;

    for (let step in reviews) {
      if (!preSelectedStep && (reviews[step].past.length || reviews[step].future.length)) {
        preSelectedStep = parseInt(step, 10);

        preSelectedReview = getFirstReviewOfStep(reviews, step);
      }
    }

    setSelectedStep(preSelectedStep);
    setSelectedReview(preSelectedReview);
  }

  // modal actions
  const onModalClose = () => {
    setShowModal(false);
    setModalTitle('');
    setModalInitialValues(null);
  }

  const onModalSuccess = async (step, reviewId) => {
    await getAllReviews();
    step && setSelectedStep(step);
    setUpdatedReviewId(reviewId);
  }

  const getModalInitialValues = () => ({
    id: selectedReview.reviewId,
    step: selectedReview.step,
    date: selectedReview.date,
    bars: selectedReview.bars?.map(({ barId, phase }) => ({ barId, phase })) || [],
  });

  const scheduleReview = () => {
    setModalTitle('Schedule a Review');
    setShowModal(true);
  }

  const changeDate = () => {
    setModalTitle('Change Date');
    setModalInitialValues(getModalInitialValues());
    setShowModal(true);
  }

  const addBasingAction = () => {
    setModalTitle('Add a Basing Action');
    setModalInitialValues(getModalInitialValues());
    setShowModal(true);
  }

  // stepnav actions
  const changeSelectedStep = (n) => {
    updatedReviewId && setUpdatedReviewId(null);
    setSelectedStep(n);
  }

  // main content action
  const onDeterminationSuccess = async (reviewId) => {
    await getAllReviews();
    setUpdatedReviewId(reviewId);
  }

  const deleteReview = async (reviewId) => {
    await axios.get(`/reviews/remove/${reviewId}`)
    await getAllReviews();
  }

  const removeBar = async (reviewId, reviewPackageBarId) => {
    await axios.get(`/reviews/remove-bar/${reviewId}/${reviewPackageBarId}`);
    await getAllReviews();
    setUpdatedReviewId(reviewId);
  }

  // history
  const goToGenerateMaterials = () => {
    history.push(history.location.pathname, { showGenerateMaterials: true });
  }

  // effects
  useEffect(() => {
    setHasRendered(true);
    getAllReviews();
  }, []);

  useEffect(() => {
    let review = null;

    if (updatedReviewId) {
      // same review
      if (reviews[selectedStep].past.length) {
        review = reviews[selectedStep].past.find(rev => rev.reviewId === updatedReviewId);
      }
      if (!review && reviews[selectedStep].future.length) {
        review = reviews[selectedStep].future.find(rev => rev.reviewId === updatedReviewId);
      }
    }
      // try and stay in the same step
      if (!review && selectedStep) {
        review = getFirstReviewOfStep(reviews, selectedStep);
      }

      // just get the earliest review
      if (!review) {
        setFirstAvailableStepAndReview();
      }

    review && setSelectedReview(review);
  }, [updatedReviewId, reviews, selectedStep]);

  // decide if the current review is a determination
  useEffect(() => {
    if (reviews && selectedReview && selectedStep) {
      const any = reviews[selectedStep].past.some(review => review.reviewId === selectedReview.reviewId);
      setReviewIsDetermination(any);
    }
  }, [reviews, selectedReview, selectedStep])

  useEffect(() => {
    if (!hasRendered && locationState.showGenerateMaterials) {
      history.replace(pathname, {});
      setShowGenerateMaterialsView(false);
    }

    if (hasRendered) {
      setShowGenerateMaterialsView(locationState.showGenerateMaterials);
    }
  }, [hasRendered, locationState])

  const generatePdf = async () => {
    const success = await generateReviewPdf(selectedReview)
    //check if user hasn't navigated away
    if(history.location.pathname.includes('/review-center') && success){
      const reviewId = selectedReview.reviewId;
      await getAllReviews();
      setUpdatedReviewId(reviewId);
      setShowGenerateMaterialsView(false);
    }
  }

  if (selectedReview && showGenerateMaterialsView) {
    return <>
      <GenerateMaterials review={selectedReview} reviews={reviews} generatePdf={generatePdf} />
    </>
  }

  return (
    <>
      <ContentView
        headerContent={<ReviewCenterHeader
          changeSelectedStep={changeSelectedStep}
          nextReviewDate={nextReviewDate}
          reviews={reviews}
          scheduleReview={scheduleReview}
          selectedStep={selectedStep}
          totalActions={totalActions}
        />}
        sidebarContent={<ReviewCenterSideNav
          reviews={reviews}
          selectedStep={selectedStep}
          selectedReview={selectedReview}
          setSelectedReview={setSelectedReview} />
        }
        mainContent={<ReviewCenterContent
          changeDate={changeDate}
          deleteReview={deleteReview}
          addBasingAction={addBasingAction}
          onDeterminationSuccess={onDeterminationSuccess}
          review={selectedReview}
          reviewIsDetermination={reviewIsDetermination}
          removeBar={removeBar}
          isGeneratingPdf={isGeneratingPdf}
          setIsGeneratingPdf={setIsGeneratingPdf}
          goToGenerateMaterials={goToGenerateMaterials}
          generatePdf={generatePdf}
          isLoading={isLoading} />
        }
      />
      {showModal && <ReviewModal
        onClose={onModalClose}
        onSuccess={onModalSuccess}
        title={modalTitle}
        initialValues={modalInitialValues} />}
      <Spinner on={isLoading}/>
    </>
  )
}

export default ReviewCenter;