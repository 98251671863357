const hasValue = (value) => {
  // falsey, but not a user-defined falsey
  if (!value && [0, false].indexOf(value) === -1) {
    return false;
  }

  // empty array
  if (value && Array.isArray(value) && value.length === 0) {
    return false;
  }

  // empty object
  if (value && typeof value === 'object' && Object.entries(value).length === 0) {
    return false;
  }

  return true;
}

export default hasValue;
