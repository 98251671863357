import React from 'react';
import Modal from '../Modal';
import ModalFooter from '../Modal/ModalFooter';
import Button from '../buttons/Button';
import styles from './ReviewCenter.module.scss';
import Anchor from '../Anchor';
import Download from '../svg/Download';

const PDFModal = ({ success, onClose }) => {

  return (
    <>
      <Modal className={styles.reviewModal} onClose={onClose} title={success ? 'Review Materials Generated' : 'Review Materials Not Generated'}>
        <div>
          {success && success.uuid ?
            <div>
              <div className={styles.pdfModalSpacer}>Read-Ahead Materials are accessible only to SAF Action Officers, unless they have been published on the Review page. Publish / Unpublish to grant / revoke access to the materials from the Dashboard of all SABER users.</div>
              <Anchor href={`${process.env.REACT_APP_API_URL}/documents/download?${new URLSearchParams({ docId: success.uuid, fileName: success.originalFileName }).toString()}`} download>{success.originalFileName} <Download className={styles.modalDownload} /></Anchor>
            </div>
            : 'Failed to generate the requested materials, please try again later. If this problem persists, please contact support'}
        </div>
        <ModalFooter className={styles.buttonRow}>
          <Button primary onClick={onClose}>CLOSE</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default PDFModal;