import React from 'react';
import { useField } from 'react-final-form';
import CheckBoxOutlineBlankSharp from '@material-ui/icons/CheckBoxOutlineBlankSharp'
import CheckBoxSharp from '@material-ui/icons/CheckBoxSharp'
import { dictionaryValue } from '../Dictionary/DictionaryContext';
import cn from 'classnames';
import styles from './Form.module.scss';

const CheckboxField = ({ checked, labelText, name, noneOfTheAbove, onChange, value }) => {
  // a bit of extra stuff here to handle "none of the above" instances.
  const type = noneOfTheAbove ? null : 'checkbox';
  const { input } = useField(name, { type, value });
  const isChecked = checked || input.checked;
  const handleChange = onChange || input.onChange;

  return (
    <label className={cn(styles.checkboxLabel)}>
      {isChecked ? <CheckBoxSharp className={styles.checkboxIcon} /> : <CheckBoxOutlineBlankSharp className={styles.checkboxIcon} />}
      <input className={styles.checkboxInput} {...input} checked={isChecked} onClick={handleChange}/>
      <span className={styles.checkboxLabelText}>{labelText || dictionaryValue(value)}</span>
    </label>
  );
}

export default CheckboxField;
