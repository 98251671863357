import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import Button from '../buttons/Button';
import Modal from '../Modal'
import ModalFooter from '../Modal/ModalFooter';
import { SessionContext } from '../Session';
import styles from './TimeOutModal.module.scss';

let secondsInterval;

// indicates when a user is inactive for too long or has been signed out due to inactivity
const TimeOutModal = () => {
  const { expired, warning, expirationDate } = useContext(SessionContext);
  const [secondsRemaining, setSecondsRemaining]= useState(-Infinity); // using 0 as falsey causes rendering issues when timer hits 0
  const showModal = expired || (secondsRemaining > -Infinity && warning);

  const expiredTitle = 'Session Expired';
  const expiredContent = '';
  const expiredButton = 'Restore Session';

  const timeRemaining = secondsRemaining > 60 ? `${Math.ceil(secondsRemaining / 60)} minutes` : `${secondsRemaining} seconds`;
  const warningTitle = `You will be logged out of SABER in ${timeRemaining}`;
  const warningContent = 'Due to inactivity, you are about to be logged out. Any unsaved edits will be lost';
  const warningButton = 'Keep Me Logged In';

  const title = expired ? expiredTitle : warningTitle;
  const content = expired ? expiredContent : warningContent;
  const buttonText = expired ? expiredButton : warningButton;

  const onClick = async () => {
    if (expired) {
      window.location.assign('/');
    } else if (warning) {
      await axios.get('/auth/extendSession');
    }
  }

  const calculateSeconds = () => {
    const now = new Date();
    const seconds = Math.floor((expirationDate - now.valueOf()) / 1000);
    setSecondsRemaining(seconds);
  }


  const startSecondsInterval = () => {
    clearInterval(secondsInterval);
    calculateSeconds();
    secondsInterval = setInterval(() => {
      calculateSeconds();
    }, 1000);
  }

  useEffect(() => {
    if (expired || !warning) {
      clearInterval(secondsInterval);
    }
    if (!expired && warning && expirationDate) {
      startSecondsInterval();
    }
  }, [expirationDate, expired, warning])

  return showModal && (
    <Modal className={styles.modal} title={title} centered={!content}>
      {content}
      <ModalFooter>
        <Button primary onClick={onClick}>{buttonText}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default TimeOutModal;