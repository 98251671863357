import React from 'react';
import DateField from '../../Form/DateField';
import Label from '../../Form/Label';
import BaseSelector from '../candidateDecision/BaseSelector';

const FinalDecisionEdit = () => {
    return (
        <>
            <Label text='Approved On' required />
            <DateField name='finalDecisionApprovalDate' />
            <BaseSelector name='finalDecisionBases' disabledSelectStatus />
        </>
    );
}

export default FinalDecisionEdit;
