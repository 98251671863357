import useCachedData from "./hooks/useCachedData"
import ROLES from '../constants/roles';

const DisplayUserRole = ({ roleId }) => {
  const { data: roles } = useCachedData('/users/roles');
  const foundRole = roles ? roles.find(role => role.id === roleId) : null;

  if (ROLES && foundRole) {
    const ROLE = ROLES[foundRole.name];
    return ROLE.label;
  }

  return null;
}

export default DisplayUserRole;