import { Form } from "react-final-form";
import Header from "../Header";
import SelectField from '../Form/SelectField';
import Label from "../Form/Label";
import styles from './Resources.module.scss';
import Button from "../buttons/Button";
import SubTitle from "../Header/SubTitle";
import AuthorizedContent from "../AuthorizedContent";

const ResourcesHeader = ({ setSelectedFilter, setShowUploadModal }) => {
  return (
    <Header
      leftContent={
        <>
          <h1>Resources</h1>
          <SubTitle>Find Documents & Templates</SubTitle>
        </>
      }
      rightContent={
        <AuthorizedContent authorizedRoles='sam'><Button onClick={() => setShowUploadModal(true)} primaryAlt>Upload</Button></AuthorizedContent>
      }
    >
      <Form onSubmit={() => true} initialValues={{ filter: '-' }}>
        {() => (
          <div className={styles.filter}>
            <Label text='Filter By:' />
            <SelectField
              className={styles.filterSelect}
              name='filter'
              fieldDataEndpoint='/content/resourceTypes'
              isClearable={false}
              onChange={setSelectedFilter} />
          </div>
        )}
      </Form>
    </Header>
  );
}

export default ResourcesHeader;