import React from 'react';
import cn from 'classnames';
import styles from './NavListItem.module.scss';

const NavListItem = ({ children, className, onClick, icon, isActive }) => {
  return (
    <li>
      <button className={cn(styles.navListItem, className)}
        type='button'
        onClick={onClick}>
          {isActive && <span className={styles.active} />}
          <span className={styles.title}>{children}</span>
          {icon}
      </button>
    </li>
  );
}

export default NavListItem;
