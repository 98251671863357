import React from 'react';
import { useFormState } from 'react-final-form';
import cn from 'classnames';
import useCachedData from '../../hooks/useCachedData';
import styles from './EnterpriseWideLook.module.scss';
import formStyles from '../../Form/Form.module.scss';
import _ from 'lodash';

const EnterpriseWideLookDisplay = () => {
  const { initialValues } = useFormState({ subscription: { initialValues: true } });
  const missionFileKey = _.get(initialValues, 'enterpriseComponents.mission.fileKey');
  const capacityFileKey = _.get(initialValues, 'enterpriseComponents.capacity.fileKey');
  const environmentalFileKey = _.get(initialValues, 'enterpriseComponents.environmental.fileKey');
  const costFileKey = _.get(initialValues, 'enterpriseComponents.cost.fileKey');
  const somfFileKey = _.get(initialValues, 'enterpriseComponents.somf.fileKey');
  
  const { data: missionSrc } = useCachedData(missionFileKey && '/documents/image', {params: { docId: missionFileKey } });
  const { data: capacitySrc } = useCachedData(capacityFileKey && '/documents/image', {params: { docId: capacityFileKey } });
  const { data: environmentalSrc } = useCachedData(environmentalFileKey && '/documents/image', {params: { docId: environmentalFileKey } });
  const { data: costSrc } = useCachedData(costFileKey && '/documents/image', {params: { docId: costFileKey } });
  const { data: somfSrc } = useCachedData(somfFileKey && '/documents/image', {params: { docId: somfFileKey } });

  return (
    <>
      <h3 className={cn(styles.headers, formStyles.sectionHeading)}>Mission</h3>
      {missionSrc && (
        <>
          <img src={missionSrc} />
        </>
      )}
      <h3 className={cn(styles.headers, formStyles.sectionHeading)}>Capacity</h3>
      {capacitySrc && (
        <>
          <img src={capacitySrc} />
        </>
      )}
      <h3 className={cn(styles.headers, formStyles.sectionHeading)}>Environmental</h3>
      {environmentalSrc && (
        <>
          <img src={environmentalSrc} />
        </>
      )}
      <h3 className={cn(styles.headers, formStyles.sectionHeading)}>Cost</h3>
      {costSrc && (
        <>
          <img src={costSrc} />
        </>
      )}
      <h3 className={cn(styles.headers, formStyles.sectionHeading)}>Support of Military Families</h3>
      {somfSrc && (
        <>
          <img src={somfSrc} />
        </>
      )}
      

    </>
  );
}

export default EnterpriseWideLookDisplay;
