import React from 'react';
import { useFormState } from 'react-final-form';
import { dictionaryValue } from '../../Dictionary/DictionaryContext'
import RichTextDisplay from '../../Form/RichTextDisplay';
import styles from '../../Form/Form.module.scss';
import ReadOnlyFacilityField from '../../Form/ReadOnlyFacilityField';
const formatTimingString =  (quarter,year) => {
  quarter = quarter === '0' ? 'CLASS' : quarter;
  year === '0' ? 'CLASS' : year;
  return `${quarter || ''}QFY${year || ''}`
}
const RequirementsDisplay = () => {
  const { initialValues = {} } = useFormState({ subscription: { initialValues: true } });
  const {
    costRequirements,
    facilityRequirements,
    missionRequirements,
    personnelRequirement,
    timeRequirements,
  } = initialValues;

  const showMissionRequirements = (missionRequirements && missionRequirements.some(item => item.requirement));
  const showFacilityRequirements = (facilityRequirements && facilityRequirements.length > 0);
  const showOneTimeCosts = costRequirements?.oneTime?.length > 0;
  const showRecurringCosts = costRequirements?.recurring?.length > 0;
  const showCustomCosts = costRequirements?.custom?.length > 0;
  const showTiming = timeRequirements && timeRequirements.some(item => (item.quarter || item.fiscalYear || item.definition));

  return (
    <>
      {showMissionRequirements && (
        <>
          <h3 className={styles.sectionHeading}>Mission</h3>
          <ul>
            {missionRequirements.map(({ requirement, subRequirements }, index) => (
              <li key={index}>
                <span>{requirement}</span>
                {subRequirements && (
                  <ul>
                    {subRequirements.map(({ subRequirement }, index) => (
                      <li key={index}>{subRequirement}</li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </>
      )}

      {showFacilityRequirements && (
        <>
          <h3 className={styles.sectionHeading}>Facilities</h3>
          <ul>
            {facilityRequirements.map((facility, index) => (
              <li key={index}>
                <ReadOnlyFacilityField name={`facilityRequirements[${index}]`} />
              </li>
            ))}
          </ul>
        </>
      )}

      {personnelRequirement?.description?.length > 0 && (
        <>
          <h3 className={styles.sectionHeading}>Personnel</h3>
          <RichTextDisplay html={personnelRequirement.description} />
        </>
      )}

      {(showOneTimeCosts || showRecurringCosts || showCustomCosts) && (
        <>
          <h3 className={styles.sectionHeading}>Costs</h3>

          {showOneTimeCosts && (
            <>
              <p>One-Time Costs</p>
              <ul>
                {costRequirements?.oneTime && costRequirements.oneTime.map((item, index) => (
                  <li key={index}>{dictionaryValue(item.cost)}</li>
                ))}
              </ul>
            </>
          )}

          {showRecurringCosts && (
            <>
              <p>Recurring Costs</p>
              <ul>
                {costRequirements?.recurring && costRequirements.recurring.map((item, index) => (
                  <li key={index}>{dictionaryValue(item.cost)}</li>
                ))}
              </ul>
            </>
          )}
          {showCustomCosts && (
            <>
              <p>Additional Costs</p>
              <ul>
                {costRequirements?.custom && costRequirements.custom.map((item, index) => (
                  <li key={index}>{dictionaryValue(item.cost)} ({item.isRecurring ? 'Recurring' : 'One-Time'})</li>
                ))}
              </ul>
            </>
          )}
        </>
      )}


      {showTiming && (
        <>
          <h3 className={styles.sectionHeading}>Timing</h3>
          {timeRequirements.map(({ capability, definition, fiscalYear, quarter }, index) => (
            (definition || fiscalYear || quarter) ? (
              <div key={index}>
                <p>{capability === 'IOC' ? 'Initial Operational Capability (IOC)' : 'Full Operational Capability (FOC)'}</p>
                <ul>
                  <li>
                    <span>{formatTimingString(quarter,fiscalYear)}: </span>
                    {definition}
                  </li>
                </ul>
              </div>
            ) : null
          ))}
        </>
      )}

    </>
  )
}

export default RequirementsDisplay;
