import React from 'react';
import { useField } from 'react-final-form';
import TextField from '../../Form/TextField';
import Grid from '../../Grid';
import Box from '../../Box';
import styles from './RiskAssessmentCriteria.module.scss';
import FacilityDisplay from '../FacilityDisplay';

const RiskAssessmentCriteriaEdit = () => {
  const { input: siteSurveyRequirements } = useField('siteSurveyRequirements');
  const templateColumns = [2, '4fr 1fr'];

  const renderLabelRow = (label) => (
    <div className={styles.labelRow}>
      <Box><h3>{label}</h3></Box>
      <Box>Risk Level</Box>
    </div>
  );

  const renderRisks = (prefix) => {
    return (
      <Grid columns={templateColumns}>
        <Box><TextField name={`${prefix}.optimalValue`} maxLength={200} /></Box>
        <Box>Low</Box>
        <Box><TextField name={`${prefix}.suitableValue`} maxLength={200} /></Box>
        <Box>Medium</Box>
        <Box><TextField name={`${prefix}.limitsValue`} maxLength={200} /></Box>
        <Box>High</Box>
      </Grid>
    );
  }

  const renderRequirement = ({ id: requirementId, subRequirements = [], ...props }, prefix, displayAsFacility) => {
    const displayRequirement = props.requirement?.label || props.requirement;

    if (!displayRequirement) {
      return null;
    }

    return (
      <Grid key={requirementId}>
        <p className={styles.requirementLabel}>{displayAsFacility ? <FacilityDisplay {...props} facilityKey='requirement' /> : displayRequirement}</p>

        {subRequirements.length ? (
          subRequirements.map((subRequirement, index) => {
            return renderRequirement(subRequirement, `${prefix}.subRequirements[${index}]`);
          })
        ) : (
          renderRisks(prefix)
        )}
      </Grid>
    );
  };

  const renderRequirements = (type, displayAsFacility) => {
    const requirements = siteSurveyRequirements.value[type];


    if (requirements) {
      return requirements.map((requirement, index) => {
        const prefix = `siteSurveyRequirements[${type}][${index}]`;
        return renderRequirement(requirement, prefix, displayAsFacility);
      });
    }

    return null;
  };

  return (
    <>
      {renderLabelRow('Mission')}
      {renderRequirements('mission')}

      {renderLabelRow('Joint and All Domain Training')}
      {renderRequirements('jointAndAllDomainTraining')}

      {renderLabelRow('Airspace and Training Areas')}
      {renderRequirements('airspaceAndTrainingAreas')}

      {renderLabelRow('Capacity')}
      {renderRequirements('facility', true)}
      {renderRequirements('baseSupportTail')}
      {renderRequirements('communitySupport')}

      {renderLabelRow('Environmental')}
      {renderRequirements('environmental')}

      {renderLabelRow('Military Family Readiness')}
      {renderRequirements('militaryFamilyReadiness')}
      {renderRequirements('supportOfMilitaryFamilies')}

      {renderLabelRow('Cost')}
      {renderRequirements('costOneTime')}
      {renderRequirements('costRecurring')}
      {renderRequirements('costAdditional')}
    </>
  );
}

export default RiskAssessmentCriteriaEdit;
