import React from 'react';
import styles from './BarLanding.module.scss';
import cn from 'classnames';

const TopMatrixCell = ({ className, isSelected, otherSelected, total, onClick }) => {
  return (
    <div className={cn(styles.phaseStepCell, className, {
      [styles.phaseStepCellEmpty]: total === 0,
      [styles.phaseStepCellSelected]: total && isSelected,
      [styles.phaseStepCellNotSelected]: total && otherSelected
    })} onClick={() => onClick()}>
      <div className={styles.phaseCellCount}>
        <span className={styles.phaseCellTotal}>{total}</span>
        <span className={styles.phaseCellUnit}>Basing<br/>Actions</span>
      </div>
    </div>
  );
}

export default TopMatrixCell;
